import { useEffect } from 'react';

export const useOutlineControl = () => {
  const handleTab = ({ keyCode }) => {
    if (keyCode === 9) {
      document.documentElement.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', handleTab);
      window.addEventListener('mousedown', handleMouse);
    }
  };

  const handleMouse = () => {
    document.documentElement.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouse);
    window.addEventListener('keydown', handleTab);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleTab);

    return () => {
      window.removeEventListener('keydown', handleTab);
      window.removeEventListener('mousedown', handleMouse);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
