import React from 'react';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { withContainer } from './components/input-field';

const Input = withContainer(ButtonWithIcon);

export const QuestionButtonInput = React.memo(({ label, buttonType, onSubmit, loading }) => (
  <Input onClick={onSubmit} type={buttonType} buttonType='submit' disabled={loading}>
    {label}
  </Input>
));
