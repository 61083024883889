import { withProps } from 'recompose';
import { insertVarIntoString } from '../../../helpers/insert-var-into-string';
import { generatePropBasedOnIdentifier } from '../helpers/generate-prop-based-on-identifier';

export const withNextPage = withProps(({ data, identifier }) => {
  const { breakReusable, nextPageLink } = data;

  if (!nextPageLink) {
    return;
  }

  //breakReusable used for braking a chain of reusable questions
  const nextLink =
    !breakReusable && identifier && !nextPageLink.includes('done') ? `${nextPageLink}/${identifier}` : nextPageLink;

  return {
    data: {
      ...data,
      nextPageLink: insertVarIntoString(nextLink, { identifier, ...generatePropBasedOnIdentifier(identifier) })
    }
  };
});
