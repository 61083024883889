import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { JOB_DETAILS } from '../helpers/navigation';

const JobDetails = Loadable({
  loader: () => import(/* webpackChunkName: "jobDetails"*/ '../pages/job-details').then(module => module.JobDetails),
  loading: Loader
});

export const jobDetailsPageRoutes = [
  {
    to: '/job-details/:slug',
    component: asTemplate(JOB_DETAILS)(JobDetails)
  }
];
