import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../../../styles/components/page-action.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { Link } from '../../link';
import { useCloseButton } from '../../layout/application-header/components/close-button';

const PageAction = React.memo(({ children, to, iconName, type, className, onClick, isButton, ariaLabel }) => {
  const defaultTo = useCloseButton();

  return (
    <Link
      to={to ?? defaultTo}
      onClick={onClick}
      isButton={isButton}
      ariaLabel={ariaLabel}
      className={classnames(styles.button, styles[type], className)}
    >
      {children ? <span className={styles.label}>{children}</span> : null}
      {iconName ? <AffiliateIcon name={iconName} className={styles.icon} /> : null}
    </Link>
  );
});

export const pageActionTypes = ['major', 'minor', 'negative', 'destructive', 'special', 'diminished'];

PageAction.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isButton: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(pageActionTypes),
  ariaLabel: PropTypes.string
};

PageAction.displayName = 'PageAction';

export { PageAction };
