import React from 'react';
import { compose } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { CalloutBlock } from '../../../components/callout';
import { sanitizeString } from '../../sanitize';
import { isHtmlElement } from '../../layout/element';
import { getAffiliateIconSync } from '../../layout/get-afiliate-icon';
import { wrap } from '../../formatters/string-format';
import { getElementFromHtmlString } from './helpers';

const ALLOWED_DESC_TAGS = ['p', 'ul'];

/**
 * This helper replaces the callout block from the passed html doc with the callout component
 * @param element - html doc from WP
 * @return {*}
 */
export const changeCallout = element => {
  const elements = element.querySelectorAll('.callout');

  if (elements.length) {
    elements.forEach(callout => {
      if (!isHtmlElement(callout)) {
        return;
      }

      const heading = callout.querySelector('.wp-block-heading');

      const description = [...callout.children]
        .filter(child => ALLOWED_DESC_TAGS.includes(child.tagName.toLowerCase()))
        .map(child => {
          return wrap(child.innerHTML, child.tagName.toLowerCase());
        });

      const jsx = (
        <CalloutBlock
          icon={getAffiliateIconSync('callout')}
          heading={sanitizeString(heading)}
          description={description.join('')}
        />
      );

      const component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      callout.parentNode.replaceChild(component, callout);
    });
  }

  return element;
};
