import { connect } from 'react-redux';
import { compose } from 'redux';
import { branch, lifecycle, renderNothing, withHandlers, withState } from 'recompose';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import { evaluateCondition } from '../parcers/parce-condition';

const mapStateToProps = ({ userAttributes, statusData: { plainStatuses }, points }) => ({
  userData: { ...userAttributes?.user, ...points },
  userAttributes,
  plainStatuses
});

export const withDisplayCondition = compose(
  connect(mapStateToProps),
  withState('isWidgetHidden', 'setHide', ({ displayCondition }) => Boolean(displayCondition)),
  withCancellableHandlers({
    evaluateConditionCancellable: () => async (userState, displayCondition) =>
      await evaluateCondition(userState, displayCondition)
  }),
  withHandlers({
    checkDisplayCondition:
      ({ setHide, displayCondition, userAttributes, plainStatuses, userData, evaluateConditionCancellable }) =>
      async () => {
        const userState = { ...userAttributes, activity: plainStatuses, user: userData };
        const shouldBeVisible = await evaluateConditionCancellable(userState, displayCondition);

        setHide(!shouldBeVisible);
      }
  }),
  lifecycle({
    componentDidMount() {
      const { displayCondition, checkDisplayCondition } = this.props;

      if (displayCondition) {
        checkDisplayCondition();
      }
    },
    componentDidUpdate(prevProps) {
      const { displayCondition, checkDisplayCondition, userAttributes } = this.props;

      if (displayCondition && userAttributes !== prevProps.userAttributes) {
        checkDisplayCondition();
      }
    }
  }),
  branch(({ isWidgetHidden }) => isWidgetHidden, renderNothing)
);
