// TODO: Consider to make it multi-dimensional
export const getTwoDimensionalArrayIterator = function* getTwoDimensionalArrayIterator(target, key = '') {
  if (!target) return [];

  let iterable = [...target],
    buffer = [];

  do {
    // fill the buffer until get enough chunks to run main loop or iterable is empty
    while (iterable.length > 0 && buffer.length < 2) {
      buffer = buffer.concat(iterable.shift()[key] ?? []);
    }

    yield buffer.shift();
  } while (buffer.length);
};

/**
 * Sort array of objects by weight property from lowest to highest.
 * @param {array} array - list of items to sort
 * @return {array}
 */
export const sortByWeight = array => {
  if (!Array.isArray(array)) {
    // Return an empty array or handle the case when the input is not an array
    return [];
  }

  // Create a shallow copy of the array to avoid mutating the original array
  return [...array].sort((x, y) => {
    // Ensure the items have a weight property that is a number
    const weightX = typeof x.weight === 'number' ? x.weight : 0;
    const weightY = typeof y.weight === 'number' ? y.weight : 0;

    return weightX - weightY;
  });
};
