import { parse } from 'query-string';
import { isNil } from '@packages/helpers/core/common';

export const isDataSharingAllowed = search => {
  const { utm_medium, dataSharing } = parse(search, { parseBooleans: true });
  const isWebview = utm_medium === 'ios' || utm_medium === 'android';

  if (!isNil(dataSharing) && !dataSharing && isWebview) {
    return false;
  }

  return true;
};
