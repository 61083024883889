import { useRef, useEffect, EventHandler } from 'react';
import { IS_ADD_EVENT_LISTENER_SUPPORTED } from '@packages/helpers/core/common/event';

export const useEventListener = (
  eventName: keyof WindowEventMap,
  handler: EventHandler<any>,
  target: Window = window
): void => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const savedHandler = useRef<EventHandler<any>>(() => {});

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (IS_ADD_EVENT_LISTENER_SUPPORTED(target)) {
      const eventListener = (event: Event) => savedHandler.current(event);

      target.addEventListener(eventName, eventListener);

      return () => {
        target.removeEventListener(eventName, eventListener);
      };
    }

    return;
  }, [eventName, target]);
};
