import { mapProps } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';

export const omitProps = (constraints = [], mapper) =>
  mapProps(props =>
    ObjectShim.omit(
      FunctionShim.isFunction(mapper)
        ? {
            ...props,
            ...mapper(props)
          }
        : props,
      constraints
    )
  );
