import React from 'react';
import PropTypes from 'prop-types';
import { branch, componentFromProp, compose, mapProps, renderNothing } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { sortByWeight } from '../../../helpers/array';
import { DigitalCoach } from './digital-coach';
import { VideoFeedback } from './feedback';

const defaultWidgets = {
  Feedback: {
    name: 'Feedback'
  },
  DigitalCoach: {
    name: 'DigitalCoach'
  }
};

const widgets = {
  Feedback: VideoFeedback,
  DigitalCoach: DigitalCoach
};

export const WidgetComponent = compose(
  mapProps(props => ({ ...props, component: widgets[props.widget] })),
  branch(({ component }) => !component, renderNothing)
)(componentFromProp('component'));

const Component = ({ widgets, ...rest }) => (
  <>
    {widgets.map(widget => (
      <WidgetComponent key={widget.name} widget={widget.name} {...rest} />
    ))}
  </>
);

export const VideoWidgets = compose(
  mapProps(props => {
    // This is needed to keep existing Feedback config to work within the new structure
    const widgetsToSort = !ObjectShim.isEmpty(props.widgets) ? props.widgets : defaultWidgets;
    const widgets = sortByWeight(Object.values(widgetsToSort));

    return { ...props, widgets };
  })
)(Component);

VideoWidgets.displayName = 'VideoWidgets';

VideoWidgets.propTypes = {
  widgets: PropTypes.shape({
    [PropTypes.oneOf(['Feedback', 'DigitalCoach'])]: PropTypes.shape({
      name: PropTypes.string,
      weight: PropTypes.number
    })
  })
};
