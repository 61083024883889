import React from 'react';
import { useSelector } from 'react-redux';
import { affiliate } from '../../../../helpers/main';
import { isVisible } from '../../with-layout';
import { Link } from '../../../link';
import { Image } from '../../../images/image';
import { ConfigService } from '../../../../services/config-service';
import styles from '../../../../styles/components/application-header.module.scss';

const Component = React.memo(({ className }) => (
  <div className={styles.wrapper}>
    <Logo className={className} />
  </div>
));

export const PureLogo = React.memo(({ className }) => {
  const {
    logoUrl,
    logoAlt = `${affiliate} site logotype`,
    logoLink,
    linkAriaLabel
  } = ConfigService.get('NAVIGATION_BAR', {});

  return (
    <LogoWrapper className={className} logoLink={logoLink} ariaLabel={linkAriaLabel}>
      <Image src={logoUrl} title={logoAlt} />
    </LogoWrapper>
  );
});

export const LogoWrapper = React.memo(({ className, logoLink, ariaLabel, children }) => {
  const disableNavigation = useSelector(state => state.navigation.disableNavigation);

  return disableNavigation ? (
    <div className={className}>{children}</div>
  ) : (
    <Link className={className} ariaLabel={ariaLabel} to={logoLink}>
      {children}
    </Link>
  );
});

export const AppLogo = isVisible({
  name: 'appLogo'
})(Component);

export const Logo = isVisible({
  name: 'appLogo'
})(PureLogo);
