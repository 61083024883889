import { parse } from 'query-string';
import { createCustomPageProps, getRoutesByPathname } from '../utils';

export const goToComplete = (pathname, redirect, search) => {
  const [pathnameGist] = getRoutesByPathname(pathname);

  const customPageUrl = `/${pathnameGist}/${createCustomPageProps(pathname).name}`;

  switch (redirect) {
    case 'dashboard':
      return '/dashboard';
    case 'custom': {
      const { link } = parse(search);

      return link;
    }
    case 'start':
      return customPageUrl + '/start';
    case 'result':
      return customPageUrl + '/result';
    default:
      return null;
  }
};
