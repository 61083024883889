import Loadable from 'react-loadable';
import { asTemplate } from '../../components/layout/with-layout';
import { Loader } from '../../components/with-loader';
import { ACCOUNT, CHECKPOINT } from '../../helpers/navigation';

const SignInEmail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-in-email"*/ '../../pages/account/pages/sign-in/email').then(
      module => module.SignInEmail
    ),
  loading: Loader
});

const SignInPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-in-password"*/ '../../pages/account/pages/sign-in/password').then(
      module => module.SignInPassword
    ),
  loading: Loader
});

const SignInCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sign-in-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.SignInCompletePage
    ),
  loading: Loader
});

export const routes = [
  {
    to: '/account/login/email',
    component: asTemplate(ACCOUNT)(SignInEmail)
  },
  {
    to: '/account/login/password',
    component: asTemplate(ACCOUNT)(SignInPassword)
  },
  {
    to: '/account/login/complete',
    component: asTemplate(CHECKPOINT)(SignInCompletePage)
  }
];
