import { MutableRefObject, RefCallback, useCallback, useRef } from 'react';

type RefEntity<T> = MutableRefObject<T> | RefCallback<T> | null;
type RefEntities<T> = Array<RefEntity<T>>;

export const useMergeRefs = <T = any>(...refs: RefEntities<T>): RefCallback<T> => {
  const targets = useRef(refs);

  return useCallback(node => {
    targets.current.forEach(ref => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref !== null && node) {
        ref.current = node;
      }
    });
  }, []);
};
