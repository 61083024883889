import { get } from './utils';

const requestResidentialResearch = () => get('/residential-affordability/start');

const checkResidentialResearch = ({ pollJobID, smartrFitSearchId }) =>
  get(`/residential-affordability/results?polJobId=${pollJobID}&smartrFitSearchId=${smartrFitSearchId}`);

export const affordability = {
  requestResidentialResearch,
  checkResidentialResearch
};
