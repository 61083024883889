import { sortByWeight } from '../array';
import { DEFAULT_CATEGORY_KEY, DEFAULT_STATUS_KEY, STATUSES } from './constants';

/**
 * @description Default Category Option
 * @type {CategoryOption}
 */
export const defaultCategory = {
  id: 0,
  label: 'All',
  value: {
    key: DEFAULT_CATEGORY_KEY,
    title: 'All'
  },
  disabled: false
};

/**
 * Category config item type.
 * @typedef {Object} CategoryConfigItem
 * @property {number} weight - Weight
 * @property {string} label - Label
 * @property {string} value - Key
 */

/**
 * Category option type.
 * @typedef {Object} CategoryOption
 * @property {number} id - ID
 * @property {string} label - Label
 * @property {string} value.key - Key
 * @property {string} value.title - Title
 * @property {boolean} disabled - Disabled
 */

/**
 * Transforms plain categories into category options.
 * @param { Array.<String> } categories
 * @returns { Array.<CategoryOption> } array of category options
 */
export const formatFeedCategories = categories => {
  const uniqueCategories = Array.from(new Set(categories));

  return uniqueCategories.map((category, index) => ({
    id: index + 1,
    label: category,
    value: {
      key: category.trim(),
      title: category
    },
    disabled: false
  }));
};

/**
 * @param {object} dataDictionaryCategories
 * @param {object} dataDictionaryCategories.buttons
 * @param {Array<{ to: string, icon: string, label: string }>} dataDictionaryCategories.buttons.data
 * @return {Array<string>} labels
 */
export const formatDataDictionaryCategories = dataDictionaryCategories =>
  dataDictionaryCategories?.buttons.data.map(c => c.label);

/**
 * Transforms plain categories into category options.
 * @param { Array.<CategoryConfigItem> } categories
 * @returns { Array.<CategoryOption> } array of category options
 */
export const getCategories = categories => {
  const formattedCategories = sortByWeight(categories).map(({ label, value, collapsed, displayCondition }, index) => ({
    id: index + 1,
    label: label,
    value: {
      key: value,
      title: label
    },
    displayCondition: displayCondition,
    collapsed: !!collapsed,
    disabled: false
  }));

  return [defaultCategory, ...formattedCategories];
};

/**
 * Combines URL params in appropriate sequence.
 * @param {object} options
 * @param {string} options.savedStatus
 * @param {string} options.category
 * @param {string | undefined} options.contentTypes
 * @return {string} stringify URL params
 */
export const buildFeedParams = ({ savedStatus, category, contentTypes }) => {
  let encodedParams = `?savedStatus=${savedStatus}`;

  // The case where we don't need to provide a category filter overall.
  if (category !== DEFAULT_CATEGORY_KEY) {
    encodedParams += `&categories=${encodeURIComponent(category)}`;
  }

  if (contentTypes) {
    encodedParams += `&contentTypes=${contentTypes}`;
  }

  return encodedParams;
};

export const matchFeedCategories = (categoriesOptions, match) => {
  const foundCategory = categoriesOptions.find(c => c.value.key === decodeURIComponent(match));

  return foundCategory?.value.key || DEFAULT_CATEGORY_KEY;
};

export const matchFeedStatuses = savedStatus => (STATUSES.includes(savedStatus) ? savedStatus : DEFAULT_STATUS_KEY);
