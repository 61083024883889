import { lifecycle } from 'recompose';
import { getScrollXPosition, getScrollYPosition, scrollTo } from '../../../helpers/layout/scroll';

export const withScroll = lifecycle({
  componentDidUpdate(prevProps) {
    const {
      location: { pathname, hash }
    } = this.props;

    const scrollX = getScrollXPosition();
    const scrollY = getScrollYPosition();
    const locationChanged = prevProps.location.pathname !== pathname;

    if (locationChanged && (scrollX || scrollY)) {
      scrollTo(window, { left: 0, top: 0 });
    }

    if (!locationChanged && hash) {
      const anchoredElement = document.querySelector(hash);
      if (!anchoredElement) {
        return;
      }

      const { top } = anchoredElement.getBoundingClientRect();

      scrollTo(window, { top, incremental: true, behavior: 'smooth' });
    }
  }
});
