import React from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { InputGroup } from '../../../../../components/inputs/input-group';
import { InputToggleSwitch as ToggleSwitch } from '../../../../../components/inputs/toggle-switch';
import { sendConsents } from '../../../../../store/reducers/user-attributes/actions';
import { withLoadingHandlers } from '../../../../../components/with-loader';
import { withContainer } from '../../../../../components/inputs/input-field';
import { sortByWeight } from '../../../../../helpers/array';

const ConsentListsComponent = React.memo(
  ({ value, label, consents, handleChange, loading, onChange, attributesConsent }) => (
    <div>
      {label ? <p className='label-s content strong'>{label}</p> : null}
      <InputGroup>
        {consents.map(({ id, title, body, type, locked }) => (
          <ToggleSwitch
            key={id}
            name={id}
            label={title}
            explainer={body}
            locked={locked}
            value={onChange ? value[type] : attributesConsent[type]}
            onChange={handleChange(type)}
            disabled={locked || loading}
          />
        ))}
      </InputGroup>
    </div>
  )
);

export const ConsentLists = compose(
  connect(
    state => ({
      consents: sortByWeight(state.consentList.list),
      attributesConsent: state.userAttributes.consents
    }),
    { sendConsents }
  ),
  withLoadingHandlers(),
  withHandlers({
    handleChange:
      ({ sendConsents, turnOnLoading, turnOffLoading, onChange, value }) =>
      type =>
      async ({ target: { checked } }) => {
        if (onChange) {
          return onChange({ ...value, [type]: checked });
        }

        turnOnLoading();
        await sendConsents({ [type]: checked });
        turnOffLoading();
      }
  }),
  withContainer
)(ConsentListsComponent);
