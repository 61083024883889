import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, lifecycle, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import styles from '../../../styles/widgets/life-score/life-score-dataviz.module.scss';
import { evaluateCondition } from '../../../helpers/parcers/parce-condition';

const Component = React.memo(({ icon = 'info', value, label, left, right }) => (
  <div className={classnames(styles.item, { [styles.left]: left, [styles.right]: right })}>
    <AffiliateIcon className={styles.icon} name={icon} />
    <div className={styles.wording}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </div>
  </div>
));

const mapStateToProps = (state, { valuePath }) => {
  const attrValue = ObjectShim.getNested(state, valuePath, 0);

  // To evaluate whether we need to show value or not
  const {
    userAttributes,
    statusData: { plainStatuses },
    points
  } = state;

  return { attrValue, userState: { userAttributes, plainStatuses, user: points } };
};

const Item = compose(
  connect(mapStateToProps),
  withState('value', 'setValue', null),
  lifecycle({
    async componentDidMount() {
      const { attrValue, setValue, userState, valueCondition } = this.props;

      // Evaluate the value to show it or not
      if (valueCondition) {
        const shouldBeVisible = await evaluateCondition(userState, valueCondition);

        const value = shouldBeVisible ? attrValue : '-';

        return setValue(value);
      }

      return setValue(attrValue);
    }
  })
)(Component);

Item.propTypes = {
  valuePath: PropTypes.string.isRequired,
  valueCondition: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool
};

Item.displayName = 'DatavizItem';

export { Item };
