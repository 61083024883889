import { calculateMaxTotal } from '../../../helpers/future-fit/calculate-max-total';
import { calculatePositiveScore } from '../../../helpers/future-fit/calculate-positive-score';
import { getPositivePursuits } from '../../../helpers/future-fit/get-positive-pursuits';
import { getGoals } from '../../../helpers/future-fit/get-goals';
import { calculateCurrentScore } from '../../../helpers/future-fit/calculate-current-score';

export const generateFFProps = state => {
  const userAttributes = state.userAttributes;
  const scoreVM = userAttributes.scoreVM;
  const beVM = userAttributes.beVM.parsed;
  const positiveScoreVM = userAttributes.positiveScoreVM;

  const maxTotal = calculateMaxTotal(beVM);
  const positiveScore = calculatePositiveScore(positiveScoreVM);
  const positivePursuits = getPositivePursuits(positiveScoreVM);

  const { highPriorityGoals, allOtherGoals } = getGoals(beVM, scoreVM);

  const score = calculateCurrentScore(highPriorityGoals);
  const highPriorityGoalsNumber = Object.values(highPriorityGoals).length;
  const goalsWithZeroScoreNumber = Object.values(highPriorityGoals).filter(item => item === 0).length;

  const goalsWithZeroScorePercent = (goalsWithZeroScoreNumber / highPriorityGoalsNumber) * 100;
  const progress = (score / maxTotal) * 100 + positiveScore;
  const positivePursuitsNumber = Object.values(positivePursuits).length;

  return {
    maxTotal,
    score,
    positiveScore,
    positivePursuits,
    highPriorityGoals,
    allOtherGoals,
    goalsWithZeroScorePercent,
    progress,
    highPriorityGoalsNumber,
    goalsWithZeroScoreNumber,
    positivePursuitsNumber
  };
};
