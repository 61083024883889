export const createHiddenElementWithText = ({ tagName, text, styles }) => {
  let hiddenElement = document.createElement(tagName);
  hiddenElement.innerHTML = text;
  const defaultStyles = {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: '0'
  };
  Object.assign(hiddenElement.style, { ...defaultStyles, ...styles });
  return hiddenElement;
};
