import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import PropTypes from 'prop-types';
import { HeadingVMPlan } from '../../../widgets/vm-plan-widget/components/heading';
import { HeadingVMMortgage } from '../../../widgets/vm-mortgage-widget/heading';
import { HeadingVMPoints } from '../../../widgets/points-widget/components/heading';
import { withMABHeader } from '../../../widgets/mab-import-portfolio/components/header';
import { PageActionsSection } from '../../page-actions-section';
import { withHeader, withSplitHeader, Icon, Body, Heading, SubHeading, withHeaderWrapper } from './components';
import { withBackButton } from './components/back-button';
import { Identifier, IdentifierConfigPropTypes } from './components/identifier';

const Component = React.memo(
  ({
    heading,
    subheading,
    icon = false,
    children,
    edit = false,
    category,
    identifier,
    headingClassName,
    headerButtons = []
  }) => (
    <>
      {icon && <Icon name={icon} />}
      <Body>
        {children}
        <Identifier category={category} identifier={identifier} />
        {heading && <Heading className={headingClassName}>{heading}</Heading>}
        {subheading && <SubHeading>{subheading}</SubHeading>}
        {headerButtons.length ? <PageActionsSection actionsList={headerButtons} /> : null}
      </Body>
      {edit}
    </>
  )
);

const DefaultHeader = compose(
  branch(({ heading, subheading, category }) => !(heading || subheading || category), renderNothing),
  withHeader,
  withSplitHeader,
  withHeaderWrapper,
  withBackButton
)(Component);

const headings = {
  'vm-plan': compose(withHeader, withHeaderWrapper)(HeadingVMPlan),
  'what-is-within-reach': compose(withHeader, withHeaderWrapper)(HeadingVMMortgage),
  'vm-points': compose(withHeader, withHeaderWrapper)(HeadingVMPoints),
  'mab-import-portfolio': compose(withHeader, withHeaderWrapper, withMABHeader)(Component)
};

const Header = React.memo(props => {
  if (props.name && headings[props.name]) {
    const CustomHeader = headings[props.name];

    return <CustomHeader {...props} />;
  }

  return <DefaultHeader {...props} />;
});

Header.displayName = 'Header';

export const headerPropTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  category: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  subBlockName: PropTypes.string,
  headingClassName: PropTypes.string,
  headerButtons: PropTypes.array,
  showBackButton: PropTypes.bool,
  identifier: PropTypes.shape(IdentifierConfigPropTypes),
  pageConfigKey: PropTypes.string
};

Header.propTypes = headerPropTypes;

export { Header };
