import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderNothing, lifecycle, withPropsOnChange, withHandlers, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { UserService } from '../../../../../services/user-service';
import { CONSENTS_TYPES } from '../../../../../helpers/constants';
import { toggleModal } from '../../../../../store/reducers/modals';
import { sendConsents } from '../../../../../store/reducers/user-attributes/actions';
import { sendStatus } from '../../../../../store/reducers/status-data';
import styles from '../../../../../styles/components/layout-popup/consent-modal.module.scss';
import { Modal } from '../../../../modal';
import { ConfigService } from '../../../../../services/config-service';
import { ManagedState } from './components/managed';
import { UnmanagedState } from './components/unmanaged';

const ExpressConsentComponent = React.memo(
  ({
    type,
    footer,
    header,
    show,
    consentsState,
    onConsentsChange,
    setConsentsType,
    handleAccept,
    handleEssential,
    handleAcceptAll,
    handleInputAppear
  }) => {
    return (
      <Modal key={type} label='User consents' show={show} title={header} footer={footer} className={styles.modal}>
        {type === CONSENTS_TYPES.MANAGED ? (
          <ManagedState
            value={consentsState}
            onAccept={handleAccept}
            onAcceptEssential={handleEssential}
            onChange={onConsentsChange}
            onInputAppear={handleInputAppear('manageSeen')}
          />
        ) : (
          <UnmanagedState
            onAccept={handleAcceptAll}
            onAcceptEssential={handleEssential}
            onManage={setConsentsType(CONSENTS_TYPES.MANAGED)}
            onInputAppear={handleInputAppear('acceptSeen')}
          />
        )}
      </Modal>
    );
  }
);

const ExpressConsentContainer = compose(
  withState('type', 'setType', () => CONSENTS_TYPES.UNMANAGED),
  withState('consentsState', 'onConsentsChange', ({ consents }) => consents),
  withHandlers({
    reportStatus:
      ({ sendStatus }) =>
      status => {
        sendStatus('consentPopup', status);
      }
  }),
  withHandlers({
    setConsentsType:
      ({ setType }) =>
      type =>
      () => {
        setType(type);
      },
    handleAccept:
      ({ toggleModal, reportStatus, sendConsents, consentsState }) =>
      () => {
        toggleModal(false, 'consent');
        reportStatus('manageComplete');

        // Sends the current consent's state.
        sendConsents(consentsState);
      },
    handleEssential:
      ({ toggleModal, reportStatus, sendConsents }) =>
      () => {
        toggleModal(false, 'consent');
        reportStatus('acceptComplete');

        // Always sends only the essential consents.
        sendConsents({ essential: true });
      },
    handleAcceptAll:
      ({ toggleModal, reportStatus, sendConsents, consents, isDataSharingAllowed, defaultConsents }) =>
      () => {
        toggleModal(false, 'consent');

        reportStatus('acceptComplete');

        // Enables & sends all possible consents.
        sendConsents(!isDataSharingAllowed ? defaultConsents : ObjectShim.map(consents, () => true));
      },
    handleInputAppear:
      ({ reportStatus }) =>
      status =>
      () => {
        reportStatus(status);
      }
  }),
  withPropsOnChange(['type'], ({ type }) => {
    if (type === CONSENTS_TYPES.UNMANAGED) return {};

    const { title, legalFooter } = ConfigService.get('ACCOUNT.MODALS.CONSENT.managed', {});

    return { header: title, footer: { html: legalFooter } };
  }),
  lifecycle({
    componentDidMount() {
      const { reportStatus } = this.props;

      reportStatus('seen');
    }
  })
)(ExpressConsentComponent);

const mapStateProps = state => {
  const isDataSharingAllowed = state.consentList.dataSharingAllowed;
  const defaultConsents = state.consentList.defaults;
  const currentConsents = state.userAttributes.consents;

  const loading = state.userAttributes.loading;
  const essential = currentConsents?.essential;
  const show = !loading && !essential && !UserService.hasAccount();

  return {
    show,
    defaultConsents,
    isDataSharingAllowed,
    consents: { ...defaultConsents, ...currentConsents }
  };
};

export const ExpressConsent = compose(
  connect(mapStateProps, { toggleModal, sendConsents, sendStatus }),
  branch(({ show }) => !show, renderNothing)
)(ExpressConsentContainer);
