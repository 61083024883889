import { branch, compose, renderComponent } from 'recompose';
import { withEmbeddedContext } from '../../with-embedded-context';
import { withGoogleBotConsent } from './bot';
import { ExpressConsent } from './express';
import { NonUserConsent } from './non-user';
import { withBugsnag } from './bugsnag';
import { withDataSharingAllowed } from './data-sharing-allowed';

export const Consents = compose(
  withDataSharingAllowed,
  withBugsnag,
  withGoogleBotConsent,
  withEmbeddedContext,
  branch(({ isEmbedded }) => window.CONFIG.DISPLAY_MODULES.CONSENT_PAGE && !isEmbedded, renderComponent(ExpressConsent))
)(NonUserConsent);
