import { compose } from 'recompose';
import { withAdvancedInformer, withAnchor } from '../../../../../components/inputs/input-field';
import { Select as Input, withSelectLabel } from '../../../../../components/inputs/select';
import { Select as InputV2 } from '../../../../../components/inputs/select/v2-select';
import { withContainer, withSubmit } from '../input-field';
import { withPreSubmitButton } from '../pre-submit-button/with-pre-submit-button';

export const Select = compose(
  withAnchor,
  withContainer,
  withSubmit,
  withPreSubmitButton,
  withSelectLabel,
  withAdvancedInformer
)(Input);
export const SelectV2 = compose(withAnchor, withContainer, withSubmit, withSelectLabel)(InputV2);
