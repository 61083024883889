import isbot from 'isbot';
import { branch, compose, renderNothing, withPropsOnChange } from 'recompose';

// This is the list of custom user agents that we want to allow to access the site
// Mainly this is for the native apps
const BOT_WHITELIST = ['LifeHub Mobile'];

export const withGoogleBotConsent = compose(
  withPropsOnChange([], () => {
    const userAgent = window.navigator.userAgent;

    return {
      isBot: BOT_WHITELIST.includes(userAgent) ? false : isbot(userAgent)
    };
  }),
  branch(({ isBot }) => isBot, renderNothing)
);
