import { FunctionShim } from '@packages/helpers/core/shims/function-shim';

export const isHtmlElement = (target, IO = HTMLElement) => target instanceof IO;

const getElementNearestSvgNode = (target = null, IO = SVGGraphicsElement, exceptions = []) => {
  let element = target;

  while (element) {
    // eslint-disable-next-line no-loop-func
    if (exceptions.some(EIO => element instanceof EIO)) {
      element = element.parentNode;

      continue;
    }

    if (element instanceof IO) {
      return {
        element,
        root: element.ownerSVGElement
      };
    }

    element = element.parentNode;
  }

  return element;
};

export const getNearestSvgNode = FunctionShim.memoize(getElementNearestSvgNode);
