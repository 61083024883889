import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

/**
 * It is used to read the content of the carousel.
 */
type AriaLiveProperty = HTMLAttributes<HTMLElement>['aria-live'];
/**
 * It is used to determine the direction of the animation.
 */
type AnimationDirectionProperty = 'next' | 'prev';

type UseCarouselConfig = {
  [key: string]: any;
  defaultItem?: number;
  beforeChange?: (oldIndex: number, newIndex: number) => void;
};

type UseCarouselProps = {
  items: unknown[];
  config?: UseCarouselConfig;
  beforeChange?: (oldIndex: number, newIndex: number) => void;
};

type UseCarouselState = {
  pages: unknown[];
  activePage: number;
  isFirst: boolean;
  isLast: boolean;
  ariaLive: AriaLiveProperty;
  animationDirection?: AnimationDirectionProperty;
};

type UseCarouselHandlers = {
  handlePrev: () => void;
  handleNext: () => void;
  onFocus: () => void;
  onBlur: () => void;
  // TODO: Add keyboard navigation (left/right arrows).
};

type UseCarouselReturn = {
  state: UseCarouselState;
  handlers: UseCarouselHandlers;
};

const useCarouselController = ({ items = [], config = {} }: UseCarouselProps): UseCarouselReturn => {
  const { defaultItem = 0, beforeChange } = config;
  const [animationDirection, setAnimationDirection] = useState<AnimationDirectionProperty>();
  const [activePage, setActivePage] = useState<UseCarouselState['activePage']>(defaultItem);

  const [pages, setPages] = useState<UseCarouselState['pages']>(items);

  useEffect(() => {
    setPages([...items]);
  }, [items]);

  const { isFirst, isLast } = useMemo<Pick<UseCarouselState, 'isFirst' | 'isLast'>>(() => {
    return {
      isFirst: activePage === 0,
      isLast: activePage === pages.length - 1
    };
  }, [pages, activePage]);

  const handlePrev = useCallback(() => {
    const newIndex = activePage - 1;

    if (newIndex > -1) {
      setAnimationDirection('prev');
      if (beforeChange) {
        beforeChange(activePage, newIndex);
      }
      setActivePage(newIndex);
    }
  }, [activePage, beforeChange]);

  const handleNext = useCallback(() => {
    const newIndex = activePage + 1;

    if (newIndex <= pages.length - 1) {
      setAnimationDirection('next');
      if (beforeChange) {
        beforeChange(activePage, newIndex);
      }
      setActivePage(newIndex);
    }
  }, [activePage, beforeChange, pages.length]);

  const [ariaLive, setAriaLive] = useState<AriaLiveProperty>('off');

  const onFocus = useCallback(() => {
    setAriaLive('polite');
  }, []);

  const onBlur = useCallback(() => {
    setAriaLive('off');
  }, []);

  return {
    state: {
      pages,
      activePage,
      isFirst,
      isLast,
      ariaLive,
      animationDirection
    },
    handlers: {
      handlePrev,
      handleNext,
      onFocus,
      onBlur
    }
  };
};

export default useCarouselController;
