import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { BLOG, BLOG_POST } from '../helpers/navigation';
import { Post } from './articles';

const BlogList = Loadable({
  loader: () => import(/* webpackChunkName: "blog-list"*/ '../pages/blog').then(module => module.BlogList),
  loading: Loader
});

export const blogRoutes = [
  {
    to: '/blog/:slug',
    component: asTemplate(BLOG_POST)(Post)
  },
  {
    to: '/blog',
    component: asTemplate(BLOG)(BlogList)
  }
];
