/**
 * To identify the first loaded page we take its uniq identifier. Among most of the pages it is the second parameter, such as:
 * '/articles|blog|project|stories/:slug'
 * '/checklist|account|tour/:activity/:questionIdentifier'
 * '/tour/:name/:tag'
 * '/lp/:name'
 * so we pick 'slug' or 'activity' or 'name' accordingly to identify the loading group of the pages.
 * For the rest we select the first available param:
 * '/dashboard'
 * '/checklist'
 * '/account'
 * @param location
 * @return {string} page identifier
 */
export const createPageIdentifier = location => {
  return location.pathname.split('/')[2] ?? location.pathname.split('/')[1];
};

export const createWidgetIdentifier = (name, order) => {
  return name + '_' + order;
};

export const checkPageInitialization = (loadingState, pageIdentifier) => {
  return loadingState.pages[pageIdentifier]?.initialized;
};
