import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ConfigService } from '../../services/config-service';
import { isModuleCompleted } from '../custom-page/is-completed';

/**
 * if activity, set in the config is not completed - returns the redirect link
 * @param {object} statusData - the activities statuses
 * @return {string | undefined}
 */
export const getRequiredActivityRedirectLink = ({ statusData }) => {
  const requiredFlow = ConfigService.get('LAUNCH_BEHAVIOUR.requiredFlow', {});

  if (!ObjectShim.isObject(requiredFlow) || ObjectShim.isEmpty(requiredFlow)) {
    return;
  }

  const { activityName, questionLink } = requiredFlow;
  const isCompleted = isModuleCompleted(statusData, activityName);

  if (isCompleted) {
    return;
  }

  return questionLink;
};
