import { compose, withState, withHandlers, lifecycle, withProps } from 'recompose';
import { connect } from 'react-redux';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { evaluateCondition } from '../../helpers/checklist/display-condition';
import { omitProps } from '../../helpers/hocs/omit-props';

const mapStateToProps = ({ userAttributes, statusData: { plainStatuses }, points }) => ({
  userAttributes,
  plainStatuses,
  userData: { ...userAttributes?.user, ...points }
});

export const withInputRenderCondition = compose(
  connect(mapStateToProps),
  withState('updatedItems', 'setUpdatedItems', props => props.items),
  withHandlers({
    updateItems:
      ({
        setUpdatedItems,
        submitValue,
        items,
        setFormValues,
        setFormDisabledItems,
        plainStatuses,
        userAttributes,
        userData
      }) =>
      async () => {
        if (!items) {
          setUpdatedItems([]);
          return;
        }

        const updatedItemsPromises = items.map(async item => {
          if (!item.data.displayCondition) {
            return item;
          }

          const payload = ObjectShim.mergeDeep(userAttributes, submitValue);

          const conditionIsTrue = await evaluateCondition(item.data.displayCondition, {
            ...payload,
            activity: plainStatuses,
            user: userData
          });

          if (!conditionIsTrue) {
            const itemPathPayload = {
              attributeType: item.data.userAttributeType,
              userAttributeField: item.data.userAttributeField
            };
            setFormDisabledItems(itemPathPayload)(false);
            setFormValues(itemPathPayload)(null);
          }

          return conditionIsTrue && item;
        });

        const updatedItems = (await Promise.all(updatedItemsPromises)).filter(Boolean);
        setUpdatedItems(updatedItems);
      }
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateItems();
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps.submitValue) !== JSON.stringify(this.props.submitValue)) {
        this.props.updateItems();
      }
    }
  }),
  withProps(({ updatedItems, submitValue }) => ({ items: updatedItems })),
  omitProps('updatedItems')
);
