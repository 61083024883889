import { get } from './utils';

const getPartnerEligibilityByKey = keyList => get(`/market-place/partners-eligibility?keys=${keyList.join(',')}`);
const getPartnerEligibility = activity => get(`/market-place/is-partner-eligible?activityKey=${activity}`);
const getGroupEligibility = groupKey => get(`/market-place/are-partners-in-group-eligible?groupKey=${groupKey}`);

export const eligibility = {
  getPartnerEligibility,
  getPartnerEligibilityByKey,
  getGroupEligibility
};
