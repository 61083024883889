import { AffiliateIcon } from '@apps/firsthomecoach/src/components/icons/affiliate-icon';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styles from '../styles/components/status-filter.module.scss';

const StatusToggler = memo(props => {
  const {
    toggle,
    label = 'Saved',
    isActive = false,
    inactiveIconName = 'star',
    activeIconName = 'star-filled'
  } = props;

  const iconName = isActive ? activeIconName : inactiveIconName;

  return (
    <button onClick={toggle} className={classnames(styles.block, { [styles.active]: isActive })}>
      <div className={styles.iconContainer}>
        <AffiliateIcon name={iconName} className={styles.icon} />
      </div>
      <label className={styles.label}>{label}</label>
    </button>
  );
});

StatusToggler.propTypes = {
  label: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  activeIconName: PropTypes.string,
  inactiveIconName: PropTypes.string
};

StatusToggler.displayName = 'StatusToggler';

export default StatusToggler;
