import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { createReducer, createAction } from '../utils';

const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';

const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';

export const getQuestions = type =>
  createAction(
    {
      request: GET_QUESTIONS_REQUEST,
      success: GET_QUESTIONS_SUCCESS,
      failure: GET_QUESTIONS_FAILURE
    },
    api => api.configuration.getQuestions
  )(type);

export const getQuestion = (type, slug) =>
  createAction(
    {
      request: GET_QUESTION_REQUEST,
      success: GET_QUESTION_SUCCESS,
      failure: GET_QUESTION_FAILURE
    },
    api => api.configuration.getQuestion
  )({ type, slug });

const initialState = {
  questions: {},
  loading: true,
  error: false
};

export const questions = createReducer(initialState, {
  [GET_QUESTIONS_REQUEST]: state => ({ ...state, loading: true }),
  [GET_QUESTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    questions: { ...state.questions, [action.params]: ArrayShim.normalize(action.payload, 'slug') }
  }),
  [GET_QUESTIONS_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_QUESTION_REQUEST]: state => ({ ...state, loading: true }),
  [GET_QUESTION_SUCCESS]: (state, { payload, params }) => ({
    ...state,
    loading: false,
    questions: { ...state.questions, [params.type]: { ...state[params.type], [params.slug]: payload } }
  }),
  [GET_QUESTION_FAILURE]: state => ({ ...state, loading: false, error: true })
});
