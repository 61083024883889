/* eslint-disable no-new-func */
import { timeframeToString, timeframeToStringShorthand } from '../time-format';
import { monthDiffFromStartingTaxYear, monthsDiffWording } from '../deposit-builder/accounts';
import { toMoneyStringWithComma } from '../formatters/money-format';

// We run it inside web worker, so it can be safely executed. It is impossible to access user's data from a web worker

const toNumberFormat = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const toIntegerFormat = value =>
  Number(value)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const transformers = [
  toNumberFormat,
  timeframeToString,
  timeframeToStringShorthand,
  monthDiffFromStartingTaxYear,
  monthsDiffWording,
  toIntegerFormat,
  toMoneyStringWithComma
];
// Minifies remove function name, so you will not get it on build phase, replicate them here
const transformerNames = [
  'toNumberFormat',
  'timeframeToString',
  'timeframeToStringShorthand',
  'monthDiffFromStartingTaxYear',
  'monthsDiffWording',
  'toIntegerFormat',
  'toMoneyStringWithComma'
];

export function evaluate(message, params) {
  const paramsNames = Object.keys(params);
  const paramsValues = Object.values(params);

  return Function(
    ...paramsNames,
    ...transformerNames,
    // eslint-disable-next-line no-template-curly-in-string
    'try { return `' + message + '`; } catch (e) { return `<p>Value is missing: ${e}</p>`}'
  )(...paramsValues, ...transformers);
}
