import { parse } from 'query-string';
import { DEVICE } from '../user-agent';

export const deviceDetection = () => {
  const { utm_medium } = parse(window.location.search);
  const isMobile = DEVICE.IS_MOBILE_BROWSER();
  const isAndroid = DEVICE.ANDROID();
  const isIOS = DEVICE.IOS();
  const mobileText = isAndroid ? 'Mobile Browser Android' : isIOS ? 'Mobile Browser IOS' : 'Mobile Browser';

  return (
    (utm_medium === 'ios' ? 'IOS App' : false) ||
    (utm_medium === 'android' ? 'Android App' : false) ||
    (isMobile ? mobileText : false) ||
    'Desktop'
  );
};
