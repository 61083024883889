const calculateUserTotal = (points, state) => {
  if (points && points.point > 0) {
    return Number(state.pointsDetails.userTotal) + Number(points.point);
  }

  return state.pointsDetails.userTotal;
};

const calculateUserAvailable = (points, state) => {
  if (points) {
    return Number(state.pointsDetails.userAvailable) + Number(points.point);
  }

  return state.pointsDetails.userAvailable;
};

const calculateUserSpent = (points, state) => {
  if (points && points.point < 0) {
    return Number(state.pointsDetails.userSpent) + Number(points.point * -1);
  }

  return state.pointsDetails.userSpent;
};

export const setPointsDetails = (state, { payload: { points } }) => ({
  ...state,
  pointsDetails: {
    ...state.pointsDetails,
    details: points && points.point > 0 ? [points, ...state.pointsDetails.details] : state.pointsDetails.details,
    userTotal: calculateUserTotal(points, state),
    userAvailable: calculateUserAvailable(points, state),
    userSpent: calculateUserSpent(points, state)
  }
});
