import { useReducer, useEffect } from 'react';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';

const TYPES = {
  VALIDATE_VALUE: 'VALIDATE_VALUE'
};

const validate = (value, validators) => {
  let errors = {},
    error = false;

  for (const key in validators) {
    if (validators.hasOwnProperty(key)) {
      const invalid = validators[key](value);

      if (invalid) {
        error = true;
      }
      errors[key] = invalid;
    }
  }

  return { error, errors };
};

const reducer = (prevState, { type, payload }) => {
  switch (type) {
    case TYPES.VALIDATE_VALUE: {
      const { value, validators } = payload;

      const nextValue = FunctionShim.isFunction(value) ? value(prevState.value) : value;

      return validate(nextValue, validators);
    }
    default:
      return prevState;
  }
};

const initialState = {
  error: false,
  errors: {}
};

export const useValidator = (value, validators) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: TYPES.VALIDATE_VALUE, payload: { value, validators } });
  }, [value, validators]);

  return state;
};
