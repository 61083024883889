import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const INDICATOR_STATES = ObjectShim.immutable(
  {
    before: 'before',
    current: 'current',
    after: 'after'
  },
  true
);

export const INDICATOR_TYPES = ObjectShim.immutable(
  {
    numeric: 'numeric',
    icon: 'icon',
    empty: 'empty'
  },
  true
);

export const INDICATOR_ICONS = ObjectShim.immutable(
  {
    before: 'progress_indicator_before',
    current: 'progress_indicator_current',
    after: 'progress_indicator_after'
  },
  true
);
