import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../../components/buttons/button-with-icon';
import { Question } from '../../../../templates/questions/question-template';
import { UserService } from '../../../../services/user-service';
import { setRedirectionLink } from '../../../../store/reducers/navigation';
import { ConfigService } from '../../../../services/config-service';

export const BarrierPage = React.memo(() => {
  const {
    templateText: { message },
    buttons: { confirm, decline }
  } = ConfigService.get('ACCOUNT.PAGES.BARRIER', {});

  return (
    <Question message={<p>{message}</p>} hideCloseButton hidePageActions>
      <ButtonWithIcon to='/account/login/email'>{confirm}</ButtonWithIcon>
      <ButtonWithIcon type='minor' to='/account/sign-up/email'>
        {decline}
      </ButtonWithIcon>
    </Question>
  );
});

const RedirectToBarrier = () => <Redirect to='/account/barrier' />;

const RedirectToBarrierContainer = compose(
  connect(null, { setRedirectionLink }),
  withRouter,
  lifecycle({
    componentDidMount() {
      const {
        location: { pathname },
        setRedirectionLink
      } = this.props;

      setRedirectionLink({ link: pathname });
    }
  })
)(RedirectToBarrier);

export const withBarrier = Component => props => {
  if (UserService.hasAccount()) {
    return <RedirectToBarrierContainer />;
  }

  return <Component {...props} />;
};
