import { useLayoutEffect, useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

const DEFAULT = ObjectShim.immutable(
  {
    options: {
      strategy: 'absolute',
      placement: 'auto'
    },
    modifiers: []
  },
  true
);

export const usePopper = (options, on = true) => {
  const [instance, setInstance] = useState(null);
  const [trigger, setTrigger] = useState(null);
  const [popover, setPopover] = useState(null);

  const instanceOptions = useRef({
    ...Object.assign({}, DEFAULT.options, options),
    modifiers: [...DEFAULT.modifiers, ...(options?.modifiers ?? [])]
  });

  useLayoutEffect(() => {
    if (on && trigger && popover) {
      const popper = createPopper(trigger, popover, instanceOptions.current);

      setInstance(popper);

      return () => {
        popper.destroy();

        setInstance(null);
      };
    }
  }, [on, trigger, popover]);

  return {
    instance,
    onTriggerRef: setTrigger,
    onPopoverRef: setPopover
  };
};
