import { get, post } from './utils';

const getUserAttributes = async type => get(`/user-attr${type ? `/${type}` : ''}`);
const sendUserAttribute = async ({ type, params }) => post(`/user-attr/${type}`, params);
const sendUserGroupAttributes = async ({ params, mergeObjects = true }) =>
  post(`/user-attr?mergeObjects=${mergeObjects}`, params);
const getUserAttributesSettings = async () => get(`/user-attribute-settings`);
const sendAdditionalUserAttributes = async ({ params, mergeObjects = false }) =>
  post(`/user-attr?mergeObjects=${mergeObjects}`, params);

export const userAttributes = {
  getUserAttributes,
  sendUserAttribute,
  getUserAttributesSettings,
  sendAdditionalUserAttributes,
  sendUserGroupAttributes
};
