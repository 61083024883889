import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const getPositivePursuits = data => {
  let result = {};

  Object.keys(data).forEach(item => {
    Object.values(data[item]).forEach(i => {
      if (i.checked && ObjectShim.isObject(i.value)) {
        result = { ...result, ...i.value };
      }
    });
  });

  return result;
};
