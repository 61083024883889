import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { TOUR } from '../helpers/navigation';

const TourPage = Loadable({
  loader: () => import(/* webpackChunkName: "tourPage" */ '../pages/tour-page').then(module => module.TourPage),
  loading: Loader
});

export const tourPageRoutes = [
  {
    to: '/tour/:name/:tag',
    component: asTemplate(TOUR)(TourPage)
  }
];
