import { compose } from 'redux';
import { mapDepositAmount, mapFees, mapSavePerMonth, mapTotalCosts } from '../user-attributes/map-user-attributes';

export const mapMyPlanChartAttributes = goals => {
  const { depositSavings, monthlySaving, savePerMonth, totalCosts } = goals;

  return {
    ...goals,
    inTotalLowerValue: totalCosts - depositSavings,
    eachMonthLowerValue: savePerMonth - monthlySaving
  };
};

export const mapMyPlanChartConfig = compose(
  mapMyPlanChartAttributes,
  mapSavePerMonth,
  mapTotalCosts,
  mapDepositAmount,
  mapFees
);
