import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getRoutesByPathname } from '../utils';
import { ConfigService } from '../../services/config-service';
import { TYPE_TO_ACTIVITY_KEY } from '../content-interaction/constants';
import { PAGE_TYPES_TO_CONFIG_NAMES, POST_TYPES } from '../constants';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const getMinutesToReadFromHTML = htmlString => htmlString && Math.ceil(htmlString.split(' ').length / 300);

export const getMinutesRead = htmlString => `${getMinutesToReadFromHTML(htmlString)} min read`;

export const getDateFromNow = timeStringUTC => {
  const stillUtc = dayjs.utc(timeStringUTC).toDate();
  const local = dayjs(stillUtc).local();

  return dayjs(local).fromNow();
};

export const getArticleType = pathname => {
  const [type] = getRoutesByPathname(pathname);

  return type;
};

export const ARTICLE_NAME_TYPES = {
  BLOG: 'blog post',
  ARTICLE: 'article',
  ANNOUNCEMENT: 'announcement'
};

export const getCategoryNameByType = (type = '') => {
  switch (type) {
    case 'blog':
      return ARTICLE_NAME_TYPES.BLOG;
    case 'announcement':
      return ARTICLE_NAME_TYPES.ANNOUNCEMENT;
    default:
      return ARTICLE_NAME_TYPES.ARTICLE;
  }
};

export const DEFAULT_POST_CONFIG = {
  pageHeader: {
    name: null,
    type: null
  },
  showTemplateBlock: {
    link: false,
    meta: true,
    featuredImage: true
  },
  bottomTemplateBlocks: {
    Repeater: {},
    ArticleRead: {},
    LikeArticle: {},
    DigitalCoach: {}
  },
  legalText: ''
};

/**
 * Gather configuration information from admins config file for specific post type
 * @param type {string} - the article type from the WP
 * @return {object}
 */

export const getConfigurationByPostType = type => {
  switch (type) {
    case POST_TYPES.blog:
    case POST_TYPES.announcement:
      return {
        ...ConfigService.get(PAGE_TYPES_TO_CONFIG_NAMES.blog, {}),
        pointsMessage: ConfigService.get('POINTS.COMMUNICATIONS.BLOG.text', '')
      };
    case POST_TYPES.project:
      return {
        ...ConfigService.get(PAGE_TYPES_TO_CONFIG_NAMES.project, {}),
        pointsMessage: ConfigService.get('POINTS.COMMUNICATIONS.PROJECT.text', '')
      };
    default:
      return {
        ...ConfigService.get(PAGE_TYPES_TO_CONFIG_NAMES.article, {}),
        pointsMessage: ConfigService.get('POINTS.COMMUNICATIONS.ARTICLE.text', '')
      };
  }
};

export const getActivityKeyByPostType = type => {
  if (TYPE_TO_ACTIVITY_KEY[type]) {
    return TYPE_TO_ACTIVITY_KEY[type];
  }

  return TYPE_TO_ACTIVITY_KEY.article;
};

export const getActivityPointsByType = (statuses, type) => {
  const activityKey = getActivityKeyByPostType(type);

  if (!statuses[activityKey]) {
    return null;
  }

  return statuses[activityKey].points;
};
