import React, { useEffect, useMemo, useRef, useState } from 'react';
import { compose, defaultProps, withProps } from 'recompose';
import classnames from 'classnames';
import { withDisplayCondition } from '../../../helpers/hocs/with-display-condition';
import styles from '../../../styles/components/input-list-popover.module.scss';
import { ConfigService } from '../../../services/config-service';
import { usePopper } from '../../../helpers/hooks/use-popper';
import { ScrollContainer } from '../../layout/scroll-container';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withCustomSpinner } from '../../with-loader';
import { withLabelCondition } from '../../../templates/checklist/with-label-condition';

const ItemComponent = React.memo(
  ({ index, isFocused, isScrollFocused, selected, disabled, onClick, onMouseDown, children, onMouseMove }) => {
    const ref = useRef();

    useEffect(() => {
      const item = ref.current;

      if (item && isScrollFocused) {
        const {
          parentElement: list,
          clientHeight: itemHeight,
          dataset: { index }
        } = item;

        const { clientHeight: listHeight, scrollTop } = list;

        // item's position related to the list;
        const offsetTop = itemHeight * index;

        if (offsetTop < scrollTop) {
          list.scrollTop = offsetTop - listHeight;
        }

        if (offsetTop + itemHeight > scrollTop + listHeight) {
          list.scrollTop = scrollTop + listHeight;
        }
      }
    }, [isScrollFocused]);

    return (
      <li
        ref={ref}
        role='option'
        aria-selected={selected}
        aria-disabled={disabled}
        data-index={index}
        className={classnames(styles.item, { [styles.focused]: isFocused, [styles.disabled]: disabled })}
        onClick={onClick}
        onKeyDown={onClick}
        onMouseDown={onMouseDown}
        onMouseMove={() => {
          !isFocused && onMouseMove();
        }}
      >
        {children}
      </li>
    );
  }
);

const ItemWithLabelComponent = ({
  index,
  focusedItemId,
  scrollFocusedItemId,
  selected,
  disabled,
  onChange,
  prevent,
  setFocusedData,
  item,
  label,
  id,
  displayCondition
}) => {
  return (
    <ItemComponent
      key={`${label}_${id}`}
      index={index}
      selected={label === selected}
      isFocused={id === focusedItemId}
      isScrollFocused={id === scrollFocusedItemId}
      disabled={disabled}
      onClick={onChange(item)}
      onMouseDown={prevent}
      onMouseMove={() => setFocusedData(item, false)}
      displayCondition={displayCondition}
    >
      <span className={styles.value}>{label}</span>
      {label === selected && <AffiliateIcon name='select-value' className={styles.icon} inline />}
    </ItemComponent>
  );
};

const ItemWithLabel = compose(withDisplayCondition, withLabelCondition)(ItemWithLabelComponent);

const ListComponent = React.memo(
  ({ items, selected, focusedItemId, scrollFocusedItemId, onChange, setFocusedData }) => {
    // This will prevent Select Input trigger focus loosing.
    const prevent = event => event.preventDefault();

    return (
      <ScrollContainer renderAs='ul' role='listbox' className={styles.items}>
        {items.map((item, index) => {
          const { id, label, disabled, displayCondition, isDisabled } = item;

          return (
            <ItemWithLabel
              key={`${label}_${id}`}
              index={index}
              selected={label === selected}
              isFocused={id === focusedItemId}
              isScrollFocused={id === scrollFocusedItemId}
              disabled={disabled || isDisabled}
              onChange={onChange}
              prevent={prevent}
              setFocusedData={setFocusedData}
              item={item}
              label={label}
              id={id}
              displayCondition={displayCondition}
            />
          );
        })}
      </ScrollContainer>
    );
  }
);

const List = compose(
  withProps(() => ({ loadingIcon: ConfigService.get('QUESTIONS.INPUTS.loadingIcon', 'loading') })),
  withCustomSpinner({ spinnerClassName: styles.spinner })
)(ListComponent);

const FOCUSED_ARIA_VALUE_PREFIX = 'press Enter to select: ';

const useContainer = ({ defaultSelected, onChange, ...props }) => {
  const { name, value, items } = props;

  const [isActive, setIsActive] = useState(false);
  const [focusedItemId, setFocusedItemId] = useState();
  const [scrollFocusedItemId, setScrollFocusedItemId] = useState();
  const [selected, setSelected] = useState(value ?? null);
  const [focusedAriaValue, setFocusedAriaValue] = useState();

  useEffect(() => {
    if (!isActive) {
      setFocusedAriaValue(undefined);
    }
  }, [isActive]);

  //Set tick icon over selected item on loading the page
  useEffect(() => {
    if (items.length && !selected) {
      const matchSelectedItem = items.find(item => {
        const test = defaultSelected ? defaultSelected(item.value) : item.value;

        return test.key === value;
      });

      setSelected(matchSelectedItem?.label);
    }
  }, [items, defaultSelected, value, selected]);

  const open = event => {
    if (event) {
      event.preventDefault();
    }

    setIsActive(true);
  };

  const close = event => {
    if (event) {
      event.preventDefault();
    }

    setIsActive(false);
  };

  const handleChange =
    ({ id, value, label, disabled }) =>
    event => {
      if (disabled) {
        return false;
      }

      if (onChange) {
        onChange(name)(value, label, id);
      }

      setSelected(label);
      close(event);
    };

  const setFocusedData = (focusedItem, isScrolling = true) => {
    if (focusedItem) {
      setFocusedItemId(focusedItem.id);
      if (isScrolling) setScrollFocusedItemId(focusedItem.id);

      const focusedAriaText = focusedItem.label !== selected ? FOCUSED_ARIA_VALUE_PREFIX + focusedItem.label : '';
      setFocusedAriaValue(focusedAriaText);
    }
  };

  const handleArrowKey = (key, event) => {
    open(event);

    if (items.length) {
      const index = items.findIndex(({ id }) => id === (focusedItemId ?? selected));

      if (key === 'ArrowUp') {
        const focusedItem = index > 0 ? items[index - 1] : items[items.length - 1];
        setFocusedData(focusedItem);
      }

      if (key === 'ArrowDown') {
        const focusedItem = index > -1 && index < items.length - 1 ? items[index + 1] : items[0];
        setFocusedData(focusedItem);
      }
    }
  };

  const handleEnterKey = event => {
    const item = items.find(({ id }) => id === focusedItemId);

    if (item && !item?.isDisabled) {
      handleChange(item)(event);
    }

    setFocusedItemId();
    setScrollFocusedItemId();
  };

  const handleKeyDown = event => {
    const { key } = event;

    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
        handleArrowKey(key, event);
        break;
      case 'Escape':
        close(event);
        break;
      case 'Enter':
        handleEnterKey(event);
        break;
      default:
        break;
    }
  };

  return {
    ...props,
    isActive: isActive && items.length > 0,
    focusedAriaValue,
    focusedItemId,
    scrollFocusedItemId,
    selected,
    open,
    close,
    handleChange,
    handleKeyDown,
    setFocusedData
  };
};

const withComponent = Component =>
  React.memo(props => {
    const {
      isActive,
      focusedItemId,
      scrollFocusedItemId,
      selected,
      items,
      loading,
      className,
      popper,
      open,
      close,
      handleChange,
      handleKeyDown,
      setFocusedData,
      focusedAriaValue,
      ...rest
    } = useContainer(props);

    const displayValue = useMemo(
      () =>
        items.find(i => {
          const foundItem = i.value.key === rest.value;
          return foundItem?.isDisabled ? '' : foundItem?.label;
        }),
      [items, rest.value]
    );

    const { onTriggerRef, onPopoverRef } = usePopper(popper, isActive);

    return (
      <div className={classnames(styles.container, className)}>
        {/*The disadvantage of a very custom implementation.*/}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div ref={onTriggerRef} onClick={open} onInput={open} onKeyDown={handleKeyDown} onBlur={close}>
          <Component {...rest} displayValue={displayValue} ariaValue={focusedAriaValue} />
        </div>
        <div ref={onPopoverRef} className={styles.menu} hidden={!isActive} data-loading={loading}>
          <List
            items={items}
            selected={selected}
            focusedItemId={focusedItemId}
            scrollFocusedItemId={scrollFocusedItemId}
            loading={loading}
            onChange={handleChange}
            setFocusedData={setFocusedData}
          />
        </div>
      </div>
    );
  });

export const withPopover = props =>
  compose(
    defaultProps({
      items: [],
      ...props
    }),
    withComponent
  );
