import React from 'react';
import classnames from 'classnames';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { isVisible } from '../../../with-layout';
import { HeaderIcon } from '../../default';
import { Initials } from '../../components/initials';
import { DefaultNav } from './default-nav';

export const Component = React.memo(({ buttons, expanded, toggle }) => (
  <nav className={styles.nav}>
    <DefaultNav items={buttons} />
    <div className={styles.icons}>
      <Initials />
      <div className={styles['mobile-nav']}>
        <HeaderIcon
          className={classnames(styles.toggle, { [styles.expanded]: expanded })}
          onClick={toggle}
          name={expanded ? 'nav-exit' : 'nav-menu'}
        />
      </div>
    </div>
  </nav>
));

export const NavigationArea = isVisible({ name: 'desktopHeaderNavigation' })(Component);
