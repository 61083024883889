import React from 'react';
import classnames from 'classnames';
import { compose, defaultProps, withHandlers, withState } from 'recompose';
import styles from '../styles/components/loader.module.scss';
import { AffiliateIcon } from './icons/affiliate-icon';

export const Spinner = ({ className, name = 'loading' }) => (
  <AffiliateIcon className={classnames(styles.spinner, className)} name={name} />
);

export const Loader = ({ className }) => <Spinner className={classnames(styles.container, className)} />;

export const ProgressLoader = ({ className, progress }) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <span className={styles.filler} style={{ width: `${progress}%` }} />
    </div>
  );
};

export const withLoader = Component => props => {
  if (props.loading) {
    return <Loader />;
  } else {
    return <Component {...props} />;
  }
};

export const withCustomSpinner = (props = {}) => compose(defaultProps(props), withSpinner);

export const withSpinner = Component => props => {
  if (props.loading) {
    return <Spinner className={props.spinnerClassName} name={props.loadingIcon} />;
  } else {
    return <Component {...props} />;
  }
};

export const withLoadingHandlers =
  (initial = false) =>
  Component =>
    compose(
      withState('loading', 'setLoading', initial),
      withHandlers({
        toggleLoading:
          ({ setLoading }) =>
          () =>
            setLoading(loading => !loading),
        turnOnLoading:
          ({ setLoading }) =>
          () =>
            setLoading(true),
        turnOffLoading:
          ({ setLoading }) =>
          () =>
            setLoading(false)
      })
    )(Component);
