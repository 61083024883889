import { Helmet } from 'react-helmet';
import { compose, withHandlers } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { sendCreditScore } from '../../store/reducers/user-attributes/actions';
import { Question } from '../../templates/questions/question-template';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { getRoutesByPathname } from '../../helpers/utils';

const message = (
  <>
    <p>Do you pay your rent regularly?</p>
    <p>Regular payments could help boost your score, while irregular may do the opposite.</p>
  </>
);

const RegularPaymentsQuestion = ({ setCreditScore }) => (
  <>
    <Helmet title='Regular payments' />
    <Question message={message}>
      <ButtonWithIcon onClick={() => setCreditScore(true)}>Yes</ButtonWithIcon>
      <ButtonWithIcon onClick={() => setCreditScore(false)}>No</ButtonWithIcon>
    </Question>
  </>
);

export const RegularPayments = compose(
  connect(null, { sendCreditScore }),
  withHandlers({
    setCreditScore:
      ({ sendCreditScore, history }) =>
      async value => {
        const {
          location: { pathname }
        } = history;
        const [gistModule] = getRoutesByPathname(pathname);
        await sendCreditScore({ regularRentPayments: value });
        history.push(`/${gistModule}/rent-reporting/payment-method`);
      }
  })
)(RegularPaymentsQuestion);
