import { Boundary, Modifier, Placement, RootBoundary, State, Padding, Context } from '@popperjs/core';

type Options = {
  placement: Placement;
  boundary: Boundary;
  rootBoundary: RootBoundary;
  elementContext: Context;
  altBoundary: boolean;
  padding: Padding;
  state: State;
};

type SameWidthModifier = Modifier<'sameWidth', Options>;

export const sameWidth: SameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    if (state.elements.reference instanceof HTMLElement) {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    }
  }
};
