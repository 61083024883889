import React from 'react';

export const customDots =
  ({ prevSlot, nextSlot }) =>
  dots => {
    return (
      <div>
        <div ref={prevSlot} />
        <ul> {dots} </ul>
        <div ref={nextSlot} />
      </div>
    );
  };
