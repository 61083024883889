import { generateValidations } from '../../../helpers/deposit-builder/validation';

const dynamicValidations = {
  'deposit-builder': (state, params) => generateValidations(state, params)
};

export const dynamicValidation = (state, match, params) => {
  const { max, min, errorMessages } = params;

  const { activity, identifier, slug } = match.params;

  if (dynamicValidations[activity] && identifier) {
    return dynamicValidations[activity](state, { max, min, identifier, slug });
  }

  return { max, min, errorMessages };
};
