import React from 'react';
import { getDisplayName } from 'recompose';
import debounce from 'lodash.debounce';
import { SCROLL_BAR_WIDTH } from '../helpers/layout';

export const BREAKPOINTS_LIST = ['xss', 'xs', 'sm', 'md', 'lg'];

// TODO: Figure out the breakpoints "dictionary" with Rob.
// Rob's spec breakpoints:
//  - SM: 0 -> 767;
//  - MD: 768 -> 1104;
//  - LG: 1105 -> ∞;

export const BREAKPOINTS = {
  LG: 1105,
  MD: 920,
  SM: 768,
  XS: 568,
  XSS: 0
};

export const BREAKPOINTS_MATCHES = {
  LG_AND_UP: 'lgAndUp',
  MD_AND_DOWN: 'mdAndDown',
  MD_AND_UP: 'mdAndUp',
  MD: 'md',
  SM_AND_DOWN: 'smAndDown',
  SM_AND_UP: 'smAndUp',
  SM: 'sm',
  XSM: 'xsm'
};

const getViewport = width => ({
  lgAndUp: width >= BREAKPOINTS.LG,
  mdAndDown: width < BREAKPOINTS.LG,
  mdAndUp: width >= BREAKPOINTS.MD,
  md: width >= BREAKPOINTS.MD && width < BREAKPOINTS.LG,
  smAndDown: width < BREAKPOINTS.SM,
  smAndUp: width >= BREAKPOINTS.SM,
  sm: width >= BREAKPOINTS.SM && width < BREAKPOINTS.MD,
  xsm: width < BREAKPOINTS.XS
});

const getDimensions = (width, height) => ({
  width,
  height
});

export const withResize = BasicComponent =>
  class WithResize extends React.PureComponent {
    static displayName = `withResize(${getDisplayName(BasicComponent)})`;

    state = {
      dimensions: {
        scrollbarWidth: SCROLL_BAR_WIDTH,
        ...getDimensions(window.innerWidth, window.innerHeight)
      },
      viewport: getViewport(window.innerWidth)
    };

    callback = ({ target: { innerWidth, innerHeight } }) => {
      if (innerWidth !== this.state.dimensions.width) {
        this.setState(({ dimensions }) => ({
          dimensions: {
            ...dimensions,
            ...getDimensions(innerWidth, innerHeight)
          },
          viewport: getViewport(innerWidth)
        }));
      }
    };

    onResize = debounce(this.callback, 250);

    componentDidMount() {
      window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
      this.onResize.cancel();

      window.removeEventListener('resize', this.onResize);
    }

    render() {
      const { viewport, dimensions } = this.state;

      return <BasicComponent viewport={viewport} dimensions={dimensions} {...this.props} />;
    }
  };
