export const TYPE_BUTTON_ACTION_RELOAD = 'reload';
export const TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION = 'callCustomAction';
export const TYPE_ORIGIN_SNOOZE = 'snoozeSpecialCondition';
export const TYPE_ORIGIN_UPDATE_MARKETING_LISTS = 'updateMarketingLists';
export const TYPE_ORIGIN_RATE_APP = 'rate';
export const TYPE_ORIGIN_POLL = 'simplePoll';
export const TYPE_SKIP_SECTION = 'skipSection';
export const TYPE_CONTENT_LINK = 'linkWithContentStatus';
export const TYPE_FAQ_MODAL = 'faqModal';
export const TYPE_BACK_BUTTON = 'backButton';
