const all = (iterable: Iterable<Promise<unknown>>): Promise<unknown> => {
  const targets = [...iterable];

  return new Promise((resolve, reject) => {
    const responses: unknown[] = [],
      length = targets.length;
    let count = 0;

    if (length < 1) {
      resolve(responses);
    }

    targets.forEach((entity, index) => {
      Promise.resolve(entity)
        .then(res => {
          responses[index] = res;
          count++;
        }, reject)
        .then(() => {
          if (count === length) {
            resolve(responses);
          }
        });
    });
  });
};

const allSettled = (iterable: Iterable<Promise<unknown>>): Promise<unknown> => {
  const targets = [...iterable];

  return new Promise(resolve => {
    const responses: unknown[] = [],
      length = targets.length;
    let count = 0;

    if (length < 1) {
      resolve(responses);
    }

    targets.forEach((entity, index) => {
      Promise.resolve(entity)
        .then(
          value => {
            responses[index] = {
              status: 'fulfilled',
              value
            };
          },
          reason => {
            responses[index] = {
              status: 'rejected',
              reason
            };
          }
        )
        .then(() => {
          if (++count === length) {
            resolve(responses);
          }
        });
    });
  });
};

const queueMicrotask = (callback: () => void) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/queueMicrotask#when_queuemicrotask_isnt_available
  return Promise.resolve()
    .then(callback)
    .catch(e =>
      setTimeout(() => {
        throw e;
      })
    );
};

const delay = (timeout = 0): Promise<number> => new Promise(resolve => setTimeout(resolve, timeout));

const unless = (promise: Promise<unknown>, timeout = 0): Promise<unknown> => {
  const startAt = performance.now();

  const onSettled =
    (settle: CallableFunction) =>
    (payload: unknown): void => {
      const timestamp = performance.now() - startAt;

      if (timestamp < timeout) {
        delay(timeout - timestamp).then(() => settle(payload));
      } else {
        settle(payload);
      }
    };

  return new Promise((resolve, reject) => Promise.resolve(promise).then(onSettled(resolve), onSettled(reject)));
};

export class PromiseShim {
  static all = Promise.all?.bind(Promise) ?? all;
  static allSettled = Promise.allSettled?.bind(Promise) ?? allSettled;
  static queueMicrotask = window.queueMicrotask?.bind(window) ?? queueMicrotask;
  static delay = delay;
  static unless = unless;
}
