export const buildAddress = ({ location, dictionary }) => {
  if (location) {
    return (
      dictionary?.items?.reduce((address, { key, separator = '' }) => {
        const value = location[key];

        if (value) {
          return address + value + separator;
        }

        return address;
      }, '') ?? ''
    );
  }

  return '';
};

export const mapAddressAsSelectItem = address =>
  address && {
    id: address.uprn,
    label: address.address,
    value: address
  };
