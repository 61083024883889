import { isNil } from '@packages/helpers/core/common';
import { numericRegex } from '../regex';

export const toNumberFormat = value => {
  if (isNil(value)) {
    return '';
  }

  return Number(value.toString().replace(numericRegex, ''));
};
