import React from 'react';
import { connect } from 'react-redux';
import { compose, defaultProps } from 'recompose';
import { Checklist as ChecklistMenu } from '../../templates/checklist';
import { Footer } from '../../components/layout/page-footer';
import { withMetaTags } from '../../components/meta-tags';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';

const SEO_PAGE_DATA_NAME = 'checklist';

const ChecklistComponent = () => (
  <>
    <ChecklistMenu />
    <Footer pageType={VISIBILITY_PAGE_TYPES.checklist} />
  </>
);

const mapStateToProps = state => ({
  metaData: state.content.seoPages[SEO_PAGE_DATA_NAME]
});

export const Checklist = compose(
  connect(mapStateToProps),
  defaultProps({ seoName: SEO_PAGE_DATA_NAME }),
  withMetaTags
)(ChecklistComponent);
