import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionNiNInput = React.memo(
  ({ label, placeholder, value, errorMessages, onChange, onError, onSubmit, explainer }) => (
    <Input
      type='text'
      placeholder={placeholder}
      inputType='text'
      label={label}
      format='upper'
      value={value}
      min={9}
      max={9}
      errorMessages={errorMessages}
      mask='aa999999a'
      maskChar={null}
      onError={onError}
      onChange={onChange}
      onSubmit={onSubmit}
      explainer={explainer}
    />
  )
);
