import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-default-value';

const worker = workerBase();

/**
 * Helps to recursively parse the passed object and replace interpolated string with appropriate user attr
 * @param object - object for parsing
 * @param params - user attributes data
 * @return {object} - returns object with parsed data in it
 */
const processObjects = async (object, params) => {
  const newObj = {};

  const promiseList = Object.keys(object).map(async key => {
    const value = object[key];

    if (ObjectShim.isObject(value)) {
      newObj[key] = await processObjects(value, params);

      return;
    }

    if (StringShim.isString(value)) {
      newObj[key] = await worker.evaluate(value, params);

      return;
    }

    newObj[key] = value;
  });

  await PromiseShim.all(promiseList);

  return newObj;
};

export const parseDefaultValue = async (userAttributes, value, extraParams) => {
  const params = {
    userAttributes,
    ...extraParams
  };

  if (ObjectShim.isObject(value)) {
    return await processObjects(value, params);
  }

  return await worker.evaluate(value, params);
};
