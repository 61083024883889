import React from 'react';
import { Heading, Body } from '../../../components/layout/page-header/components';
import { PointsWidgetVM } from '../vm-points';

export const HeadingVMPoints = () => {
  const {
    POINTS: {
      POINTS_HOME: { title }
    }
  } = window.CONFIG;

  return (
    <>
      <Body>
        <Heading>{title}</Heading>
        <PointsWidgetVM />
      </Body>
    </>
  );
};
