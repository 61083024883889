export class StorageListenerService {
  static listeners = {};
  static handleStorageEvent = null;

  static addStorageListener(key, callback) {
    if (!Object.keys(this.listeners).length) {
      // Bind the event handler once and store the reference
      this.handleStorageEvent = this._handleStorageEvent.bind(this);
      window.addEventListener('storage', this.handleStorageEvent);
    }

    if (!this.listeners[key]) {
      this.listeners[key] = [];
    }

    this.listeners[key].push(callback);
  }

  static removeStorageListener(key, callback) {
    if (!this.listeners[key]) return;

    this.listeners[key] = this.listeners[key].filter(listener => listener !== callback);

    if (this.listeners[key].length === 0) {
      delete this.listeners[key];
    }

    if (Object.keys(this.listeners).length === 0) {
      this.removeGlobalStorageListener();
    }
  }

  static _handleStorageEvent(event) {
    if (event.key in this.listeners) {
      this.listeners[event.key].forEach(listener => listener(event.newValue));
    }
  }

  static clear() {
    this.listeners = {};

    this.removeGlobalStorageListener();
  }

  static removeGlobalStorageListener() {
    window.removeEventListener('storage', this.handleStorageEvent);
    this.handleStorageEvent = null;
  }
}
