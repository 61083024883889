import { api } from '../../api';
import { ConfigService } from '../../services/config-service';

/**
 * Triggers the calculation of the footprint
 * @param name {('Home-quiz' | 'Travel-quiz')}
 * @return {Promise<Response|{}>}
 */

export const calculationCall = async name => {
  const flowTypes = ConfigService.get('FOOTPRINT_CALCULATOR.activityToFlowType', {});
  const flow = flowTypes[name];

  const result = await api.footprintCalculator.footprintCalculate({ flow });

  return await result.json();
};
