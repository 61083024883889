import { calculationCall } from '../footprint-calculator/calculation-call';

// TODO: move this functionality to store middleware apps/firsthomecoach/src/store/middlewares/helpers/after-activity-complete.js
const nameToMethod = {
  'Home-quiz': calculationCall,
  'Travel-quiz': calculationCall
};

/**
 * Allows to call set actions on checkpoint screens
 * @param name {string}
 * @return {*}
 */

export const callActionBeforeResultPage = async name => {
  const method = nameToMethod[name];

  if (method) {
    return await method(name);
  }
};
