import { CURRENT_MORTGAGE_OUTSTANDING_KEY, getPropertyMortgages, sumByKey, toNumber } from './helpers';

const LTV = 'ltv';
const RENTAL_INCOME = 'rentalIncome';
const MARKET_RENTAL_ESTIMATE = 'marketRentalEstimate';
const RENTAL_OPPORTUNITY = 'rentalOpportunity';
const MORTGAGE_OUTSTANDING = 'mortgageOutstanding';
const EQUITY_AVAILABLE = 'equityAvailable';
const EQUITY = 'equity';

export const generateGeneralProps = (properties, mortgages) => {
  let ltv = 0;
  let rentalIncome = 0;
  let marketRentalEstimate = 0;
  let mortgageOutstanding = 0;
  let equityAvailable = 0;
  let equity = 0;

  try {
    Object.keys(properties).forEach(propertyKey => {
      const property = properties[propertyKey];

      const propertyMortgages = getPropertyMortgages(mortgages, propertyKey);
      const propertyMortgageOutstanding = sumByKey(propertyMortgages, CURRENT_MORTGAGE_OUTSTANDING_KEY);
      mortgageOutstanding += propertyMortgageOutstanding;
      const ltvCalculation = (propertyMortgageOutstanding / Number(property.value ?? 0)) * 100;

      rentalIncome += Number(property.rentalIncome ?? 0);
      marketRentalEstimate += Number(property.marketRental ?? 0);
      ltv += !isNaN(ltvCalculation) ? ltvCalculation : 0;
      equityAvailable += Number(property.equityRelease ?? 0);
      equity += Number(property.value ?? 0) - propertyMortgageOutstanding;
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  return {
    [LTV]: !isNaN(ltv) && properties ? ltv / Object.keys(properties).length : 0,
    [RENTAL_INCOME]: toNumber(rentalIncome),
    [MARKET_RENTAL_ESTIMATE]: toNumber(marketRentalEstimate),
    [RENTAL_OPPORTUNITY]: toNumber(marketRentalEstimate - rentalIncome),
    [MORTGAGE_OUTSTANDING]: toNumber(mortgageOutstanding),
    [EQUITY_AVAILABLE]: toNumber(equityAvailable),
    [EQUITY]: toNumber(equity)
  };
};
