import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { sendAccounts } from '../../../store/reducers/user-attributes/actions';
import { prettifyName } from '../helpers/prettify-name';
import { QuestionTextInput } from '../inputs/text';
import { DynamicInputQuestion } from './components/dynamic-input';

export const DepositBuilderAccountNameQuestion = props => <DynamicInputQuestion {...props} component={Input} />;

const mapStateToProps = state => ({
  previousState: state.userAttributes.accounts.items
});

const Input = compose(
  connect(mapStateToProps, { sendAccounts }),
  withState('value', 'onChange', ''),
  withHandlers({
    onSubmit:
      ({ sendAccounts, userAttributeField, value, history, nextPageLink, previousState }) =>
      async () => {
        const prettifiedName = prettifyName(value, previousState, '-');

        if (userAttributeField) {
          await sendAccounts({
            items: {
              ...previousState,
              [prettifiedName]: { ...previousState[prettifiedName], [userAttributeField]: value, type: 'others' }
            }
          });
        }

        history.push(`${nextPageLink}/${prettifiedName}`);
      }
  })
)(QuestionTextInput);
