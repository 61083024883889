import React, { forwardRef, ReactElement, ElementType } from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { mapClassName } from './helpers/class-names';

type OwnProps = {
  children?: React.ReactNode;
  className?: string;
};

export type GridCSSProperties = {
  '--app-custom-grid-cols'?: number | string;
  '--app-custom-grid-rows'?: number | string;
  '--app-custom-grid-gap-row'?: number | string;
  '--app-custom-grid-gap-column'?: number | string;
  '--app-custom-grid-width'?: number | string;
};

type GridProps<Element extends ElementType> = {
  fill?: boolean;
  noGutter?: boolean;
  style?: GridCSSProperties;
} & PolymorphicComponentProps<Element, OwnProps>;

const defaultElement = 'div';

export const GridComponent = <T extends ElementType = typeof defaultElement>(
  { children, className, fill, noGutter, ...props }: GridProps<T>,
  ref?: typeof props.ref
): JSX.Element => {
  return (
    <Box
      as={defaultElement}
      ref={ref}
      className={mapClassName('provider', className, { fill, 'no-gutter': noGutter })}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Grid: <T extends ElementType = typeof defaultElement>(props: GridProps<T>) => ReactElement | null =
  forwardRef(GridComponent);

type ColumnProps<Element extends ElementType> = {
  auto?: boolean;
} & PolymorphicComponentProps<Element, OwnProps>;

export const ColumnComponent = <T extends ElementType = typeof defaultElement>(
  { children, className, auto, ...props }: ColumnProps<T>,
  ref?: typeof props.ref
): JSX.Element => {
  return (
    <Box as={defaultElement} ref={ref} className={mapClassName('column', className, { auto })} {...props}>
      {children}
    </Box>
  );
};

export const Column: <T extends ElementType = typeof defaultElement>(props: ColumnProps<T>) => ReactElement | null =
  forwardRef(ColumnComponent);
