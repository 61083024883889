import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from '../../styles/components/card-slider.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { SampleStoryNextArrow, SampleStoryPrevArrow } from './components/arrows';
import { defaultSliderSettings } from './index';

const StoryCardsSlider = withMemoAndRef(({ sliderConfiguration, className, beforeChange, name, children }, ref) => {
  const arrowConfiguration = {
    prevArrow: <SampleStoryPrevArrow />,
    nextArrow: <SampleStoryNextArrow />
  };

  const accessibilityConfiguration = {
    pauseOnFocus: true,
    accessibility: true,
    // TODO: Dirty (?)
    // Force focus to reproduce accessibility announcement.
    afterChange: current => document.getElementById(`slide-${current}`)?.focus()
  };

  const mergedSliderConfig = {
    ...defaultSliderSettings,
    ...sliderConfiguration,
    ...(sliderConfiguration.arrows && { ...arrowConfiguration }),
    ...accessibilityConfiguration
  };

  const inlineStyles = {
    '--custom-slides-to-show': mergedSliderConfig.slidesToShow
  };

  return (
    <Slider
      style={inlineStyles}
      className={classnames(styles.carousel, className)}
      beforeChange={beforeChange}
      ref={ref}
      {...mergedSliderConfig}
    >
      {children}
    </Slider>
  );
});

StoryCardsSlider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  sliderConfiguration: PropTypes.object, //Configurations Docs https://react-slick.neostack.com/docs/api
  className: PropTypes.string,
  beforeChange: PropTypes.func
};

export { StoryCardsSlider };
