export const HEADER_TYPES = {
  split: 'split',
  default: 'default'
};

/**
 *
 * @param type {string}
 * @return {string} - by default, to support the existing products, the `split` configuration will be returned
 */

export const getHeaderType = type => {
  if (type && HEADER_TYPES[type]) {
    return HEADER_TYPES[type];
  }

  return HEADER_TYPES.split;
};
