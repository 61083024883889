import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import styles from '../../../../styles/widgets/carousel/custom-carousel.module.scss';

export const DEFAULT_DELTA_X = 0;
export const STARTING_SHIFT_X = 100;

export const SwipeableWrapper = React.memo(({ onSwipedRight, onSwipedLeft, onSwipedDown, onSwipedUp, children }) => {
  const [shiftX, setShiftX] = useState(DEFAULT_DELTA_X);
  const updateShiftX = useCallback(({ deltaX = DEFAULT_DELTA_X }) => setShiftX(deltaX), []);
  const resetShiftX = useCallback(() => setShiftX(DEFAULT_DELTA_X), []);

  const swipeable = useSwipeable({
    onSwipedRight,
    onSwipedLeft,
    onSwipedDown,
    onSwipedUp,
    onSwiping: updateShiftX,
    onSwiped: resetShiftX,
    onTouchEndOrOnMouseUp: resetShiftX,
    delta: STARTING_SHIFT_X,
    preventScrollOnSwipe: true
  });

  return (
    <div className={styles['swipe-wrapper']} {...swipeable}>
      <div style={{ transform: `translateX(${shiftX}px)` }}>{children}</div>
    </div>
  );
});

SwipeableWrapper.displayName = 'SwipeableWrapper';

SwipeableWrapper.propTypes = {
  onSwipedRight: PropTypes.func.isRequired,
  onSwipedLeft: PropTypes.func.isRequired,
  onSwipedDown: PropTypes.func,
  onSwipedUp: PropTypes.func,
  children: PropTypes.node.isRequired
};
