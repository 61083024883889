import React from 'react';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { branch, renderComponent, withPropsOnChange } from 'recompose';
import styles from '../../styles/components/toggle-switch.module.scss';
import { ConfigService } from '../../services/config-service';
import { Input } from './checkbox/checkbox';
import { withAnchor, withContainer, withExplainer, withLabel } from './input-field';

const Component = React.memo(({ id, value = false, className, ...props }) => (
  <div className={className}>
    <Input id={id} checked={value} className={styles.input} {...props} />
    {/*The disadvantage of a very custom implementation.*/}
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={id} className={styles.body} />
  </div>
));

export const ToggleSwitch = withAnchor(Component);

const MandatoryComponent = React.memo(({ ...props }) => {
  const { lockedActive = 'Always active', lockedInactive = 'Disallowed' } = ConfigService.get(
    'QUESTIONS.inputs.toggleSwitch',
    {}
  );

  const label = props.value ? lockedActive : lockedInactive;

  return <div className={classnames(styles.mandatory, { [styles.disallowed]: !props.value })}>{label}</div>;
});

const withMandatoryState = compose(
  withPropsOnChange(['locked'], ({ locked }) => ({ mandatory: locked })),
  branch(({ mandatory }) => mandatory, renderComponent(MandatoryComponent))
);

export const InputToggleSwitch = compose(
  withAnchor,
  withContainer,
  withExplainer,
  withLabel({ className: styles.label, layout: styles.container }),
  withMandatoryState
)(Component);
