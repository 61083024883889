import { compose, withHandlers, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { evaluateStringCondition } from '../../helpers/checklist/display-condition';
import { omitProps } from '../../helpers/hocs/omit-props';
import { ConfigService } from '../../services/config-service';
import { createConditionStateSelector } from '../../helpers/display-condition/create-condition-state-selector';

const mapStateToProps = params => {
  const config = ConfigService.get('CHECKLIST', {});
  return createConditionStateSelector(config?.conditionVersion)(params);
};

export const withDisplayCondition = compose(
  connect(mapStateToProps),
  withHandlers(() => ({
    isDisplayConditionMet:
      ({ userState, displayCondition }) =>
      () => {
        if (!displayCondition) return true;
        return evaluateStringCondition(displayCondition, userState);
      }
  })),
  branch(({ isDisplayConditionMet }) => !isDisplayConditionMet(), renderNothing),
  omitProps(['isDisplayConditionMet'])
);
