export const changePoints = state => element => {
  const {
    DISPLAY_MODULES: { POINTS }
  } = window.CONFIG;

  const elements = element.getElementsByClassName('promotions');
  const promotions = state.consents && state.consents.promotions;

  if (!POINTS || !promotions) {
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  return element;
};
