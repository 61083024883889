import React from 'react';
import { configuration } from '../../../api/configuration';
import { withAutoCompleteHandlers } from './components/autocomplete/handlers';
import { AutocompleteInput } from './components/autocomplete';

const Input = withAutoCompleteHandlers({
  valueName: 'name',
  // eslint-disable-next-line no-template-curly-in-string
  userAttributeName: 'pension.items.${identifier}.pensionProvider',
  method: configuration.getPensionProvider
})(AutocompleteInput);

export const QuestionPensionProviderInput = React.memo(
  ({ label, placeholder, value, onChange, onSubmit, explainer }) => (
    <Input
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      explainer={explainer}
    />
  )
);
