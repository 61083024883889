import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { ConfigService } from '../../../../../services/config-service';
import { Grid, Column } from '../../../grid';
import { SkipLink } from '../../../skip-link';
import { SafeArea } from '../../../safe-area';
import { withApplicationHeader } from '../../component';
import { Logo as AppLogo } from '../../components/logo';
import { CollapsedNav } from './collapsed-nav';
import { NavigationArea } from './navigation-area';

export const ApplicationHeader = React.memo(({ className }) => {
  const { buttons } = ConfigService.get('NAVIGATION_BAR', {});
  const [expanded, setExpanded] = useState(false);
  const disableNavigation = useSelector(state => state.navigation.disableNavigation);

  const toggle = () => setExpanded(!expanded);
  const showNavigations = !disableNavigation && !!buttons?.length;
  const showCollapsedNavigation = expanded && !disableNavigation;

  return (
    <SafeArea edges={['top', 'left', 'right']}>
      <header className={classnames(styles.header, className)}>
        <SkipLink />
        <Grid>
          <Column>
            <div className={styles.container}>
              <AppLogo className={styles.logo} />
              {showNavigations ? <NavigationArea buttons={buttons} expanded={expanded} toggle={toggle} /> : null}
            </div>
          </Column>
        </Grid>
        {showCollapsedNavigation ? <CollapsedNav expanded={expanded} buttons={buttons} toggle={setExpanded} /> : null}
      </header>
    </SafeArea>
  );
});

export const DesktopHeader = withApplicationHeader(ApplicationHeader);
