import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/screen.module.scss';
import { withResize } from '../with-resize';
import { SafeArea } from './safe-area';

const ScreenComponent = React.memo(({ viewport, className, children }) => (
  <SafeArea className={styles.container}>
    <div className={classnames(className, 'grid fill-height')}>
      {typeof children === 'function' ? children(viewport) : children}
    </div>
  </SafeArea>
));

export const Screen = withResize(ScreenComponent);
