import { useHistory } from 'react-router';
import { parse } from 'query-string';
import { useMemo } from 'react';
import { branch, compose } from 'recompose';
import * as NAVIGATION from '../../../../helpers/navigation';
import { HeaderIcon } from '../default';
import { isVisible, not, useLayoutSection } from '../../with-layout';
import { LAYOUT_SECTIONS } from '../../../../helpers/layout/sections';
import { BREAKPOINTS_MATCHES } from '../../../with-resize';
import { withEmbeddedContext } from '../../with-embedded-context';

const Component = () => {
  const history = useHistory();
  const location = history.location;
  const isDisplay = Boolean(history.location.key);

  const { goTo } = useMemo(() => {
    const { pathname, search } = location;

    const isExternal = parse(search).is_external;

    let backNavigation = {
      label: '',
      goTo: () => history.goBack()
    };

    if (isExternal) {
      if (pathname.includes(NAVIGATION.ARTICLES)) {
        backNavigation = {
          label: '',
          goTo: () => history.push(`/${NAVIGATION.ARTICLES}`)
        };
      }

      if (pathname.includes(NAVIGATION.CHECKLIST)) {
        backNavigation = {
          label: '',
          goTo: () => history.push(`/${NAVIGATION.CHECKLIST}`)
        };
      }
    }

    return backNavigation;
  }, [history, location]);

  useLayoutSection(LAYOUT_SECTIONS.TOP_BACK_BUTTON);

  return isDisplay && <HeaderIcon name='nav-back' onClick={goTo} />;
};

export const BackButton = compose(
  withEmbeddedContext,
  branch(
    ({ isEmbedded }) => !isEmbedded,
    isVisible({
      onBreakpoints: [BREAKPOINTS_MATCHES.MD_AND_DOWN],
      forTemplates: [not(NAVIGATION.TOUR), not(NAVIGATION.BARRIER), not(NAVIGATION.NOT_FOUND)]
    })
  )
)(Component);
