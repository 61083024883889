import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';
import { resetStaticNavChecklistLink } from '../../store/reducers/navigation';
import { ConfigService } from '../../services/config-service';

export const withStaticChecklist = compose(
  connect(null, { resetStaticNavChecklistLink }),
  withProps(() => {
    const { SECTIONS } = ConfigService.get('CHECKLIST', {});

    return {
      checklist: SECTIONS
    };
  }),
  lifecycle({
    componentDidMount() {
      const { resetStaticNavChecklistLink } = this.props;

      resetStaticNavChecklistLink();
    }
  })
);
