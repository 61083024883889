import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { getMonthFromTimeframe } from '../../../../helpers/time-format';

const FORMATTERS = {
  value: {},
  computed: {
    time: getMonthFromTimeframe
  }
};

export const useFormatter = (value, computed, format) => {
  const formatted = {
    value,
    computed
  };

  if (ObjectShim.isObject(format)) {
    Object.keys(format).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(formatted, key)) {
        const formatter = format[key];

        if (FunctionShim.isFunction(formatter)) {
          return (formatted[key] = formatter(formatted[key]));
        }

        const valueFormatter = FORMATTERS[key][formatter];

        if (FunctionShim.isFunction(valueFormatter)) {
          return (formatted[key] = valueFormatter(value));
        }
      }
    });
  }

  if (FunctionShim.isFunction(format)) {
    formatted.value = format(value);
  }

  return formatted;
};
