import { useState, useEffect } from 'react';
import { isNil } from '@packages/helpers/core/common';
import { useOnViewportIntersect } from './use-on-viewport-intersect';

const DEFAULT_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
  once: true
};

export const useLazyImg = (src, placeholder, options) => {
  const [state, setState] = useState(() => ({
    src: placeholder ?? src,
    error: isNil(src) && isNil(placeholder),
    blurred: !!placeholder
  }));

  const [ref, visible] = useOnViewportIntersect({
    ...DEFAULT_OPTIONS,
    ...options
  });

  useEffect(() => {
    // Setting state on an unmounted component leads to a memory leak.
    let detached = false;

    const img = new Image();

    img.src = src;

    img.onload = () => {
      if (!detached) {
        setState({
          src: src,
          error: false,
          blurred: false
        });
      }
    };

    img.onerror = error => {
      if (!detached) {
        setState(({ src: prev }) => ({
          src: prev,
          error: error,
          blurred: false
        }));
      }
    };

    return () => {
      detached = true;
    };
  }, [visible, src]);

  return { ...state, ref: isNil(placeholder) ? null : ref };
};
