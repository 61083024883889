import { USER_SERVICE_KEYS, UserService } from '../../services/user-service';

const getDefaultHeaders = () => {
  return new Headers({ 'Content-Type': 'application/json' });
};

export const createHeaders = type => {
  const headers = getDefaultHeaders();
  const token = UserService.getToken() ?? '';
  const shadowId = UserService.getItem(USER_SERVICE_KEYS.SHADOW_ID) ?? '';

  switch (type) {
    case 'content':
    case 'static':
      break;
    case 'main':
    default: {
      headers.set('authorization', token);
      headers.set('X-Shadow-User', shadowId);
      break;
    }
  }

  return headers;
};
