export const SELECT_SERVICE_KEY = 'selects';

export class SelectService {
  static getSelects() {
    const selects = sessionStorage.getItem(SELECT_SERVICE_KEY);

    if (selects) {
      return JSON.parse(selects);
    } else {
      const selects = {};

      sessionStorage.setItem(SELECT_SERVICE_KEY, JSON.stringify(selects));

      return selects;
    }
  }

  static deleteSelects() {
    const selects = sessionStorage.getItem(SELECT_SERVICE_KEY);

    if (selects) {
      sessionStorage.removeItem(SELECT_SERVICE_KEY);
    }

    return Boolean(selects);
  }

  static setSelects({ name, value }) {
    const selects = {
      ...this.getSelects(),
      [name]: value
    };
    sessionStorage.setItem(SELECT_SERVICE_KEY, JSON.stringify(selects));

    return value;
  }
}
