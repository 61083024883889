const getExpirationDate = (hours = 1) => {
  const date = new Date();

  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date.toUTCString();
};

export const setCookie = (name, value = '', options = {}) => {
  const { expires, ...restOptions } = options;

  const attributes = {
    path: '/',
    expires: getExpirationDate(expires),
    ...restOptions
  };

  let cookie = `${name}=${value};`;

  for (const attr in attributes) {
    if (attributes.hasOwnProperty(attr)) {
      cookie += `${attr}=${attributes[attr]};`;
    }
  }

  document.cookie = cookie;
};

export const readCookie = name => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const hasCookie = name => {
  return document.cookie.split(';').some(cookie => cookie.trim().startsWith(name + '='));
};

export const getCookiesByOrigin = name => {
  return document.cookie
    .split(';')
    .filter(cookie => cookie.trim().startsWith(name))
    .map(cookie => cookie.trim().split('=')[0]);
};

export const deleteCookie = (name, options = {}) => {
  const { byOrigin, domains = [], ...restOptions } = options;

  let cookies = [];

  if (byOrigin) {
    // 'Origin; means that we are going to delete all cookies matched by provided template
    // For instance: '_ga' will delete all the cookies startedWith '_ga' string
    cookies = getCookiesByOrigin(name);
  } else if (hasCookie(name)) {
    cookies = cookies.concat(name);
  }

  cookies.forEach(cookie => {
    // To delete a cookie we accualy need to provide the same domain
    // as was used (by app or some external script) for setting it.

    // In most case it would be the hostname only.
    // Additional domains would come from options object.
    [window.location.hostname, ...domains].forEach(domain => {
      // To delete a cookie it is enough to set empty value and negative expiration date
      setCookie(cookie, '', { ...restOptions, domain, expires: -1 });
    });
  });
};
