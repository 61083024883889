import React from 'react';
import { ConfigService } from '../../../services/config-service';
import { DesktopHeader } from './custom/desktop';
import { DefaultHeader } from './component';

const appHeaders = {
  desktop: DesktopHeader
};

export const ApplicationHeader = props => {
  const { name } = ConfigService.get('NAVIGATION_BAR', {});

  if (name && appHeaders[name]) {
    const CustomHeader = appHeaders[name];

    return <CustomHeader {...props} />;
  }

  return <DefaultHeader {...props} />;
};
