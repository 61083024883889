import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { withLoadingHandlers, withSpinner } from '../../../components/with-loader';
import { getGuidanceList } from '../../../store/reducers/guidance';
import { getGoalsListBySelector, mapGoalsListToSelectItems } from '../../../helpers/guidance';
import { Select } from './components/select';

const PriorityGoalsComponent = React.memo(
  ({
    items,
    value,
    placeholder,
    label,
    onChange,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    preSubmitButton,
    informer
  }) => {
    // item.name and value.key are both equal to guidance slug prop
    const currentValue = useMemo(() => items.find(item => item.name === value?.key)?.label, [items, value]);

    return (
      <Select
        name='priority-goals-select'
        label={label}
        placeholder={placeholder}
        value={currentValue}
        items={items}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        preSubmitButton={preSubmitButton}
        advancedInformer={informer}
      />
    );
  }
);

const mapStateToProps = ({ guidance, userAttributes }) => {
  return {
    guidanceList: guidance.list,
    userAttributes
  };
};

export const QuestionPriorityGoalsInput = compose(
  connect(mapStateToProps, { getGuidanceList }),
  // `selectGoals` is set in input's config
  withPropsOnChange(['guidanceList', 'userAttributes'], ({ guidanceList, userAttributes, selectGoals }) => {
    if (!guidanceList.length) return { items: [] };

    const goals = getGoalsListBySelector({ guidances: guidanceList, userAttributes, goalsSelector: selectGoals });
    const items = mapGoalsListToSelectItems(goals);

    return { items };
  }),
  withLoadingHandlers(true),
  lifecycle({
    async componentDidMount() {
      const { items, getGuidanceList, turnOffLoading } = this.props;

      if (!items.length) {
        await getGuidanceList();
      }
      turnOffLoading();
    }
  }),
  withSpinner
)(PriorityGoalsComponent);
