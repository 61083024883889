import React from 'react';
import { compose } from 'recompose';
import { PropertyAddressInput } from '../../../components/inputs/property-address';
import { withAdvancedInformer, withAnchor, withError, withExplainer } from '../../../components/inputs/input-field';
import { withContainer, withSubmit } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(
  withAnchor,
  withContainer,
  withSubmit,
  withError,
  withExplainer,
  withPreSubmitButton,
  withAdvancedInformer
)(PropertyAddressInput);

export const QuestionPropertyAddressInput = React.memo(
  ({
    labels,
    messages,
    value,
    errorMessages,
    onChange,
    onError,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    placeholders,
    preSubmitButton,
    explainer,
    isRequired,
    isOptional,
    informer,
    maxResults
  }) => (
    <Input
      placeholders={placeholders}
      labels={labels}
      messages={messages}
      value={value}
      errorMessages={errorMessages}
      onChange={onChange}
      onError={onError}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      preSubmitButton={preSubmitButton}
      explainer={explainer}
      isRequired={isRequired}
      isOptional={isOptional}
      advancedInformer={informer}
      maxResults={maxResults}
    />
  )
);
