import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { createReducer } from '../utils';
import { api } from '../../api';
import { isDataSharingAllowed } from '../../helpers/consents';
import { CONSENT_COOKIE_TYPES } from '../../helpers/constants';

const GET_CONSENT_LIST_REQUEST = 'GET_CONSENT_LIST_REQUEST';
export const GET_CONSENT_LIST_SUCCESS = 'GET_CONSENT_LIST_SUCCESS';
const GET_CONSENT_LIST_FAILURE = 'GET_CONSENT_LIST_FAILURE';

export const getConsentList = location => async dispatch => {
  dispatch({ type: GET_CONSENT_LIST_REQUEST });
  try {
    const response = await api.configuration.getConsentList();
    const payload = await response.json();

    const dataSharingAllowed = isDataSharingAllowed(location.search);

    const normalized = ArrayShim.normalize(payload, 'type');

    const defaultConsents = ObjectShim.map(normalized, ({ optInDefault, type }) =>
      dataSharingAllowed ? optInDefault : type === CONSENT_COOKIE_TYPES.essential ? true : optInDefault
    );

    const list = !dataSharingAllowed
      ? ObjectShim.map(normalized, item => {
          if (item.type === CONSENT_COOKIE_TYPES.tracking || item.type === CONSENT_COOKIE_TYPES.advertising) {
            item.locked = true;
          }
          return item;
        })
      : normalized;
    return dispatch({ type: GET_CONSENT_LIST_SUCCESS, normalized, defaultConsents, list, dataSharingAllowed, payload });
  } catch (e) {
    dispatch({ type: GET_CONSENT_LIST_FAILURE });
  }
};

const initialState = {
  list: [],
  normalizedList: {},
  loading: true,
  error: false
};

export const consentList = createReducer(initialState, {
  [GET_CONSENT_LIST_REQUEST]: state => ({ ...state, loading: true }),
  [GET_CONSENT_LIST_SUCCESS]: (state, { payload, defaultConsents, list, dataSharingAllowed }) => {
    return {
      ...state,
      list: payload,
      normalizedList: list,
      defaults: defaultConsents,
      loading: false,
      error: false,
      dataSharingAllowed
    };
  },
  [GET_CONSENT_LIST_FAILURE]: state => ({ ...state, loading: false, errorConsent: true })
});
