import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { sendConsents } from '../../../../../store/reducers/user-attributes/actions';
import { CONSENT_COOKIE_TYPES } from '../../../../../helpers/constants';

const mapStateToProps = state => ({
  isDataSharingAllowed: state.consentList.dataSharingAllowed,
  consents: state.userAttributes.consents
});

export const withDataSharingAllowed = compose(
  connect(mapStateToProps, { sendConsents }),
  lifecycle({
    async componentDidMount() {
      const { isDataSharingAllowed, sendConsents, consents } = this.props;

      if (!isDataSharingAllowed) {
        const currentConsents = Object.keys(consents).reduce((acc, cur) => {
          if (cur !== CONSENT_COOKIE_TYPES.essential && cur !== CONSENT_COOKIE_TYPES.promotions) {
            acc[cur] = false;
          }

          return acc;
        }, {});

        await sendConsents({ ...consents, ...currentConsents });
      }
    }
  })
);
