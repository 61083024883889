import { compose } from 'redux';

const generateTimestampId = () => Math.floor(Date.now() + Math.random() * 100).toString();

export const replaceSpaceWith = (str, char = '-') => str.trim().toLowerCase().replace(/[ ,]+/g, char);

export const enhanceName =
  (items, separator = '') =>
  str => {
    const id = generateTimestampId();

    if (items.hasOwnProperty(str)) {
      while (items.hasOwnProperty(str)) {
        str = str + separator + `${id}`;
      }
    }

    return str;
  };

export const prettifyName = (str, items, separator) => compose(enhanceName(items, separator), replaceSpaceWith)(str);
