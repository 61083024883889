import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { NativeAction } from '../../../services/native-action';
import { SET_NATIVE_TRIGGER_LOADING_STATE } from '../../../store/reducers/application';
import { checkPageInitialization, createPageIdentifier } from '../../../helpers/reducers/application';
import { applicationLoadingActions, applicationLoadingState } from './with-native-loading-state';

export const withNativeLoadingTrigger = compose(
  connect(applicationLoadingState, applicationLoadingActions),
  lifecycle({
    componentDidUpdate() {
      const { location, loadingState, isNativeTriggerSent, setNativeLoadingState } = this.props;

      const pageIdentifier = createPageIdentifier(location);
      const isPageInitialized = checkPageInitialization(loadingState, pageIdentifier);

      // A message notifies native apps to disable their own loading spinner.
      if (isPageInitialized && !isNativeTriggerSent) {
        NativeAction.trigger('web_init_finished', { pageIdentifier });
        // eslint-disable-next-line no-console
        console.log(`[WNT] All ${pageIdentifier} widgets are initialized`, new Date());
        setNativeLoadingState(SET_NATIVE_TRIGGER_LOADING_STATE, true);
      }
    }
  })
);
