import { calculateSingleQuestionScore } from './calculate-single-question-score';

export const getGoals = (priorities, score) => {
  const highPriorityGoals = {};
  const allOtherGoals = {};

  Object.keys(priorities).forEach(key => {
    const lowerCaseKey = key.toLowerCase();

    if (priorities[key] === 'high') {
      highPriorityGoals[lowerCaseKey] = calculateSingleQuestionScore(score[lowerCaseKey]);
    } else {
      allOtherGoals[lowerCaseKey] = true;
    }
  });

  return { highPriorityGoals, allOtherGoals };
};
