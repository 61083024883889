import uuid from 'uuid';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { compose } from 'redux';
import { isHtmlElement } from '../../layout/element';
import { InputGroup } from '../../../components/inputs/input-group';
import { CoachMessage } from '../../../components/coach-message';
import { attachEventToElementById, getElementFromHtmlString } from './helpers';
import { renderButton } from './button';

export const changeCoachies = history => element => {
  const coachies = element.querySelectorAll('.inline-coach');

  coachies.forEach(coachBody => {
    if (isHtmlElement(coachBody)) {
      const messages = [];
      const buttons = [];

      [...coachBody.children].forEach(child => {
        if (child.classList.value?.includes('button')) {
          const element = child.querySelector('.wp-block-button') ?? child;

          buttons.push(renderButton(history)(element));
        } else {
          messages.push(<p>{child.innerText}</p>);
        }
      });

      const jsx = (
        <div className='coach'>
          <CoachMessage parse={false}>
            {messages.map(message => React.cloneElement(message, { key: uuid() }))}
          </CoachMessage>
          <InputGroup type='coach'>
            {buttons.map(({ Component }) => React.cloneElement(Component, { key: uuid() }))}
          </InputGroup>
        </div>
      );

      let component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      buttons.forEach(({ id, handler }) => {
        component = attachEventToElementById({ id, handler })(component);
      });

      coachBody.parentNode.replaceChild(component, coachBody);
    }
  });

  return element;
};
