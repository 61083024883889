import { compose } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getMortgagesByPropertyId } from '../../../../helpers/property-portfolio';

export const CURRENT_MONTHLY_MORTGAGE_KEY = 'currentMortgageMonthly';
export const CURRENT_MORTGAGE_OUTSTANDING_KEY = 'currentMortgageOutstanding';

const pickMortgages = items => keys => ObjectShim.pick(items, keys);

export const getPropertyMortgages = (mortgages, key) =>
  compose(pickMortgages(mortgages), getMortgagesByPropertyId(key), Object.keys)(mortgages);

export const sumByKey = (items, key) =>
  Object.keys(items).reduce((acc, current) => {
    return (acc += items[current][key]);
  }, 0);

export const toNumber = value => {
  try {
    if (!value || isNaN(Number(value))) {
      return 0;
    }

    return Number(value).toFixed(0);
  } catch {
    return 0;
  }
};
