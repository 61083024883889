import { useCallback, useRef, useEffect, useState } from 'react';
import { isUndefined } from '@packages/helpers/core/common';
import { isHtmlElement } from '../layout/element';
import { ScrollControl } from '../../services/scroll-control';

export const useScrollControl = (on, options) => {
  const scrollController = useRef(new ScrollControl(options));

  const [target, setTarget] = useState();

  const onRef = useCallback(node => {
    setTarget(node);
  }, []);

  const enable = useCallback(() => {
    const { current: SC } = scrollController;

    SC.enableScroll();
  }, []);

  const disable = useCallback(ref => {
    const { current: SC } = scrollController;

    SC.disableScroll(ref);
  }, []);

  useEffect(() => {
    const { current: SC } = scrollController;

    if (isHtmlElement(target) && !isUndefined(on)) {
      on ? SC.disableScroll(target) : SC.enableScroll();
    }

    return () => {
      SC.enableScroll();
    };
  }, [on, target]);

  return {
    anchor: onRef,
    enableScroll: enable,
    disableScroll: disable
  };
};
