import { LAYOUT_SECTIONS } from '../../helpers/layout/sections';
import { createReducer } from '../utils';

const SET_LAYOUT_TEMPLATE = 'SET_LAYOUT_TEMPLATE';
const SET_LAYOUT_SECTION = 'SET_LAYOUT_SECTION';

const initialState = {
  template: null,
  sections: {
    [LAYOUT_SECTIONS.SIDEBAR]: false,
    [LAYOUT_SECTIONS.TOP_NAVIGATION]: false,
    [LAYOUT_SECTIONS.TOP_SECTION_NAVIGATION]: false,
    [LAYOUT_SECTIONS.TOP_BACK_BUTTON]: false,
    [LAYOUT_SECTIONS.BOTTOM_NAVIGATION]: false
  },
  sizes: {
    [LAYOUT_SECTIONS.SIDEBAR]: 0,
    [LAYOUT_SECTIONS.TOP_NAVIGATION]: 0,
    [LAYOUT_SECTIONS.BOTTOM_NAVIGATION]: 0
  }
};

export const setLayoutTemplate = payload => ({ type: SET_LAYOUT_TEMPLATE, payload });
export const setLayoutSection = payload => ({ type: SET_LAYOUT_SECTION, payload });

export const layout = createReducer(initialState, {
  [SET_LAYOUT_TEMPLATE]: (state, { payload: { name } }) => ({
    ...state,
    template: name
  }),
  [SET_LAYOUT_SECTION]: (state, { payload: { name, size, visibility } }) => ({
    ...state,
    sections: { ...state.sections, [name]: visibility },
    ...(isFinite(size) ? { sizes: { ...state.sizes, [name]: size } } : {})
  })
});
