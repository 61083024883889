import { ORDER_TYPES } from '../helpers/constants';
import { get } from './utils';
import { MAIN } from './utils/constants';

const getBrokerNames = ({ value, limit = 50, order }) => {
  const orderType = ORDER_TYPES[order] ?? ORDER_TYPES.default;
  const term = value ? `term=${value}&` : '';

  return get(`/introducers/broker-names?${term}order=${orderType}&limit=${limit}`, MAIN);
};

export const introducer = {
  getBrokerNames
};
