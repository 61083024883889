import { useEffect, useRef } from 'react';

export const useDidUpdateEffect = (callback, dependencies) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      return callback();
    } else {
      didMount.current = true;
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
