import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/aspect-ratio.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';

const AspectRatioComponent = (
  { renderAs: Component = 'div', aspectRatio = '16x9', className, children, ...rest },
  ref
) => (
  <div ref={ref} className={classnames(styles.container, styles[`as-${aspectRatio}`])} {...rest}>
    <Component className={classnames(styles.content, className)}>{children}</Component>
  </div>
);

export const AspectRatioContainer = withMemoAndRef(AspectRatioComponent);
