import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import uuid from 'uuid';
import styles from '../styles/components/accordion.module.scss';
import { AccordionsService } from '../services/accordion-service';
import { withClassNames } from '../helpers/hocs/with-class-names';
import { AffiliateIcon } from './icons/affiliate-icon';
import { Collapse, CollapsePanel, CollapseTrigger } from './collapse';

const AccordingIcon = compose(withClassNames(styles.icon))(AffiliateIcon);

AccordingIcon.displayName = 'AccordingIcon';

export { AccordingIcon };

export const AccordionTrigger = React.memo(
  ({
    children,
    expanded,
    icon = <AccordingIcon name={`${expanded ? 'close' : 'open'}-vertical`} aria-hidden='true' />
  }) => (
    <div className={styles.wrapper}>
      {children}
      {icon}
    </div>
  )
);

const ActionTrigger = React.memo(({ children }) => {
  return <div className={styles['action-wrapper']}>{children}</div>;
});

export const AccordionItemTitle = React.memo(({ children }) => <div className={styles.title}>{children}</div>);

export const AccordionItem = React.memo(
  ({
    title,
    subTitle,
    ariaLabel,
    expanded,
    children,
    icon,
    onClick,
    actions,
    className,
    innerComponent,
    renderWrapperAs = 'li'
  }) => {
    const Title = typeof title === 'function' ? title : AccordionItemTitle;

    const triggerId = useMemo(() => uuid('collapse-trigger'), []);
    const panelId = useMemo(() => uuid('collapse-panel'), []);

    return (
      <Collapse
        renderAs={renderWrapperAs}
        className={classnames(styles.item, className)}
        expanded={expanded}
        triggerId={triggerId}
        panelId={panelId}
      >
        <CollapseTrigger className={styles.trigger} onClick={onClick} aria-label={ariaLabel}>
          <AccordionTrigger icon={icon} expanded={expanded}>
            <div className={styles['content-container']}>
              <div className={styles.words}>
                <Title>{title}</Title>
                {subTitle ?? <div className={styles['sub-title']}>{subTitle}</div>}
              </div>
              {innerComponent && <div className={styles.actions}>{innerComponent}</div>}
            </div>
          </AccordionTrigger>
          {actions && <ActionTrigger>{actions}</ActionTrigger>}
        </CollapseTrigger>
        <CollapsePanel>
          <div className={styles.content}>{children}</div>
        </CollapsePanel>
      </Collapse>
    );
  }
);

export const Accordion = React.memo(({ atid, children }) => {
  return (
    <ul className={styles.container} data-atid={atid}>
      {children}
    </ul>
  );
});

export const useAccordionState = (name, initial = {}) => {
  const [state, setState] = useState(() => ({
    ...initial,
    ...AccordionsService.getAll(name)
  }));

  const lastToggledKey = useRef(null);

  const toggle = useCallback(key => {
    if (key) {
      lastToggledKey.current = key;

      setState(state => ({
        ...state,
        [key]: !state[key]
      }));
    }
  }, []);

  useEffect(() => {
    const { current: key } = lastToggledKey;

    if (key) {
      AccordionsService.set(name, key, state[key]);
    }
  }, [state, name]);

  return [state, toggle];
};
