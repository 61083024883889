import { connect } from 'react-redux';
import { compose, lifecycle, renderNothing, withHandlers } from 'recompose';
import { UserService } from '../../../../../services/user-service';
import { sendConsents as sendConsentsAction } from '../../../../../store/reducers/user-attributes/actions';

export const NonUserConsent = compose(
  connect(
    state => ({
      essential: UserService.hasAccount() || state.userAttributes.consents?.essential,
      defaultConsents: state.consentList.defaults
    }),
    { sendConsentsAction }
  ),
  withHandlers({
    sendConsents:
      ({ essential, sendConsentsAction, defaultConsents }) =>
      () => {
        if (!essential) {
          sendConsentsAction(defaultConsents);
        }
      }
  }),
  lifecycle({
    componentDidMount() {
      const { sendConsents } = this.props;

      sendConsents();
    },
    componentDidUpdate(prevProps) {
      const { essential, sendConsents } = this.props;

      if (prevProps.essential !== essential) {
        sendConsents();
      }
    }
  }),
  renderNothing
)();
