import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { interpolateRegex, starsRegex } from './regex';
import { wrap } from './formatters/string-format';

export const insertVarIntoString = (template, data, wrapper) => {
  if (!template) {
    return '';
  }

  return template
    .replace(/\n/g, '<br />')
    .replace(interpolateRegex, (_, name) => wrap(ObjectShim.getNested(data, name), wrapper) ?? '');
};

export const insertVarBeforeInterpolation = (template, data) => {
  return template.replace(starsRegex, (_, name) => data[name] ?? '');
};
