import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { isUndefined } from '@packages/helpers/core/common';
import { getStaticData } from '../../../store/reducers/static-data';
import { sendQuestionSectionInfo } from '../../../store/reducers/user-attributes/actions';
import {
  FULL_FLOW_TYPE,
  SECTION_FLOW_TYPE,
  STATIC_DATA_CHECKPOINT_IDENTIFIER
} from '../../../helpers/chekpoint-page/constants';

const mapStateToProps = ({ userAttributes, statusData, staticData, points }, { activity }) => {
  const {
    sectionsParams = [],
    redirectTimeout = 2500,
    customDonePage
  } = staticData[STATIC_DATA_CHECKPOINT_IDENTIFIER]?.[activity] ?? {};

  const { questionSection } = userAttributes;
  const plainStatuses = statusData.plainStatuses;
  const userData = { ...userAttributes?.user, ...points };

  return { sectionsParams, redirectTimeout, customDonePage, questionSection, userAttributes, plainStatuses, userData };
};

export const withSectionData = compose(
  connect(mapStateToProps, { getStaticData, sendQuestionSectionInfo }),
  withState('currentSection', 'setSection', null),
  withState('isSkipped', 'setSkip', false),
  withHandlers({
    getCurrentSectionName:
      ({ sectionsParams, questionSection, location: { pathname } }) =>
      () => {
        const sections = sectionsParams.find(({ link, type }) => type === SECTION_FLOW_TYPE && link === pathname);

        return sections?.name || questionSection.currentSection || null;
      }
  }),
  withHandlers({
    setCurrentSectionInfo:
      ({
        sendQuestionSectionInfo,
        getCurrentSectionName,
        questionSection: { isSectionEdition },
        setSection,
        setSkip
      }) =>
      skipAttrUpdate => {
        const currentSection = getCurrentSectionName();

        setSkip(skipAttrUpdate);
        setSection(currentSection);

        if (!isUndefined(skipAttrUpdate)) {
          sendQuestionSectionInfo({
            currentSection,
            endOfSection: null,
            flowType: isSectionEdition ? SECTION_FLOW_TYPE : FULL_FLOW_TYPE,
            isSectionEdition: false
          });
        }
      }
  })
);
