import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import styles from '../../../styles/components/breadcrumbs.module.scss';
import { Link } from '../../link';
import { withHistory } from '../../with-history';
import { openLink } from '../../../helpers/utils';

const BreadcrumbLinkComponent = React.memo(({ name, onClick }) => (
  <Link onClick={onClick} className={styles.link} isButton>
    {name}
  </Link>
));

const BreadcrumbLink = compose(
  withHistory,
  withHandlers({
    onClick:
      ({ url, history, isExternal }) =>
      () =>
        openLink(url, history, isExternal)
  })
)(BreadcrumbLinkComponent);

BreadcrumbLink.displayName = 'BreadcrumbLink';

BreadcrumbLink.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  isExternal: PropTypes.bool
};

export { BreadcrumbLink };
