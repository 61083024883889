import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/components/breadcrumbs.module.scss';
import { BreadcrumbLink } from './components/breadcrumb-link';
import { BreadcrumbDelimiter } from './components/breadcrumb-delimiter';

export const BreadcrumbsComponent = React.memo(({ items }) => {
  return (
    <div className={styles.container}>
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbLink url={item.url} className={styles.link} name={item.name} isExternal={item.isExternal} />
          {index < items.length - 1 && <BreadcrumbDelimiter />}
        </React.Fragment>
      ))}
    </div>
  );
});

BreadcrumbsComponent.displayName = 'BreadcrumbsComponent';

BreadcrumbsComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(BreadcrumbLink.propTypes))
};
