import { GET_USER_ATTRIBUTES_SUCCESS, SEND_USER_ATTRIBUTE_SUCCESS } from '../reducers/user-attributes/actions';
import { ACTIVITY_COMPLETED } from '../reducers/status-data';
import { afterUserAttributesSend } from './helpers/after-user-attributes-send';
import { afterUserAttributesGet } from './helpers/after-user-attributes-get';
import { afterActivityComplete } from './helpers/after-activity-complete';

const triggers = {
  [SEND_USER_ATTRIBUTE_SUCCESS]: props => afterUserAttributesSend(props),
  [GET_USER_ATTRIBUTES_SUCCESS]: props => afterUserAttributesGet(props),
  [ACTIVITY_COMPLETED]: props => afterActivityComplete(props)
};

export const postActionMiddleware = store => next => action => {
  const result = next(action);
  const type = action.type;
  const trigger = triggers[type];

  if (trigger) {
    trigger({ store, action });
  }

  return result;
};
