import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/scroll-container.module.scss';
import { useScrollControl } from '../../helpers/hooks/use-scroll-control';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { useMergeRefs } from '../../helpers/hooks/use-merge-refs';

const ScrollContainerComponent = (
  { renderAs: Component = 'div', className, children, horizontal = false, disabled = false, ...props },
  ref
) => {
  const { anchor } = useScrollControl(disabled);

  return (
    <Component
      ref={useMergeRefs(ref, anchor)}
      className={classnames(
        styles.container,
        {
          [styles.vertical]: !horizontal,
          [styles.horizontal]: horizontal
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export const ScrollContainer = withMemoAndRef(ScrollContainerComponent);
