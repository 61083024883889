import React from 'react';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { DefaultItem } from './default-item';

export const DefaultNav = React.memo(({ items }) => {
  return (
    <ul className={styles.navigation}>
      {items.map(({ labelText, link, nativeTrigger, isExternal, openNewTab, displayCondition }) => (
        <DefaultItem
          key={labelText}
          labelText={labelText}
          link={link}
          nativeTrigger={nativeTrigger}
          isExternal={isExternal}
          openNewTab={openNewTab}
          displayCondition={displayCondition}
        />
      ))}
    </ul>
  );
});
