import React, { useEffect, useMemo } from 'react';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { Column, Grid } from '../../components/layout/grid';
import { ConfigService } from '../../services/config-service';
import { Template, TImage } from '../../components/template';
import { Header } from '../../components/layout/page-header';
import { Spacer } from '../../components/layout/spacers';
import { CategoryFilter } from '../../components/layout/category-filter';
import { SegmentedButton } from '../../components/buttons/segmented-button';
import styles from '../../styles/templates/articles-template.module.scss';
import { InputToggleSwitch } from '../../components/inputs/toggle-switch';
import { PurifiedHtmlContainer } from '../../components/layout/purified-html-container';
import { TABS } from '../../helpers/knowledgebase/constants';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';
import { PAGE_TYPES_TO_CONFIG_NAMES } from '../../helpers/constants';
import { KnowledgeBaseTopicWrapper } from './components/topic';
import { withKnowledgeBase } from './with-knowledge-base';

const KnowledgeBaseComponent = React.memo(
  ({
    topicFilter,
    handleFilterChange,
    handleTopicFilterChange,
    handleWithPersonalisation,
    categoryFilterOptions,
    loading,
    refreshKnowledgeBaseData,
    knowledgeBaseTopics = [],
    isPersonalisationTurnedOn = true
  }) => {
    const {
      pageHeader: { heading, subheading, image, category, type, backgroundPosition, showBackButton },
      TEMPLATE: { filter: withFilter, personalisedFilter, listType, image: templateImage }
    } = ConfigService.get(PAGE_TYPES_TO_CONFIG_NAMES.articlesHomePage, {});

    const initialAccordionState = useMemo(
      () => ArrayShim.normalize(categoryFilterOptions, 'label', ({ collapsed }) => !collapsed),
      [categoryFilterOptions]
    );

    useEffect(() => {
      if (!loading) {
        refreshKnowledgeBaseData();
      }
    }, [refreshKnowledgeBaseData, loading, isPersonalisationTurnedOn]);

    return (
      <>
        <Header
          className={styles.header}
          heading={heading}
          subheading={subheading}
          category={category}
          image={image}
          type={type}
          backgroundPosition={backgroundPosition}
          showBackButton={showBackButton}
          withKeyline
        />
        <Breadcrumbs pageType={VISIBILITY_PAGE_TYPES.knowledgebase} />
        <Template className={styles.container}>
          {templateImage?.show ? (
            <TImage
              renderAs='section'
              src={templateImage?.src}
              title={templateImage?.alt}
              className={styles.image}
              edge
            />
          ) : null}
          {personalisedFilter?.show ? (
            <Column>
              <InputToggleSwitch
                key={personalisedFilter.label}
                name={personalisedFilter.label}
                label={<PurifiedHtmlContainer renderAs='span' html={personalisedFilter.label} className='typography' />}
                value={isPersonalisationTurnedOn}
                onChange={handleWithPersonalisation}
              />
            </Column>
          ) : null}
          {withFilter?.show ? (
            <>
              {withFilter.type === 'homebuying-stage' ? <Spacer type='top' /> : null}
              <Column edge={['xss', 'lg']}>
                {withFilter.type === 'homebuying-stage' ? (
                  <>
                    <Spacer type='component' />
                    <div className='grid'>
                      <SegmentedButton items={TABS} onChange={handleTopicFilterChange} />
                    </div>
                    <Spacer type='component' />
                  </>
                ) : null}
                {withFilter.type === 'category' ? (
                  <>
                    <Spacer type='content' />
                    <div className='grid'>
                      <CategoryFilter
                        selectName='knowledgeBase'
                        values={categoryFilterOptions}
                        filterTitle={withFilter.filterTitle}
                        handleFilterChange={handleFilterChange}
                      />
                    </div>
                  </>
                ) : null}
              </Column>
            </>
          ) : null}
          <Column edge>
            {knowledgeBaseTopics?.map(topic => (
              <Grid className={styles.section} key={topic.id}>
                <Column>
                  <KnowledgeBaseTopicWrapper
                    key={topic.id}
                    topicFilter={topicFilter}
                    initialState={initialAccordionState}
                    listType={listType}
                    {...topic}
                  />
                </Column>
              </Grid>
            ))}
          </Column>
          <Spacer type='bottom' />
        </Template>
      </>
    );
  }
);

export const KnowledgeBase = withKnowledgeBase(KnowledgeBaseComponent);
