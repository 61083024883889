import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { withFetchFAQData, withShowFAQ } from '../../../pages/dashboard/widgets/future-fit-v2/decorators';
import { getFAQ } from '../../../store/reducers/faq';
import { toggleModal } from '../../../store/reducers/modals';

export const FAQModalButtonComponent = React.memo(props => {
  const { label, ariaLabel, openInfoModal: onClick, buttonType } = props;

  return (
    <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
      {label}
    </ButtonWithIcon>
  );
});

export const FAQModalButton = compose(
  withPropsOnChange(['faqGroup'], ({ faqGroup }) => ({
    FAQGroupName: faqGroup
  })),
  connect(null, { getFAQ, toggleModal }),
  withFetchFAQData,
  withShowFAQ,
  withHandlers({
    openInfoModal:
      ({ showInfo, FAQGroupName, trackAction }) =>
      () => {
        trackAction();
        showInfo(FAQGroupName);
      }
  })
)(FAQModalButtonComponent);
