import { isDevelopmentMode } from '../helpers/main';
import { dashboardRoutes } from './dashboard';
import { articlesRoutes } from './articles';
import { homePageRoutes } from './home-pages';
import { jobDetailsPageRoutes } from './job-details';
import { statusPageRoutes } from './status-page';
import { customPageRoutes } from './custom-page';
import { legalPageRoutes } from './legal-page';
import { accountsRoutes } from './account';
import { blogRoutes } from './blog';
import { rentReportingRoutes } from './rent-reporting';
import { questions } from './questions';
import { tourPageRoutes } from './tour-page';
import { pointsRoutes } from './points';
import { demoPageRoutes } from './demo';
import { propertyPageRoutes } from './property-page';
import { coinsRoutes } from './coins';
import { projectsRoutes } from './projects';
import { storiesRoutes } from './stories';
import { contentFeedRoutes } from './content-feed';
import { videoRoutes } from './videos';

export const routes = () => {
  const {
    DISPLAY_MODULES: { DASHBOARD, ARTICLES, BLOGHOMEPAGE, PROJECT, ACCOUNT, POINTS, COINS, STORIES, FEED, VIDEOS }
  } = window.CONFIG;

  return [
    ...(DASHBOARD ? [...dashboardRoutes] : []),
    ...(ARTICLES ? [...articlesRoutes] : []),
    ...(BLOGHOMEPAGE ? [...blogRoutes] : []),
    ...(PROJECT ? [...projectsRoutes] : []),
    ...propertyPageRoutes,
    ...homePageRoutes,
    ...statusPageRoutes,
    ...customPageRoutes,
    ...legalPageRoutes,
    ...(ACCOUNT ? [...accountsRoutes] : []),
    ...rentReportingRoutes,
    ...tourPageRoutes,
    ...questions,
    ...(POINTS ? [...pointsRoutes] : []),
    ...(STORIES ? [...storiesRoutes] : []),
    ...(FEED ? [...contentFeedRoutes] : []),
    ...(isDevelopmentMode ? [...demoPageRoutes] : []),
    ...jobDetailsPageRoutes,
    ...(COINS ? [...coinsRoutes] : []),
    ...(VIDEOS ? [...videoRoutes] : [])
  ];
};
