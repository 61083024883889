import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getRoutesByPathname, openLink } from '../../../helpers/utils';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { pickAdditionalDataByContentTypeFromStore } from '../../../helpers/content-interaction';

export const ContentLinkAction = React.memo(
  ({
    label,
    ariaLabel,
    link,
    isExternal,
    activityKey,
    statusKey,
    sendContentStatus,
    contentPages,
    stories,
    buttonType = 'major'
  }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const onClick = useCallback(async () => {
      if (activityKey && statusKey) {
        const [type, slug] = getRoutesByPathname(pathname);

        const data = pickAdditionalDataByContentTypeFromStore({
          contentType: type,
          slug,
          store: {
            contentPages,
            stories
          }
        });

        await sendContentStatus({
          activityKey: activityKey,
          activityStatusKey: statusKey,
          data
        });
      }

      return openLink(link, history, isExternal);
    }, [link, history, pathname, isExternal, activityKey, statusKey, contentPages, stories, sendContentStatus]);

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);
