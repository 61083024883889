import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { sendStatus } from '../../store/reducers/status-data';
import styles from '../../styles/widgets/digital-coach-widget/simple-poll.module.scss';
import { CoachBubbles } from '../../components/coach-message';
import PollBars from './components/poll-bars';

const Component = ({ data, description }) => (
  <div className={styles.container}>
    <PollBars data={data} />
    {description && <CoachBubbles html={description} />}
  </div>
);

const SimplePoll = compose(
  connect(null, { sendStatus }),
  lifecycle({
    componentDidMount() {
      const { sendStatus, activityKey, statusKey } = this.props;

      if (activityKey && statusKey) {
        sendStatus(activityKey, statusKey);
      }
    }
  })
)(Component);

SimplePoll.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      count: PropTypes.string
    })
  ),
  description: PropTypes.string,
  activityKey: PropTypes.string,
  statusKey: PropTypes.string
};

export { SimplePoll };
