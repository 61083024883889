import React, { useRef } from 'react';
import useMountEffect from '@packages/hooks/core/use-mount-effect';
import classnames from 'classnames';
import { ConfigService } from '../../services/config-service';
import styles from '../../styles/components/required-indicator.module.scss';

const RequiredIndicator = React.memo(() => {
  const requiredText = ConfigService.get('QUESTIONS.requiredText', '*');

  return <span className={styles.text}>{requiredText}</span>;
});

export const withRequiredIndicator =
  Component =>
  ({ isRequired, className, ...props }) => {
    if (!isRequired) {
      return <Component {...props} />;
    }

    const { current: root } = useRef(document.documentElement);
    const content = ConfigService.get('QUESTIONS.requiredText', '*');

    useMountEffect(() => root.style.setProperty('--app-required-indicator-content', JSON.stringify(content)));

    return <Component className={classnames(styles.text, className)} {...props} />;
  };

RequiredIndicator.displayName = 'RequiredIndicator';

export { RequiredIndicator };
