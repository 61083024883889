import classnames from 'classnames';
import React from 'react';
import styles from '../../../styles/components/input-label.module.scss';
import { Informer } from '../input-label';
import { withRequiredIndicator } from '../required-indicator';

const WheelLabelText = withRequiredIndicator(({ children }) => <span>{children}</span>);

export const WheelLabel = ({ className, informer, label }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classnames({ [styles['no-informer']]: !informer })}>
        {informer && (
          <Informer ariaLabel={`More information about ${label}`} className={styles.informer} {...informer} />
        )}
      </div>
      <div className={className}>
        <WheelLabelText>{label}</WheelLabelText>
      </div>
    </div>
  );
};

WheelLabel.displayName = 'WheelLabel';
