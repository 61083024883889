import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { getUser } from '../../../store/reducers/account/get-user-action';
import { InputWithButton as Input } from './components/input-with-button';

const Component = React.memo(
  ({
    label,
    format,
    displayName,
    value,
    max,
    min,
    errorMessages,
    onInputChange,
    onError,
    onInputSubmit,
    loading,
    disabled,
    submitError,
    buttonLabel,
    buttonType,
    placeholder,
    preSubmitButton,
    explainer,
    isRequired,
    informer
  }) => (
    <Input
      type='text'
      placeholder={placeholder}
      defaultInputValue={displayName}
      inputType='text'
      label={label}
      format={format}
      value={value}
      max={max}
      min={min}
      submitError={submitError}
      errorMessages={errorMessages}
      onChange={onInputChange}
      onError={onError}
      onSubmit={onInputSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      preSubmitButton={preSubmitButton}
      explainer={explainer}
      isRequired={isRequired}
      advancedInformer={informer}
    />
  )
);

export const QuestionUpdateUserInput = compose(
  connect(({ account }) => ({ displayName: account.displayName }), { getUser }),
  withState('submitError', 'setSubmitError', ''),
  withHandlers({
    onInputChange: ({ setSubmitError, errorMessages, onChange }) => onChange({ setSubmitError, errorMessages }),
    onInputSubmit: ({ setSubmitError, errorMessages, onSubmit, value }) =>
      onSubmit({ setSubmitError, errorMessages, value })
  })
)(Component);
