import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { createAction, createReducer } from '../utils';
import {
  getActivityStatusesWithExclusiveStatusGroup,
  hasContentHigherOrder,
  mapContentStatuses
} from '../../helpers/content-interaction';
import { isStatus } from '../../helpers/status-data';

const GET_CONTENT_INTERACTION_REQUEST = 'GET_CONTENT_INTERACTION_REQUEST';
const GET_CONTENT_INTERACTION_SUCCESS = 'GET_CONTENT_INTERACTION_SUCCESS';
const GET_CONTENT_INTERACTION_FAILURE = 'GET_CONTENT_INTERACTION_FAILURE';

const REMOVE_CONTENT_STATUS_REQUEST = 'REMOVE_CONTENT_STATUS_REQUEST';
const REMOVE_CONTENT_STATUS_SUCCESS = 'REMOVE_CONTENT_STATUS_SUCCESS';
const REMOVE_CONTENT_STATUS_FAILURE = 'REMOVE_CONTENT_STATUS_FAILURE';

const SEND_CONTENT_STATUS_REQUEST = 'SEND_CONTENT_STATUS_REQUEST';
export const SEND_CONTENT_STATUS_SUCCESS = 'SEND_CONTENT_STATUS_SUCCESS';
const SEND_CONTENT_STATUS_FAILURE = 'SEND_CONTENT_STATUS_FAILURE';

export const removeContentStatus = options =>
  createAction(
    {
      request: REMOVE_CONTENT_STATUS_REQUEST,
      success: REMOVE_CONTENT_STATUS_SUCCESS,
      failure: REMOVE_CONTENT_STATUS_FAILURE
    },
    api => api.statusDataContent.removeStatusDataContent
  )(options);

export const sendContentStatus =
  ({ activityKey, activityStatusKey, data }) =>
  async (dispatch, getState) => {
    const { userAttributes, contentInteraction, statusData } = getState();

    // Check if the status is exclusive and remove other statuses from the store
    const statusesToExcludeFromStore = getActivityStatusesWithExclusiveStatusGroup(
      statusData.activitiesConfig[activityKey],
      activityStatusKey
    );

    if (statusesToExcludeFromStore.length) {
      statusesToExcludeFromStore.forEach(status => {
        if (!contentInteraction.contentStatuses[data.key]?.[status]) {
          return;
        }

        const removeParams = {
          ownKey: data.key,
          contentActivityStatus: status
        };

        dispatch({ type: REMOVE_CONTENT_STATUS_SUCCESS, params: removeParams });
      });
    }

    // Send the status to BE
    const { point, status } = await dispatch(
      createAction(
        {
          request: SEND_CONTENT_STATUS_REQUEST,
          success: SEND_CONTENT_STATUS_SUCCESS,
          failure: SEND_CONTENT_STATUS_FAILURE
        },
        api => api.statusDataContent.sendStatusDataContent
      )({
        activityKey,
        activityStatusKey,
        data
      })
    );

    const contentStatusData = {
      activityKey,
      status,
      contentKey: data.key,
      contentStatuses: contentInteraction.contentStatuses,
      activitiesConfig: statusData.activitiesConfig
    };

    // Update the store with the new status
    if (isStatus(status) && hasContentHigherOrder(contentStatusData)) {
      dispatch({
        type: SEND_CONTENT_STATUS_SUCCESS,
        payload: {
          contentKey: data.key,
          status: { ...status, activity: activityKey },
          points: point,
          promotions: userAttributes.consents.promotions
        }
      });
    }
  };

export const getFeedbackAndFavorites = () =>
  createAction(
    {
      request: GET_CONTENT_INTERACTION_REQUEST,
      success: GET_CONTENT_INTERACTION_SUCCESS,
      failure: GET_CONTENT_INTERACTION_FAILURE
    },
    api => api.statusDataContent.getStatusDataContent
  )();

const initialState = {
  contentStatuses: {},
  loading: true,
  error: false
};

export const contentInteraction = createReducer(initialState, {
  [GET_CONTENT_INTERACTION_REQUEST]: state => ({ ...state, loading: true, error: false }),
  [GET_CONTENT_INTERACTION_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: false,
    ...mapContentStatuses(payload)
  }),
  [GET_CONTENT_INTERACTION_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [REMOVE_CONTENT_STATUS_REQUEST]: state => ({ ...state, loading: true }),
  [REMOVE_CONTENT_STATUS_SUCCESS]: (state, { params }) => {
    return {
      ...state,
      error: false,
      loading: false,
      contentStatuses: {
        ...state.contentStatuses,
        [params.ownKey]: ObjectShim.omit(state.contentStatuses[params.ownKey], params.contentActivityStatus)
      }
    };
  },
  [REMOVE_CONTENT_STATUS_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SEND_CONTENT_STATUS_REQUEST]: state => ({ ...state, loading: true }),
  [SEND_CONTENT_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    contentStatuses: {
      ...state.contentStatuses,
      ...(payload.contentKey && {
        [payload.contentKey]: { ...state.contentStatuses[payload.contentKey], [payload.status.key]: payload.status }
      })
    }
  }),
  [SEND_CONTENT_STATUS_FAILURE]: state => ({ ...state, loading: false, error: true })
});
