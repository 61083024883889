import React from 'react';
import { branch } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { ariaDisabledInterceptor } from '../../helpers/aria-disabled-interceptor';

const renderAsButton =
  Component =>
  ({ type, label, onClick, onFocus, onBlur, className, ariaLabel, ariaDisabled, ariaControls, ...props }) => {
    const { name, inline } = props;

    return (
      <button
        style={{ display: inline ? 'inline-block' : 'block' }}
        type={type}
        className={className}
        aria-label={ariaLabel ?? label ?? name}
        aria-disabled={ariaDisabled}
        aria-controls={ariaControls}
        onClick={ariaDisabledInterceptor(onClick)}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <Component {...props} />
      </button>
    );
  };

export const withButton = branch(({ onClick }) => !isNil(onClick), renderAsButton);
