import React from 'react';
import { branch, compose, mapProps, renderNothing, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import { sortByWeight } from '../../../helpers/array';
import { Informer } from '../../../components/informer';

const VideoInformer = mapProps(({ type, body, label, name }) => ({ type, body, buttonLabel: label, title: name }))(
  Informer
);

const Component = ({ list }) => (
  <>
    {list.map((item, index) => (
      <VideoInformer key={index} {...item} />
    ))}
  </>
);

export const Informers = compose(
  branch(({ faqs }) => !faqs?.length, renderNothing),
  withPropsOnChange(['faqs'], ({ faqs }) => ({
    list: sortByWeight(faqs)
  }))
)(Component);

Informers.displayName = 'Informers';

Informers.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      key: PropTypes.string,
      group: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      body: PropTypes.string,
      button: PropTypes.object,
      image: PropTypes.string,
      isDigitalCoach: PropTypes.bool
    })
  )
};
