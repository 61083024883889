import { combineReducers } from 'redux';
import { depositBuilder } from './deposit-builder';
import { content } from './content';
import { faq } from './faq';
import { statusData } from './status-data';
import { digitalCoach } from './digital-coach';
import { navigation } from './navigation';
import { account } from './account';
import { layout } from './layout';
import { modals } from './modals';
import { questions } from './questions';
import { lottie } from './lottie';
import { userAttributes } from './user-attributes';
import { validations } from './validations';
import { points } from './points-and-awards';
import { wheels } from './wheels';
import { marketingLists } from './marketing-lists';
import { toast } from './toast';
import { consentList } from './consent-list';
import { tooltips } from './tooltips';
import { marketplace } from './marketplace';
import { pensionFinder } from './pension-finder';
import { staticData } from './static-data';
import { checklist } from './checklist';
import { contentInteraction } from './content-interaction';
import { repeaters } from './repeaters';
import { application } from './application';

export const coreReducers = {
  userAttributes,
  content,
  faq,
  statusData,
  digitalCoach,
  navigation,
  account,
  layout,
  modals,
  questions,
  validations,
  points,
  marketingLists,
  toast,
  consentList,
  tooltips,
  marketplace,
  checklist,
  staticData,
  contentInteraction,
  repeaters,
  application
};

export const reducers = combineReducers({
  ...coreReducers,
  lottie, // several places
  wheels, // uniq
  depositBuilder, // several places
  pensionFinder // uniq
});
