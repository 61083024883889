export const InteractiveVisibleDiv = ({ children, className, onClick }) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onClick(event);
    }
  };

  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      style={{ cursor: 'pointer' }}
      role='button'
      tabIndex={0}
    >
      {children}
    </div>
  );
};
