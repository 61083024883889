import { ORDER_TYPES } from '../helpers/constants';
import { get } from './utils';
import { STATIC } from './utils/constants';

const getDataSet = ({ datasetKey, value, limit = 50, order }) => {
  const orderType = ORDER_TYPES[order] ?? ORDER_TYPES.default;
  const term = value ? `term=${value}&` : '';

  return get(`/question-input-datasets/${datasetKey}?${term}order=${orderType}&limit=${limit}`, STATIC);
};

export const dataset = {
  getDataSet
};
