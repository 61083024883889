// eslint-disable-next-line import/no-unassigned-import
import 'intersection-observer';

export const getElementFromHtmlString = htmlString => {
  const container = document.createElement('div');
  container.innerHTML = htmlString;

  return container.firstElementChild;
};

export const attachEventToElementById =
  ({ id, type = 'click', handler } = {}) =>
  element => {
    const target = element.querySelector(`[id='${id}']`);

    if (target && handler) target.addEventListener(type, handler);

    return element;
  };

export const attachObserverToElement = (handler, options) => {
  const { once, ...rest } = options;

  return new IntersectionObserver(
    (entries, self) =>
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          handler && handler(entry, self);

          once && self.unobserve(entry.target);
        }
      }),
    rest
  );
};
