import uuid from 'uuid';
import { insertVarIntoString } from '../insert-var-into-string';

const getMessageFromPoints = points => {
  const { messages } = window.CONFIG.TOAST;

  return {
    message: insertVarIntoString(messages.points.text, { points: points.point }),
    id: uuid(),
    to: messages.points.to
  };
};

export const setToastMessage = (state, { payload: { points, promotions } }) => {
  const {
    DISPLAY_MODULES: { POINTS },
    DISPLAY_COMPONENTS: { TOAST }
  } = window.CONFIG;

  if (!POINTS || !TOAST.POINTS || !promotions) {
    return state;
  }

  return {
    ...state,
    messages: points && points.point > 0 ? [...state.messages, getMessageFromPoints(points)] : state.messages
  };
};
