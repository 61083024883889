import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import styles from '../../../../styles/components/page-header-back-button.module.scss';
import { AffiliateIcon } from '../../../icons/affiliate-icon';
import * as NAVIGATION from '../../../../helpers/navigation';

const BackButton = React.memo(({ className, onClick }) => (
  <AffiliateIcon name='nav-back' ariaLabel='Back' onClick={onClick} className={className} />
));

export const withBackButton =
  Component =>
  ({ showBackButton: configShow, ...props }) => {
    const history = useHistory();
    const isDisplay = Boolean(history.location.key);
    const showBackButton = configShow && isDisplay;

    const onBackButtonClick = useCallback(() => {
      const { pathname } = history.location;

      // For pathnames like /checklist/:activity/start or /checklist/:activity/result - we redirect by default to '/checklist'
      if (
        pathname.includes(NAVIGATION.CHECKLIST) &&
        (pathname.includes(NAVIGATION.START) || pathname.includes(NAVIGATION.RESULT))
      ) {
        return history.push(`/${NAVIGATION.CHECKLIST}`);
      }

      return history.goBack();
    }, [history]);

    return (
      <div className={classnames(styles.container, { [styles['container-controls']]: showBackButton })}>
        {showBackButton && <BackButton className={styles['back-icon']} onClick={onBackButtonClick} />}
        <Component {...props} />
      </div>
    );
  };
