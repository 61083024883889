import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { transformBeforeSend } from '../../../helpers/user-attributes/transform-before-send';
import {
  getActionTriggeredData,
  getGeneratedTriggeredData
} from '../../../store/middlewares/helpers/after-user-attributes-send';
import { FULL_FLOW_TYPE } from '../../../helpers/chekpoint-page/constants';

export const processDefaultValuesOnSectionSkip = ({ defaultValues, state }) => {
  const normalized = ArrayShim.normalize(defaultValues, 'userAttributeType');

  return Object.keys(normalized).reduce((acc, key) => {
    //add additional data depending on question userAttributeType
    const transformedState = transformBeforeSend({
      type: key,
      params: normalized[key].userAttributeValues,
      prevState: state.userAttributes
    });
    const transformedData = { [key]: transformedState };

    const mergedUserAttributes = ObjectShim.mergeDeep(state.userAttributes, { [key]: transformedState });
    //calculate additional data based on updated state and userAttributeType
    const triggeredData = getActionTriggeredData({
      propType: key,
      state: ObjectShim.mergeDeep(state, { userAttributes: mergedUserAttributes })
    });

    return { ...acc, ...transformedData, ...triggeredData };
  }, {});
};

export const processGeneratedDataOnSectionSkip = ({ defaultValues, state }) => {
  const normalized = ArrayShim.normalize(defaultValues, 'userAttributeType');

  return Object.keys(normalized).reduce((acc, key) => {
    const generatedData = getGeneratedTriggeredData({ propType: key, state });

    return { ...acc, ...generatedData };
  }, {});
};

export const getNextSectionLink = ({ sectionsParams, questionSection }) => {
  const { currentSection, endOfSection } = questionSection;

  const questionSections = sectionsParams.filter(({ type }) => type !== FULL_FLOW_TYPE);

  const sectionNames = Object.keys(ArrayShim.normalize(questionSections, 'name'));

  const searchableSectionName = endOfSection || currentSection;
  const endedSection = sectionNames.indexOf(searchableSectionName);

  const searchableSectionIndex = endOfSection ? endedSection + 1 : endedSection;
  const { link } = sectionsParams[searchableSectionIndex];

  return link;
};
