import { useHistory } from 'react-router';
import { createCustomPageProps, getRoutesByPathname } from '../../../../helpers/utils';
import * as NAVIGATION from '../../../../helpers/navigation';
import { getLocationFromModeState } from '../../../../helpers/navigation/routing';
import { HeaderIcon } from '../default';

export const useCloseButton = () => {
  const history = useHistory();

  const {
    location: { pathname, state }
  } = history;

  const toOrigin = getLocationFromModeState(state);

  if (toOrigin) {
    return toOrigin;
  }

  const [gistModule] = getRoutesByPathname(pathname);

  switch (gistModule) {
    case NAVIGATION.ACCOUNT:
    case NAVIGATION.TOUR:
      return `/${NAVIGATION.DASHBOARD}`;
    default:
      const { name } = createCustomPageProps(pathname);

      return `/${gistModule}/${name}/${NAVIGATION.START}`;
  }
};

export const CloseButton = () => {
  const to = useCloseButton();
  const history = useHistory();

  const handleClose = () => history.push(to);

  return <HeaderIcon name='nav-exit' onClick={handleClose} />;
};
