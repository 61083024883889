import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { compose, lifecycle, branch, withProps, renderComponent, withState, withHandlers } from 'recompose';
import { sortByWeight } from '../../helpers/array';
import styles from '../../styles/widgets/digital-coach-widget/digital-coach.module.scss';
import { CoachMessage } from '../../components/coach-message';
import { InputGroup } from '../../components/inputs/input-group';
import { withCustomSpinner } from '../../components/with-loader';
import { enableCoachLoader, getNextDC } from '../../store/reducers/digital-coach';
import { UserService } from '../../services/user-service';
import {
  TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION,
  TYPE_BUTTON_ACTION_RELOAD,
  TYPE_BACK_BUTTON
} from '../../helpers/digital-coach/constants';
import { useConfirm } from '../../components/layout/popups/confirm';
import { isModuleCompleted } from '../../helpers/custom-page/is-completed';
import { withEmbeddedContext } from '../../components/layout/with-embedded-context';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import {
  withStaticWidgetLoadingLifecycle,
  withWidgetLoadingHandlers
} from '../../components/layout/content/with-native-loading-state';
import { SET_WIDGET_STATE_ASYNC_SUCCESS } from '../../store/reducers/application';
import { statusData } from '../../api/status-data';
import { DigitalCoachDisplayController } from './display-controller';
import { ActionComponent, ReloadAction, CustomAction } from './actions';
import { ActionBackButton } from './buttons/back-button';

const withDigitalCoachActionTracking = compose(
  withHandlers({
    handleAction:
      ({ actionId }) =>
      async () => {
        await statusData.handleDCAction({ actionId: actionId });
      }
  })
);

export const DigitalCoachAction = compose(
  withDigitalCoachActionTracking,
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_RELOAD, renderComponent(ReloadAction)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION, renderComponent(CustomAction)),
  branch(({ actionType }) => actionType === TYPE_BACK_BUTTON, renderComponent(ActionBackButton))
)(ActionComponent);

const DigitalCoachWidgetComponent = React.memo(props => {
  const { activity, isMounted } = props;
  const { message, actions, options, id: specialConditionId = null } = activity;

  const [confirm, handleConfirm] = useConfirm({
    props: {
      acceptType: 'major',
      declineType: 'minor'
    }
  });

  return (
    <>
      <DigitalCoachDisplayController type={options?.slideUp} isMounted={isMounted}>
        <>
          {message && <CoachMessage html={message.text} />}
          {actions && (
            <InputGroup type='coach'>
              {sortByWeight(actions).map(action => (
                <DigitalCoachAction
                  key={uuid('digital-coach-action')}
                  onConfirm={handleConfirm}
                  actionId={action.id}
                  specialConditionId={specialConditionId}
                  {...action}
                />
              ))}
            </InputGroup>
          )}
        </>
      </DigitalCoachDisplayController>
      {confirm}
    </>
  );
});

DigitalCoachWidgetComponent.displayName = 'DigitalCoachWidgetComponent';

const nextMapStateToProps = state => {
  const activity = state.digitalCoach.nextDC;
  const { forcedLoading, loading } = state.digitalCoach;
  const { activityKey } = activity;

  return {
    activity,
    loading: forcedLoading || loading,
    isCompleted: activityKey && isModuleCompleted(state.statusData, activityKey)
  };
};

const StaticDigitalCoachWidget = compose(
  withStaticWidgetLoadingLifecycle({ condition: true }),
  withProps(() => {
    const {
      ACCOUNT: { DIGITAL_COACH_ACTIVITY }
    } = window.CONFIG;

    return {
      activity: DIGITAL_COACH_ACTIVITY
    };
  })
)(DigitalCoachWidgetComponent);

StaticDigitalCoachWidget.displayName = 'StaticDigitalCoachWidget';

export const DigitalCoachWidget = compose(
  connect(nextMapStateToProps, { getNextDC, enableCoachLoader }),
  withState('isMounted', 'setMounted', false),
  withEmbeddedContext,
  withCancellableHandlers({
    getNextActivityCancellable:
      ({ getNextDC }) =>
      async () =>
        getNextDC()
  }),
  withWidgetLoadingHandlers,
  lifecycle({
    componentDidMount() {
      const {
        getNextActivityCancellable,
        setMounted,
        setWidgetLoadedState,
        isEmbedded,
        postResizeMessage,
        withLoader,
        enableCoachLoader
      } = this.props;

      if (withLoader) {
        enableCoachLoader();
      }
      const fetchNextActivity = async () => await getNextActivityCancellable();
      fetchNextActivity()
        .then(() => {
          setMounted(true);
          setWidgetLoadedState(SET_WIDGET_STATE_ASYNC_SUCCESS, true);
        })
        .finally(() => {
          if (isEmbedded) {
            postResizeMessage();
          }
        });
    },
    componentDidUpdate() {
      const { getNextDC, isCompleted } = this.props;

      if (isCompleted) {
        const fetchNextActivity = async () => await getNextDC();
        fetchNextActivity();
      }
    }
  }),
  branch(() => UserService.hasAccount(), renderComponent(StaticDigitalCoachWidget)),
  withCustomSpinner({ className: styles.spinner })
)(DigitalCoachWidgetComponent);

DigitalCoachWidget.displayName = 'DigitalCoachWidget';
