import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { evaluate } from '../workers/evaluate-message';
import { withUserStateData } from './with-user-state-data';

export const withFaq = compose(
  withUserStateData,
  connect(({ faq }) => ({ faq })),
  withPropsOnChange(['userState', 'faq'], ({ faq, userState }) => {
    if (!faq || !userState) return { faq: {} };
    const updatedFaqQuestions = {
      ...faq,
      questions: Object.keys(faq.questions).reduce((acc, questionKey) => {
        acc[questionKey] = Object.keys(faq.questions[questionKey]).reduce((acc, answerKey) => {
          acc[answerKey] = {
            ...faq.questions[questionKey][answerKey],
            body: evaluate(faq.questions[questionKey][answerKey].body, userState)
          };
          return acc;
        }, {});

        return acc;
      }, {})
    };
    return { faq: updatedFaqQuestions };
  })
);
