import { useLayoutEffect, useRef, useState } from 'react';
import { createPopper, Instance, Options } from '@popperjs/core';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

const DEFAULT_OPTIONS: Options = {
  strategy: 'absolute',
  placement: 'auto',
  modifiers: []
};

export type RefSetter = (node: HTMLElement | null) => void;

export type UsePopper = {
  (options?: Partial<Options>, on?: boolean): {
    instance: Instance | null;
    onTriggerRef: RefSetter;
    onPopoverRef: RefSetter;
  };
};

export const usePopper: UsePopper = (options, on = true) => {
  const [instance, setInstance] = useState<Instance | null>(null);
  const [trigger, setTrigger] = useState<HTMLElement | null>(null);
  const [popover, setPopover] = useState<HTMLElement | null>(null);

  const instanceOptions = useRef(ObjectShim.merge(DEFAULT_OPTIONS, options));

  useLayoutEffect(() => {
    if (on && trigger instanceof HTMLElement && popover instanceof HTMLElement) {
      const popper = createPopper(trigger, popover, instanceOptions.current);

      setInstance(popper);

      return () => {
        popper.destroy();

        setInstance(null);
      };
    }

    return void 0;
  }, [on, trigger, popover]);

  return {
    instance,
    onTriggerRef: setTrigger,
    onPopoverRef: setPopover
  };
};
