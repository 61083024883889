import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { DASHBOARD } from '../helpers/navigation';

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ '../pages/dashboard').then(module => module.Dashboard),
  loading: Loader
});

export const dashboardRoutes = [
  {
    to: '/dashboard',
    component: asTemplate(DASHBOARD)(Dashboard)
  }
];
