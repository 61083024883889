import React from 'react';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import classnames from 'classnames';
import { Column } from '@packages/ui-kit/core/grid';
import styles from '../../styles/widgets/story-step/story-step.module.scss';
import { sortBlocks } from '../../helpers/stories/stories';
import { StoryBlock } from './story-block';
import { StoryRead } from './components/story-read';

const Component = React.memo(({ id, isLastSlide, blocksToShow, onStoryClick, onStoryRead, ...rest }) => {
  // TODO: Mocks.
  const description = `description ${id}`;
  const ariaLabel = isLastSlide ? `${description}, last slide` : description;

  return (
    <Column
      onClick={onStoryClick}
      className={styles.wrapper}
      tabIndex={0}
      role='combobox'
      id={`slide-${id}`}
      aria-label={ariaLabel}
    >
      <div className={classnames(rest.theme, styles.step)}>
        {blocksToShow.map(([name, block]) => (
          <StoryBlock key={name} name={name} data={rest} {...block} />
        ))}
        {isLastSlide && <StoryRead onStoryRead={onStoryRead} />}
      </div>
    </Column>
  );
});

export const StoryStep = compose(
  withPropsOnChange([], ({ displayConfig }) => ({ blocksToShow: sortBlocks(displayConfig) })),
  withHandlers({
    onStoryRead:
      ({ reportStatus }) =>
      () =>
        reportStatus('read')
  })
)(Component);
