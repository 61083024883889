import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink, withRouter } from 'react-router-dom';
import { branch, compose, renderComponent, withProps } from 'recompose';
import { omitProps } from '../helpers/hocs/omit-props';
import { openLink } from '../helpers/utils';
import { withCombinedAriaLabel } from './screen-reader-hidden-text';

const Button = React.memo(({ type, className, onClick, children, ariaLabel }) => (
  <button aria-label={ariaLabel} data-type={type} className={className} onClick={onClick}>
    {children}
  </button>
));

const ExternalLink = React.memo(({ to, history, openNewTab, ...props }) => {
  const handleClick = () => openLink(to, history, true, openNewTab);

  return <Button type='external' onClick={handleClick} {...props} />;
});

const BlockedLink = React.memo(props => <Button type='blocked' disabled {...props} />);

const ButtonLink = React.memo(props => <Button type='link' {...props} />);

const LinkComponent = React.memo(({ to, replace, className, style, children, onClick, ariaLabel }) => (
  <RouterLink aria-label={ariaLabel} to={to} replace={replace} onClick={onClick} style={style} className={className}>
    {children}
  </RouterLink>
));

const NavLinkComponent = React.memo(({ to, replace, className, activeClassName, children, onClick, ariaLabel }) => (
  <RouterNavLink
    aria-label={ariaLabel}
    to={to}
    replace={replace}
    activeClassName={activeClassName}
    className={className}
    onClick={onClick}
  >
    {children}
  </RouterNavLink>
));

const PureExternal = compose(omitProps(['onClick']))(ExternalLink);

const Enhance = compose(
  withRouter,
  withCombinedAriaLabel,
  branch(({ isExternal }) => isExternal, renderComponent(PureExternal)),
  branch(({ isBlocked }) => isBlocked, renderComponent(BlockedLink)),
  branch(({ isButton }) => isButton, renderComponent(ButtonLink)),
  withProps(({ replace, location, to }) => ({
    replace: typeof replace === 'function' ? replace(to, location) : to === location.pathname
  }))
);

export const Link = Enhance(LinkComponent);
export const NavLink = Enhance(NavLinkComponent);
