import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../../styles/components/badge.module.scss';

const Badge = ({ text, styling }) => (
  <span className={classnames(styles.container, styles[`container-${styling}`])}>
    <span className={classnames(styles.label, styles[`label-${styling}`])}>{text}</span>
  </span>
);

Badge.displayName = 'Badge';

Badge.propTypes = {
  text: PropTypes.string,
  styling: PropTypes.oneOf(['option1', 'option2', 'option3', 'option4', 'option5'])
};

export { Badge };
