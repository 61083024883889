import { useLayoutEffect, useRef } from 'react';
import { useOnOrientationChange } from './use-on-orientation-change';

export const useViewportControl = () => {
  const { current: root } = useRef(document.documentElement);

  const setViewportHeight = () => {
    root.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
  };

  useOnOrientationChange(setViewportHeight);

  useLayoutEffect(() => {
    setViewportHeight();
  });
};
