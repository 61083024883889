export const BUYERS_QUANTITY = {
  SINGLE: 'On my own',
  MULTIPLE: 'With someone'
};

export const KEY_FACTOR_TYPES = {
  BY_PRICE: 'Property price',
  BY_DATE: 'Move in date'
};

export const BUYING_TYPES = [
  {
    label: BUYERS_QUANTITY.SINGLE,
    value: 0
  },
  {
    label: BUYERS_QUANTITY.MULTIPLE,
    value: 1
  }
];

export const KEY_FACTORS = [
  {
    label: KEY_FACTOR_TYPES.BY_PRICE,
    value: KEY_FACTOR_TYPES.BY_PRICE
  },
  {
    label: KEY_FACTOR_TYPES.BY_DATE,
    value: KEY_FACTOR_TYPES.BY_DATE
  }
];

export const DEPOSIT_STATES = {
  LESS_THEN_PREFERRED: 'lessThenPreferred',
  MORE_THEN_PREFERRED: 'moreThenPreferred',
  EQUALS_TO_PREFERRED: 'equalsToPreferred'
};

export const CHECKPOINT_POSITIONS_MAP = {
  20: {
    labels: {
      x: 119.521,
      y: 0.806641,
      width: 56.1926,
      height: 32
    },
    house: {
      x: 139.617,
      y: 33.2646,
      width: 16,
      height: 16
    }
  },
  40: {
    labels: {
      x: 279.416,
      y: 9.26758,
      width: 56.8273,
      height: 32
    },
    house: {
      x: 295.832,
      y: 45.7256,
      width: 24,
      height: 24
    }
  },
  60: {
    labels: {
      x: 146.771,
      y: 74.8203,
      width: 55.8351,
      height: 32
    },
    house: {
      x: 158.801,
      y: 111.278,
      width: 32,
      height: 32
    }
  },
  80: {
    labels: {
      x: 2.51367,
      y: 141.069,
      width: 57.1167,
      height: 32
    },
    house: {
      x: 11.0703,
      y: 177.527,
      width: 40,
      height: 40
    }
  },
  100: {
    labels: {
      x: 98.166,
      y: 228.411,
      width: 57.4504,
      height: 32
    },
    house: {
      x: 99.582,
      y: 264.869,
      width: 56,
      height: 56
    }
  }
};
