import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { createAction, createReducer } from '../utils';

const GET_GUIDANCE_REQUEST = 'GET_GUIDANCE_REQUEST';
const GET_GUIDANCE_SUCCESS = 'GET_GUIDANCE_SUCCESS';
const GET_GUIDANCE_FAILURE = 'GET_GUIDANCE_FAILURE';

const GET_GUIDANCE_LIST_REQUEST = 'GET_GUIDANCE_LIST_REQUEST';
const GET_GUIDANCE_LIST_SUCCESS = 'GET_GUIDANCE_LIST_SUCCESS';
const GET_GUIDANCE_LIST_FAILURE = 'GET_GUIDANCE_LIST_FAILURE';

export const getGuidance = ({ slug, isExternal }) =>
  createAction(
    {
      request: GET_GUIDANCE_REQUEST,
      success: GET_GUIDANCE_SUCCESS,
      failure: GET_GUIDANCE_FAILURE
    },
    api => api.guidance.getGuidance
  )({ slug, isExternal });

export const getGuidanceList = () =>
  createAction(
    {
      request: GET_GUIDANCE_LIST_REQUEST,
      success: GET_GUIDANCE_LIST_SUCCESS,
      failure: GET_GUIDANCE_LIST_FAILURE
    },
    api => api.guidance.getGuidanceList
  )();

const initialState = {
  normalizedList: {},
  list: [],
  loading: true,
  error: false
};

export const guidance = createReducer(initialState, {
  [GET_GUIDANCE_REQUEST]: state => ({ ...state, loading: true }),
  [GET_GUIDANCE_SUCCESS]: (state, action) => ({
    ...state,
    normalizedList: { ...state.list, [action.payload.slug]: action.payload },
    loading: false,
    error: false
  }),
  [GET_GUIDANCE_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_GUIDANCE_LIST_REQUEST]: state => ({ ...state, loading: true }),
  [GET_GUIDANCE_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    normalizedList: ArrayShim.normalize(payload, 'slug'),
    list: payload,
    loading: false,
    error: false
  })
});
