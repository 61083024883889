import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { ARTICLE, ARTICLES } from '../helpers/navigation';

export const Post = Loadable({
  loader: () => import(/* webpackChunkName: "article"*/ '../templates/post-page').then(module => module.Post),
  loading: Loader
});
const ArticleList = Loadable({
  loader: () =>
    import(/* webpackChunkName: "article-list"*/ '../pages/knowledge-base').then(module => module.ArticleList),
  loading: Loader
});

export const articlesRoutesObject = {
  article: {
    to: '/articles/:slug',
    component: asTemplate(ARTICLE)(Post)
  },
  knowledgeBase: {
    to: '/articles',
    component: asTemplate(ARTICLES)(ArticleList),
    hideOn: 'lgAndUp'
  }
};

export const articlesRoutes = Object.values(articlesRoutesObject);
