import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = ({ userAttributes, points, statusData: { plainStatuses } }) => ({
  userAttributes,
  activity: plainStatuses,
  user: { ...userAttributes?.user, ...points }
});

// TODO: Replace all occurrences of userState usage with this HOC
/**
 * This HOC provides user state data from a Redux store to the wrapped component.
 * userState data is used for displayCondition evaluation and other user state related operations.
 * @param {React.Component} Component - component to wrap
 * @returns {React.Component} - wrapped component with user state data
 *
 * @typedef {Object} UserState - user state data
 * @property {object} userAttributes - user attributes
 * @property {object} activity - user activity
 * @property {object} user - user data
 */
export const withUserStateData = Component =>
  compose(
    connect(mapStateToProps),
    withPropsOnChange(['userAttributes', 'activity', 'user'], ({ userAttributes, activity, user }) => ({
      /**
       * @type {UserState}
       */
      userState: { userAttributes, activity, user }
    }))
  )(Component);
