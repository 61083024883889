import { StringShim } from '../string-shim';

type AnyObject = Record<PropertyKey, unknown>;
type AnyArray = unknown[];

const areObjectsEqual = (a: AnyObject, b: AnyObject): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const deepClone = <T extends AnyObject>(target: T): T => {
  return JSON.parse(JSON.stringify(target));
};

const omit = <T extends AnyObject>(target: T, keys: (keyof T)[] = []): Partial<T> => {
  return Object.keys(target)
    .filter(key => !keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: target[key] }), {});
};

const pick = <T extends AnyObject>(target: T, keys: (keyof T)[] = []): Partial<T> => {
  return Object.keys(target)
    .filter(key => keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: target[key] }), {});
};

const entries = <T extends AnyObject>(target: T): [keyof T, unknown][] => {
  return Object.keys(target).reduce((acc: [keyof T, unknown][], key: keyof T): [keyof T, unknown][] => {
    return [...acc, [key, target[key]]];
  }, []);
};

const isEmpty = <T extends AnyObject>(target: T): boolean => {
  if (!ObjectShim.isObject(target)) {
    return true;
  }

  for (const prop in target) {
    if (Object.prototype.hasOwnProperty.call(target, prop)) {
      return false;
    }
  }

  return true;
};

const isObject = <T extends unknown>(target?: T): boolean => {
  return typeof target === 'object' && target !== null && !Array.isArray(target);
};

// TODO: Fix Typings
const fromEntries = (target: any) => {
  return target.reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value
    }),
    {}
  );
};

const truthyKeys = <T extends AnyObject>(target: T): (keyof T)[] => {
  return ObjectShim.entries(target).reduce((acc: (keyof T)[], [key, value]) => [...acc, ...(value ? [key] : [])], []);
};

const immutable = <T extends AnyObject>(props: T, isFrozen = false): T => {
  const entity = Object.create(null);

  Object.getOwnPropertyNames(props).forEach(prop => {
    entity[prop] = props[prop];
  });

  if (isFrozen) {
    Object.freeze(entity);
  } else {
    Object.seal(entity);
  }

  return entity;
};

const merge = <Left extends AnyObject, Right extends AnyObject>(
  left: Left,
  right?: Right,
  mapper?: (items: Left | Partial<Left & Right>) => Partial<Left> | Partial<Left & Right>
): Partial<Left> | Partial<Left & Right> => {
  if (!ObjectShim.isObject(left)) {
    throw new Error(`'left' must be an object`);
  }

  if (!ObjectShim.isObject(right)) {
    return typeof mapper === 'function' ? mapper(left) : left;
  }

  const result = {};
  const source = right as Right;

  const keys = new Set([...Object.keys(left), ...Object.keys(source)]);

  for (const key of keys) {
    const leftValue = left[key];

    if (Object.prototype.hasOwnProperty.call(source, key)) {
      let rightValue = source[key];

      if ([leftValue, rightValue].every(Array.isArray)) {
        rightValue = (leftValue as AnyArray).concat(rightValue as AnyArray);
      }

      if ([leftValue, rightValue].every(isObject)) {
        rightValue = { ...(leftValue as AnyObject), ...(rightValue as AnyObject) };
      }

      result[key] = rightValue;
    } else {
      result[key] = leftValue;
    }
  }

  return typeof mapper === 'function' ? mapper(result as Left & Right) : result;
};

const mergeDeep = <Left extends AnyObject, Right extends AnyObject>(
  left: Left,
  right?: Right,
  mapper?: (items: Left | Partial<Left & Right>) => Partial<Left> | Partial<Left & Right>
): Partial<Left> | Partial<Left & Right> => {
  if (!ObjectShim.isObject(left)) {
    throw new Error(`'left' must be an object`);
  }

  if (!ObjectShim.isObject(right)) {
    return typeof mapper === 'function' ? mapper(left) : left;
  }

  const result = {};
  const source = right as Right;

  let keys = Array.from(new Set([...Object.keys(left), ...Object.keys(source)]));

  while (keys.length) {
    const key = StringShim.trim(keys.shift());
    const leftValue = ObjectShim.getNested(left, key);

    if (ObjectShim.has(source, key)) {
      let rightValue = ObjectShim.getNested(source, key);

      if ([leftValue, rightValue].every(Array.isArray)) {
        rightValue = (leftValue as AnyArray).concat(rightValue);
      }

      if ([leftValue, rightValue].every(isObject)) {
        keys = Object.keys(rightValue as AnyObject)
          .map(path => `${key}.${path}`)
          .concat(keys);

        rightValue = { ...(leftValue as AnyObject), ...(rightValue as AnyObject) };
      }

      ObjectShim.setNested(result, key, rightValue);
    } else {
      ObjectShim.setNested(result, key, leftValue);
    }
  }

  return typeof mapper === 'function' ? mapper(result as Left & Right) : result;
};

const getNested = <T, Value, Default>(
  target: T,
  path = '',
  defaultValue?: Default,
  onError?: (key: string, path: string) => void
): T | Value | Default => {
  if (!ObjectShim.isObject(target) && !Array.isArray(target)) {
    return target as T;
  }

  if (!StringShim.isString(path) || StringShim.trim(path).length < 1) {
    return defaultValue as Default;
  }

  const keys = path.split('.');
  const iterableKeys = [...keys];

  let cursor: T | Value = target;

  while (iterableKeys.length > 0) {
    const keyIndex = keys.length - iterableKeys.length;
    const key = StringShim.trim(iterableKeys.shift());

    if (
      (ObjectShim.isObject(cursor) && Object.prototype.hasOwnProperty.call(cursor, key)) ||
      (Array.isArray(cursor) && isFinite(Number(key)))
    ) {
      cursor = cursor[key];
    } else {
      if (onError) {
        onError(keys[keyIndex], path);
      }

      return defaultValue as Default;
    }
  }

  return cursor;
};

const setNested = <T extends AnyObject, Value>(target: T, path = '', value: Value): boolean => {
  if (!isObject(target)) {
    return false;
  }

  if (!StringShim.isString(path) || StringShim.trim(path).length < 1) {
    return false;
  }

  const keys = path.split('.');

  let cursor: any = target;

  while (keys.length > 1) {
    const key = StringShim.trim(keys.shift());

    if (!ObjectShim.isObject(cursor[key])) {
      cursor[key] = {};
    }

    cursor = cursor[key];
  }

  const key = StringShim.trim(keys.shift());

  cursor[key] = value;

  return true;
};

const has = <T extends AnyObject>(target: T, path = ''): boolean => {
  if (!ObjectShim.isObject(target)) {
    return false;
  }

  if (!StringShim.isString(path) || StringShim.trim(path).length < 1) {
    return false;
  }

  const keys = path.split('.');

  let cursor: any = target;

  while (keys.length) {
    const key = StringShim.trim(keys.shift());

    if (Object.prototype.hasOwnProperty.call(cursor, key)) {
      cursor = cursor[key];
    } else {
      return false;
    }
  }

  return true;
};

const map = <T extends AnyObject>(target: T, callback: (element: any, index: number, target: T) => any): T => {
  const result = {};

  Object.keys(target).forEach((key, index) => {
    result[key] = callback(target[key], index, target);
  });

  return result as T;
};

const deepen = <T extends AnyObject, Value>(target: T, separator = '.'): Value => {
  const result = {};

  for (const key in target) {
    const parts = key.split(separator);

    let cursor = result;

    while (parts.length > 1) {
      const part = StringShim.trim(parts.shift());

      cursor = cursor[part] = cursor[part] ?? {};
    }
    cursor[parts[0]] = target[key];
  }

  return result as Value;
};
export class ObjectShim {
  static omit = omit;
  static pick = pick;
  static entries = entries;
  static fromEntries = fromEntries;
  static truthyKeys = truthyKeys;
  static immutable = immutable;
  static isEmpty = isEmpty;
  static isObject = isObject;
  static merge = merge;
  static mergeDeep = mergeDeep;
  static has = has;
  static getNested = getNested;
  static setNested = setNested;
  static map = map;
  static deepen = deepen;
  static areObjectsEqual = areObjectsEqual;
  static deepClone = deepClone;
}
