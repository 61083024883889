import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { toggleModal } from '../../../store/reducers/modals';
import { withStopPropagation } from '../../../helpers/hocs/with-stop-propagation';

export const withModalInformer = Component =>
  compose(
    connect(null, { toggleModal }),
    withHandlers({
      onClick:
        ({ body, title, toggleModal }) =>
        event => {
          event.preventDefault();
          toggleModal(true, 'help', { buttonEnabled: false, body, title });
        }
    }),
    withPropsOnChange(['buttonLabel'], ({ buttonLabel }) => ({
      informerHtml: buttonLabel
    })),
    withStopPropagation('onClick')
  )(Component);
