import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import styles from '../../styles/components/checkpoint.module.scss';
import { ConfigService } from '../../services/config-service';
import { AffiliateIcon } from '../../components/icons/affiliate-icon';
import { Animation } from '../../components/layout/animation';
import { Screen } from '../../components/layout/screen';
import { Spinner } from '../../components/with-loader';
import { withEmbeddedContext } from '../../components/layout/with-embedded-context';

const Loading = React.memo(({ lottie, iconName = 'loading' }) => {
  return lottie?.loading ? <Animation path={lottie.loading} /> : <Spinner name={iconName} />;
});

const Done = React.memo(({ lottie, callback, iconName = 'tick' }) => {
  return lottie?.done ? (
    <Animation path={lottie.done} loop={false} onComplete={callback} />
  ) : (
    <AffiliateIcon name={iconName} />
  );
});

const CheckpointTemplate = React.memo(({ handleDone, calculating, calculatingMsg, completeMsg, isEmbedded }) => {
  const {
    lottie,
    labels: { loading, done },
    loadingIcon,
    doneIcon
  } = ConfigService.get('CHECKPOINT', { labels: {} });

  return (
    <Screen className={classnames(styles.container, { [styles.embedded]: isEmbedded })}>
      <div className={classnames(styles.icon, { [styles.simple]: !lottie })}>
        {calculating ? (
          <Loading lottie={lottie} iconName={loadingIcon} />
        ) : (
          <Done lottie={lottie} callback={handleDone} iconName={doneIcon} />
        )}
      </div>
      <h2 className={styles.label}>{calculating ? calculatingMsg ?? loading : completeMsg ?? done}</h2>
    </Screen>
  );
});

export const Checkpoint = compose(
  withRouter,
  withState('calculating', 'setCalculating', true),
  withState('done', 'setDone', false),
  withHandlers({
    handleDone:
      ({ setDone }) =>
      () => {
        setDone(true);
      }
  }),
  withEmbeddedContext,
  withHandlers({
    handleComplete:
      ({ setCalculating, handleDone }) =>
      () => {
        const { lottie, timeouts } = ConfigService.get('CHECKPOINT', {});

        setTimeout(() => {
          setCalculating(false);

          !lottie?.done && setTimeout(handleDone, timeouts.done);
        }, timeouts.loading);
      }
  }),
  lifecycle({
    componentDidMount() {
      const { loading, setCalculating, handleDone, isEmbedded, postResizeMessage } = this.props;

      const { timeouts } = ConfigService.get('CHECKPOINT', {});

      if (!loading) {
        if (isEmbedded) {
          postResizeMessage();
        }

        setTimeout(() => {
          setCalculating(false);

          setTimeout(handleDone, timeouts.done);
        }, timeouts.loading);
      }
    },
    componentDidUpdate(prevProps) {
      const { loading, done, handleComplete, isEmbedded, postResizeMessage } = this.props;

      if (prevProps.loading !== loading && !loading) {
        if (isEmbedded) {
          postResizeMessage();
        }

        handleComplete();
      }

      if (prevProps.done !== done && done) {
        const { history, to } = this.props;

        history.replace(to);
      }
    }
  })
)(CheckpointTemplate);
