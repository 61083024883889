import React from 'react';
import uuid from 'uuid';
import styles from '../../../../../../styles/components/layout-popup/unmanaged.module.scss';
import { CoachMessage } from '../../../../../coach-message';
import IntersectionVisible from '../../../../../intersection-visibility';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../../../buttons/button-with-icon';
import { InputGroup } from '../../../../../inputs/input-group';
import { TBody } from '../../../../../template';
import { PurifiedHtmlContainer } from '../../../../purified-html-container';
import { Spacer } from '../../../../spacers';
import { ConfigService } from '../../../../../../services/config-service';

export const UnmanagedState = React.memo(({ onAccept, onAcceptEssential, onManage, onInputAppear }) => {
  const { body, message, buttons } = ConfigService.get('ACCOUNT.MODALS.CONSENT.unmanaged', {});
  const { confirm, essential, manage } = buttons;
  const messages = [].concat(message);

  return (
    <>
      {message ? (
        <CoachMessage>
          {messages.map(line => (
            <PurifiedHtmlContainer
              key={uuid('consent-unmanaged-message')}
              renderAs='p'
              className='typography'
              html={line}
            />
          ))}
        </CoachMessage>
      ) : null}
      {body ? <TBody html={body} /> : null}
      <Spacer renderAs='div' type='component' />
      <IntersectionVisible onShow={onInputAppear} once>
        <InputGroup type='coach'>
          <ButtonWithIcon className={styles.button} onClick={onAccept}>
            {confirm}
          </ButtonWithIcon>
          {essential ? (
            <ButtonWithIcon className={styles.button} onClick={onAcceptEssential}>
              {essential}
            </ButtonWithIcon>
          ) : null}
          <ButtonWithIcon className={styles.button} onClick={onManage}>
            {manage}
          </ButtonWithIcon>
        </InputGroup>
      </IntersectionVisible>
    </>
  );
});
