import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import styles from '../../../styles/templates/custom-page/trust-card.module.scss';
import { Image } from '../../../components/images/image';
import { AffiliateIcon } from '../../../components/icons/affiliate-icon';
import { ConfigService } from '../../../services/config-service';
import { getMarketplacePartner } from '../../../store/reducers/marketplace';
import { withLoader } from '../../../components/template';
import { getPartnerTrustCard } from '../../../helpers/marketplace';
import { withLoadingHandlers } from '../../../components/with-loader';

const Entry = React.memo(({ icon, title, children }) => (
  <div className={styles.item}>
    <div className={styles.info}>
      <AffiliateIcon name={icon} className={styles.icon} />
      <p className={styles.heading}>{title}</p>
    </div>
    <p className={styles.description}>{children}</p>
  </div>
));

const TrustCardComponent = React.memo(
  ({
    id,
    hideDetails,
    headerAs: Header = 'h2',
    partner: { name, logo, valueAlignment, commercialDeal, regulator }
  }) => {
    const { title, firstRow, secondRow, thirdRow } = ConfigService.get('COMPONENTS.TRUST_CARD', {});

    return (
      <section className={styles.wrapper} id={id}>
        <article className={styles.container}>
          <div className={styles.header}>
            {logo && <Image src={logo.url} alt={logo.alt} className={styles.image} />}
            <Header className={styles.partner}>
              <span className={styles.title}>{title}</span>
              <span className={styles.name}>{name}</span>
            </Header>
          </div>
          {!hideDetails && (
            <div className={styles.body}>
              {valueAlignment && (
                <Entry icon='trust-why-we-like-them' title={firstRow}>
                  {valueAlignment}
                </Entry>
              )}
              {commercialDeal && (
                <Entry icon='trust-how-we-make-money' title={secondRow}>
                  {commercialDeal}
                </Entry>
              )}
              {regulator && (
                <Entry icon='trust-the-legal-bit' title={thirdRow}>
                  {regulator}
                </Entry>
              )}
            </div>
          )}
        </article>
      </section>
    );
  }
);

TrustCardComponent.displayName = 'TrustCardComponent';

TrustCardComponent.propTypes = {
  id: PropTypes.string,
  headerAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  hideDetails: PropTypes.bool,
  partner: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    }),
    valueAlignment: PropTypes.string,
    commercialDeal: PropTypes.string,
    regulator: PropTypes.string
  })
};

const mapStateToProps = (state, { partnerKey }) => {
  const normalizedPartners = state.marketplace?.normalizedPartners ?? {};

  return {
    partner: getPartnerTrustCard(normalizedPartners, partnerKey)
  };
};

const TrustCard = compose(
  connect(mapStateToProps, { getMarketplacePartner }),
  withLoadingHandlers(true),
  lifecycle({
    async componentDidMount() {
      const { partnerKey, partner, getMarketplacePartner, turnOffLoading } = this.props;

      if (ObjectShim.isEmpty(partner)) {
        await getMarketplacePartner(partnerKey);
      }
      turnOffLoading();
    }
  }),
  withLoader,
  branch(({ partner }) => ObjectShim.isEmpty(partner), renderNothing)
)(TrustCardComponent);

TrustCard.displayName = 'TrustCard';

TrustCard.propTypes = {
  id: PropTypes.string,
  partnerKey: PropTypes.string.isRequired,
  headerAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  hideDetails: PropTypes.bool
};

export { TrustCard, TrustCardComponent };
