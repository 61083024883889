import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../workers/evaluate-condition';
import { interpolateRegex } from '../regex';
import { insertVarBeforeInterpolation } from '../insert-var-into-string';

const worker = workerBase();

//The main idea here is to prevent possible XSS attacks
//For those reasons we are evaluating code inside web worker which doesn't have access to window, local storage, etc.

/**
 *
 * @param {object} userState - state, including spread userAttributes and activity = statusData.plainStatuses
 * @param {string} condition - stringifies display condition from admin
 * @param {object} params - additional params
 * @return {Promise<boolean|*>}
 */

//If you want to compare strings. You must wrap condition in admin console into `` (e.g `${goal.location}` !== `London`)
export const evaluateCondition = async (userState, condition, params) => {
  //if error occurs we set false
  try {
    const parsedCondition = parse(userState, condition, params);

    return await worker.evaluate(parsedCondition);
  } catch {
    return false;
  }
};

export const parse = (userState, value, params) => {
  if (typeof value !== 'string') {
    return value;
  }

  value = insertVarBeforeInterpolation(value, { ...params });

  return value.replace(interpolateRegex, (_, name) => {
    //here we access any nesting level of parent
    //stringify the result to be able to work with objects in worker functions e.g. isItemExist
    const userStateVal = ObjectShim.getNested(userState, name);

    return typeof userStateVal === 'object' ? JSON.stringify(userStateVal) : userStateVal;
  });
};
