import React from 'react';
import PropTypes from 'prop-types';
import { PlainTopic } from './plain-topic';
import { CollapsibleTopic } from './collapsible-topic';

const customLists = {
  collapsible: CollapsibleTopic
};

const KnowledgeBaseTopicWrapper = React.memo(({ listType, ...props }) => {
  if (customLists[listType]) {
    const Component = customLists[listType];
    return <Component {...props} />;
  }

  return <PlainTopic {...props} />;
});

KnowledgeBaseTopicWrapper.propTypes = {
  listType: PropTypes.oneOf(['collapsible', undefined])
};

KnowledgeBaseTopicWrapper.displayName = 'KnowledgeBaseTopicWrapper';

export { KnowledgeBaseTopicWrapper };
