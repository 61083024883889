import Loadable from 'react-loadable';
import { Loader } from '@apps/firsthomecoach/src/components/with-loader';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { ACCOUNT, CHECKPOINT, CUSTOM, LANDING, MARKET_PLACE } from '@apps/firsthomecoach/src/helpers/navigation';
import { CustomPageComplete } from '@apps/firsthomecoach/src/pages/custom-page/complete';

const CustomPage = Loadable({
  loader: () => import(/* webpackChunkName: "custom-page" */ '../pages/custom-page').then(module => module.CustomPage),
  loading: Loader
});

const LandingPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "landingPage" */ '@apps/firsthomecoach/src/pages/landing').then(
      module => module.Landing
    ),
  loading: Loader
});

export const customPageRoutes = [
  {
    to: '/account/:module/(start|result|info)',
    component: asTemplate(ACCOUNT)(CustomPage)
  },
  {
    to: '/market-place/:module/(start|result|info)',
    component: asTemplate(MARKET_PLACE)(CustomPage)
  },
  {
    to: '/checklist/:module/(start|result|info)',
    component: asTemplate(CUSTOM)(CustomPage)
  },
  {
    to: '/(checklist|account|market-place)/:module/done/:redirectTo?',
    component: asTemplate(CHECKPOINT)(CustomPageComplete)
  },
  {
    to: '/lp/:name',
    component: asTemplate(LANDING)(LandingPage)
  }
];
