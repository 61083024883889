export const affiliate = window.affiliate;

export const createQueryParams = params => {
  const pairs = [];
  for (let prop in params) {
    if (!params.hasOwnProperty(prop)) {
      continue;
    }

    if (params[prop]) {
      pairs.push(prop + '=' + params[prop]);
    }
  }
  return pairs.join('&');
};

export const isLocalhost = window.location.hostname === 'localhost';

export const isDevelopmentMode =
  isLocalhost ||
  window.location.hostname.split('.').includes('dev') ||
  window.location.hostname.indexOf('local.lifehubplatform.com') >= 0;
