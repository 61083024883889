import { isUndefined } from '@packages/helpers/core/common';

export const validateCheckbox = (value, config) => {
  const { max, min, requiredAll, items, defaultValue, errorMessages } = config;

  const totalItems = items.length;
  const checkedItems = Object.values(value).filter(item => item.checked).length;

  if (requiredAll && checkedItems !== totalItems) {
    return errorMessages?.requiredAll ?? 'Please select all options';
  }

  if (checkedItems < min) {
    return errorMessages?.min ?? `Please select at least ${min} options`;
  }

  if (checkedItems > max) {
    return errorMessages?.max ?? `Please select no more than ${max} options`;
  }

  if (!Object.values(value).some(item => item.checked) && isUndefined(defaultValue)) {
    return errorMessages?.base ?? 'Please select at least one option';
  }

  return null;
};
