import { get, getPage, post, put, remove } from './utils';

const getActivities = async () => get('/status-data/digitalCoach');

const getActivitiesList = async () => get('/status-data/activities');

const getNextActivity = async () => get(`/status-data/next-activity?page=${getPage()}`);

const getSpecificActivity = async pageIdentifier => get(`/status-data/next-activity?page=${pageIdentifier}`);

const sendStatusData = async params => post(`/status-data`, params);

const trackDCAction = async params => post(`/track/special-condition-action`, params);

const handleDCAction = async params => post(`/digital-coach/handle`, params);
const getNextDC = async () => get(`/digital-coach/next?page=${getPage()}`);

const removeStatusData = async ({ activityKey, activityStatusKey }) =>
  remove(`/status-data/${activityKey}/${activityStatusKey}`);

const recalculateActivity = async () => put('/status-data/recalculate-activity');

const getStatusData = async () => get(`/status-data`);

const callCustomPostAction = async (url, params = {}) => post(url, params);

export const statusData = {
  getActivities,
  getActivitiesList,
  getNextActivity,
  getSpecificActivity,
  sendStatusData,
  getStatusData,
  removeStatusData,
  recalculateActivity,
  callCustomPostAction,
  handleDCAction,
  getNextDC,
  trackDCAction
};
