import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, lifecycle } from 'recompose';
import * as NAVIGATION from '../../helpers/navigation';
import { getLocationPropertyGoalState } from '../../helpers/navigation/routing';
import { getUserAttributes, sendPropertyGoal } from '../../store/reducers/user-attributes/actions';
import { withLoadingHandlers } from '../../components/with-loader';
import { Checkpoint } from '../checkpoint';

const RefreshPageComponent = ({ loading, to }) => (
  <Checkpoint to={to} loading={loading}>
    <Helmet title='Complete' />
  </Checkpoint>
);

const mapActionByGist =
  dispatch =>
  ({
    location: { state },
    match: {
      params: { gist }
    }
  }) => {
    switch (gist) {
      case NAVIGATION.PROPERTY_GOAL:
        const goal = getLocationPropertyGoalState(state);

        return { refreshAction: () => goal && dispatch(sendPropertyGoal(goal)) };
      default:
        return { refreshAction: () => dispatch(getUserAttributes()) };
    }
  };

const mapStateToProps = state => ({
  to: state.digitalCoach.to
});

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    ...mapActionByGist(dispatch)(ownProps)
  };
};

export const RefreshPage = compose(
  connect(mapStateToProps, null, mergeProps),
  withLoadingHandlers(true),
  lifecycle({
    async componentDidMount() {
      const { refreshAction, turnOffLoading } = this.props;

      await refreshAction();
      turnOffLoading();
    }
  })
)(RefreshPageComponent);
