import { UserService } from '../../../services/user-service';
import { createAction } from '../../utils';
import * as ACCOUNT from './action-types';

export const getUser = () => async dispatch => {
  const getUserResponse = await dispatch(
    createAction(
      {
        request: ACCOUNT.GET_USER_REQUEST,
        success: ACCOUNT.GET_USER_SUCCESS,
        failure: ACCOUNT.GET_USER_FAILURE
      },
      api => api.users.getAuthorizedUser
    )()
  );

  if (getUserResponse.userId) {
    UserService.setUserShadowId(getUserResponse.userId);
  }

  return getUserResponse;
};
