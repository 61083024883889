import { getPositivePursuits } from './get-positive-pursuits';

export const calculatePositiveScore = data => {
  const obj = getPositivePursuits(data);

  const score = Object.values(obj).length;

  if (score >= 3) {
    return 15;
  }

  return score * 5;
};
