import { get, put } from '@apps/firsthomecoach/src/api/utils';

const updateProperty = async params => put('/property-data/properties', params);
const getAlternativeMortgages = async ({ propertyId, attributeName = 'property.items' }) =>
  get(`/property-data/mortgage-deals?attributeName=${attributeName}&propertyKey=${propertyId}`);
const getPropertyByUprnCode = async ({ uprn, propertyId, attributeName = 'property.items', addressKey }) =>
  get(
    `/property-data/load-property?uprn=${uprn}&attributeName=${attributeName}&propertyKey=${propertyId}&addressKey=${addressKey}`
  );

export const propertyAddress = {
  updateProperty,
  getAlternativeMortgages,
  getPropertyByUprnCode
};
