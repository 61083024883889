import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { FHC_MODULE_STATUSES } from '../../../../helpers/constants';

dayjs.extend(minMax);
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
const DEFAULT_DATE_VALUE = 'Never';

export const lastAnsweredDateByActivityStatuses = ({ statuses, activityKeys }) => {
  const existedActivities = [];

  for (const key of activityKeys) {
    if (statuses[key]) {
      existedActivities.push(statuses[key]);
    }
  }

  const dates = existedActivities
    .filter(({ key }) => key === FHC_MODULE_STATUSES.IN_PROGRESS || key === FHC_MODULE_STATUSES.COMPLETED)
    .map(({ dateTime }) => dateTime);

  let date = DEFAULT_DATE_VALUE;

  if (dates.length) {
    date = dayjs.max(dates.map(dateTime => dayjs(dateTime))).format(DEFAULT_DATE_FORMAT);
  }

  return date;
};
