/* eslint-disable no-console */
export class NativeAction {
  static isInitialized = false;

  static initialize() {
    const android = window.NativeTrigger;
    const iOS = window.webkit && window.webkit.messageHandlers.NativeTrigger;
    const cordova = window.webkit && window.webkit.messageHandlers.cordova_iab;

    NativeAction.isInitialized = android || iOS || cordova;

    this.method =
      (android && {
        trigger: (name, params) => {
          try {
            return android.trigger(name, JSON.stringify(params));
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`[NativeAction Trigger Android] ${name} failed`, e);
          }
        }
      }) ||
      (iOS && {
        trigger: (name, params) => {
          try {
            return iOS.postMessage({ trigger: name, params: JSON.stringify(params) });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`[NativeAction Trigger iOS] ${name} failed`, e);
          }
        }
      }) ||
      (cordova && {
        trigger: (name, params) => {
          try {
            return cordova.postMessage(JSON.stringify({ trigger: name, ...params }));
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`[NativeAction Trigger Cordova] ${name} failed`, e);
          }
        }
      });

    if (cordova) {
      document.querySelector('body').addEventListener(
        'click',
        e => {
          const anchor = e.target.closest('a');

          if (anchor?.target === '_blank' && this.method?.trigger) {
            e.preventDefault();
            e.stopPropagation();

            this.method.trigger('external', { url: anchor.href });
          }
        },
        false
      );
    }
  }

  static trigger = (name, params = {}) => {
    if (this.method && this.method.trigger) {
      try {
        return this.method.trigger(name, params);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`[NativeAction] ${name} failed`, e);
      }
    }
  };
}
/* eslint-enable no-console */
