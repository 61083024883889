import React from 'react';
import compose from 'recompose/compose';
import { popper } from '../../helpers/inputs/autocomplete-popover';
import { omitProps } from '../../helpers/hocs/omit-props';
import { withAnchor, withContainer, withError, withExplainer } from './input-field';
import { withPopover } from './popovers/list';
import { Input, withInputLabel } from './input';

const CONSTRAINTS = ['displayValue'];

const Component = React.memo(({ onInput, ...props }) => {
  return <Input role='combobox' aria-autocomplete='list' onChange={onInput} {...props} />;
});

const AutocompleteInput = omitProps(CONSTRAINTS)(Component);

export const Autocomplete = withPopover({ popper })(AutocompleteInput);
export const InputAutocomplete = compose(
  withAnchor,
  omitProps(CONSTRAINTS),
  withContainer,
  withExplainer,
  withError,
  withInputLabel
)(Autocomplete);
