import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, branch, renderComponent } from 'recompose';
import { ConfigService } from '../../services/config-service';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { getNextDC, toggleCoachForcedLoading } from '../../store/reducers/digital-coach';
import { openLink } from '../../helpers/utils';
import { TYPE_ORIGIN_SNOOZE } from '../../helpers/digital-coach/constants';
import { SnoozeButton } from './buttons/snooze';
import { CustomButton } from './buttons/custom';

export const CustomAction = compose(
  branch(({ originType }) => originType === TYPE_ORIGIN_SNOOZE, renderComponent(SnoozeButton))
)(CustomButton);

const ReloadActionComponent = React.memo(
  ({
    label,
    ariaLabel,
    activityKey,
    statusKey,
    attributes,
    getNextDC,
    handleAction,
    toggleCoachForcedLoading,
    buttonType = 'major'
  }) => {
    const reloadTimeout = ConfigService.get('COACH.reloadTimeout', 1500);

    const onClick = async () => {
      toggleCoachForcedLoading(true);
      setTimeout(() => toggleCoachForcedLoading(false), reloadTimeout);

      await handleAction();
      getNextDC();
    };

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const ReloadAction = connect(null, {
  getNextDC,
  toggleCoachForcedLoading
})(ReloadActionComponent);

export const ActionComponent = React.memo(
  ({ label, ariaLabel, link, isExternal, handleAction, buttonType = 'major' }) => {
    const history = useHistory();

    const onClick = useCallback(async () => {
      await handleAction();

      return openLink(link, history, isExternal);
    }, [handleAction, link, history, isExternal]);

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);
