import React from 'react';
import classnames from 'classnames';
import styles from '../../../styles/widgets/digital-coach-widget/digital-coach.module.scss';
import { TComponent } from '../../../components/template';
import { Column, Grid } from '../../../components/layout/grid';

export const DigitalCoachContainer = React.memo(({ className, id, children }) => (
  <TComponent renderAs='section' edge className={classnames(styles.container, className)} id={id}>
    <Grid>
      <Column>{children}</Column>
    </Grid>
  </TComponent>
));

export const withDigitalCoachContainer = Component => props =>
  (
    <DigitalCoachContainer>
      <Component {...props} />
    </DigitalCoachContainer>
  );
