import debounce from 'lodash.debounce';
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose';

export const withDebouncedHandlers = (handlers = {}, timeout = 200) => {
  const keys = Object.keys(handlers);

  let cleanup = [];

  return compose(
    withHandlers(handlers),
    withPropsOnChange([...keys], props =>
      keys.reduce((handlers, handlerName) => {
        const callback = props[handlerName];
        const handler = debounce(callback, timeout);

        cleanup.push(handler);

        return {
          ...handlers,
          [handlerName]: handler
        };
      }, {})
    ),
    lifecycle({
      componentWillUnmount() {
        cleanup.forEach(debounced => {
          debounced.cancel();
        });

        cleanup = [];
      }
    })
  );
};
