import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';

const ActionBackButton = React.memo(({ label, ariaLabel, buttonType, trackAction }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    trackAction();

    const isOpenedDirectly = history.length < 2 || history.action === 'POP';

    if (isOpenedDirectly) {
      // this is a fallback route for the case when the user opens link where back button is present
      return history.push('/dashboard');
    }

    return history.goBack();
  }, [trackAction, history]);

  return (
    <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
      {label}
    </ButtonWithIcon>
  );
});

ActionBackButton.displayName = 'ActionBackButton';

ActionBackButton.propTypes = {
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  buttonType: PropTypes.string,
  trackAction: PropTypes.func.isRequired
};

export { ActionBackButton };
