/**
 * Checks whether aria-disabled true or false.
 * Based on its value executes wrapped function or skips it.
 * @param fn
 * @return {(function(*): void)|*}
 */
export const ariaDisabledInterceptor = fn => event => {
  const isAriaDisabled = event?.currentTarget?.getAttribute('aria-disabled') === 'true';

  if (!isAriaDisabled && !!fn) {
    fn(event);
  }
};
