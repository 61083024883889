import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, branch, defaultProps, renderNothing } from 'recompose';
import { Column } from '../../../../components/layout/grid';
import styles from '../../../../styles/widgets/feature-fit-v2/ff-kpi-list.module.scss';
import { KPIIcon } from '../../../../pages/dashboard/widgets/future-fit-v2/components/kpi-indicator';

export const Item = React.memo(({ label, checked }) => (
  <li className={styles.item}>
    <div className={styles.icon}>
      <KPIIcon active={checked} />
    </div>
    <p className={classnames(styles['item-label'], { [styles['item-label-off']]: !checked })}>{label}</p>
  </li>
));

Item.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
};

const List = React.memo(({ items, className }) => (
  <Column>
    <ul className={className}>
      {items.map(({ label, checked }) => (
        <Item key={label} label={label} checked={checked} />
      ))}
    </ul>
  </Column>
));

export const KPIList = compose(
  defaultProps({
    items: []
  }),
  branch(({ items }) => items.length < 1, renderNothing)
)(List);
