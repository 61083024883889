// MODULES
export const MY_PLAN = 'plan';
export const HELP_TO_BUY = 'help-to-buy';
export const MORTGAGE = 'how-much-can-i-borrow';
export const DEPOSIT_BUILDER = 'deposit-builder';
export const PROPERTY_GOAL = 'property-goal';

// PAGES
export const ACCOUNT = 'account';
export const BLOG = 'blog';
export const BLOG_POST = 'post';
export const ARTICLES = 'articles';
export const ARTICLE = 'article';
export const CHECKLIST = 'checklist';
export const TOUR = 'tour';
export const LANDING = 'landing';
export const DASHBOARD = 'dashboard';
export const BARRIER = 'barrier';
export const LEGAL = 'legal';
export const JOB_DETAILS = 'job-details';
export const DEMO = 'demo';
export const CALCULATORS = 'calculators';
export const POINTS = 'points';
export const REWARDS = 'rewards';
export const STATUS = 'status';
export const CUSTOM = 'custom';
export const PROPERTY = 'property-page';
export const NOT_FOUND = 'not-found';
export const MARKET_PLACE = 'market-place';
export const STORIES = 'stories';
export const STORY = 'story';
export const VIDEO = 'video';
export const COINS = 'coins';
export const PROJECT = 'project';
export const HELP = 'help';

// CUSTOM PAGES
export const DONE = 'done';
export const START = 'start';
export const RESULT = 'result';
export const CHECKPOINT = 'checkpoint';
export const QUESTION = 'question';
export const SECTION = 'section-page';

// ACCOUNT PAGES
export const ACCOUNT_HOME = 'account-home';
export const ACCOUNT_HOME_OPTION = 'account-home-option';
export const ACCOUNT_BUSINESS_PROFILE = 'account-business-profile';
export const ACCOUNT_DELETE_BUSINESS_PROFILE = 'account-delete-business-profile';
export const ACCOUNT_SIGNUP = 'account-signup';
export const ACCOUNT_SIGNIN = 'account-signin';
export const ACCOUNT_RESET_PASSWORD = 'account-reset-password';
export const ACCOUNT_COMPLETE = 'account-complete';

// MODES
export const EDIT_MODE = 'edit';
export const EDIT_MODE_WITH_SKIP = 'edit-with-skip';
