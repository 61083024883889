import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { createReducer, createAction } from '../utils';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';

const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';

export const getFAQ = params =>
  createAction(
    {
      request: GET_FAQ_REQUEST,
      success: GET_FAQ_SUCCESS,
      failure: GET_FAQ_FAILURE
    },
    api => api.content.getKnowledgeFAQ
  )(params);

const initialState = {
  questions: {},
  loading: true,
  error: false
};

export const faq = createReducer(initialState, {
  [GET_FAQ_REQUEST]: state => ({ ...state, loading: true }),
  [GET_FAQ_SUCCESS]: (state, { params, payload }) => ({
    ...state,
    questions: {
      ...state.questions,
      ...{
        [params.name]: params.normalize
          ? ArrayShim.normalize(payload, params.normalize)
          : ArrayShim.normalizeByArrayIndex(payload)
      }
    },
    loading: false
  }),
  [GET_FAQ_FAILURE]: (state, { params }) => ({
    ...state,
    questions: { ...state.questions, ...{ [params.pathname]: params.normalize ? {} : [] } },
    loading: false,
    error: true
  }),
  [DELETE_ACCOUNT_SUCCESS]: () => ({ ...initialState })
});
