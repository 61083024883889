import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../styles/components/page-header/secondary-header.module.scss';
import { Image } from '../components';
import { Identifier, IdentifierConfigPropTypes } from './identifier';

const ImageSubBlock = ({ image, category, identifier, alt }) => (
  <div className={styles.image}>
    <Image src={image} alt={alt} className={styles['block-image']}>
      <Identifier className={styles['category-sub-block']} category={category} identifier={identifier} />
    </Image>
  </div>
);

ImageSubBlock.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string,
  identifier: PropTypes.shape(IdentifierConfigPropTypes),
  alt: PropTypes.string
};

ImageSubBlock.displayName = 'ImageSubBlock';

export { ImageSubBlock };
