import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, withHandlers } from 'recompose';
import { sendCreditScore } from '../../store/reducers/user-attributes/actions';
import { Question } from '../../templates/questions/question-template';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { getRoutesByPathname } from '../../helpers/utils';

const message = (
  <>
    <p>Are you currently living in rented accommodation?</p>
    <p>This service only works if you’re a renter.</p>
  </>
);

const RentingQuestion = ({ setCreditScore }) => (
  <>
    <Helmet title='Renting' />
    <Question message={message}>
      <ButtonWithIcon onClick={() => setCreditScore(true)}>Yes</ButtonWithIcon>
      <ButtonWithIcon onClick={() => setCreditScore(false)}>No</ButtonWithIcon>
    </Question>
  </>
);

export const Renting = compose(
  connect(null, { sendCreditScore }),
  withHandlers({
    setCreditScore:
      ({ history, sendCreditScore }) =>
      async value => {
        const {
          location: { pathname }
        } = history;
        const [gistModule] = getRoutesByPathname(pathname);
        const tenant = Boolean(value);
        const slug = value ? 'regular-payments' : 'done';

        await sendCreditScore({ tenant });
        history.push(`/${gistModule}/rent-reporting/${slug}`);
      }
  })
)(RentingQuestion);
