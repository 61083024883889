import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getThemeByPathname } from '../helpers/layout';

const ThemeContext = React.createContext();

const { Provider, Consumer } = ThemeContext;

const ThemeProvider = React.memo(({ children }) => {
  const { pathname } = useLocation();
  const [theme, setTheme] = useState(() => ({ prev: null, current: null }));
  const themedPages = useSelector(state => state.navigation.themedPages);

  const setCustomTheme = useCallback(
    customTheme => {
      document.documentElement.classList.add('');
      setTheme(stateTheme => ({
        prev: stateTheme.current,
        current: customTheme || getThemeByPathname(pathname, themedPages)
      }));
    },
    [pathname, themedPages]
  );

  useEffect(() => {
    const newCurrentTheme = getThemeByPathname(pathname, themedPages);
    if (theme.current !== newCurrentTheme) {
      setTheme(theme => ({
        prev: theme.current,
        current: newCurrentTheme
      }));
    }
  }, [pathname, themedPages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    theme.prev && document.documentElement.classList.remove(theme.prev);
    theme.current && document.documentElement.classList.add(theme.current);
  }, [pathname, theme]);

  return <Provider value={{ theme: theme.current, setCustomTheme }}>{children}</Provider>;
});

export { ThemeContext, ThemeProvider, Consumer as ThemeConsumer };
