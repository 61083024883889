import React from 'react';
import uuid from 'uuid';
import styles from '../../../../../../styles/components/layout-popup/managed.module.scss';
import { ConsentLists } from '../../../../../../pages/account/pages/cookies/components/consent-list';
import IntersectionVisible from '../../../../../intersection-visibility';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../../../buttons/button-with-icon';
import { CoachMessage } from '../../../../../coach-message';
import { InputGroup } from '../../../../../inputs/input-group';
import { TBody } from '../../../../../template';
import { PurifiedHtmlContainer } from '../../../../purified-html-container';
import { Spacer } from '../../../../spacers';
import { ConfigService } from '../../../../../../services/config-service';

export const ManagedState = React.memo(({ value, onAccept, onAcceptEssential, onChange, onInputAppear }) => {
  const { body, message, buttons } = ConfigService.get('ACCOUNT.MODALS.CONSENT.managed', {});
  const { confirm, essential } = buttons;
  const messages = [].concat(message);

  return (
    <>
      {message ? (
        <CoachMessage>
          {messages.map(line => (
            <PurifiedHtmlContainer
              key={uuid('consent-managed-message')}
              renderAs='p'
              className='typography'
              html={line}
            />
          ))}
        </CoachMessage>
      ) : null}
      {body ? <TBody html={body} /> : null}
      {body ? <Spacer renderAs='div' type='component' /> : null}
      <ConsentLists onChange={onChange} value={value} />
      <Spacer renderAs='div' type='component' />
      <IntersectionVisible onShow={onInputAppear} once>
        <InputGroup className={styles.wrapper}>
          <ButtonWithIcon className={styles.button} onClick={onAccept}>
            {confirm}
          </ButtonWithIcon>
          {essential ? (
            <ButtonWithIcon className={styles.button} onClick={onAcceptEssential}>
              {essential}
            </ButtonWithIcon>
          ) : null}
        </InputGroup>
      </IntersectionVisible>
    </>
  );
});
