import { createCustomPageProps } from '../utils';

export const getStatusPlain = currentStatus => currentStatus?.key;
export const isModuleCompletedPlain = currentStatus => currentStatus?.key === 'completed';
export const isModuleCompleted = (statusData, statusKey) => statusData.parsedStatuses[statusKey]?.key === 'completed';
export const redirectToModule = (to, statusMapping, statusData) => {
  const result = `/checklist/${createCustomPageProps(to).name}/result`;

  return isModuleCompleted(statusData, statusMapping) ? result : to;
};
