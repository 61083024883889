import React from 'react';
import PropTypes from 'prop-types';
import { LifeScoreDataviz } from '../../../life-score-dataviz';
import styles from '../../../../styles/components/page-header/secondary-header.module.scss';
import { HeaderContent } from './header-content-container';
import { Identifier, IdentifierConfigPropTypes } from './identifier';

const LifeScoreSubBlock = ({ category, identifier }) => (
  <HeaderContent>
    <LifeScoreDataviz />
    <Identifier className={styles['category-sub-block']} category={category} identifier={identifier} />
  </HeaderContent>
);

LifeScoreSubBlock.propTypes = {
  category: PropTypes.string,
  identifier: PropTypes.shape(IdentifierConfigPropTypes)
};

LifeScoreSubBlock.displayName = 'LifeScoreSubBlock';

export { LifeScoreSubBlock };
