import React from 'react';
import { ImageSubBlock } from './image-sub-block';
import { LifeScoreSubBlock } from './life-score-sub-block';

const blocks = {
  lifeScoreDataviz: LifeScoreSubBlock,
  image: ImageSubBlock
};

/**
 *
 * @param subBlockName - to support previous headers - the 'image' is set to default.
 * Secondary Header component renders if image is provided or subBlockName is set in HOC `withSecondaryHeader`
 * @param props - other props that may be needed for specific sub block.
 * @return {JSX.Element}
 */

export const SubBlock = ({ subBlockName = 'image', ...props }) => {
  const Component = blocks[subBlockName];

  return <Component {...props} />;
};
