const mapStateToProps = ({ userAttributes, points, statusData: { plainStatuses } }) => ({
  ...userAttributes,
  activity: plainStatuses,
  user: { ...userAttributes?.user, ...points }
});

const mapStateToPropsV2 = ({ userAttributes, points, statusData: { plainStatuses } }) => ({
  activity: plainStatuses,
  userAttribute: {
    ...userAttributes,
    user: {
      ...userAttributes?.user,
      ...points
    }
  }
});

const stateHandlers = {
  2: mapStateToPropsV2
};

export const createConditionStateSelector =
  (conditionVersion, defaultStateToProps = mapStateToProps) =>
  params => {
    const statePropsHandler = stateHandlers?.[conditionVersion] || defaultStateToProps;

    return {
      userState: {
        ...statePropsHandler(params)
      }
    };
  };
