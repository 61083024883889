/**
 * Check whether the exit block hidden or not depending on how hiddenForActivities is configured
 * @param { string } activity - activity key (from question configuration)
 * @param { string } pathnameActivity - activity from url
 * @param { bool } hidePageActions - set in code to hide on some templates
 * @param { bool } isEnabled - is enabled in configuration
 * @param { Array.<String> } hiddenForActivities - array of activity keys or seoFriendlyNames - see admin activities
 * @returns { boolean }
 */
export const checkExitBlockVisibility = ({
  activityKey,
  pathnameActivity,
  hiddenForActivities,
  hidePageActions,
  isEnabled
}) => {
  if (!isEnabled || hidePageActions || hiddenForActivities?.includes(activityKey)) {
    return true;
  }

  return hiddenForActivities?.includes(pathnameActivity);
};
