import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { createReducer } from '../utils';

const SUBSCRIBE_TOOLTIP = 'SUBSCRIBE_TOOLTIP';
const UNSUBSCRIBE_TOOLTIP = 'UNSUBSCRIBE_TOOLTIP';

const DEFAULT_OPTIONS = {
  once: false
};

export const subscribeTooltip =
  (name, { once } = DEFAULT_OPTIONS) =>
  dispatch => {
    const value = once ? () => dispatch(unsubscribeTooltip(name)) : true;

    dispatch({ type: SUBSCRIBE_TOOLTIP, name, value });
  };

export const unsubscribeTooltip = name => ({ type: UNSUBSCRIBE_TOOLTIP, name });

const initialState = {
  subscribers: {}
};

export const tooltips = createReducer(initialState, {
  [SUBSCRIBE_TOOLTIP]: (state, { name, value }) => ({ ...state, subscribers: { ...state.subscribers, [name]: value } }),
  [UNSUBSCRIBE_TOOLTIP]: (state, { name }) => ({ ...state, subscribers: ObjectShim.omit(state.subscribers, [name]) })
});
