import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withPropsOnChange, withState } from 'recompose';
import { getChecklist } from '../../store/reducers/checklist';
import { get2DChecklistItems } from '../../helpers/checklist';
import {
  calculateActivityProgress,
  mapVisibleChecklistActivitiesToStatus
} from '../../helpers/activity-progress/checklist';
import { Component } from './component';

const mapStateToProps = ({ checklist, statusData, userAttributes, points }) => ({
  checklist: checklist.items ?? [],
  statuses: statusData.parsedStatuses,
  plainStatuses: statusData.plainStatuses,
  userAttributes,
  userData: { ...userAttributes?.user, ...points }
});

export const ActivityProgress = compose(
  connect(mapStateToProps, { getChecklist }),
  withPropsOnChange(['checklist'], ({ checklist }) => ({ checklist: get2DChecklistItems(checklist) })),
  withState('quantity', 'setQuantity', { total: 0, completed: 0 }),
  withState('isCalculated', 'setCalculated', false),
  withHandlers({
    performCalculation:
      ({ checklist, statuses, userAttributes, setQuantity, setCalculated, plainStatuses, userData }) =>
      async () => {
        const activitiesToStatusList = await mapVisibleChecklistActivitiesToStatus({
          checklist,
          userState: {
            ...userAttributes,
            activity: plainStatuses,
            user: userData
          },
          statuses
        });

        setQuantity(calculateActivityProgress(activitiesToStatusList));
        setCalculated(true);
      }
  }),
  lifecycle({
    async componentDidUpdate() {
      const { performCalculation, checklist, isCalculated } = this.props;

      if (checklist.length && !isCalculated) {
        performCalculation();
      }
    },
    componentDidMount() {
      const { checklist, getChecklist, performCalculation } = this.props;

      if (!checklist.length) {
        return getChecklist();
      }

      performCalculation();
    }
  })
)(Component);
