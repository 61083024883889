import React from 'react';
import { compose } from 'recompose';
import { toMoneyStringWithComma } from '../../../helpers/formatters/money-format';
import { withAdvancedInformer } from '../../../components/inputs/input-field';
import { timeframeToString } from '../../../helpers/time-format';
import { Wheel } from '../../../components/inputs/wheel';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(withContainer, withButton, withAdvancedInformer, withPreSubmitButton)(Wheel);

export const QuestionWheelInput = React.memo(
  ({
    label,
    format,
    value,
    max,
    min,
    buttonLabel,
    buttonType,
    userAttributeField,
    prefix,
    iterator,
    onChange,
    onSubmit,
    preSubmitButton,
    informer
  }) => {
    const formats = {
      currency: toMoneyStringWithComma,
      time: timeframeToString
    };

    return (
      <Input
        label={label}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        name={userAttributeField}
        value={value}
        format={{
          value: formats[format],
          computed: format
        }}
        prefix={prefix}
        increment={iterator}
        min={min}
        max={max}
        onChange={onChange}
        onSubmit={onSubmit}
        preSubmitButton={preSubmitButton}
        advancedInformer={informer}
      />
    );
  }
);
