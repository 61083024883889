import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { BugsnagService } from './bugsnag';

export class ConfigService {
  static get(path, defaultValue) {
    const CONFIG = window.CONFIG;

    try {
      return ObjectShim.getNested(CONFIG, path, path ? defaultValue : CONFIG);
    } catch (e) {
      const error = new Error(`ConfigService.get failed to get config value for path: ${path}:\n ${e.message}`);

      // eslint-disable-next-line no-console
      console.warn(error);
      BugsnagService.notify(error);
    }
  }
}
