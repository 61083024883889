import React from 'react';
import { useOutlineControl } from '@apps/firsthomecoach/src/helpers/hooks/use-outline-control';
import { useViewportControl } from '@apps/firsthomecoach/src/helpers/hooks/use-viewport-control';
import { GoogleTagManager } from '@apps/firsthomecoach/src/components/google-tag-manager';
import { Router } from '@apps/firsthomecoach/src/components/layout/content/router';
import { Consents as ConsentsModal } from '@apps/firsthomecoach/src/components/layout/popups/consents';
import { SharingOptionsModal } from '@apps/firsthomecoach/src/components/layout/popups/sharing-options';
import { HelpModal } from '@apps/firsthomecoach/src/components/layout/popups/help-popup';
import { withContent } from '@apps/firsthomecoach/src/components/layout/content/with-content';
import { Toast } from '@apps/firsthomecoach/src/components/layout/content';

import { routes } from '../../routes';

const Component = React.memo(() => {
  useOutlineControl();
  useViewportControl();

  return (
    <>
      <GoogleTagManager />
      <Toast />
      <Router routes={routes} />
      <ConsentsModal />
      <SharingOptionsModal />
      <HelpModal />
    </>
  );
});

export const Content = withContent(Component);
