import React, { forwardRef } from 'react';
import { Grid as UIKitGrid, Column as UIKitColumn } from '@packages/ui-kit/core/grid';
import { mapProps } from '../../helpers/grid';

export const Grid = forwardRef(({ renderAs, ...props }, ref) => {
  return <UIKitGrid as={renderAs} ref={ref} {...props} />;
});
export const Column = forwardRef(({ renderAs, ...props }, ref) => {
  return <UIKitColumn as={renderAs} ref={ref} {...mapProps(props)} />;
});
