import React from 'react';
import { Spinner } from '../../with-loader';
import { InputGroup } from '../input-group';
import { InputField as Input } from '../input';
import { AddressSelect } from './components/address-select';
import { LoadingMessage } from './components/loading-message';
import { PROPERTY_ADDRESS_NAME, PROPERTY_POSTCODE_NAME, STAGES } from './constants';

export const Component = React.memo(
  ({
    labels = {},
    placeholders = {},
    messages = {},
    stage,
    state,
    errors,
    errorMessages,
    maxResults,
    loading,
    processing,
    handleAddressChange,
    handlePostCodeChange
  }) => {
    return (
      <>
        {stage === STAGES.ADDRESS_LOOKUP && <LoadingMessage message={messages.addressLookup} />}
        {stage === STAGES.PROCESSING && <LoadingMessage message={messages.processing} />}
        <InputGroup heir>
          <Input
            type='text'
            name={PROPERTY_POSTCODE_NAME}
            label={labels.postcode}
            value={state.postcode}
            format='upper'
            error={errors.postcode}
            placeholder={placeholders.postcode}
            onChange={handlePostCodeChange}
            disabled={loading}
          />
          {stage >= STAGES.VALID_POST_CODE && state.postcode && (
            <AddressSelect
              name={PROPERTY_ADDRESS_NAME}
              label={labels.address}
              value={state.address}
              error={errors.address}
              errorMessages={errorMessages}
              postcode={state.postcode}
              placeholder={placeholders.address}
              disabled={loading}
              onChange={handleAddressChange}
              onLookUp={processing}
              maxResults={maxResults}
            />
          )}
          {loading && <Spinner />}
        </InputGroup>
      </>
    );
  }
);
