import { connect } from 'react-redux';

const mapStateToProps = ({ userAttributes, points, statusData: { plainStatuses } }) => ({
  userState: {
    ...userAttributes,
    activity: plainStatuses,
    user: { ...userAttributes?.user, ...points }
  }
});

// TODO: Replace all occurences of userState usage with this HOC to centralize display condition state management
export const withDisplayConditionState = connect(mapStateToProps);
