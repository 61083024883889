import { insertVarIntoString } from '../insert-var-into-string';
import { isLisa, monthDiffFromStartingTaxYear } from './accounts';

const calculateTotalInAccounts = (accounts, identifier) =>
  Object.keys(accounts).reduce((acc, current) => {
    const item = accounts[current];

    if (item.inCurrentTaxYear && isLisa(item.type) && current !== identifier) {
      return acc + item.inCurrentTaxYear;
    }

    return acc;
  }, 0);

const calculateMaxPossibleContribution = (maxMonthlyContribution, maxYearContribution) => {
  if (maxYearContribution) {
    return maxYearContribution;
  }

  if (!maxYearContribution && !maxMonthlyContribution) {
    return Infinity;
  }

  return maxMonthlyContribution * monthDiffFromStartingTaxYear(new Date());
};

const calculate = (accounts, params, identifier) => {
  const { maxMonthlyContribution, maxYearContribution, maxTotal = 20000, min, errorMessages } = params;

  let max = 0;

  //total in LISA accounts
  const totalInAccounts = calculateTotalInAccounts(accounts, identifier);
  //total possible contribution in current tax year
  const maxPossibleContribution = calculateMaxPossibleContribution(maxMonthlyContribution, maxYearContribution);
  //total in current account
  const currentAccountTotal = accounts[identifier]?.total ?? Infinity;

  const remains = maxTotal - totalInAccounts;

  if (remains > 0) {
    max = Math.min(remains, maxPossibleContribution, currentAccountTotal);
  }

  return {
    max,
    min,
    errorMessages: {
      ...errorMessages,
      max: insertVarIntoString(errorMessages.max, { max })
    }
  };
};

const inCurrentTaxYearSlug = 'contribution-in-current-tax-year';

const createValidation = ({ rule, slug, accounts, identifier }) => {
  if (slug === inCurrentTaxYearSlug && isLisa(accounts[identifier].type)) {
    return calculate(accounts, rule, identifier);
  }

  return rule;
};

export const generateValidations = (state, { slug, identifier, min, max, errorMessages }) => {
  try {
    const rules = window.CONFIG.DEPOSIT_BUILDER.VALIDATIONS;

    const accounts = state.userAttributes.accounts.items;

    const accountType = accounts[identifier]?.type;

    const rule = rules[accountType] && rules[accountType][slug];

    if (rule) {
      return createValidation({ rule, slug, accounts, identifier });
    }

    return {
      min,
      max,
      errorMessages
    };
  } catch (e) {
    throw new Error(`Generating deposit builder validation failed: ${e.toString()}`);
  }
};
