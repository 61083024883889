import Loadable from 'react-loadable';
import { Loader } from '../components/with-loader';
import { CustomPageComplete } from '../pages/custom-page/complete';
import { asTemplate } from '../components/layout/with-layout';
import { CHECKPOINT, CUSTOM, LANDING, ACCOUNT, MARKET_PLACE } from '../helpers/navigation';

const CustomPage = Loadable({
  loader: () => import(/* webpackChunkName: "customPage" */ '../pages/custom-page').then(module => module.CustomPage),
  loading: Loader
});

const LandingPage = Loadable({
  loader: () => import(/* webpackChunkName: "landingPage" */ '../pages/landing').then(module => module.Landing),
  loading: Loader
});

export const customPageRoutesObject = {
  customPageAccount: {
    to: '/account/:module/(start|result|info)',
    component: asTemplate(ACCOUNT)(CustomPage)
  },
  marketPlace: {
    to: '/market-place/:module/(start|result|info)',
    component: asTemplate(MARKET_PLACE)(CustomPage)
  },
  customPage: {
    to: '/checklist/:module/(start|result|info)',
    component: asTemplate(CUSTOM)(CustomPage)
  },
  checkpoint: {
    to: '/(checklist|account|market-place)/:module/done/:redirectTo?',
    component: asTemplate(CHECKPOINT)(CustomPageComplete)
  },
  landing: {
    to: '/lp/:name',
    component: asTemplate(LANDING)(LandingPage)
  }
};

export const customPageRoutes = Object.values(customPageRoutesObject);
