import { asTemplate } from '../components/layout/with-layout';
import { PROJECT } from '../helpers/navigation';
import { Post } from './articles';

export const projectsRoutes = [
  {
    to: '/projects/:slug',
    component: asTemplate(PROJECT)(Post)
  }
];
