import React from 'react';
import Loadable from 'react-loadable';
import { useOutlineControl } from '../../../helpers/hooks/use-outline-control';
import { useViewportControl } from '../../../helpers/hooks/use-viewport-control';
import { GoogleTagManager } from '../../google-tag-manager';
import { Consents as ConsentsModal } from '../popups/consents';
import { SharingOptionsModal } from '../popups/sharing-options';
import { HelpModal } from '../popups/help-popup';
import { routes as routesBase } from '../../../routes';
import { Router } from './router';
import { withContent } from './with-content';

export const Toast = Loadable({
  loader: () => import(/* webpackChunkName: "toast" */ '../../toast').then(module => module.Toast),
  loading: () => null
});

export const DefaultContent = React.memo(() => {
  const {
    DISPLAY_COMPONENTS: { TOAST }
  } = window.CONFIG;

  useOutlineControl();
  useViewportControl();

  return (
    <>
      <GoogleTagManager />
      {TOAST.SHOW && <Toast />}
      <Router routes={routesBase()} />
      <ConsentsModal />
      <SharingOptionsModal />
      <HelpModal />
    </>
  );
});

export const Content = withContent(DefaultContent);
