import classnames from 'classnames';
import { compose } from 'redux';
import { parseSpanValue, parseEdgeValue, parseEdgeUnlessValue, parseHideValue, parseFlexValue } from './parsers';

export const mapSpanHelpers = ({ className, span, ...props }) => {
  return {
    ...props,
    className: classnames(className, parseSpanValue(span))
  };
};
export const mapEdgeHelpers = ({ className, edge, 'edge-unless': unless, ...props }) => {
  return {
    ...props,
    className: classnames(className, parseEdgeValue(edge), parseEdgeUnlessValue(unless))
  };
};
export const mapFlexHelpers = ({ className, flex, wrap, align, justify, self, ...props }) => {
  return {
    ...props,
    className: classnames(
      className,
      parseFlexValue([
        [flex, 'direction'],
        [wrap, 'wrap'],
        [align, 'align'],
        [justify, 'justify'],
        [self, 'self']
      ])
    )
  };
};
export const mapHideHelpers = ({ className, hide, ...props }) => {
  return {
    ...props,
    className: classnames(className, parseHideValue(hide))
  };
};

export const mapProps = compose(mapSpanHelpers, mapEdgeHelpers, mapHideHelpers, mapFlexHelpers);
