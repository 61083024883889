import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { api } from '../api';
import { reducers } from './reducers';
import { postActionMiddleware } from './middlewares/post-action';

const enhancer = composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ api }), postActionMiddleware));

export const store = createStore(reducers, enhancer);
