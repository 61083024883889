import React from 'react';
import { ConfigService } from '../../../services/config-service';
import styles from '../../../styles/components/breadcrumbs.module.scss';

const BreadcrumbDelimiter = () => {
  const delimiter = ConfigService.get('BREADCRUMBS.delimiter', '/');

  return <span className={styles.delimiter}>{delimiter}</span>;
};

BreadcrumbDelimiter.displayName = 'BreadcrumbSeparator';

export { BreadcrumbDelimiter };
