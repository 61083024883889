import { compose, withProps, withPropsOnChange } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import {
  getCategoriesByHomePageType,
  pickPropsByHomePageType,
  getSortedCategoriesFromPostsList,
  plainCategoriesToCategoryOptionsList
} from '../../helpers/knowledgebase/category-filter';
import { getCategories } from '../../helpers/feed';

export const withPostsCategoryFilter = pageType =>
  compose(
    //Provide with props 'postsList' and 'accessor'
    withProps(props => ({ ...pickPropsByHomePageType(pageType, props) })),
    withPropsOnChange([''], () => ({
      // Get categories from config, if exist
      configCategories: getCategoriesByHomePageType(pageType)
    })),
    withPropsOnChange(['configCategories', 'accessor', 'postsList'], ({ postsList, accessor, configCategories }) => {
      //Creating list of filter categories to fill into input (filter)
      if (!isNil(configCategories) && Array.isArray(configCategories)) {
        return { categoryFilterOptions: getCategories(configCategories) };
      }

      if (postsList?.length) {
        const existingPostsCategories = getSortedCategoriesFromPostsList({ items: postsList, accessor });

        return { categoryFilterOptions: plainCategoriesToCategoryOptionsList(existingPostsCategories) };
      }
    })
  );
