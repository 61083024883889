export const ADVERT_VIEW_MODE_TYPED = {
  grid: 'grid',
  slider: 'slider'
};

export const DEFAULT_ADVERT_NO_GROUP_KEY = 'all';

export const getAdvertStoreGroupName = storeParams => storeParams?.groupName ?? DEFAULT_ADVERT_NO_GROUP_KEY;

export const getAdvertViewMode = type => ADVERT_VIEW_MODE_TYPED[type] ?? ADVERT_VIEW_MODE_TYPED.grid;

export const checkIfSlickDisabledClassExist = classList => classList.split(' ').includes('slick-disabled');
