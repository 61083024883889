const isBrowser = () => typeof window !== 'undefined';
const hasTouchScreen = () => window.navigator && window.navigator.maxTouchPoints > 1;
const hasPlatform = () => window.navigator && window.navigator.platform;

const REGEXP = {
  IOS: /iP(ad|hone|od)/,
  IOSSimulator: /iP(ad|hone|od) Simulator/,
  ANDROID: /Android/,
  WEBKIT: /(Mac|iPhone|iPod|iPad)/i,
  WEBVIEW:
    /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)(?!.*CriOS)|; wv)|.*(Linux;.*AppleWebKit.*Version\/\d+\.\d+.*Mobile).*/
};

export const DEVICE = {
  IOS: () =>
    isBrowser() &&
    hasPlatform() &&
    hasTouchScreen() &&
    (REGEXP.IOS.test(window.navigator.platform) ||
      REGEXP.IOSSimulator.test(window.navigator.platform) ||
      window.navigator.userAgent.includes('Mac')),
  ANDROID: () => isBrowser() && hasTouchScreen() && REGEXP.ANDROID.test(window.navigator.userAgent),
  WEBKIT: () => isBrowser() && hasPlatform() && REGEXP.WEBKIT.test(navigator.platform),
  IS_MOBILE_BROWSER: () => (DEVICE.ANDROID() || DEVICE.IOS()) && !REGEXP.WEBVIEW.test(window.navigator.userAgent)
};
