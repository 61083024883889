import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router-dom';
import { SpecificDigitalCoachWidget } from '../../../widgets/digital-coach-widget';
import { Modal } from '../../modal';
import { toggleModal } from '../../../store/reducers/modals';
import { InputGroup } from '../../inputs/input-group';
import { InputButtonWithIcon as ButtonWithIcon } from '../../buttons/button-with-icon';
import { PurifiedHtmlContainer } from '../purified-html-container';
import { Spacer } from '../spacers';
import { withFaq } from '../../../helpers/hocs/with-faq';

const HelpModalComponent = React.memo(
  ({
    show,
    modalContent: {
      key,
      title,
      body,
      image,
      isDigitalCoach,
      buttonEnabled,
      buttonLabel,
      buttonOpenNewWindow,
      buttonLink
    },
    hide
  }) => (
    <Modal leftImage={image} title={title} show={show} onClose={hide}>
      <PurifiedHtmlContainer html={body} className='typography typography-extended' />
      {isDigitalCoach ? <SpecificDigitalCoachWidget pageIdentifier={key} /> : null}
      {buttonEnabled && (
        <>
          <Spacer type='component' />
          <InputGroup>
            <ButtonWithIcon to={buttonLink} isExternal={buttonOpenNewWindow} onBeforeRoutePush={hide}>
              {buttonLabel}
            </ButtonWithIcon>
          </InputGroup>
        </>
      )}
    </Modal>
  )
);

const mapStateToProps = state => ({
  show: state.modals.showHelp,
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  hide: () => {
    dispatch(toggleModal(false, 'help'));
  }
});

export const HelpModal = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFaq,
  withPropsOnChange(['faq', 'modals'], ({ faq, modals }) => ({
    modalContent: faq.questions?.[modals.params.faqName]?.[modals.params.faqIndex] || modals.params || {}
  })),
  withRouter,
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        location: { pathname },
        show,
        hide
      } = this.props;

      if (prevProps.location.pathname !== pathname && show) {
        hide();
      }
    }
  })
)(HelpModalComponent);

HelpModal.displayName = 'HelpModal';
