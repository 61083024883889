import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import { getChecklist } from '../../store/reducers/checklist';
import { resetDynamicNavChecklistLink } from '../../store/reducers/navigation';
import { withLoader } from '../../components/with-loader';

const mapStateToProps = ({ checklist }, { isRendering }) => ({
  checklist: checklist.items,
  loading: isRendering ? isRendering : checklist.loading
});

export const withDynamicChecklist = compose(
  withState('isRendering', 'setRendering', true),
  connect(mapStateToProps, { getChecklist, resetDynamicNavChecklistLink }),
  lifecycle({
    async componentDidMount() {
      const { getChecklist, resetDynamicNavChecklistLink, setRendering } = this.props;

      await getChecklist();
      await resetDynamicNavChecklistLink();
      setRendering(false);
    }
  }),
  withLoader
);
