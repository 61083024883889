import {
  PROPERTY_ADDRESS_NAME,
  PROPERTY_POSTCODE_NAME,
  PROPERTY_UPRN_NAME
} from '../../../components/inputs/property-address/constants';

export const isValidPropertyAddress = (addressValue = {}) => {
  const address = addressValue?.[PROPERTY_ADDRESS_NAME];
  const postcode = addressValue?.[PROPERTY_POSTCODE_NAME];
  const uprn = addressValue?.[PROPERTY_UPRN_NAME];

  return address && postcode && uprn;
};
