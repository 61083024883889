import React from 'react';
import classnames from 'classnames';
import styles from '../../../../styles/components/page-header/header-content.module.scss';
import { Column, Grid } from '../../grid';
import { Body } from '../components';
import { withBackButton } from './back-button';

const ContentComponent = ({ children }) => <div className={styles.wrapper}>{children}</div>;

const ContentWithBackButton = withBackButton(ContentComponent);

export const HeaderContent = ({ className, showBackButton, contentAlign = 'left', children }) => (
  <div className={classnames(styles.content, className, styles[contentAlign])}>
    <Grid as={Body} className={styles.body}>
      <Column className={styles.column}>
        <ContentWithBackButton showBackButton={showBackButton}>{children}</ContentWithBackButton>
      </Column>
    </Grid>
  </div>
);
