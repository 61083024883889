import uuid from 'uuid';
import { parse } from 'query-string';
import { UserService } from '../../../services/user-service';
import { affiliate } from '../../../helpers/main';
import { extractIntroducerDataFromUrl } from '../../../helpers/introducer';
import { createAction } from '../../utils';
import * as ACCOUNT from './action-types';

export const createShadowUser = () => async dispatch => {
  if (UserService.hasShadowUser()) {
    // eslint-disable-next-line no-console
    return console.log('[US] User has already been assigned with shadowUserId. Remove existing shadowUserId first.');
  }

  const { client_customer_id: clientCustomerId, client_scheme_id: clientSchemeId } = parse(window.location.search);
  const introducerData = extractIntroducerDataFromUrl();
  const firebaseUserID = UserService.getFirebaseToken();

  const userData = {
    affiliateSource: affiliate ?? 'fhc',
    shadowId: uuid.v1(),
    ...introducerData.userData,
    ...(clientCustomerId ? { clientCustomerId } : {}),
    ...(clientCustomerId ? { clientSchemeId: clientSchemeId?.toLowerCase() === 'leaver' ? null : clientSchemeId } : {}),
    ...(firebaseUserID ? { firebaseToken: firebaseUserID } : {})
  };

  const createUserResponse = await dispatch(
    createAction(
      {
        request: ACCOUNT.CREATE_SHADOW_USER_REQUEST,
        success: ACCOUNT.CREATE_SHADOW_USER_SUCCESS,
        failure: ACCOUNT.CREATE_SHADOW_USER_FAILURE
      },
      api => api.users.createUser
    )(userData)
  );

  if (createUserResponse.userId) {
    UserService.setUserShadowId(createUserResponse.userId);
  }

  return createUserResponse;
};
