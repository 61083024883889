import validationLib from 'vlid';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { isEmail } from '../../../helpers/validation/account';
import { phoneNumberRegex } from '../../../helpers/regex';
import { isInputValueOptional } from '../../../helpers/validation/schema-keys';
import { applyAdditionalRules } from './additional-rules';

dayjs.extend(customParseFormat);

const DEFAULT_ERROR_MESSAGES = {
  min: '',
  max: '',
  base: ''
};

const generateRules = (type, { errorMessages = DEFAULT_ERROR_MESSAGES, max, min, format, isOptional }) => {
  switch (type) {
    case 'number':
      return validationLib
        .number()
        .rule(value => {
          if (isInputValueOptional(isOptional, value)) return true;

          return !isNaN(value);
        }, errorMessages.base)
        .required(errorMessages.base)
        .min(min, errorMessages.min)
        .max(max, errorMessages.max)
        .allow([...(isOptional ? [null, undefined, ''] : [])]);
    case 'date':
      return validationLib
        .string()
        .rule(value => {
          if (isInputValueOptional(isOptional, value)) return true;

          const date = dayjs(value.toString(), format, true);

          return date.isValid();
        }, `Invalid date! The date format should be ${format}`)
        .rule(value => {
          const date = dayjs(value.toString(), format);

          const minimum = dayjs(min, 'DD/MM/YYYY');

          return date.isAfter(minimum.isValid() ? minimum : dayjs());
        }, errorMessages.min)
        .rule(value => {
          const date = dayjs(value.toString(), format);

          const maximum = dayjs(max, 'DD/MM/YYYY');

          return date.isBefore(maximum.isValid() ? maximum : dayjs());
        }, errorMessages.max);
    case 'text':
      return validationLib
        .string()
        .required(errorMessages.base)
        .max(max, errorMessages.max)
        .min(min, errorMessages.min)
        .allow([...(isOptional ? [null, undefined, ''] : [])]);
    case 'email':
      return validationLib
        .string()
        .rule(value => {
          if (isInputValueOptional(isOptional, value)) return true;

          return isEmail(value);
        }, errorMessages.base)
        .max(max, errorMessages.max)
        .min(min, errorMessages.min);
    case 'phoneNumber':
      return validationLib
        .string()
        .rule(value => {
          const valueAfterTrim = value.toString().trim();

          return valueAfterTrim.length > min;
        }, errorMessages.min)
        .rule(value => {
          const valueAfterTrim = value.toString().trim();

          return valueAfterTrim.length <= max;
        }, errorMessages.max)
        .rule(value => {
          if (isInputValueOptional(isOptional, value)) return true;

          return phoneNumberRegex.test('+' + value);
        }, errorMessages.base);

    default:
      return null;
  }
};

export const inputSchema = (type, props) => {
  const validation = generateRules(type, props);
  const rulesToApply = props.additionalRules;

  if (rulesToApply) {
    applyAdditionalRules(type, validation, rulesToApply);
  }

  return validation;
};
