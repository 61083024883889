import React from 'react';
import classnames from 'classnames';
import { getImageUrlByName } from '../../helpers/layout';
import { useLazyImg } from '../../helpers/hooks/use-lazy-load-image';

import styles from '../../styles/components/image.module.scss';

export const Image = React.memo(
  ({
    className,
    src: initial,
    placeholderSrc,
    fallbackSrc,
    srcset,
    sizes,
    title,
    ariaHidden = false,
    height,
    cover,
    fill,
    fluid,
    style,
    loading
  }) => {
    const { ref, src, error, blurred } = useLazyImg(initial, placeholderSrc);

    return (
      <img
        ref={ref}
        sizes={sizes}
        srcSet={srcset}
        src={fallbackSrc && error ? fallbackSrc : src}
        style={{ height, ...style }}
        className={classnames(
          styles.container,
          {
            [styles.cover]: cover,
            [styles.fill]: fill,
            [styles.fluid]: fluid,
            [styles.blur]: blurred
          },
          className
        )}
        alt={title}
        aria-hidden={ariaHidden}
        loading={loading}
      />
    );
  }
);

export const SvgImage = React.memo(({ className, src, ...props }) => {
  return <image xlinkHref={src} className={className} {...props} />;
});

export const AffiliateImage = React.memo(({ name, extension, svg, ...props }) => {
  const Component = svg ? SvgImage : Image;

  return <Component src={getImageUrlByName(name, extension)} {...props} />;
});
