import { createReducer } from '../utils';

const SUBSCRIBE_DYNAMIC_WHEEL = 'SUBSCRIBE_DYNAMIC_WHEEL';
const UNSUBSCRIBE_DYNAMIC_WHEEL = 'UNSUBSCRIBE_DYNAMIC_WHEEL';

const initialState = {
  dynamic: []
};

export const subscribe = (name, callback) => ({ type: SUBSCRIBE_DYNAMIC_WHEEL, payload: { name, callback } });
export const unsubscribe = name => ({ type: UNSUBSCRIBE_DYNAMIC_WHEEL, payload: { name } });

export const wheels = createReducer(initialState, {
  [SUBSCRIBE_DYNAMIC_WHEEL]: (state, action) => {
    const { name, callback } = action.payload;

    return {
      dynamic: [...state.dynamic, [name, callback]]
    };
  },
  [UNSUBSCRIBE_DYNAMIC_WHEEL]: (state, action) => {
    const { name } = action.payload;

    return {
      dynamic: state.dynamic.filter(([wheel]) => wheel !== name)
    };
  }
});
