import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { QUESTION } from '../helpers/navigation';
import { Loader } from '../components/with-loader';

const AffordabilityLoading = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "affordability-loading"*/ '../../../../apps/firsthomecoach/src/pages/affordability-loading'
    ).then(module => module.AffordabilityLoading),
  loading: Loader
});

export const affordability = [
  {
    to: '/affordability-loading',
    component: asTemplate(QUESTION)(AffordabilityLoading)
  }
];
