import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import uuid from 'uuid';
import { INDICATOR_STATES } from './constants';

const getStepState = (currStepNumber, questionPageNumber) => {
  if (currStepNumber < questionPageNumber) {
    return INDICATOR_STATES.before;
  }

  if (currStepNumber === questionPageNumber) {
    return INDICATOR_STATES.current;
  }

  return INDICATOR_STATES.after;
};

export const createStepsArray = (pagesTotal, pageNumber) =>
  ArrayShim.range(0, Number(pagesTotal)).map(currentStep => ({
    key: uuid(),
    state: getStepState(currentStep + 1, Number(pageNumber)),
    currentStep: currentStep + 1
  }));
