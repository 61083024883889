import React from 'react';
import styles from '../../styles/widgets/life-score/life-score-dataviz.module.scss';
import { ConfigService } from '../../services/config-service';
import { Item } from './components/item';

export const LifeScoreDataviz = () => {
  const { LEFT, RIGHT } = ConfigService.get('COMPONENTS.LIFE_SCORE_DATAVIZ', { LEFT: {}, RIGHT: {} });

  return (
    <div className={styles.container}>
      <Item label={LEFT.label} icon={LEFT.icon} valuePath={LEFT.valuePath} left />
      <Item
        label={RIGHT.label}
        icon={RIGHT.icon}
        valuePath={RIGHT.valuePath}
        valueCondition={RIGHT.valueCondition}
        right
      />
    </div>
  );
};
