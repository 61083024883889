import { createAction, createReducer } from '../utils';

const GET_MARKETING_LIST_REQUEST = 'GET_MARKETING_LIST_REQUEST';
const GET_MARKETING_LIST_SUCCESS = 'GET_MARKETING_LIST_SUCCESS';
const GET_MARKETING_LIST_FAILURE = 'GET_MARKETING_LIST_FAILURE';

export const getMarketingLists = () =>
  createAction(
    {
      request: GET_MARKETING_LIST_REQUEST,
      success: GET_MARKETING_LIST_SUCCESS,
      failure: GET_MARKETING_LIST_FAILURE
    },
    api => api.configuration.getMarketingLists
  )();

const initialState = {
  lists: [],
  loading: true,
  error: false
};

export const marketingLists = createReducer(initialState, {
  [GET_MARKETING_LIST_REQUEST]: state => ({ ...state, loading: true }),
  [GET_MARKETING_LIST_SUCCESS]: (state, action) => ({
    ...state,
    lists: action.payload,
    loading: false,
    error: false
  }),
  [GET_MARKETING_LIST_FAILURE]: state => ({ ...state, loading: false, errorConsent: true })
});
