import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withHandlers } from 'recompose';
import { getVideo } from '../../store/reducers/content';
import { withLoader } from '../../components/with-loader';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import { sendContentStatus } from '../../store/reducers/content-interaction';
import { setDigitalCoachDelayLoading } from '../../store/reducers/digital-coach';
import { VideoTemplateComponent } from './component';

const mapStateToProps = ({ content }, { match }) => {
  const video = content.videos[match.params.slug];
  const loading = content.loadingVideos;

  return {
    video,
    loading
  };
};

export const VideoTemplate = compose(
  connect(mapStateToProps, { getVideo, sendContentStatus, setDigitalCoachDelayLoading }),
  withHandlers({
    reportStatus:
      ({ video: { key }, sendContentStatus }) =>
      status => {
        const options = {
          activityKey: TYPE_TO_ACTIVITY_KEY.video,
          activityStatusKey: status,
          data: { key }
        };

        return sendContentStatus(options);
      },
    getVideoBySlug:
      ({ getVideo, match }) =>
      () =>
        getVideo(match.params.slug)
  }),
  lifecycle({
    async componentDidMount() {
      const { getVideoBySlug } = this.props;

      getVideoBySlug();
    },
    async componentDidUpdate(prevProps) {
      const { video, match, getVideoBySlug, reportStatus, setDigitalCoachDelayLoading } = this.props;

      if (video && prevProps.video !== video) {
        await reportStatus('read');
        setDigitalCoachDelayLoading(false);
      }

      if (prevProps.match.params.slug !== match.params.slug) {
        getVideoBySlug();
      }
    }
  }),
  withLoader,
  branch(({ video }) => !video, renderNothing)
)(VideoTemplateComponent);
