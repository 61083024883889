import uuid from 'uuid';
import { createReducer } from '../utils';
import { setToastMessage } from '../../helpers/reducers/toast';
import { SEND_STATUS_DATA_SUCCESS } from './status-data';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';
import { SEND_CONTENT_STATUS_SUCCESS } from './content-interaction';

const HIDE_TOAST = 'HIDE_TOAST';
const ADD_TOAST = 'ADD_TOAST';

export const hideToast = () => ({ type: HIDE_TOAST });
export const addToast = payload => ({ type: ADD_TOAST, payload: { id: uuid(), ...payload } });

const initialState = {
  messages: []
};

export const toast = createReducer(initialState, {
  [SEND_STATUS_DATA_SUCCESS]: setToastMessage,
  [SEND_CONTENT_STATUS_SUCCESS]: setToastMessage,
  [ADD_TOAST]: (state, { payload }) => ({ ...state, messages: [...state.messages, payload] }),
  [HIDE_TOAST]: state => ({
    ...state,
    messages: state.messages.filter((_, index) => index !== 0)
  }),
  [DELETE_ACCOUNT_SUCCESS]: () => initialState
});
