import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { DEMO } from '../helpers/navigation';

const DemoPage = Loadable({
  loader: () => import(/* webpackChunkName: "demoPage"*/ '../pages/demo').then(module => module.DemoPage),
  loading: Loader
});

export const demoPageRoutes = [
  {
    to: '/demo/:feature',
    component: asTemplate(DEMO)(DemoPage)
  }
];
