import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { withInputRenderCondition } from '../../../templates/checklist/with-input-render-condition';
import { withSubmitHandlers } from './with-handlers';

const checkDisableConditions = ({ disabledItems, errors }) => {
  const isDisabled = Object.values(disabledItems).some(isDisabled => isDisabled);

  if (!ObjectShim.isEmpty(errors)) {
    return Object.values(errors).some(error => error) || isDisabled;
  }

  return isDisabled;
};

export const withForm = compose(
  withState('submitValue', 'setSubmitValue', {}),
  withState('submitAdditionalValues', 'setSubmitAdditionalValues', []),
  withState('disabledItems', 'setDisabledItems', {}),
  withState('errors', 'setErrors', {}),
  withPropsOnChange(['disabledItems', 'errors'], ({ disabledItems, errors }) => ({
    disabled: checkDisableConditions({ disabledItems, errors })
  })),
  withHandlers({
    setFormValues:
      ({ setSubmitValue }) =>
      ({ userAttributeField, userAttributeValue, staticPayload, attributeType }) =>
      value => {
        if (attributeType) {
          setSubmitValue(submitValue => ({
            ...submitValue,
            [attributeType]: {
              ...submitValue[attributeType],
              [userAttributeField]: userAttributeValue ?? value,
              ...staticPayload
            }
          }));
        }
      },
    setFormDisabledItems:
      ({ setDisabledItems }) =>
      ({ attributeType, userAttributeField }) =>
      isDisabled => {
        setDisabledItems(state => ({ ...state, [`${attributeType}_${userAttributeField}`]: isDisabled }));
      },
    setFormErrorItems:
      ({ setErrors }) =>
      ({ attributeType, userAttributeField }) =>
      error => {
        setErrors(state => ({ ...state, [`${attributeType}_${userAttributeField}`]: error }));
      },
    setSubmitAdditionalValues:
      ({ setSubmitAdditionalValues }) =>
      additionalValues => {
        setSubmitAdditionalValues(prevAdditionalValues => [...prevAdditionalValues, ...additionalValues]);
      }
  }),
  withSubmitHandlers,
  withInputRenderCondition
);
