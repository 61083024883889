export const pluralize = (count, string, suffix = 's') =>
  count > 1 ? `${count} ${string}${suffix}` : `${count} ${string}`;

export const formatValueToPercent = (value, precision = 0, reflect = false) => {
  const _symbol = '%';
  const _value = Number(value);

  if (reflect) {
    return 100 - _value.toFixed(precision) + _symbol;
  } else {
    return _value.toFixed(precision) + _symbol;
  }
};

export const formatValueToString = (value, suffix = '') => (value ? String(value).trim() + suffix.trim() : '');
