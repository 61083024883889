import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../../../styles/components/navigation-bar.module.scss';
import { setGlobalConfig } from '../../../api/config';
import { NativeAction } from '../../../services/native-action';
import { withMemoAndRef } from '../../../helpers/hocs/with-memo-and-ref';
import { NavigationItem } from './navigation-item';

export const getNewConfig = (nativeTrigger, introducerCode) => async e => {
  if (nativeTrigger) {
    e.preventDefault();

    NativeAction.trigger(nativeTrigger);
  } else {
    await setGlobalConfig(introducerCode);
  }
};

export const NavigationBar = withMemoAndRef(({ items, top, className }, ref) => {
  const introducer = useSelector(state => state.account.introducer);
  return (
    <ul ref={ref} className={classnames(styles.container, { [styles.top]: top }, className)}>
      {items.map(({ icon, labelText, link, nativeTrigger, ...restProps }) => (
        <NavigationItem
          key={labelText}
          icon={icon}
          labelText={labelText}
          link={link}
          nativeTrigger={nativeTrigger}
          introducer={introducer}
          {...restProps}
        />
      ))}
    </ul>
  );
});
