import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { branch, compose, renderNothing, withHandlers, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import { SafeArea } from '../../../components/layout/safe-area';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { ConfigService } from '../../../services/config-service';
import styles from '../../../styles/widgets/story-step/buttons.module.scss';
import { DigitalCoachAction } from '../../../widgets/digital-coach-widget';
import { sortByWeight } from '../../../helpers/array';
import { withStoryBlockWrapper } from './hocs/with-story-block-wrapper';

const WidgetButtonComponent = ({ onClick, label, ariaLabel, type }) => (
  <ButtonWithIcon
    key={label}
    ariaLabel={ariaLabel}
    className={styles.link}
    onClick={onClick}
    type={type === 'exit' ? 'major' : 'negative'}
  >
    {label}
  </ButtonWithIcon>
);

const WidgetButton = compose(
  withRouter,
  withHandlers({
    onClick:
      ({ history, type, link }) =>
      () => {
        if (type === 'exit') {
          const isOpenedDirectly = history.length < 2 || history.action === 'POP';

          if (isOpenedDirectly) {
            const { fallbackRoute = '/dashboard' } = ConfigService.get('STORIES.exitBlock', {});
            return history.push(fallbackRoute);
          }

          return history.goBack();
        }

        return history.push(link);
      }
  }),
  branch(({ label }) => !label, renderNothing)
)(WidgetButtonComponent);

const Component = ({ buttons, actions }) => (
  <SafeArea edges={['bottom']} fixed timeout={250}>
    <div className={classnames(styles.container, 'fill-height')}>
      {buttons.map(({ link, label, ariaLabel, type }) => (
        <WidgetButton key={label} link={link} type={type} label={label} ariaLabel={ariaLabel} />
      ))}
      {actions?.length && actions.map((action, index) => <DigitalCoachAction key={index} {...action} />)}
    </div>
  </SafeArea>
);

const ButtonsComponent = compose(
  withStoryBlockWrapper,
  withPropsOnChange(['actions'], ({ actions = [] }) => ({
    actions: sortByWeight(actions)
  }))
)(Component);

ButtonsComponent.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      type: PropTypes.oneOf(['exit', 'custom'])
    })
  )
};

export { ButtonsComponent };
