import { compose } from 'redux';
import { branch, componentFromProp, mapProps, renderNothing } from 'recompose';
import { withDisplayCondition } from '../../../helpers/hocs/with-display-condition';
import { createPageIdentifier, createWidgetIdentifier } from '../../../helpers/reducers/application';
import { withInitialWidgetLoadingLifecycle } from '../../../components/layout/content/with-native-loading-state';

export const DashboardWidget = compose(
  mapProps(({ name, widgets = {}, loadingState, ...props }) => {
    const dashboardWidgets = { ...widgets };
    const pageIdentifier = createPageIdentifier(window.location);
    const widgetIdentifier = props.widgetIdentifier || createWidgetIdentifier(name, props.order);
    const widgetTrackingName = props.widgetTrackingName || name;

    return {
      ...props,
      component: dashboardWidgets[name] ?? null,
      id: widgetIdentifier,
      widgetName: name,
      widgetTrackingName,
      widgetIdentifier,
      pageIdentifier
    };
  }),
  withDisplayCondition,
  branch(({ component }) => component, withInitialWidgetLoadingLifecycle, renderNothing)
)(componentFromProp('component'));
