export const pickRadioOptionByValue = (inputConfig, value) => {
  const { items, plainValue: usePlainValue } = inputConfig;
  const userAttrGroup = Object.values(value)[0];
  const userAttrValue = Object.values(userAttrGroup)[0];

  if (usePlainValue) {
    return items.find(item => item.value === userAttrValue);
  }

  const userAttrRadioKey = Object.keys(userAttrValue)[0];

  return items.find(item => item.name === userAttrRadioKey);
};
