import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { parseBreakpoint, parseArrayOfBreakpoints, parseMapOfBreakpoints } from '@packages/ui-kit/core/grid/helpers';
import { BREAKPOINTS_LIST } from '../../components/with-resize';

const parseSpan = (entity, breakpoint) => {
  return parseBreakpoint(breakpoint, 'span', entity);
};

const parseStartAndSpan = (entity, breakpoint) => {
  const PROPERTIES = ['start', 'span'];

  return String(entity)
    .split('+')
    .map((value, index) => parseBreakpoint(breakpoint, PROPERTIES[index], value));
};

// span='6'
// span='7+6'
// span={md: '6'}
// span={md: '7+6'}
// span={sm: '12', md: '7+6'}
// span={xss: '12', xs-md: '8', lg: '6+6'}

export const parseSpanValue = (entity, breakpoint) => {
  if (entity) {
    if (ObjectShim.isObject(entity)) {
      return parseMapOfBreakpoints(entity, parseSpanValue);
    }

    if (StringShim.isString(entity)) {
      if (entity.includes('+')) {
        return parseStartAndSpan(entity, breakpoint);
      }

      return parseSpan(entity, breakpoint);
    }
  }
};

// edge
// edge=xs
// edge=xs-lg
// edge=['xss', 'lg']
// edge=['xss-sm', 'lg']

export const parseEdgeValue = entity => {
  if (entity) {
    if (StringShim.isString(entity)) {
      return parseBreakpoint(entity, 'edge');
    }

    if (Array.isArray(entity)) {
      return parseArrayOfBreakpoints(entity, 'edge');
    }

    return 'edge';
  }
};

// edge-unless='sm'
// edge-unless=['sm', 'lg']

export const parseEdgeUnlessValue = entity => {
  if (entity) {
    return BREAKPOINTS_LIST.reduce((acc, breakpoint) => {
      if (!entity.includes(breakpoint)) {
        return [].concat(acc, parseBreakpoint(breakpoint, 'edge'));
      }

      return acc;
    }, []);
  }
};

// hide
// hide=xs
// hide=xs-lg
// hide=['xss', 'lg']
// hide=['xss-sm', 'lg']

export const parseHideValue = entity => {
  if (entity) {
    if (StringShim.isString(entity)) {
      return parseBreakpoint(entity, 'hide');
    }

    if (Array.isArray(entity)) {
      return parseArrayOfBreakpoints(entity, 'hide');
    }

    return 'hide';
  }
};

const parseHelperValue = (entity, breakpoint, helperName) => {
  if (entity) {
    if (ObjectShim.isObject(entity)) {
      return parseMapOfBreakpoints(entity, parseHelperValue, helperName);
    }

    if (StringShim.isString(entity)) {
      return parseBreakpoint(breakpoint, StringShim.toKebabCase('flex', helperName), entity);
    }

    return 'flex';
  }
};

// flex
// flex='column'
// flex={xss: true, xs-md: 'column', lg: 'column-reverse'}

export const parseFlexValue = entity => {
  return entity.reduce((acc, [helper, helperName]) => {
    if (helper) {
      return [].concat(acc, parseHelperValue(helper, void 0, helperName));
    }

    return acc;
  }, []);
};
