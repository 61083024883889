import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import styles from '../../styles/components/modal-header.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Image } from '../images/image';
import { PurifiedHtmlContainer } from '../layout/purified-html-container';
import { useSubscribe } from '../slots';

const ModalHeaderComponent = React.memo(({ html, leftImage, title, subTitle, children, onClose }) => {
  const modalTitleSlot = useSubscribe('modal-title');
  const modalSubTitleSlot = useSubscribe('modal-sub-title');

  return (
    <hgroup className={styles.container}>
      <PurifiedHtmlContainer html={html} className={styles.wrapper}>
        {title || onClose ? (
          <h2 className={styles.label}>
            {leftImage ? <Image src={leftImage} alt={leftImage} className={styles['left-image']} /> : null}
            <span className={styles.title} ref={modalTitleSlot}>
              {title}
            </span>
            {onClose ? <AffiliateIcon name='nav-exit' className={styles.icon} onClick={onClose} /> : null}
          </h2>
        ) : null}
        <p className={styles.subtitle} ref={modalSubTitleSlot}>
          {subTitle}
        </p>
      </PurifiedHtmlContainer>
      {children}
    </hgroup>
  );
});

export const ModalHeader = compose(
  branch(({ html, title, subTitle, children, onClose }) => {
    return [html, title, subTitle, children, onClose].some(Boolean) === false;
  }, renderNothing)
)(ModalHeaderComponent);
