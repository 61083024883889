import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ConfigService } from '../../services/config-service';

export const PROPERTY_PRICE = { MIN: 60000, MAX: 12100000, ITERATOR: 1000 };
export const TIMEFRAME = { MIN: 3, MAX: 10000, ITERATOR: 1 };
export const OWN_INCOME = { MIN: 8000, MAX: 1000000, ITERATOR: 1000 };
export const SECOND_BUYER_INCOME = { MIN: 8000, MAX: 1000000, ITERATOR: 1000 };
export const DEPOSIT = { MIN: 0, MAX: 1000000, ITERATOR: 100 };
export const SUPPORT = { MIN: 0, MAX: 1000000, ITERATOR: 100 };
export const MONTHLY_SAVINGS = { MIN: 0, MAX: 20000, ITERATOR: 10 };
export const DEPOSIT_PERCENT = { MIN: 5, MAX: 100, ITERATOR: 1 };
export const BUYING_IN = { MIN: 1, MAX: 120, ITERATOR: 1 };
export const MORTGAGE_LENGTH = { MIN: 12, MAX: 480, ITERATOR: 12 };
export const MORTGAGE_DEPOSIT = { MIN: 5, MAX: 90, ITERATOR: 1 };
export const MORTGAGE_RATE = { MIN: 0.1, MAX: 10, ITERATOR: 0.01 };
export const BIRTHDAY = { DATE_FORMAT: 'DD/MM/YYYY', MIN: '01/01/1900' };
export const UNI_CURRENT_SAVINGS = { MIN: 0, MAX: 100000, ITERATOR: 100 };
export const UNI_MONTHLY_SAVINGS = { MIN: 0, MAX: 100000, ITERATOR: 100 };
export const UNI_WEEKLY_ACCOMMODATION = { MIN: 0, MAX: 100000, ITERATOR: 100 };
export const UNI_DAY_TO_DAY_COST = { MIN: 0, MAX: 100000, ITERATOR: 100 };
export const UNI_TOTAL_SUPPORT = { MIN: 0, MAX: 100000, ITERATOR: 100 };

const nameToWheelConfig = {
  PROPERTY_PRICE,
  TIMEFRAME,
  OWN_INCOME,
  SECOND_BUYER_INCOME,
  DEPOSIT,
  SUPPORT,
  MONTHLY_SAVINGS,
  DEPOSIT_PERCENT,
  BUYING_IN,
  MORTGAGE_LENGTH,
  MORTGAGE_DEPOSIT,
  MORTGAGE_RATE,
  BIRTHDAY,
  UNI_CURRENT_SAVINGS,
  UNI_MONTHLY_SAVINGS,
  UNI_WEEKLY_ACCOMMODATION,
  UNI_DAY_TO_DAY_COST,
  UNI_TOTAL_SUPPORT
};

export const mergeCodeAndConfigValidationSchema = () => {
  const configValidation = ConfigService.get('DEFAULT_VALUES.WHEEL_VALIDATION_SCHEMA', {});

  return ObjectShim.mergeDeep(nameToWheelConfig, configValidation);
};

export const isInputValueOptional = (isOptional, value) => {
  return !value && isOptional;
};
