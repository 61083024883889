import Loadable from 'react-loadable';
import { Loader } from '../components/with-loader';
import { asTemplate } from '../components/layout/with-layout';
import { COINS } from '../helpers/navigation';

const CoinsPage = Loadable({
  loader: () => import(/* webpackChunkName: "coins-page" */ '../pages/coins/index').then(module => module.CoinsPage),
  loading: Loader
});

export const coinsRoutes = [
  {
    to: '/coins',
    component: asTemplate(COINS)(CoinsPage)
  }
];
