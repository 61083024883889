import { parse } from 'query-string';
import { calculateMyPlan } from '../../../helpers/my-plan';
import { initialBorrowingCalculation } from '../../../helpers/mortgage/calculations';
import { sendAdditionalUserAttributes } from '../../reducers/user-attributes/actions';
// import { calculationCall } from '../../../helpers/footprint-calculator/calculation-call';

// TODO: move 'Home-quiz' and 'Travel-quiz' actions here as well (or into similar middleware in aviva-mcrp product),
//  use the key 'footprint-calculate'. moduleName can be used in calculationCall instead of name
//  JIRA ticket - https://life-moments.atlassian.net/browse/AV-7

const triggerList = {
  // 'footprint-calculate': { method: state => calculationCall(state) },
  'borrow-calculate': { method: state => initialBorrowingCalculation(state), attributeType: 'goal' },
  'plan-calculate': { method: ({ userAttributes }) => calculateMyPlan(userAttributes.goal), attributeType: 'goal' }
};
/**
 * Here we are calling some custom action by the trigger name, passed via search params in url
 * to calculate and send some user attributes, returned by custom action (if needed)
 * @param action
 * @param store
 */
export const afterActivityComplete = ({ action, store }) => {
  const { trigger } = parse(window.location.search);
  const state = store.getState();

  const { moduleName } = action.payload;

  if (triggerList[trigger]) {
    const action = triggerList[trigger].method;
    const attributeType = triggerList[trigger].attributeType;

    const params = action({ ...state, moduleName });

    if (attributeType) {
      store.dispatch(sendAdditionalUserAttributes({ [attributeType]: params }));
    }
  }
};
