import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../helpers/constants';
import { createReducer } from '../utils';
import { calculateAge, mapAlternativeAccounts } from '../../helpers/deposit-builder';
import { hasAccount as hasAccountFunc, purifyAccounts } from '../../helpers/deposit-builder/accounts';
import { depositBuilder as depositBuilderApi } from '../../api/deposit-builder';
import { sendAccounts, sendAltAccounts } from './user-attributes/actions';
import { removeStatus } from './status-data';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';

const GET_RESULTS_REQUEST = 'GET_RESULTS_REQUEST';
const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
const GET_RESULTS_FAILURE = 'GET_RESULTS_FAILURE';

const initialState = {
  currentAccount: {},
  loading: true,
  error: false,
  loadingResults: true,
  existing: [],
  alternative: []
};

export const getResults = () => async (dispatch, getState) => {
  dispatch({ type: GET_RESULTS_REQUEST });

  const userAttributes = getState().userAttributes;

  const { accounts, savings, goal } = userAttributes;
  const { propertyPrice, timeframe, goalsLocationParsed } = goal;
  const { dateOfBirth, homeOrRentedOut, startDate } = savings;

  const hasAccount = hasAccountFunc(accounts.items);

  const accountsData = hasAccount
    ? purifyAccounts(Object.values(accounts.items))
    : [{ total: goal.depositSavings, monthly: goal.monthlySaving }];

  const params = {
    accounts: accountsData,
    age: calculateAge(dateOfBirth),
    homeOrRentedOut: homeOrRentedOut,
    propertyPrice,
    timeframe,
    isLondon: goalsLocationParsed ? goalsLocationParsed.isLondon : false,
    notStartedYet: !hasAccount,
    startDate: startDate ? startDate : dayjs().format(DATE_FORMAT),
    dateOfBirth
  };
  dispatch({ type: GET_RESULTS_REQUEST });

  const response = await depositBuilderApi.getResults(params);

  if (!response.ok) {
    dispatch({ type: GET_RESULTS_FAILURE });

    return {};
  }

  const { existing, alternative: pureAlternative } = await response.json();

  const alternative = mapAlternativeAccounts(pureAlternative);

  dispatch({ type: GET_RESULTS_SUCCESS, payload: { existing, alternative } });

  return { existing, alternative };
};

export const restart = () => async dispatch => {
  await dispatch(removeStatus('depositBuilder', 'completed'));
  await dispatch(sendAccounts({ items: {} }));
  await dispatch(sendAltAccounts({ items: {} }));
};

export const depositBuilder = createReducer(initialState, {
  [GET_RESULTS_REQUEST]: state => ({ ...state, loadingResults: true }),
  [GET_RESULTS_SUCCESS]: (state, action) => ({
    ...state,
    existing: action.payload.existing,
    alternative: action.payload.alternative,
    loadingResults: false
  }),
  [GET_RESULTS_FAILURE]: state => ({ ...state, loadingResults: false, error: true }),
  [DELETE_ACCOUNT_SUCCESS]: () => ({ ...initialState })
});
