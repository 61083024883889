export const DEFAULT_CATEGORY_KEY = 'All';

export const TABS = [
  {
    label: DEFAULT_CATEGORY_KEY,
    value: DEFAULT_CATEGORY_KEY
  },
  {
    label: 'Saving',
    value: 'Saving'
  },
  {
    label: 'Hunting',
    value: 'Hunting'
  },
  {
    label: 'Buying',
    value: 'Buying'
  }
];

export const PERSONALISATION_FILTER = 'personalisationFilter';
