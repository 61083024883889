import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import isbot from 'isbot';
import dayjs from 'dayjs';
import { Column } from '../../components/layout/grid';
import styles from '../../styles/templates/status-page-template.module.scss';
import { getUser } from '../../store/reducers/account/get-user-action';
import { FHC_MODULE_STATUSES } from '../../helpers/constants';
import { withLoader } from '../../components/with-loader';
import { Header } from '../../components/layout/page-header';
import { DigitalCoachWidget } from '../../widgets/digital-coach-widget';
import { DigitalCoachWidget as NewDigitalCoachWidget } from '../../widgets/digital-coach-widget-new';
import { Template } from '../../components/template';
import { Spacer } from '../../components/layout/spacers';
import { getUserAttributes } from '../../store/reducers/user-attributes/actions';

const StatusPageComponent = ({ statuses, nextActivity, user, userData }) => {
  return (
    <>
      <Header heading='Status Page' />
      <Template>
        <Spacer />
        <Column>
          <h3>LM External ID: {user.externalCode}</h3>
          <h3>LM Shadow ID: {user.userId}</h3>
          <h3>Introducer Code: {user.introducerCode}</h3>
          <h3>External Client ID: {user.clientCustomerId}</h3>
          <h3>External Scheme ID: {user.clientSchemeId}</h3>
          <h3>Session Device: {user.sessionDevice}</h3>
          <h3>Firebase token: {user.firebaseToken}</h3>
          <h3>userAgent: {window.navigator.userAgent}</h3>
          <h3>Is userAgent bot: {JSON.stringify(isbot(window.navigator.userAgent))}</h3>
        </Column>
        <Spacer />
        <Column>
          <DigitalCoachWidget />
          <b>New widget:</b>
          <NewDigitalCoachWidget />
        </Column>
        <Spacer />
        <Column>
          <h3>Next Activity: {nextActivity}</h3>
          <div className='body'>
            <table className={styles.table}>
              <tbody>
                <tr key='status-heading' className={styles.heading}>
                  <td>Activity</td>
                  <td>Status</td>
                  <td>Date and Time</td>
                </tr>
                {Object.keys(statuses).map(key => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{statuses[key].status.key}</td>
                    <td>{statuses[key].dateTime || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Column>
        <Spacer />
        <Column>
          <div>
            <pre>{JSON.stringify(userData, null, 4)}</pre>
          </div>
        </Column>
        <Spacer />
      </Template>
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.statusData.loading,
  statuses: state.statusData.statuses
});

export const StatusPage = compose(
  connect(mapStateToProps, { getUser, getUserAttributes }),
  withState('nextActivity', 'setNextActivity', null),
  withState('user', 'setUser', {}),
  withState('userData', 'setUserData', {}),
  lifecycle({
    async componentDidMount() {
      const { setNextActivity, statuses, getUser, setUser, setUserData, getUserAttributes } = this.props;
      setUser(await getUser());
      setUserData(await getUserAttributes());

      for (const activityKey of Object.keys(statuses)) {
        const activity = statuses[activityKey];

        if (activity.status.key === FHC_MODULE_STATUSES.SNOOZED) {
          const timeDifference = dayjs().diff(dayjs(activity.dateTime), 'hours');

          if (timeDifference > activity.snoozedPeriod) {
            setNextActivity(activityKey);
            break;
          }
        } else if (
          ![
            FHC_MODULE_STATUSES.NOT_APPLICABLE,
            FHC_MODULE_STATUSES.ALREADY_HAVE_ONE,
            FHC_MODULE_STATUSES.COMPLETED,
            FHC_MODULE_STATUSES.NOT_KEEN
          ].includes(activity.status.key)
        ) {
          setNextActivity(activityKey);
          break;
        }
      }
    }
  }),
  withLoader
)(StatusPageComponent);
