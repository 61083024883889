import { api } from '../../../../../api';

// dataset
const getDataSetItems = async ({ datasetKey, value, limit, order }) =>
  await api.dataset.getDataSet({ datasetKey, value, limit, order });
const mapDataSetItems = ({ data, valueName }) =>
  data.map((item, index) => ({
    id: `${item.key}_${index}`,
    label: item.label ?? item[valueName],
    value: item[valueName]
  }));

// introducer-autocomplete
const getBrokerNamesItems = async ({ datasetKey, value, limit, order }) =>
  await api.introducer.getBrokerNames({ datasetKey, value, limit, order });
const mapBrokerNamesItems = ({ data, valueName }) =>
  data.map((item, index) => ({
    id: `${item.key}_${index}`,
    label: item[valueName],
    value: item
  }));

const inputMethods = {
  dataset: {
    get: getDataSetItems,
    map: mapDataSetItems
  },
  introducerAutocomplete: {
    get: getBrokerNamesItems,
    map: mapBrokerNamesItems
  }
};

export { inputMethods };
