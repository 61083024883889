import { parse } from 'query-string';

/**
 * This function extracts the introducer data from the URL to be used in the shadow user creation and updating existing users' introducer on app launch
 * It supports the prev version of the URL (with 'utm_source=affiliate') and the new version (with 'introducercode')
 * If 'introducerupdate' is true, it will update the introducer of the existing user
 *
 * @return {{userData: {introducer: string} | {}, updateIntroducer: boolean | undefined}}
 */
export const extractIntroducerDataFromUrl = () => {
  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    introducercode,
    introducerupdate
  } = parse(window.location.search);

  if (utmSource && utmSource === 'affiliate') {
    return { userData: { introducer: utmCampaign } };
  }

  if (introducercode) {
    return { userData: { introducer: introducercode }, updateIntroducer: introducerupdate === 'true' };
  }

  return { userData: {} };
};
