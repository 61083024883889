import { mapChecklist } from '../../helpers/checklist';
import { createReducer, createAction } from '../utils';

const GET_CHECKLIST_REQUEST = 'GET_CHECKLIST_REQUEST';
const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';
const GET_CHECKLIST_FAILURE = 'GET_CHECKLIST_FAILURE';

export const getChecklist = type =>
  createAction(
    {
      request: GET_CHECKLIST_REQUEST,
      success: GET_CHECKLIST_SUCCESS,
      failure: GET_CHECKLIST_FAILURE
    },
    api => api.configuration.getChecklist
  )(type);

const initialState = {
  items: [],
  list: {},
  loading: true,
  error: false
};

export const checklist = createReducer(initialState, {
  [GET_CHECKLIST_REQUEST]: state => ({ ...state, loading: true }),
  [GET_CHECKLIST_SUCCESS]: (state, action) => {
    const items = mapChecklist(action.payload);

    return {
      ...state,
      items,
      error: false,
      loading: false
    };
  },
  [GET_CHECKLIST_FAILURE]: state => ({ ...state, loading: false, error: true })
});
