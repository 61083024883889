import classnames from 'classnames';
import React from 'react';
import { compose, withProps } from 'recompose';
import styles from '../../styles/components/progress-indicator.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { INDICATOR_ICONS, INDICATOR_TYPES } from '../../helpers/progress-indicator/constants';

export const Component = ({ state, icon, pager }) => {
  return (
    <div className={classnames(styles.step, styles[state])}>
      {pager && <span>{pager}</span>}
      {icon && <AffiliateIcon name={icon} className={styles.icon} />}
    </div>
  );
};

export const ProgressIndicatorStep = compose(
  withProps(({ type, state, currentStep }) => {
    const stepType = INDICATOR_TYPES[type] ?? INDICATOR_TYPES.empty;
    const isNumeric = stepType === INDICATOR_TYPES.numeric;
    const isIconic = stepType === INDICATOR_TYPES.icon;

    const icon = INDICATOR_ICONS[state];

    return {
      type: stepType,
      ...(isNumeric && { pager: currentStep }),
      ...(isIconic && { icon })
    };
  })
)(Component);
