import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/components/activity-progress.module.scss';
import { Column, Grid } from '../layout/grid';
import { ProgressIndicator } from '../progress-indicator';
import { Message } from './components/message';

const Component = ({ quantity }) => (
  <Grid className={styles.container}>
    <Column className={styles.wrapper}>
      <Message className={styles.text} quantity={quantity} />
      <ProgressIndicator pageNumber={quantity.completed} pagesTotal={quantity.total} className={styles.progress} />
    </Column>
  </Grid>
);

Component.propTypes = {
  quantity: PropTypes.shape({
    total: PropTypes.number,
    completed: PropTypes.number
  }).isRequired
};

Component.displayName = 'ActivityProgress';

export { Component };
