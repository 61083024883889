import React, { Component } from 'react';
import { observe } from '@packages/helpers/core/shims/intersection-observer-shim';

/**
 * Track the visibility of the wrapped sections
 *
 *
 * @export
 * @class IntersectionVisible
 * @extends {Component}
 */
export default class IntersectionVisible extends Component {
  static defaultProps = {
    active: true,
    className: 'intersection-visible-wrapper',
    onHide: () => null,
    onShow: () => null,
    onIntersect: () => null
  };

  /**
   * Handles the visibility changes
   *
   * @param {array} entries
   */
  handleObserverUpdate = entries => {
    const { onIntersect, onShow, onHide, once } = this.props;
    const { intersectionRect } = entries[0];
    const { top, left, bottom, right } = intersectionRect;

    if ([top, bottom, left, right].some(Boolean) && onShow) {
      onShow(entries);

      if (once) {
        this.stopObserving();
      }
    } else if (onHide) {
      onHide(entries);
    }

    onIntersect(entries);
  };

  /**
   * Starts the observer
   */
  startObserving() {
    this.observer.observe(this.node);
  }

  /**
   * Stops the observer
   */
  stopObserving() {
    this.observer.unobserve(this.node);
  }

  /**
   * Start the observer when the component is mounted
   */
  componentDidMount() {
    const { options } = this.props;
    this.observer = observe(this.handleObserverUpdate, options);

    if (this.props.active) {
      this.startObserving();
    }
  }

  /**
   * Update observer state on prop changes
   */
  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.startObserving();
    }

    if (!this.props.active && prevProps.active) {
      this.stopObserving();
    }
  }

  /**
   * Stop the observer on unmounting
   */
  componentWillUnmount() {
    this.observer.disconnect();
  }

  /**
   * Render component
   *
   * @returns {JSX.Element}
   */
  render() {
    const { className } = this.props;

    return (
      <div className={className} ref={node => (this.node = node)}>
        {this.props.children}
      </div>
    );
  }
}
