import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/components/knowledge-base-topic.module.scss';
import { AffiliateIcon } from '../../../components/icons/affiliate-icon';

const TopicHeader = React.memo(({ topicIcons, category, subHeading, slug }) => (
  <div className={styles.header}>
    {topicIcons.show && (
      <AffiliateIcon
        name={`articles-section-${slug}`}
        fallback={{ external: topicIcons.defaultIcon }}
        className={styles.icon}
      />
    )}
    <div className={styles.label}>
      <h2 className={styles.heading}>{category}</h2>
      {subHeading && <p className={styles.subheading}>{subHeading}</p>}
    </div>
  </div>
));

TopicHeader.propTypes = {
  category: PropTypes.string.isRequired,
  slug: PropTypes.string,
  subHeading: PropTypes.string,
  topicIcons: PropTypes.shape({
    show: PropTypes.bool,
    defaultIcon: PropTypes.string
  })
};

TopicHeader.displayName = 'TopicHeader';

export { TopicHeader };
