export const SECTION_CHECKPOINT_QUESTION_SLUG = 'section-checkpoint';

export const FULL_FLOW_TYPE = 'full';
export const SECTION_FLOW_TYPE = 'section';

export const LOCAL_STORAGE_FLOW_TYPE = 'questionFlowType';
export const STATIC_DATA_CHECKPOINT_IDENTIFIER = 'sectionsCheckpoints';

export const flowTypes = {
  full: FULL_FLOW_TYPE,
  section: SECTION_FLOW_TYPE
};
