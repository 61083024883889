import { articlesRoutes } from '@apps/firsthomecoach/src/routes/articles';
import { homePageRoutes } from '@apps/firsthomecoach/src/routes/home-pages';
import { accountsRoutes } from '@apps/firsthomecoach/src/routes/account';
import { tourPageRoutes } from '@apps/firsthomecoach/src/routes/tour-page';
import { legalPageRoutes } from '@apps/firsthomecoach/src/routes/legal-page';
import { statusPageRoutes } from '@apps/firsthomecoach/src/routes/status-page';

import { questions } from './questions';
import { dashboardRoutes } from './dashboard';
import { customPageRoutes } from './custom-page';

export const routes = [
  ...dashboardRoutes,
  ...customPageRoutes,
  ...articlesRoutes,
  ...homePageRoutes,
  ...accountsRoutes,
  ...tourPageRoutes,
  ...questions,
  ...legalPageRoutes,
  ...statusPageRoutes
];
