import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from '../../styles/components/card-slider.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { useSubscribe } from '../slots';
import { SampleArrow } from './components/arrows';
import { customDots } from './components/dots';

export const defaultSliderSettings = {
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: false,
  draggable: true,
  swipe: true,
  swipeToSlide: true,
  variableWidth: false,
  dotsClass: `slick-dots ${styles.dots}`,
  dots: true
};

const CardsSlider = withMemoAndRef(({ sliderConfiguration, className, beforeChange, name, children }, ref) => {
  const prevButtonName = `prev-slider-button-${name}`;
  const nextButtonName = `next-slider-button-${name}`;

  const prevSlot = useSubscribe(prevButtonName);
  const nextSlot = useSubscribe(nextButtonName);

  const arrowConfiguration = {
    prevArrow: <SampleArrow slotName={prevButtonName} name='prev-slide' />,
    nextArrow: <SampleArrow slotName={nextButtonName} name='next-slide' />
  };

  const mergedSliderConfig = {
    ...defaultSliderSettings,
    ...sliderConfiguration,
    ...(sliderConfiguration.arrows && { ...arrowConfiguration })
  };

  mergedSliderConfig.appendDots = customDots({ prevSlot, nextSlot });

  const inlineStyles = {
    '--custom-slides-to-show': mergedSliderConfig.slidesToShow
  };

  return (
    <Slider
      style={inlineStyles}
      className={classnames(styles.carousel, className)}
      beforeChange={beforeChange}
      ref={ref}
      {...mergedSliderConfig}
    >
      {children}
    </Slider>
  );
});

CardsSlider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  sliderConfiguration: PropTypes.object, //Configurations Docs https://react-slick.neostack.com/docs/api
  className: PropTypes.string,
  beforeChange: PropTypes.func
};

export { CardsSlider };
