import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { parse } from 'query-string';
import debounce from 'lodash.debounce';
import { isUndefined } from '@packages/helpers/core/common';
import { ConfigService } from '../../services/config-service';

const EmbeddedContext = createContext({ isEmbedded: false });

EmbeddedContext.displayName = 'EmbeddedContext';

export const useEmbeddedContext = () => {
  const { isEmbedded, postResizeMessage } = useContext(EmbeddedContext);

  return { isEmbedded, postResizeMessage };
};

export const withEmbeddedContext = Component => props => {
  const { isEmbedded, postResizeMessage } = useEmbeddedContext();

  return <Component {...props} isEmbedded={isEmbedded} postResizeMessage={postResizeMessage} />;
};

export const EmbeddedProvider = React.memo(({ children }) => {
  const { embedded } = parse(window.location.search);
  const isEmbedded = useMemo(() => (!isUndefined(embedded) ? embedded.toString() === 'true' : false), [embedded]);

  const { current: root } = useRef(document.documentElement);

  const postResizeMessage = useCallback(() => {
    const height = document.querySelector('body').scrollHeight;

    const targetOrigin = ConfigService.get('STANDALONE.targetOrigin', '*');

    window.parent.postMessage(['setHeight', height], targetOrigin);
  }, []);

  const debouncePost = debounce(postResizeMessage, 150);

  if (isEmbedded) {
    //unset root container stretching horizontally to window height - must fit the content
    root.style.setProperty('--app-custom-root-flex', 0);
  }

  const value = useMemo(() => ({ isEmbedded, postResizeMessage: debouncePost }), [isEmbedded, debouncePost]);

  return <EmbeddedContext.Provider value={value}>{children}</EmbeddedContext.Provider>;
});
