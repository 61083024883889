import uuid from 'uuid';
import { GEO_LOCATION_ENGLAND_REGIONS } from '../constants';

export const MORTGAGE_TYPES = [
  {
    label: 'Standard mortgage',
    value: 'Standard mortgage'
  },
  {
    label: 'Help to Buy Equity Loan',
    value: 'Help to Buy Equity Loan'
  }
];

export const NUMBER_OF_BUYERS = [
  {
    label: 'On my own',
    value: 'On my own'
  },
  {
    label: 'With someone',
    value: 'With someone'
  }
];

//consider moving this list to config file or admin console
export const htbList = {
  [GEO_LOCATION_ENGLAND_REGIONS.LONDON]: {
    percent: 40,
    cap: 650000
  },
  [GEO_LOCATION_ENGLAND_REGIONS.NORTH_EAST_ENGLAND]: {
    percent: 20,
    cap: 186100
  },
  [GEO_LOCATION_ENGLAND_REGIONS.NORTH_WEST_ENGLAND]: {
    percent: 20,
    cap: 224400
  },
  [GEO_LOCATION_ENGLAND_REGIONS.SOUTH_EAST_ENGLAND]: {
    percent: 20,
    cap: 437600
  },
  [GEO_LOCATION_ENGLAND_REGIONS.SOUTH_WEST_ENGLAND]: {
    percent: 20,
    cap: 349000
  },
  [GEO_LOCATION_ENGLAND_REGIONS.YORKSHIRE]: {
    percent: 20,
    cap: 228100
  },
  [GEO_LOCATION_ENGLAND_REGIONS.EAST_MIDLANDS]: {
    percent: 20,
    cap: 261900
  },
  [GEO_LOCATION_ENGLAND_REGIONS.WEST_MIDLANDS]: {
    percent: 20,
    cap: 255600
  },
  [GEO_LOCATION_ENGLAND_REGIONS.EAST_ENGLAND]: {
    percent: 20,
    cap: 407400
  },
  [GEO_LOCATION_ENGLAND_REGIONS.WALES]: {
    percent: 20,
    cap: 250000
  }
};

export const LOCATIONS = Object.keys(htbList).map(location => ({
  id: uuid('location'),
  label: location,
  value: location
}));

export const REPAYMENT_CALCULATION_TYPES = {
  default: 'default',
  lbs: 'lbs'
};
