import { createReducer } from '@apps/firsthomecoach/src/store/utils';

export const SET_WIDGET_STATE_INITIAL = 'SET_WIDGET_STATE_INITIAL';
export const SET_WIDGET_STATE_STATIC_SUCCESS = 'SET_WIDGET_STATE_STATIC_SUCCESS';
export const SET_WIDGET_STATE_ASYNC_SUCCESS = 'SET_WIDGET_STATE_ASYNC_SUCCESS';
export const SET_WIDGET_STATE_POSTPONED = 'SET_WIDGET_STATE_POSTPONED';
export const SET_PAGE_STATE_INITIAL = 'SET_PAGE_STATE_INITIAL';
export const SET_PAGE_STATE_SUCCESS = 'SET_PAGE_STATE_SUCCESS';
export const SET_NATIVE_TRIGGER_LOADING_STATE = 'SET_NATIVE_TRIGGER_LOADING_STATE';

export const SET_PAGE_HEADER_CONFIG = 'SET_PAGE_HEADER_CONFIG';

export const setNativeLoadingState = (type, payload) => ({ type, payload });

export const setPageHeaderConfig = name => ({ type: SET_PAGE_HEADER_CONFIG, payload: name });

const initialState = {
  loadingState: {
    pages: {
      dashboard: {
        initialized: false,
        widgets: {}
      }
    }
  },
  pageHeader: {
    currentPageConfigName: ''
  },
  isNativeTriggerSent: false
};

const handleSetWidgetStateCase = (state, action) => {
  const { pageIdentifier, widgetIdentifier, loadingState: loaded, order } = action.payload;

  const combinedWidgets = {
    ...state.loadingState.pages[pageIdentifier]?.widgets,
    [widgetIdentifier]: {
      ...state.loadingState.pages[pageIdentifier]?.widgets[widgetIdentifier],
      order,
      loaded
    }
  };

  return {
    ...state,
    loadingState: {
      ...state.loadingState,
      pages: {
        ...state.loadingState.pages,
        [pageIdentifier]: {
          widgets: { ...combinedWidgets },
          initialized: Object.values(combinedWidgets).every(w => !!w.loaded)
        }
      }
    }
  };
};

const handleSetPageLoadingState = (state, action, initialized) => {
  const { pageIdentifier } = action.payload;

  return {
    ...state,
    loadingState: {
      ...state.loadingState,
      pages: {
        ...state.loadingState.pages,
        [pageIdentifier]: {
          initialized
        }
      }
    }
  };
};

export const application = createReducer(initialState, {
  [SET_WIDGET_STATE_INITIAL]: (state, action) => handleSetWidgetStateCase(state, action),
  [SET_WIDGET_STATE_STATIC_SUCCESS]: (state, action) => handleSetWidgetStateCase(state, action),
  [SET_WIDGET_STATE_ASYNC_SUCCESS]: (state, action) => handleSetWidgetStateCase(state, action),
  [SET_WIDGET_STATE_POSTPONED]: (state, action) => handleSetWidgetStateCase(state, action),
  [SET_PAGE_STATE_INITIAL]: (state, action) => handleSetPageLoadingState(state, action, false),
  [SET_PAGE_STATE_SUCCESS]: (state, action) => handleSetPageLoadingState(state, action, true),
  [SET_NATIVE_TRIGGER_LOADING_STATE]: (state, action) => ({ ...state, isNativeTriggerSent: action.payload }),
  [SET_PAGE_HEADER_CONFIG]: (state, action) => ({
    ...state,
    pageHeader: { ...state.pageHeader, ...action.payload }
  })
});
