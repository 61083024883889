import React, { createContext, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getScrollYPosition, scrollTo } from '../../helpers/layout/scroll';

export const ScrollPositionContext = createContext({ scrollInfo: {} });

const { Provider } = ScrollPositionContext;

// Defining to `manual` to fix the issue with scroll to top on back and forward navigation

// eslint-disable-next-line no-restricted-globals
history.scrollRestoration = 'manual';

const ScrollPositionController = React.memo(({ children }) => {
  const [state, setState] = useState({ scrollInfo: {} });

  const history = useHistory();

  const { key } = useLocation();

  const restoreScrollPosition = useCallback(() => {
    const pathnameScrollYPosition = state.scrollInfo[key];
    const { action } = history;

    if (pathnameScrollYPosition && action === 'POP') {
      setTimeout(() => scrollTo(window, { top: pathnameScrollYPosition, left: 0, behavior: 'smooth' }), 300);
    }
  }, [key, history, state]);

  const rememberScrollPosition = useCallback(() => {
    setState(state => ({ scrollInfo: { ...state.scrollInfo, [key]: getScrollYPosition() } }));
  }, [key]);

  return <Provider value={{ rememberScrollPosition, restoreScrollPosition }}>{children}</Provider>;
});

ScrollPositionController.displayName = 'ScrollPositionController';

export { ScrollPositionController };
