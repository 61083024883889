export const calculateMaxTotal = items => {
  // eslint-disable-next-line array-callback-return
  const count = Object.values(items).reduce((acc, current) => {
    if (current === 'high') {
      acc++;
    }

    return acc;
  }, 0);

  return count * 3;
};
