import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import {
  getPropertyMortgages,
  sumByKey,
  CURRENT_MORTGAGE_OUTSTANDING_KEY,
  CURRENT_MONTHLY_MORTGAGE_KEY
} from './helpers';

const MONTHLY_RENTAL_KEY = 'monthlyRentalIncome';
const GROSS_YIELD = 'grossYield';
const PROPERTY_LOAN = 'propertyLoan';
const EQUITY_KEY = 'equity';

export const generatePropsPerProperty = (properties, mortgages) => {
  let items = {};

  Object.keys(properties).forEach(propertyKey => {
    const propertyMortgages = getPropertyMortgages(mortgages, propertyKey);

    const property = properties[propertyKey];

    const monthlyRentalIncome = calculateMonthlyRentalIncome(property, propertyMortgages);
    const grossYield = calculateGrossYield(property);
    const propertyLoan = calculatePropertyLoan(property, propertyMortgages);
    const isMortgagesAdded = propertyMortgages && Object.keys(propertyMortgages).length > 0;
    const isMortgagesDynamicReady = getMortgageState(propertyMortgages);
    const equity = calculateEquity(property, propertyMortgages);
    const isResetNeeded = getResetNeeded(propertyMortgages);

    items = {
      ...items,
      [propertyKey]: {
        ...property,
        [MONTHLY_RENTAL_KEY]: monthlyRentalIncome,
        [GROSS_YIELD]: grossYield,
        [PROPERTY_LOAN]: propertyLoan,
        [EQUITY_KEY]: equity,
        isMortgagesAdded,
        isMortgagesDynamicReady,
        ...(!isMortgagesDynamicReady && { isCalculationsTriggered: false, isSyncTriggered: false }),
        ...(isResetNeeded && {
          isCalculationsTriggered: false,
          isMortgagesReady: false,
          isMortgagesEarlyReview: false,
          isMortgagesExpiry: false,
          isEquityAvailable: false,
          alternativeMortgageDeal: false,
          mortgageDeals: []
        })
      }
    };
  });

  return { items };
};

const PERCENT = 0.12;

const calculateMonthlyRentalIncome = (property, mortgages) => {
  try {
    const { rentalIncome } = property;

    const monthlyMortgagePayment = sumByKey(mortgages, CURRENT_MONTHLY_MORTGAGE_KEY);

    const result = Number(rentalIncome) - Number(rentalIncome) * PERCENT - monthlyMortgagePayment;

    return Math.round(result);
  } catch {
    return 'Not enough data';
  }
};

const calculateGrossYield = property => {
  try {
    const { price, rentalIncome } = property;

    const result = ((Number(rentalIncome) * 12) / Number(price)) * 100;

    return result;
  } catch {
    return 'Not enough data';
  }
};

const calculatePropertyLoan = (property, mortgages) => {
  try {
    const { value } = property;

    const outStandingMortgages = sumByKey(mortgages, CURRENT_MORTGAGE_OUTSTANDING_KEY);

    const result = (outStandingMortgages / Number(value)) * 100;

    return result;
  } catch {
    return 'Not enough data';
  }
};

const calculateEquity = (property, mortgages) => {
  try {
    const { value } = property;

    const outStandingMortgages = sumByKey(mortgages, CURRENT_MORTGAGE_OUTSTANDING_KEY);

    const result = value - outStandingMortgages;

    return result;
  } catch {
    return 'Not enough data';
  }
};

const getMortgageState = mortgages => {
  try {
    const isReady = !ObjectShim.isEmpty(mortgages) && Object.values(mortgages).every(item => item.isReady);

    return isReady;
  } catch {
    return 'Not enough data';
  }
};

const getResetNeeded = mortgages => {
  try {
    const isResetNeeded = !ObjectShim.isEmpty(mortgages) && Object.values(mortgages).some(item => item.isResetNeeded);

    return isResetNeeded;
  } catch {
    return 'Not enough data';
  }
};
