const offset = {
  name: 'offset',
  enabled: true,
  options: {
    offset: () => {
      return [0, 4];
    }
  }
};

export const popper = {
  placement: 'bottom',
  modifiers: [offset]
};
