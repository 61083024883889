import { branch } from 'recompose';
import { ConfigService } from '../../services/config-service';
import { withDynamicChecklist } from './with-dynamic-checklist';
import { withStaticChecklist } from './with-static-checklist';

export const withChecklist = branch(
  () => ConfigService.get('CHECKLIST.dynamic', false),
  withDynamicChecklist,
  withStaticChecklist
);
