import { isNil } from '@packages/helpers/core/common';

export const CHECKLIST_SERVICE_KEYS = {
  CHECKLIST: 'checklist'
};

export class ChecklistService {
  static getChecklist() {
    const checklist = localStorage.getItem(CHECKLIST_SERVICE_KEYS.CHECKLIST);

    if (checklist) {
      return JSON.parse(checklist);
    } else {
      const checklist = {};

      localStorage.setItem(CHECKLIST_SERVICE_KEYS.CHECKLIST, JSON.stringify(checklist));

      return checklist;
    }
  }

  static deleteChecklist() {
    const checklist = localStorage.getItem(CHECKLIST_SERVICE_KEYS.CHECKLIST);

    if (checklist) {
      localStorage.removeItem(CHECKLIST_SERVICE_KEYS.CHECKLIST);
    }

    return Boolean(checklist);
  }

  static setCategoryState({ heading, expanded }) {
    const checklist = {
      ...this.getChecklist(),
      [heading]: expanded
    };

    localStorage.setItem(CHECKLIST_SERVICE_KEYS.CHECKLIST, JSON.stringify(checklist));

    return expanded;
  }

  static getCategoryState({ heading, expanded }) {
    const checklist = this.getChecklist();

    const category = checklist[heading];

    if (isNil(category)) {
      return this.setCategoryState({ heading, expanded });
    }

    return category;
  }

  static toggleCategoryState({ heading }) {
    const checklist = this.getChecklist();

    const state = checklist[heading];

    return this.setCategoryState({ heading, expanded: !state });
  }
}
