import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { removeContentStatus, sendContentStatus } from '../../store/reducers/content-interaction';
import { addSavedRepeaterItem, removeSavedRepeaterItem } from '../../store/reducers/repeaters';
import { DEFAULT_REPEATER_FOR_SAVING } from '../../widgets/card/constants';
import { getAdditionalDataByContentType } from '../content-interaction';
import { TYPE_TO_ACTIVITY_KEY } from '../content-interaction/constants';
import { buildQueryParams } from './index';

export const SAVED_STATUS = 'saved';

export const useFavorite = ({ ownKey, slug, type, targetRepeaterKey, sourceRepeaterKey, id, title }) => {
  const dispatch = useDispatch();

  const contentStatuses = useSelector(state => state.contentInteraction.contentStatuses, shallowEqual);
  const isSavedInStore = ObjectShim.has(contentStatuses[ownKey], SAVED_STATUS);

  // Requested by PO. The purpose is to have quicker UX.
  const [isSaved, setIsSaved] = useState(isSavedInStore);
  useEffect(() => {
    setIsSaved(isSavedInStore);
  }, [isSavedInStore]);

  const iconName = isSaved ? 'star-filled' : 'star';

  const handleAction = useCallback(
    event => {
      event.stopPropagation();
      setIsSaved(prev => !prev);

      const data = getAdditionalDataByContentType({
        contentType: TYPE_TO_ACTIVITY_KEY[type],
        key: ownKey,
        slug,
        id,
        title
      });

      const options = {
        activityKey: TYPE_TO_ACTIVITY_KEY[type],
        activityStatusKey: 'saved',
        data
      };

      const postAddFavorite = targetRepeaterKey => {
        if (DEFAULT_REPEATER_FOR_SAVING) {
          dispatch(addSavedRepeaterItem({ filterKey: ownKey, targetRepeaterKey, sourceRepeaterKey }));
        }

        return void 0;
      };

      const postRemoveFavorite = targetRepeaterKey => {
        switch (targetRepeaterKey) {
          case DEFAULT_REPEATER_FOR_SAVING:
            dispatch(removeSavedRepeaterItem({ filterKey: ownKey, targetRepeaterKey }));
            break;
          default:
            return;
        }
      };

      const handleAddFavorite = options => {
        dispatch(sendContentStatus(options)).then(() => postAddFavorite(targetRepeaterKey));
      };

      const handleRemoveFavorite = options => {
        dispatch(removeContentStatus(options)).then(() => postRemoveFavorite(targetRepeaterKey));
      };

      return isSaved
        ? handleRemoveFavorite({ queryParams: buildQueryParams(options), ownKey, contentActivityStatus: SAVED_STATUS })
        : handleAddFavorite(options);
    },
    [dispatch, isSaved, ownKey, slug, sourceRepeaterKey, targetRepeaterKey, type, id, title]
  );

  return { iconName, handleAction };
};
