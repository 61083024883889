import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { ConfigService } from '../../../services/config-service';
import styles from '../../../styles/templates/story-template.module.scss';
import { StoryStep } from '../../../pages/story-step';
import { StoryCardsSlider } from '../../../components/cards-slider/story-cards-slider';

const StorySlider = React.memo(({ sortedSteps, beforeChange, reportStatus }) => {
  const sliderRef = useRef(null);

  const sliderConfiguration = ConfigService.get('STORIES.sliderConfiguration', {});

  const onStoryClick = useCallback(() => {
    sliderRef?.current?.slickNext();
  }, []);

  return (
    <React.Fragment key={uuid()}>
      <StoryCardsSlider
        sliderConfiguration={sliderConfiguration}
        className={styles.slider}
        beforeChange={beforeChange}
        name='story'
        ref={sliderRef}
      >
        {sortedSteps.map(({ id, ...step }, i, sortedSteps) => (
          <StoryStep
            id={i}
            key={id}
            onStoryClick={onStoryClick}
            beforeChange={beforeChange}
            isLastSlide={i + 1 === sortedSteps.length}
            reportStatus={reportStatus}
            {...step}
          />
        ))}
      </StoryCardsSlider>
    </React.Fragment>
  );
});

StorySlider.propTypes = {
  sortedSteps: PropTypes.array,
  beforeChange: PropTypes.func.isRequired,
  progressBarHeight: PropTypes.number,
  reportStatus: PropTypes.func
};

StorySlider.displayName = 'StorySlider';

export { StorySlider };
