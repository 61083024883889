import { LocalStorageService } from '@packages/helpers/core/services/local-storage';

export class TrackingService {
  static store = new LocalStorageService('Trackers');

  static getTimestamp() {
    return Date.now();
  }

  static setTrackerTimestamp(tracker) {
    return TrackingService.store.set(store => ({
      ...store,
      [tracker]: TrackingService.getTimestamp()
    }));
  }

  static getTrackerTimestamp(tracker) {
    return TrackingService.store.get()[tracker];
  }
}
