import { lifecycle } from 'recompose';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';

export const withEmbeddedContent = lifecycle({
  async componentDidMount() {
    const { sendApplicationData, turnOffLoading } = this.props;

    const promises = [sendApplicationData({ isEmbedded: true })];

    await PromiseShim.allSettled(promises);
    turnOffLoading();

    // eslint-disable-next-line no-console
    console.log('[HBC] All required data is loaded', new Date());
  }
});
