import React from 'react';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { PurifiedHtmlContainer } from '../../components/layout/purified-html-container';
import iconStyles from '../../styles/components/affiliate-icon.module.scss';
import { getIconUrlByName } from './index';

export const getAffiliateIconSync = FunctionShim.memoize(name => {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', getIconUrlByName(name), false);
  xhr.send();

  if (xhr.status !== 200) {
    return null;
  }

  return <PurifiedHtmlContainer className={iconStyles.container} parse={false} html={xhr.responseText} />;
});
