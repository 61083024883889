import React from 'react';
import classnames from 'classnames';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import styles from '../../../../../styles/widgets/feature-fit-v2/ff-kpi-indicator.module.scss';
import { AffiliateIcon } from '../../../../../components/icons/affiliate-icon';

const ITEMS = ArrayShim.range(1, 4);

export const KPIIcon = React.memo(({ active }) => (
  <AffiliateIcon
    className={classnames(styles.icon, { [styles.active]: active })}
    name={`vm-ff-kpi-indicator-${active ? 'on' : 'off'}`}
  />
));

export const KPIIndicator = React.memo(({ value, className }) => (
  <ul className={classnames(styles.container, className)}>
    {ITEMS.map(index => (
      <li key={index}>
        <KPIIcon active={index <= value} />
      </li>
    ))}
  </ul>
));

KPIIndicator.displayName = 'KPIIndicator';
