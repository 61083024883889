import React from 'react';
import { compose } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { InformerComponent } from '../../../components/informer';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import { getAffiliateIconSync } from '../../layout/get-afiliate-icon';
import { getElementFromHtmlString } from './helpers';

export const changeExplainer = element => {
  const explainers = element.querySelectorAll('.coach-explainer');

  if (explainers.length) {
    explainers.forEach(explainer => {
      const [, icon] = explainer.classList;

      const jsx = (
        <InformerComponent icon={getAffiliateIconSync(icon)}>
          <PurifiedHtmlContainer renderAs='span' html={explainer.innerHTML} parse={false} />
        </InformerComponent>
      );

      const component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      explainer.parentNode.replaceChild(component, explainer);
    });
  }

  return element;
};
