import PropTypes from 'prop-types';
import { branch, compose, lifecycle, renderNothing, withPropsOnChange, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import { evaluateTextContentByScenarios } from '../../helpers/workers/evaluate-text-content-by-scenarios';
import { withUserStateData } from '../../helpers/hocs/with-user-state-data';
import { Informer } from '../informer';
import { INFORMER_TYPES } from '../../helpers/components/informer';

/**
 * @typedef {Object} ScenarioData
 * @property {string} icon - icon
 * @property {string} title - title
 * @property {string} body - a html-like string
 * @property {string} buttonLabel
 * @property {string} buttonAriaLabel
 */

const InputInformer = compose(
  withState('data', 'setInformerData', {}),
  withUserStateData,
  withCancellableHandlers({
    getAppropriateInformerScenario:
      ({ userState, scenarios }) =>
      async () =>
        /**
         * @return {Promise<ScenarioData>}
         */
        await evaluateTextContentByScenarios({
          userState,
          scenarios: scenarios,
          fieldNames: ['body'],
          restFields: ['icon', 'title', 'buttonLabel', 'buttonAriaLabel']
        })
  }),
  lifecycle({
    async componentDidMount() {
      const { getAppropriateInformerScenario, scenarios, setInformerData } = this.props;

      if (scenarios) {
        const informerScenario = await getAppropriateInformerScenario();
        setInformerData(informerScenario);
      }
    }
  }),
  branch(({ data }) => ObjectShim.isEmpty(data), renderNothing),
  withPropsOnChange(['data'], ({ data }) => ({
    ...data
  }))
)(Informer);

InputInformer.propTypes = {
  type: PropTypes.oneOf(Object.values(INFORMER_TYPES)),
  scenarios: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      body: PropTypes.string.isRequired,
      icon: PropTypes.string,
      title: PropTypes.string,
      buttonLabel: PropTypes.string,
      buttonAriaLabel: PropTypes.string,
      buttonType: PropTypes.string
    })
  }).isRequired
};

InputInformer.displayName = 'InputInformer';

export { InputInformer };
