import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { parse } from 'query-string';
import { createCustomPageProps } from '../../helpers/utils';
import { getLocationFromModeState } from '../../helpers/navigation/routing';
import { goToComplete } from '../../helpers/custom-page/go-to-complete';
import { FHC_MODULE_STATUSES } from '../../helpers/constants';
import { RESULT } from '../../helpers/navigation';
import { FULL_FLOW_TYPE } from '../../helpers/chekpoint-page/constants';
import { sendQuestionSectionInfo } from '../../store/reducers/user-attributes/actions';
import { callActionBeforeResultPage } from '../../helpers/custom-page/call-action';
import { sendStatus } from '../../store/reducers/status-data';
import { getCustomPage } from '../../store/reducers/content';
import { withLoadingHandlers } from '../../components/with-loader';
import { Checkpoint } from '../checkpoint';
import { withLastQuestion } from '../question/hocs/with-last-question';
import { ConfigService } from '../../services/config-service';

const CustomPageCompleteComponent = ({ to, loading, calculatingMsg }) => (
  <>
    <Helmet title='Complete' />
    <Checkpoint to={to} loading={loading} calculatingMsg={calculatingMsg} />
  </>
);

const mapStateToProps = ({ userAttributes: { questionSection } }) => ({ questionSection });

export const CustomPageComplete = compose(
  connect(mapStateToProps, { getCustomPage, sendStatus, sendQuestionSectionInfo }),
  withLastQuestion,
  withProps(
    ({
      location: { pathname, state, search },
      match: {
        params: { redirectTo = RESULT, module }
      }
    }) => {
      const toOrigin = getLocationFromModeState(state);
      const calculatingMsg = ConfigService.get('CHECKPOINT.calculatingMessages.customPage', 'Updating your results');

      return {
        calculatingMsg,
        module,
        toResult: !toOrigin && redirectTo === RESULT,
        to: goToComplete(pathname, redirectTo, search) ?? toOrigin ?? '/'
      };
    }
  ),
  withLoadingHandlers(({ toResult }) => toResult),
  withHandlers({
    sendActivityStatus:
      ({ location, sendStatus }) =>
      cb => {
        const { pathname, search } = location;
        const { name } = createCustomPageProps(pathname);
        const { activityName = name, activityStatus = FHC_MODULE_STATUSES.COMPLETED } = parse(search);

        sendStatus(activityName, activityStatus, cb);
      }
  }),
  withHandlers({
    getPageByTrigger:
      ({ location, sendActivityStatus, getCustomPage, turnOffLoading }) =>
      trigger => {
        const { pathname } = location;
        const { name } = createCustomPageProps(pathname);

        const sendStatusCallback = async () => {
          await callActionBeforeResultPage(name);
          getCustomPage({ name, trigger, full: `${name}-${trigger}`, from: pathname });
          turnOffLoading();
        };

        sendActivityStatus(sendStatusCallback);
      }
  }),
  lifecycle({
    componentDidMount() {
      const {
        toResult,
        getPageByTrigger,
        unsetLastQuestion,
        module,
        sendActivityStatus,
        questionSection,
        sendQuestionSectionInfo
      } = this.props;

      if (toResult) {
        unsetLastQuestion(module);
        getPageByTrigger(RESULT);
      } else {
        sendActivityStatus();
      }

      if (questionSection?.isSectionEdition) {
        sendQuestionSectionInfo({ ...questionSection, isSectionEdition: false, flowType: FULL_FLOW_TYPE });
      }
    }
  })
)(CustomPageCompleteComponent);
