import { useCallback, useLayoutEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { checkOptionEntryVisibility, padExclusionMark, trimExclusionMark } from '../../helpers/layout/sections';
import { HIDDEN_COMPONENTS_IF_EMBEDDED } from '../../helpers/layout/constants';
import { ConfigService } from '../../services/config-service';
import { setLayoutSection, setLayoutTemplate } from '../../store/reducers/layout';
import { withResize } from '../with-resize';
import { withEmbeddedContext } from './with-embedded-context';

export const useLayoutSection = (name, metric) => {
  const ref = useRef();

  const dispatch = useDispatch();

  const setSection = useCallback(
    payload => {
      dispatch(setLayoutSection(payload));
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    const node = ref.current;

    if (node) {
      setSection({ name, visibility: true, ...(metric ? { size: node[metric] } : {}) });

      return () => {
        setSection({ name, visibility: false, ...(metric ? { size: 0 } : {}) });
      };
    }
  }, [name, metric, setSection]);

  return ref;
};

const mapStateToProps = ({ layout, navigation }) => ({ template: layout.template, navigation });

export const isVisible = options =>
  compose(
    connect(mapStateToProps),
    withResize,
    withEmbeddedContext,
    branch(({ viewport, template, navigation, isEmbedded }) => {
      const { name } = options;
      //for the phase 1 - pass the option hideIfEmbedded for all components needed to be hidden,
      //for phase 2 - as an option - add configurable part in config file

      //if 'hide' prop is provided explicitly - hide part of layout
      const isHiddenByConfig = name && ConfigService.get(`TEMPLATE.hide.${name}`, false);
      const isHiddenWhenEmbedded = name && isEmbedded && HIDDEN_COMPONENTS_IF_EMBEDDED[name];

      if (isHiddenByConfig || isHiddenWhenEmbedded) {
        return true;
      }

      const isVisible = []
        .concat(options)
        .some(({ onBreakpoints = [], forTemplates = [], onTrueNavigationState = [] }) => {
          let isVisibleState = ConfigService.get('NAVIGATION_BAR.isVisible', true);

          if (isVisibleState && forTemplates.length) {
            // check component visibility on specific TEMPLATE
            isVisibleState = checkOptionEntryVisibility({
              optionEntry: forTemplates,
              inclusiveMapper: name => template === name,
              exclusiveMapper: name => template !== trimExclusionMark(name)
            });
          }

          if (isVisibleState && onBreakpoints.length) {
            // check component visibility on specific BREAKPOINT
            isVisibleState = checkOptionEntryVisibility({
              optionEntry: onBreakpoints,
              inclusiveMapper: name => viewport[name],
              exclusiveMapper: name => !viewport[trimExclusionMark(name)]
            });
          }

          if (isVisibleState && onTrueNavigationState.length) {
            // check component visibility depending on NAVIGATION STATE
            isVisibleState = checkOptionEntryVisibility({
              optionEntry: onTrueNavigationState,
              inclusiveMapper: name => navigation[name],
              exclusiveMapper: name => !navigation[trimExclusionMark(name)]
            });
          }

          return isVisibleState;
        });

      return !isVisible;
    }, renderNothing)
  );

export const asTemplate = name =>
  compose(
    connect(null, { setLayoutTemplate }),
    lifecycle({
      componentDidMount() {
        const { setLayoutTemplate } = this.props;

        setLayoutTemplate({ name });
      }
    })
  );

export const not = padExclusionMark;
