import { createReducer, createAction } from '../utils';

const GET_PF_DIFFICULTY_INDICATORS_REQUEST = 'GET_PF_DIFFICULTY_INDICATORS_REQUEST';
const GET_PF_DIFFICULTY_INDICATORS_SUCCESS = 'GET_PF_DIFFICULTY_INDICATORS_SUCCESS';
const GET_PF_DIFFICULTY_INDICATORS_FAILURE = 'GET_PF_DIFFICULTY_INDICATORS_FAILURE';

const initialState = {
  difficulties: {
    levels: {},
    services: [],
    periods: []
  },
  difficultiesLoading: false,
  difficultiesError: false
};

export const getDifficulties = () =>
  createAction(
    {
      request: GET_PF_DIFFICULTY_INDICATORS_REQUEST,
      success: GET_PF_DIFFICULTY_INDICATORS_SUCCESS,
      failure: GET_PF_DIFFICULTY_INDICATORS_FAILURE
    },
    api => api.configuration.getPensionDifficultyIndicators
  )();

export const pensionFinder = createReducer(initialState, {
  [GET_PF_DIFFICULTY_INDICATORS_REQUEST]: state => ({ ...state, difficultiesLoading: true }),
  [GET_PF_DIFFICULTY_INDICATORS_SUCCESS]: (state, { payload }) => ({
    ...state,
    difficulties: {
      levels: payload.difficulties,
      periods: payload.pensionTimePeriods,
      services: payload.serviceLengths
    },
    difficultiesError: false,
    difficultiesLoading: false
  }),
  [GET_PF_DIFFICULTY_INDICATORS_FAILURE]: state => ({ ...state, difficultiesLoading: false, difficultiesError: true })
});
