import { connect } from 'react-redux';
import { compose, withHandlers, withState, withStateHandlers } from 'recompose';
import { isValid as isValidPostCode } from 'postcode';
import { isUndefined } from '@packages/helpers/core/common';
import { getPropertyByUprnCode } from '../../../store/reducers/user-attributes/actions';
import { mapAddressAsSelectItem } from '../../../helpers/property-portfolio/property-address';
import { withLoadingHandlers } from '../../with-loader';
import { STAGES, DEFAULT_ERROR, PROPERTY_POSTCODE_NAME, PROPERTY_ADDRESS_NAME, PROPERTY_UPRN_NAME } from './constants';
import { Component } from './component';

export const PropertyAddressInput = compose(
  connect(null, { getPropertyByUprnCode }),
  withState('state', 'setState', ({ value = {} }) => {
    const { postcode } = value;

    return {
      [PROPERTY_POSTCODE_NAME]: postcode,
      [PROPERTY_ADDRESS_NAME]: mapAddressAsSelectItem(value)
    };
  }),
  withState('stage', 'setStage', ({ state: { postcode, address } }) => {
    if (address && postcode) {
      return STAGES.READY;
    }

    if (postcode) {
      return STAGES.VALID_POST_CODE;
    }

    return STAGES.DEFAULT;
  }),
  withStateHandlers(
    ({ state: { postcode }, errorMessages }) => ({
      errors: {
        postcode: null,
        address: null
      }
    }),
    {
      setError:
        ({ errors }) =>
        (key, value) => ({
          errors: {
            ...errors,
            [key]: isUndefined(value) ? DEFAULT_ERROR : value
          }
        })
    }
  ),
  withLoadingHandlers(false),
  withHandlers({
    processing:
      ({ setLoading, setStage, setError }) =>
      (loading, stage, error) => {
        if (error) {
          setError(error.key, error.value);
        }

        setLoading(loading);

        if (isFinite(stage)) {
          setStage(stage);
        }
      }
  }),
  withHandlers({
    handlePostCodeChange:
      ({ setState, stage, setStage, setError, errorMessages, processing, onChange }) =>
      value => {
        setState(() => ({ postcode: value }));
        setError('postcode', isValidPostCode(value) ? null : errorMessages?.postcode);

        if (stage > STAGES.DEFAULT) {
          setStage(STAGES.DEFAULT);
        }

        if (isValidPostCode(value)) {
          processing(false, STAGES.VALID_POST_CODE);
        } else {
          onChange(null);
        }
      },
    handleAddressChange:
      ({ setState, stage, setStage, onChange }) =>
      () =>
      value => {
        setState(state => ({
          ...state,
          address: value
        }));

        if (stage >= STAGES.READY) {
          setStage(STAGES.VALID_POST_CODE);
        }

        // If item is selected
        if (value?.value) {
          const { address, postcode, uprn } = value.value;
          const inputValue = {
            [PROPERTY_ADDRESS_NAME]: address,
            [PROPERTY_POSTCODE_NAME]: postcode,
            [PROPERTY_UPRN_NAME]: uprn
          };

          onChange(inputValue);
        }
      }
  })
)(Component);
