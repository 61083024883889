import { asTemplate } from '../components/layout/with-layout';
import ContentFeed from '../pages/content-feed';

const CONTENT_FEED = 'content-feed';

export const contentFeedRoutes = [
  {
    to: '/feed',
    component: asTemplate(CONTENT_FEED)(ContentFeed)
  }
];
