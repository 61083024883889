import { evaluateCondition } from '../checklist/display-condition';

export const mapVisibleChecklistActivitiesToStatus = async ({ checklist, userState, statuses }) => {
  const visibleItemsList = [];
  for (const { displayCondition, statusMappingDetails } of checklist) {
    const isVisible = await evaluateCondition(displayCondition, userState);

    if (!displayCondition || isVisible) {
      visibleItemsList.push([statusMappingDetails, statuses[statusMappingDetails].key]);
    }
  }

  return visibleItemsList;
};

export const calculateActivityProgress = activityToStatusList => {
  return {
    total: activityToStatusList.length,
    completed: activityToStatusList.filter(([_, status]) => status === 'completed').length
  };
};
