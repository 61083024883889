import React from 'react';
import classnames from 'classnames';
import { defaultProps } from 'recompose';
import styles from '../../styles/components/form-explainer.module.scss';

const Explainer = React.memo(({ children, className, tabIndex, role, explainerId = children }) => (
  <p id={explainerId} tabIndex={tabIndex} role={role} className={classnames(styles['form-explainer'], className)}>
    {children}
  </p>
));

const FormExplainer = defaultProps({ tabIndex: 0, role: 'term' })(Explainer);

Explainer.displayName = 'Explainer';

export { Explainer, FormExplainer };
