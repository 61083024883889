import { StringShim } from '@packages/helpers/core/shims/string-shim';

const BREAKPOINTS_MAP = ['on', 'to'];

export const parseBreakpoint = (entity = '', helper: string, value?: string): string => {
  return StringShim.toKebabCase(
    helper,
    entity
      .split('-')
      .map((target, index) =>
        StringShim.trim(target).length ? StringShim.toKebabCase(BREAKPOINTS_MAP[index], target) : target
      )
      .join('-'),
    value
  );
};

export const parseArrayOfBreakpoints = (entity: string[] = [], helper: string): string[] => {
  return entity.map(breakpoint => parseBreakpoint(breakpoint, helper));
};

export const parseMapOfBreakpoints = (
  entity: Record<PropertyKey, string> = {},
  helper: string | ((value?: string, breakpoint?: string, ...rest: any[]) => string),
  ...rest: any[]
): string[] => {
  return Object.keys(entity).reduce((acc: string[], breakpoint: string): string[] => {
    const value =
      typeof helper === 'function'
        ? helper(entity[breakpoint], breakpoint, ...rest)
        : parseBreakpoint(breakpoint, helper, entity[breakpoint]);

    acc.push(value);

    return acc;
  }, []);
};
