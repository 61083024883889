import React from 'react';
import classnames from 'classnames';
import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import styles from '../../../../../styles/templates/post-page/article-like.module.scss';
import { ariaDisabledInterceptor } from '../../../../../helpers/aria-disabled-interceptor';
import { AffiliateIcon } from '../../../../../components/icons/affiliate-icon';

const Component = React.memo(({ data, label, ariaLabel, icon, feedback, disabled, onSendFeedback }) => (
  <button
    key={data}
    className={styles.answer}
    onClick={ariaDisabledInterceptor(onSendFeedback(data))}
    aria-disabled={Boolean(disabled)}
    aria-label={ariaLabel}
  >
    <span className={styles.label}>{label}</span>
    <AffiliateIcon
      name={icon}
      className={classnames(styles.unanswered, {
        [styles.answered]: !!feedback,
        [styles.selected]: data === feedback
      })}
    />
  </button>
));

const mapStateToProps = ({ contentInteraction }, { contentKey, data, propFeedback }) => {
  const feedback = ObjectShim.has(contentInteraction.contentStatuses[contentKey], data) ? data : propFeedback;

  return {
    disabled: contentInteraction.loading,
    feedback
  };
};

const FeedbackAction = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      const { feedback, propFeedback, setFeedback } = this.props;

      // Make sure that we have the feedback from the store on mount
      if (feedback && !propFeedback) {
        setFeedback(feedback);
      }
    }
  })
)(Component);

FeedbackAction.displayName = 'FeedbackAction';

FeedbackAction.propTypes = {
  contentKey: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  propFeedback: PropTypes.string,
  onSendFeedback: PropTypes.func,
  setFeedback: PropTypes.func
};

export { FeedbackAction };
