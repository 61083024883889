const isBrowser = () => typeof window !== 'undefined';
const hasNavigator = () => isBrowser() && Object.prototype.hasOwnProperty.call(window, 'navigator');
const hasTouchScreen = () => hasNavigator() && window.navigator.maxTouchPoints > 1;
const hasPlatform = () => hasNavigator() && Object.prototype.hasOwnProperty.call(window.navigator, 'platform');

const REGEXP = {
  IOS: /iP(ad|hone|od)/,
  IOSSimulator: /iP(ad|hone|od) Simulator/,
  WEBKIT: /(Mac|iPhone|iPod|iPad)/i
};

export const DEVICE = {
  IOS: (): boolean =>
    hasPlatform() &&
    hasTouchScreen() &&
    (REGEXP.IOS.test(window.navigator.platform) ||
      REGEXP.IOSSimulator.test(window.navigator.platform) ||
      window.navigator.userAgent.includes('Mac')),
  WEBKIT: (): boolean => hasPlatform() && REGEXP.WEBKIT.test(navigator.platform)
};
