import { compose } from 'redux';
import { mapFees, mapSavePerMonth } from '../user-attributes/map-user-attributes';
import { mapMyPlanChartAttributes } from './get-doughnuts-config';

const mapMyPlanAttributes = goals => {
  const { savePerMonth, eachMonthLowerValue, totalCosts, inTotalLowerValue } = goals;

  return {
    ...goals,
    monthlySavingNeeded: savePerMonth,
    monthlySavingDifference: eachMonthLowerValue,
    depositSavingsNeeded: totalCosts,
    depositSavingsDifference: inTotalLowerValue
  };
};

export const calculateMyPlan = compose(mapMyPlanAttributes, mapMyPlanChartAttributes, mapSavePerMonth, mapFees);

export const mapValueFromConfig = (value, status) => {
  return value?.[status] ?? value?.fallback ?? value;
};

export const mapButtonsFromConfig = (buttons = [], status) => {
  // https://www.npmjs.com/package/memoize-state#warning
  return [...buttons]
    .filter(({ show }) => {
      return mapValueFromConfig(show, status);
    })
    .sort((a, b) => {
      return mapValueFromConfig(a.weight, status) - mapValueFromConfig(b.weight, status);
    })
    .map(({ label, link, buttonType }) => {
      return {
        label: mapValueFromConfig(label, status),
        link: mapValueFromConfig(link, status),
        buttonType: mapValueFromConfig(buttonType, status)
      };
    });
};
