import mergeWith from 'lodash.mergewith';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

//'high' value has biggest weight it can't be overridden back to 'low' if it was high once
const customizer = (value1, value2) => {
  if (value1 === 'high') {
    return value1;
  }

  return value2;
};

export const transformBE = (params, previousState) => {
  const prevState = ObjectShim.omit(previousState, 'parsed');
  const items = { ...prevState, ...params };
  let parsed = {};

  Object.values(items).forEach(item =>
    Object.values(item).forEach(option => {
      if (option.checked) {
        parsed = mergeWith(parsed, option.value, customizer);
      }
    })
  );

  return {
    ...params,
    parsed
  };
};
