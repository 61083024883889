import { compose } from 'redux';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import { ConfigService } from '../../services/config-service';
import { calculateLegalFees } from './calculate-fees/calculate-legal-fees';
import { calculateStumpDuty } from './calculate-fees/calculate-stump-duty';

export const mapSavePerMonth = goals => {
  const { totalCosts, depositSavings, timeframe } = goals;

  const savePerMonth = NumberShim.inRange((totalCosts - depositSavings) / timeframe, { min: 0 });

  return {
    ...goals,
    savePerMonth: Math.round(savePerMonth)
  };
};
export const mapTotalSavings = goals => {
  const { depositSavings, financialSupport } = goals;

  return {
    ...goals,
    totalSavings: depositSavings + financialSupport
  };
};
export const mapTotalIncome = goals => {
  const { buyersQuantity, ownIncome, secondAnnualIncome } = goals;

  switch (buyersQuantity) {
    case 0:
      return {
        ...goals,
        totalIncome: ownIncome
      };
    case 1:
      return {
        ...goals,
        totalIncome: ownIncome + secondAnnualIncome
      };
    default:
      return goals;
  }
};
export const mapMaxLoan = goals => {
  const { totalIncome } = goals;

  const {
    DEFAULT_VALUES: { MORTGAGE_MAX_AMOUNT_TO_LEND_MULTIPLIER }
  } = window.CONFIG;

  return {
    ...goals,
    maxLoan: Math.round(totalIncome * MORTGAGE_MAX_AMOUNT_TO_LEND_MULTIPLIER)
  };
};
export const mapDepositAmount = goals => {
  const { depositPercent, propertyPrice } = goals;

  return {
    ...goals,
    depositAmount: NumberShim.percentIs(depositPercent, propertyPrice)
  };
};
export const mapTotalCosts = goals => {
  const { depositAmount, stampDuty, legalFees, misc } = goals;

  return {
    ...goals,
    totalCosts: depositAmount + stampDuty + legalFees + misc
  };
};
export const mapMovingInDate = goals => {
  const { timeframe } = goals;

  const {
    DEFAULT_VALUES: { MOVING_IN_TIMEFRAME }
  } = window.CONFIG;

  return {
    ...goals,
    movingInDate: timeframe + MOVING_IN_TIMEFRAME
  };
};
export const mapLegalFees = goals => {
  const { legalFeesReal, propertyPrice } = goals;

  return {
    ...goals,
    legalFees: legalFeesReal ?? calculateLegalFees(propertyPrice)
  };
};
export const mapStampDuty = goals => {
  return {
    ...goals,
    stampDuty: calculateStumpDuty(goals)
  };
};

const processDefaultGoalsData = goal => ({
  ...goal,
  preferredDepositPercent:
    goal.preferredDepositPercent !== 0
      ? goal.preferredDepositPercent
      : ConfigService.get('DEFAULT_VALUES.DEPOSIT_PERCENT_STANDARD'),
  depositPercent:
    goal.depositPercent !== 0 ? goal.depositPercent : ConfigService.get('DEFAULT_VALUES.DEPOSIT_PERCENT_STANDARD'),
  misc: goal.misc !== 0 ? goal.misc : ConfigService.get('DEFAULT_VALUES.MISC_COSTS')
});

export const mapFees = compose(mapTotalCosts, mapStampDuty, mapLegalFees, mapDepositAmount, processDefaultGoalsData);
