import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '../../../components/accordion';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import ActionSelect from './action-select';

const AccordionActions = ({ actions, activityStatuses, isExternal, className }) => {
  const [accordions, setAccordions] = useState({});

  const handleTrigger = key => () => {
    setAccordions(value => ({ ...value, [key]: !value[key] }));
  };

  return (
    <div className={className}>
      <Accordion>
        {actions.map(({ key, body, description, activityStatusKey }) => {
          const innerComponent = isExternal ? null : (
            <ActionSelect statusKey={key} activityStatusKey={activityStatusKey} activityStatuses={activityStatuses} />
          );

          return (
            <AccordionItem
              key={key}
              title={<PurifiedHtmlContainer html={body} renderAs='p' />}
              expanded={accordions[key]}
              onClick={handleTrigger(key)}
              innerComponent={innerComponent}
            >
              <PurifiedHtmlContainer className='typography typography-extended' html={description} />
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

AccordionActions.propTypes = {
  className: PropTypes.string,
  isExternal: PropTypes.bool,
  activityStatuses: ActionSelect.propTypes.activityStatuses,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      description: PropTypes.string,
      activityStatusKey: PropTypes.string,
      activityStatuses: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          order: PropTypes.number.isRequired
        })
      )
    })
  )
};

AccordionActions.displayName = 'AccordionActions';

export default AccordionActions;
