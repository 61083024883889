import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { DASHBOARD } from '@apps/firsthomecoach/src/helpers/navigation';
import { Dashboard } from '../pages/dashboard';

export const dashboardRoutes = [
  {
    to: '/dashboard',
    component: asTemplate(DASHBOARD)(Dashboard)
  }
];
