import React from 'react';
import { branch } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { Link } from '../link';

const renderAsLink =
  Component =>
  ({ to, className, ariaDisabled, ...props }) => {
    const { inline } = props;

    return (
      <Link to={to} style={{ display: inline ? 'inline' : 'block' }} className={className} aria-disabled={ariaDisabled}>
        <Component {...props} />
      </Link>
    );
  };

export const withLink = branch(({ to }) => !isNil(to), renderAsLink);
