import React from 'react';
import styles from '../../styles/components/input-error.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';

export const InputError = React.memo(({ children, errorId }) => (
  <div data-atid='input-error' className={styles.container}>
    <AffiliateIcon name='form-error' className={styles.icon} />
    <div className={styles.message} id={errorId} aria-live='polite'>
      {children}
    </div>
  </div>
));
