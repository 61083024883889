import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose';
import { cancellablePromise } from '../../helpers/workers/cancellable-promise';
import { omitProps } from '../../helpers/hocs/omit-props';

// used for promisified handlers, that will be cancelled on component unmount.
// Without cancelling - we receive the memory leak error
export const withCancellableHandlers = (handlers = {}) =>
  compose(
    withHandlers(handlers),
    // withState('cleanup', 'setCleanUp', []),
    withPropsOnChange([...Object.keys(handlers)], props =>
      // Mapping the names of the cancellable handlers
      Object.keys(handlers).reduce(
        (handlers, handlerName) => {
          const callback = props[handlerName];
          const handler = cancellablePromise(callback);

          return {
            ...handlers,
            [handlerName]: handler.promise,
            //will not allow this HOC to re-render
            cleanup: [...handlers.cleanup, handler]
          };
        },
        { cleanup: [] }
      )
    ),
    lifecycle({
      componentWillUnmount() {
        const { cleanup } = this.props;

        cleanup.forEach(promise => {
          promise.cancel();
        });
      }
    }),
    omitProps(['cleanup'])
  );
