type Store<T> = Record<string, T>;

export class LocalStorageService<T> {
  constructor(private key: string) {}

  init(): Store<T> {
    const store = {};

    localStorage.setItem(this.key, JSON.stringify(store));

    return store as Store<T>;
  }

  get(): Store<T> {
    const store = localStorage.getItem(this.key);

    if (store === null) {
      return this.init();
    }

    return JSON.parse(store) as Store<T>;
  }

  set(updater: (prev: Store<T>) => Store<T>): boolean {
    try {
      localStorage.setItem(this.key, JSON.stringify(updater(this.get())));
    } catch {
      return false;
    }

    return true;
  }
}
