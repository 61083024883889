import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { compose, branch, renderComponent, withPropsOnChange } from 'recompose';
import { getHeaderType, HEADER_TYPES } from '../../../helpers/layout/header';
import styles from '../../../styles/components/page-header.module.scss';
import { setPageHeaderConfig } from '../../../store/reducers/application';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { Image as ImageBase } from '../../images/image';
import { Grid, Column } from '../grid';
import { SecondaryHeader } from './custom/secondary';

export const Heading = React.memo(({ renderAs: Component = 'h1', children, className }) => (
  <Component className={classnames(styles.heading, className)}>{children}</Component>
));

export const SubHeading = React.memo(({ children, className }) => (
  <p className={classnames(styles.subheading, className)}>{children}</p>
));

export const Body = React.memo(({ children, className }) => (
  <div className={classnames(styles.body, className)}>{children}</div>
));

export const Icon = React.memo(({ name, className }) => (
  <AffiliateIcon name={name} className={classnames(styles.icon, className)} />
));

export const BlogBackButton = React.memo(({ className, onClick, icon = 'nav-back', text = 'All blog posts' }) => (
  <button className={classnames(styles['nav-back'], 'typography', 'typography-extended', className)} onClick={onClick}>
    <AffiliateIcon name={icon} inline />
    <p>{text}</p>
  </button>
));

export const Image = React.memo(({ src, alt, children, className }) => (
  <div className={classnames(styles.image, className)}>
    <ImageBase src={src} title={alt} cover fluid />
    {children}
  </div>
));

export const withHeader =
  Component =>
  ({ withKeyline, className, ...props }) => {
    const inlineStyles = {
      '--app-custom-page-header-bg-image': `url(${JSON.stringify(props.image ?? '')})`,
      '--app-custom-page-header-bg-position': props.backgroundPosition
    };

    // Setting the page header config to avoid the need to pass it down to the children
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setPageHeaderConfig({ currentPageConfigName: props.pageConfigKey }));
    }, [props.pageConfigKey, dispatch]);

    const headerType = getHeaderType(props.type);

    return (
      <hgroup
        style={inlineStyles}
        className={classnames(
          styles.container,
          {
            [styles['with-keyline']]: withKeyline,
            [styles['with-bg-image']]: headerType === HEADER_TYPES.default && props.image
          },
          className
        )}
      >
        <Component {...props} />
      </hgroup>
    );
  };

export const withHeaderWrapper = Component => props =>
  (
    <Grid>
      <Column>
        <div className={styles.wrapper}>
          <Component {...props} />
        </div>
      </Column>
    </Grid>
  );

export const withSplitHeader = compose(
  withPropsOnChange(['type', 'image'], ({ type, image }) => ({
    isSplitHeader: getHeaderType(type) === HEADER_TYPES.split && image
  })),
  branch(({ isSplitHeader, subBlockName }) => isSplitHeader || subBlockName, renderComponent(SecondaryHeader))
);
