import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { redirectToModule } from '../../helpers/custom-page/is-completed';

const Component = React.memo(({ redirectTo }) => {
  return <Redirect to={redirectTo} />;
});

export const ChecklistRedirect = connect(({ statusData }, { to: { link, statusMappingDetails } }) => ({
  redirectTo: redirectToModule(link, statusMappingDetails, statusData)
}))(Component);
