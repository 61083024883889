import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { resetCustomPage } from '../store/reducers/content';
import { resetFailedNavChecklistLink } from '../store/reducers/navigation';
import { createCustomPageProps } from '../helpers/utils';
import { BugsnagService } from '../services/bugsnag';
import { NotFoundPage } from './layout/not-found-page';

export class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };

    this.props.history.listen(() => {
      this.submitError();
    });
  }

  static getDerivedStateFromError(error) {
    BugsnagService.notify(error);

    return {
      hasError: true
    };
  }

  submitError = () => this.setState({ hasError: false });

  componentDidUpdate(_, { hasError: hadError }) {
    const {
      location: { pathname },
      resetCustomPage,
      resetFailedNavChecklistLink
    } = this.props;

    const { hasError } = this.state;

    if (hadError !== hasError && hasError) {
      const params = createCustomPageProps(pathname);

      if (params.trigger === 'start' || params.trigger === 'result') {
        resetCustomPage(params);
        resetFailedNavChecklistLink(params.name);
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return <NotFoundPage errorPage={true} history={this.props.history} />;
    }
    return this.props.children;
  }
}

export const ErrorBoundary = compose(
  withRouter,
  connect(null, { resetCustomPage, resetFailedNavChecklistLink })
)(ErrorBoundaryComponent);
