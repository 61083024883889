import { connect } from 'react-redux';
import { branch, compose, lifecycle } from 'recompose';
import { setDigitalCoachDelayLoading } from '../../../store/reducers/digital-coach';
import { withSpinner } from '../../../components/with-loader';

const mapStateToProps = ({ digitalCoach }) => ({ loading: digitalCoach.delayLoading });

/**
 * HOC that delays the mounting of the digital coach
 * Useful for pages that have a content and need to load the page and send status first
 */
export const withDelayedDigitalCoach = compose(
  connect(mapStateToProps, { setDigitalCoachDelayLoading }),
  lifecycle({
    componentWillUnmount() {
      const { setDigitalCoachDelayLoading } = this.props;
      setDigitalCoachDelayLoading(true);
    }
  }),
  branch(({ loading }) => loading, withSpinner)
);
