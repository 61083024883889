import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import styles from '../../../styles/widgets/digital-coach-widget/poll-bars.module.scss';
import { getMaxValueByKey, sumByKey } from '../../../helpers/digital-coach/simple-poll';
import PollBarRow from './poll-bar';

const Component = ({ data, maxCount, totalCount }) => (
  <div className={styles.container}>
    {data
      .sort((a, b) => b.count - a.count)
      .map(({ value, count }) => (
        <PollBarRow key={value} label={value} count={Number(count)} maxCount={maxCount} totalCount={totalCount} />
      ))}
  </div>
);

const PollBars = compose(
  withPropsOnChange({}, ({ data }) => ({
    totalCount: sumByKey(data, 'count'),
    maxCount: getMaxValueByKey(data, 'count')
  }))
)(Component);

PollBars.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      count: PropTypes.string
    })
  )
};

export default PollBars;
