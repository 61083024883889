import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { SAVED_STATUS } from '../status-data/use-favorite';
import { CONTENT_ROUTE_TO_STORE_KEY, ROUTE_TO_TYPE, TYPE_TO_ACTIVITY_KEY, TYPE_TO_ROUTE } from './constants';

const MISSING_ACTIVITY_STATUSES_ERROR = 'Activity statuses are not provided - please check Activity to product tab';

const checkIfActivityStatusesExist = activityStatuses => {
  if (!activityStatuses) {
    throw new Error(MISSING_ACTIVITY_STATUSES_ERROR);
  }
};

export const getAdditionalDataByContentType = ({ contentType, key, slug, id, title }) => {
  switch (contentType) {
    case TYPE_TO_ACTIVITY_KEY.article:
    case TYPE_TO_ACTIVITY_KEY.blog:
      return {
        wpId: id,
        name: title,
        wpSlug: slug,
        key
      };
    default:
      return { key, slug };
  }
};

export const pickAdditionalDataByContentTypeFromStore = ({ contentType, slug, store = {} }) => {
  const contentTypeEnum = ROUTE_TO_TYPE[contentType];
  const storeKey = CONTENT_ROUTE_TO_STORE_KEY[contentTypeEnum];

  if (!store[storeKey][slug]) {
    return { slug };
  }

  switch (contentType) {
    case TYPE_TO_ROUTE.article:
    case TYPE_TO_ROUTE.blog:
      const selectedPost = store.contentPages[slug];

      return {
        wpId: selectedPost.id,
        name: selectedPost.title,
        wpSlug: selectedPost.slug,
        key: selectedPost.key
      };
    case TYPE_TO_ROUTE.story:
      const selectedStory = store.stories[slug];

      return {
        slug: selectedStory.slug,
        key: selectedStory.key
      };
    default:
      return { slug };
  }
};

/**
 * @typedef {Object<string, ActivityStatuses>} ActivitiesConfig
 * @description An object where each key is an activityKey and value is list of statuses.
 */
/**
 * @typedef {Object<string, Status>} ActivityStatuses
 * @description An object where each key is an activityStatusKey and value is status info.
 */
/**
 * @typedef {Object} Status
 * @property {string} key
 * @property {number} order
 * @property {?string} exclusiveStatusGroup
 */

/**
 * @typedef {Object<string, ContentStatuses>} ContentStatuses
 * @description An object where each key is an contentKey and value is list of statuses for this content.
 */
/**
 * @typedef {Object<string, ContentStatus>} ContentStatuses
 * @description An object where each key is an activityStatusKey and value is status info.
 */
/**
 * @typedef {Object} ContentStatus
 * @property {string} activity
 * @property {number} order
 * @property {string} key
 */

/**
 * @typedef {Object} ContentStatusBE
 * @property {string} key
 * @property {string} order
 */

export const mapContentStatuses = payload =>
  payload.reduce(
    (acc, item) => {
      const { key: contentKey, activityStatusKey, activityKey, activityStatusOrder } = item;
      if (!acc.contentStatuses[contentKey]) {
        acc.contentStatuses[contentKey] = {};
      }
      acc.contentStatuses[contentKey][activityStatusKey] =
        /** @type {ContentStatus} */
        {
          key: activityStatusKey,
          order: activityStatusOrder,
          activity: activityKey
        };

      return acc;
    },
    { contentStatuses: {} }
  );

/**
 * This helper is used to check if the sent content status is higher than the current one
 * It is an analogue for helper hasHigherOrder from helpers/status-data
 * But the main difference is that content can have multiple statuses
 * @param {string} activityKey
 * @param {string} contentKey
 * @param {ContentStatusBE} status - status from the BE
 * @param {ContentStatuses} contentStatuses
 * @param {ActivitiesConfig} activitiesConfig - statuses config
 */
export const hasContentHigherOrder = ({ activityKey, contentKey, status, contentStatuses, activitiesConfig }) => {
  const existingStatuses = contentStatuses[contentKey];
  const activityStatuses = activitiesConfig[activityKey];
  // If there are no statuses for this content in the store, we can update it
  // If the status is interactive (feedback or saved), we can update it
  if (ObjectShim.isEmpty(existingStatuses) || isStatusInteractive(activityStatuses, status.key)) {
    return true;
  }

  // If the status already exists, we don't need to update it
  if (!ObjectShim.isEmpty(existingStatuses[status.key])) {
    return false;
  }

  const currentContentStatus = getHighestContentStatus(existingStatuses, activityStatuses);

  // If there are no statuses for this content in the store, we can update it
  if (ObjectShim.isEmpty(currentContentStatus)) {
    return true;
  }

  return status.order >= currentContentStatus.order;
};

/**
 * This helper is used to exclude statuses that are interactive (feedback, saved, etc.)
 * @param {ContentStatuses} contentStatuses
 * @param {ActivityStatuses} activityStatuses
 */

const excludeInteractiveStatuses = (contentStatuses, activityStatuses) => {
  return Object.entries(contentStatuses).reduce((acc, [key, status]) => {
    if (!isStatusInteractive(activityStatuses, status.key)) {
      acc[key] = status;
    }

    return acc;
  }, {});
};

/**
 * This helper is used to check if the status is interactive
 * @param {ActivityStatuses} activityStatuses
 * @param {string} statusKey
 * @returns {boolean}
 */

export const isStatusInteractive = (activityStatuses, statusKey) => {
  checkIfActivityStatusesExist(activityStatuses);

  const exclusiveStatusGroup = getActivityExclusiveStatusGroup(activityStatuses, statusKey);
  const isSavedStatus = statusKey === SAVED_STATUS;

  return !!exclusiveStatusGroup || isSavedStatus;
};

/**
 * This helper is used to get the highest order status for the content
 * @param {ContentStatuses} existingStatuses
 * @param {ActivityStatuses} activityStatuses
 * @returns {ContentStatus|null}
 */
export const getHighestContentStatus = (existingStatuses, activityStatuses) => {
  checkIfActivityStatusesExist(activityStatuses);

  if (ObjectShim.isEmpty(existingStatuses)) {
    return null;
  }
  const remainingStatuses = excludeInteractiveStatuses(existingStatuses, activityStatuses);

  return Object.values(remainingStatuses).sort((a, b) => b.order - a.order)[0] || null;
};

/**
 * @param {ActivityStatuses} activityStatuses
 * @param {string} statusKey
 */
export const getActivityExclusiveStatusGroup = (activityStatuses, statusKey) => {
  return activityStatuses[statusKey]?.exclusiveStatusGroup || null;
};

/**
 * @param {ActivityStatuses} activityStatuses
 * @param {string} statusKey
 * @return {string[]}
 */
export const getActivityStatusesWithExclusiveStatusGroup = (activityStatuses, statusKey) => {
  checkIfActivityStatusesExist(activityStatuses);

  const exclusiveStatusGroup = getActivityExclusiveStatusGroup(activityStatuses, statusKey);

  return Object.values(activityStatuses).reduce((acc, status) => {
    if (exclusiveStatusGroup && status.exclusiveStatusGroup === exclusiveStatusGroup) {
      acc.push(status.key);
    }

    return acc;
  }, []);
};
