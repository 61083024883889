import { createHeaders } from './headers';
import { MAIN } from './constants';

const baseUrl = {
  main: '/api/user',
  static: '/api/configuration',
  content: '/api/knowledgebase'
};

export const getPage = () => window.location.pathname.split('/').splice(1).join('-');

export const request = async (method, url, params, type = MAIN) => {
  const headers = createHeaders(type);
  const body = JSON.stringify(params);
  try {
    return await fetch(baseUrl[type] + url, { headers, method, body });
  } catch (err) {
    throw err;
  }
};

export const get = async (url, type) => request('GET', url, undefined, type);
export const post = async (url, params, type) => request('POST', url, params, type);
export const patch = async (url, params, type) => request('PATCH', url, params, type);
export const remove = async (url, params, type) => request('DELETE', url, params, type);
export const put = async (url, params, type) => request('PUT', url, params, type);
