import React, { createContext, useReducer, useContext, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { reducer, initialState, actions } from './reducer';

const Context = createContext({
  state: initialState,
  dispatch: () => void 0
});

export const useSubscribe = name => {
  const { dispatch } = useContext(Context);

  const subscribe = useCallback(
    ref => {
      if (ref) {
        dispatch({ type: actions.addSlot, payload: { ref, name } });
      }
    },
    [name, dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch({ type: actions.removeSlot, payload: { name } });
    };
  }, [name, dispatch]);

  return subscribe;
};

export const useSlot = name => {
  const { state } = useContext(Context);

  return state[name];
};

export const SlotsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const Slot = ({ name, children }) => {
  const slot = useSlot(name);

  return slot ? createPortal(children, slot) : null;
};
