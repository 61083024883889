import React from 'react';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { Select } from './components/select';

export const QuestionSelectInput = React.memo(
  ({
    items,
    value,
    placeholder,
    label,
    onChange,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    preSubmitButton,
    isOptional,
    informer,
    handleParseLabel
  }) => {
    const currentValue = ArrayShim.normalize(items, 'value')[value]?.label ?? value;

    return (
      <Select
        name='select'
        label={label}
        placeholder={placeholder}
        value={currentValue && handleParseLabel(currentValue)}
        items={items}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        preSubmitButton={preSubmitButton}
        isOptional={isOptional}
        advancedInformer={informer}
      />
    );
  }
);
