import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../../components/layout/page-header';
import styles from '../../../styles/widgets/story-step/header.module.scss';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';

const HeaderComponent = ({ title, subTitle, identifier }) => (
  <Header
    className={styles.header}
    heading={title}
    subheading={subTitle && <PurifiedHtmlContainer html={subTitle} renderAs='p' />}
    category={identifier}
  />
);

HeaderComponent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  identifier: PropTypes.string
};

export { HeaderComponent };
