import React from 'react';
import useMountEffect from '@packages/hooks/core/use-mount-effect';
import { withRequiredRedirect } from './content/with-required-redirect';

const Component = React.memo(({ processRequiredActivityRedirect }) => {
  useMountEffect(() => processRequiredActivityRedirect());

  return null;
});
// Moved to a separate component to avoid Content re-rendering
export const RequiredActivityController = withRequiredRedirect(Component);
