import { createAction, createReducer } from '../utils';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';

const GET_NEXT_ACTIVITY_REQUEST = 'GET_NEXT_ACTIVITY_REQUEST';
const GET_NEXT_ACTIVITY_SUCCESS = 'GET_NEXT_ACTIVITY_SUCCESS';
const GET_NEXT_ACTIVITY_FAILURE = 'GET_NEXT_ACTIVITY_FAILURE';
const GET_NEXT_DC_REQUEST = 'GET_NEXT_DC_REQUEST';
const GET_NEXT_DC_SUCCESS = 'GET_NEXT_DC_SUCCESS';
const GET_NEXT_DC_FAILURE = 'GET_NEXT_DC_FAILURE';
const GET_SPECIFIC_ACTIVITY_REQUEST = 'GET_SPECIFIC_ACTIVITY_REQUEST';
const GET_SPECIFIC_ACTIVITY_SUCCESS = 'GET_SPECIFIC_ACTIVITY_SUCCESS';
const GET_SPECIFIC_ACTIVITY_FAILURE = 'GET_SPECIFIC_ACTIVITY_FAILURE';
const SET_REFRESH_PAGE_BACK_LINK = 'SET_REFRESH_PAGE_BACK_LINK';
const SET_ACTIVITY_FORCED_LOADING = 'SET_ACTIVITY_FORCED_LOADING';
const SET_DIGITAL_COACH_DELAY_LOADING = 'SET_DIGITAL_COACH_DELAY_LOADING';

const initialState = {
  nextActivity: {},
  nextDC: {},
  specificActivity: {},
  forcedLoading: false,
  delayLoading: true,
  loading: true,
  error: false,
  to: '/'
};

export const getNextDC = () =>
  createAction(
    {
      request: GET_NEXT_DC_REQUEST,
      success: GET_NEXT_DC_SUCCESS,
      failure: GET_NEXT_DC_FAILURE
    },
    api => api.statusData.getNextDC
  )();

export const getNextActivity = () =>
  createAction(
    {
      request: GET_NEXT_ACTIVITY_REQUEST,
      success: GET_NEXT_ACTIVITY_SUCCESS,
      failure: GET_NEXT_ACTIVITY_FAILURE
    },
    api => api.statusData.getNextActivity
  )();

export const getSpecificActivity = activity =>
  createAction(
    {
      request: GET_SPECIFIC_ACTIVITY_REQUEST,
      success: GET_SPECIFIC_ACTIVITY_SUCCESS,
      failure: GET_SPECIFIC_ACTIVITY_FAILURE
    },
    api => api.statusData.getNextActivity
  )(activity);

export const enableCoachLoader = () => ({ type: GET_NEXT_ACTIVITY_REQUEST });

export const toggleCoachForcedLoading = forcedLoading => ({ type: SET_ACTIVITY_FORCED_LOADING, forcedLoading });

export const setRefreshPageBackLink = to => ({ type: SET_REFRESH_PAGE_BACK_LINK, to });

export const setDigitalCoachDelayLoading = delayLoading => ({ type: SET_DIGITAL_COACH_DELAY_LOADING, delayLoading });

export const digitalCoach = createReducer(initialState, {
  [GET_NEXT_ACTIVITY_REQUEST]: state => ({ ...state, loading: true }),
  [GET_NEXT_ACTIVITY_SUCCESS]: (state, action) => ({ ...state, nextActivity: action.payload, loading: false }),
  [GET_NEXT_ACTIVITY_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_NEXT_DC_REQUEST]: state => ({ ...state, loading: true }),
  [GET_NEXT_DC_SUCCESS]: (state, action) => ({ ...state, nextDC: action.payload, loading: false }),
  [GET_NEXT_DC_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_SPECIFIC_ACTIVITY_REQUEST]: state => ({ ...state, loading: true }),
  [GET_SPECIFIC_ACTIVITY_SUCCESS]: (state, action) => ({ ...state, specificActivity: action.payload, loading: false }),
  [GET_SPECIFIC_ACTIVITY_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SET_REFRESH_PAGE_BACK_LINK]: (state, { to }) => ({ ...state, to }),
  [SET_ACTIVITY_FORCED_LOADING]: (state, { forcedLoading }) => ({ ...state, forcedLoading }),
  [SET_DIGITAL_COACH_DELAY_LOADING]: (state, { delayLoading }) => ({ ...state, delayLoading }),
  [DELETE_ACCOUNT_SUCCESS]: () => ({ ...initialState })
});
