import React from 'react';
import { connect } from 'react-redux';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { ConfigService } from '../../services/config-service';
import { omitProps } from '../../helpers/hocs/omit-props';
import { withResize } from '../with-resize';
import { ChecklistRedirect } from './checklist-redirect';
import { ArticlesRedirect } from './articles-redirect';

const Component = ({ render, ...props }) => <ReactRoute render={render} {...props} />;

const mapStateToProps = ({ navigation }) => ({
  checklistRedirect: navigation.checklistLink,
  knowledgeRedirect: navigation.knowledgeLink
});

export const Route = compose(
  connect(mapStateToProps),
  withResize,
  withHandlers({
    render:
      ({ route, viewport, checklistRedirect, knowledgeRedirect }) =>
      props => {
        const {
          to,
          scenario,
          component: Component,
          hideOn,
          redirect = ConfigService.get('NAVIGATION_BAR.launchPage', '/')
        } = route;

        const preventRedirectFrom = ConfigService.get('TEMPLATE.preventRedirectFrom', {});

        if (viewport[hideOn]) {
          switch (to) {
            case '/checklist':
              if (preventRedirectFrom[to] || !checklistRedirect) break;

              return <ChecklistRedirect to={checklistRedirect} />;
            case '/articles':
              if (preventRedirectFrom[to] || !knowledgeRedirect) break;

              return <ArticlesRedirect to={knowledgeRedirect} />;
            default:
              return <Redirect to={redirect} />;
          }
        }

        return <Component {...props} scenario={scenario} />;
      }
  }),
  omitProps(['route', 'viewport', 'dimensions', 'checklistRedirect', 'knowledgeRedirect'])
)(Component);
