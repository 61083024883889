import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/components/application.module.scss';

export const SkipLink = props => {
  const { text = 'Skip Navigation', sectionId = 'article' } = props;

  return (
    <a className={styles['skip-nav-link']} href={`#${sectionId}`} aria-label={text}>
      {text}
    </a>
  );
};

SkipLink.propTypes = {
  text: PropTypes.string,
  sectionId: PropTypes.string
};

SkipLink.displayName = 'SkipLink';
