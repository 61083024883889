import React from 'react';
import styles from '../../styles/components/modal.module.scss';
import { LegalFooter } from '../layout/page-footer';

export const ModalFooter = React.memo(({ html, children }) => {
  return (
    <LegalFooter className={styles['modal-footer']} html={html}>
      {children}
    </LegalFooter>
  );
});
