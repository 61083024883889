import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/widgets/feature-fit-v2/ff-kpi-list.module.scss';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';

const UpdateBlock = ({
  updateLabel,
  formattedDate,
  updateAction,
  updateLinkTitle,
  isUpdateLabelOff = false,
  buttonType
}) => {
  return (
    <div className={styles['update-container']}>
      <p className={classnames(styles['update-label'], { 'update-label-off': isUpdateLabelOff })}>
        {updateLabel}&nbsp;<b>{formattedDate}</b>
      </p>
      {updateAction ? (
        <ButtonWithIcon type={buttonType} onClick={updateAction}>
          {updateLinkTitle}
        </ButtonWithIcon>
      ) : null}
    </div>
  );
};

UpdateBlock.propTypes = {
  formattedDate: PropTypes.string.isRequired,
  updateLabel: PropTypes.string.isRequired,
  updateAction: PropTypes.func,
  updateLinkTitle: PropTypes.string,
  buttonType: PropTypes.string,
  isUpdateLabelOff: PropTypes.bool
};

export default UpdateBlock;
