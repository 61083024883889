import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../../styles/components/sticky.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { getLayer, getPosition } from '../../helpers/components/sticky';
import { SafeArea } from './safe-area';

/**
 * This is a component that will stick to the top or bottom of the screen.
 * It will also take into account the navigation bars and safe areas.
 * @param {string} renderAs - The element to render as
 * @param {string} placement - `top` or `bottom`
 * @param {boolean} nested
 * @param style - The style object
 * @param className
 * @param children
 * @param props
 * @param ref
 * @return {JSX.Element}
 */
export const StickyComponent = (
  { renderAs: Component = 'div', placement = 'top', nested = true, style = {}, className, children, ...props },
  ref
) => {
  const topNavigation = useSelector(({ layout }) => layout.sizes.topNavigation);
  const bottomNavigation = useSelector(({ layout }) => layout.sizes.bottomNavigation);

  const { zIndex = 0, top: topStyle = 0, bottom: bottomStyle = 0, ...outerStyles } = style;

  return (
    <Component
      ref={ref}
      className={classnames(styles.container, className)}
      style={{
        ...getLayer(zIndex, nested),
        ...getPosition({
          nested,
          placement,
          offset: {
            top: topStyle,
            bottom: bottomStyle
          },
          nav: {
            top: topNavigation,
            bottom: bottomNavigation
          },
          safearea: {
            top: '--sat',
            bottom: '--sab'
          }
        }),
        ...outerStyles
      }}
      {...props}
    >
      <SafeArea edges={[placement, 'left', 'right']} fixed>
        {children}
      </SafeArea>
    </Component>
  );
};

export const Sticky = withMemoAndRef(StickyComponent);
