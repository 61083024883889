import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sendContentStatus } from '../../store/reducers/content-interaction';
import { TYPE_TO_ACTIVITY_KEY } from '../content-interaction/constants';
import { getAdditionalDataByContentType } from '../content-interaction';
import { CARD_LOCATION_PAGE_TYPES } from './constants';
import { getAdditionalDataByPageType } from './index';

export const useRepeaterCardIntersection = ({ pageType, repeaterBlockName, pageActivityName, articleSlug }) => {
  const dispatch = useDispatch();

  const onCardInView = useCallback(
    ({ type, slug, ownKey, id, title }) => {
      const additionalData = getAdditionalDataByPageType({
        pageType,
        repeaterBlockName,
        articleSlug,
        pageActivityName,
        id
      });
      const contentData = getAdditionalDataByContentType({
        contentType: TYPE_TO_ACTIVITY_KEY[type],
        id,
        slug,
        title,
        key: ownKey ?? slug
      });

      const options = {
        activityKey: TYPE_TO_ACTIVITY_KEY[type],
        activityStatusKey: 'seenPost',
        data: {
          pageType: CARD_LOCATION_PAGE_TYPES[pageType],
          ...additionalData,
          ...contentData
        }
      };
      dispatch(sendContentStatus(options));
    },
    [dispatch, pageType, repeaterBlockName, pageActivityName, articleSlug]
  );

  return {
    onCardInView
  };
};
