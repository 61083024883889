import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { BugsnagService } from '../../../../../services/bugsnag';

const mapStateToProps = state => ({
  tracking: state.userAttributes.consents?.tracking,
  isDataSharingAllowed: state.consentList.dataSharingAllowed
});

export const withBugsnag = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      const { tracking, isDataSharingAllowed } = this.props;

      if (tracking && isDataSharingAllowed) {
        BugsnagService.initialize();
      }
    }
  })
);
