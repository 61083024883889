import { asTemplate } from '../components/layout/with-layout';
import { STATUS } from '../helpers/navigation';
import { StatusPage } from '../pages/status-page/index';

export const statusPageRoutes = [
  {
    to: '/status',
    component: asTemplate(STATUS)(StatusPage)
  }
];
