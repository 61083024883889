// Created to avoid memory leak using setState during componentDidMount with async data inside.
export const cancellablePromise = promise => {
  const isCancelled = { value: false };

  const wrappedPromise = (...args) =>
    new Promise((res, rej) => {
      promise(...args)
        .then(d => {
          if (!isCancelled.value) {
            return res(d);
          }
        })
        .catch(e => {
          rej(isCancelled.value ? isCancelled : e);
        });
    });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    }
  };
};
