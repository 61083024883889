import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isUndefined } from '@packages/helpers/core/common';
import { getStatusPlain } from '../custom-page/is-completed';

const widgetsToKeyValuePairedArray = (widgets = {}) => {
  return ObjectShim.entries(widgets).map(([key, { type, ...widget }]) => [isUndefined(type) ? key : type, widget]);
};
/**
 * Widget item
 * @typedef {array} WidgetItem
 * @property {string} type
 * @property {object} widget
 *
 * @param {object} widgets - list of widgets from config file
 * @return {Array<WidgetItem>}
 */
export const sortWidgets = (widgets = {}) => {
  const structuredWidgets = widgetsToKeyValuePairedArray(widgets);

  return structuredWidgets.sort(([_a, a], [_b, b]) => a.weight - b.weight);
};

export const mapWidgetTitle = (title, statuses = {}, coach) => {
  if (ObjectShim.isObject(title)) {
    if (coach.loading) {
      return '';
    }

    const activity = coach.nextActivity.activityKey;

    const titleByActivity = title[activity];

    if (ObjectShim.isObject(titleByActivity)) {
      const status = getStatusPlain(statuses[activity]);

      return title[activity][status] ?? title[activity].fallback;
    }

    return titleByActivity ?? title.fallback;
  }

  return title;
};
