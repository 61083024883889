import classnames from 'classnames';
import styles from '../grid.module.scss';

export const mapClassName = (
  ownClassName: string,
  extraClassName?: string,
  conditions?: Record<string, unknown>
): string => {
  return classnames(
    styles[ownClassName],
    conditions &&
      Object.keys(conditions).reduce((acc, key) => {
        return {
          ...acc,
          [styles[key]]: conditions[key]
        };
      }, {}),
    extraClassName?.split(' ').map(cn => styles[cn] ?? cn)
  );
};
