import { branch, compose, renderNothing, withProps } from 'recompose';
import { ConfigService } from '../../../services/config-service';
import { DigitalCoachWidget } from '../../../widgets/digital-coach-widget';
import { withDigitalCoachContainer } from '../../../widgets/digital-coach-widget/components/container';
import { withDelayedDigitalCoach } from '../../../widgets/digital-coach-widget/hocs/with-delayed-digital-coach';

export const DigitalCoach = compose(
  withProps(({ feedbacks, match, storyKey }) => {
    const { show } = ConfigService.get('VIDEOS.widgets.digitalCoach', {});

    return { show };
  }),
  branch(({ show }) => !show, renderNothing),
  withDigitalCoachContainer,
  withDelayedDigitalCoach
)(DigitalCoachWidget);
