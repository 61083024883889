import React, { useState } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import classnames from 'classnames';
import ReactSVG from 'react-svg';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import styles from '../../styles/components/affiliate-icon.module.scss';
import { getIconUrlByName } from '../../helpers/layout';
import { withButton } from './with-button';
import { withLink } from './with-link';

const appendSVGStyle = (svg, style) => {
  ObjectShim.entries(style).forEach(([prop, value]) => {
    svg.style[prop] = value;
  });
};

const appendAccessibleSVGAttributes = (svg, { ariaHidden, ariaDisabled, ariaLabel, role = 'img' }) => {
  svg.setAttribute('role', role);
  svg.setAttribute('aria-label', ariaLabel);
  svg.setAttribute('aria-disabled', ariaDisabled);
  svg.setAttribute('aria-hidden', ariaHidden);
};

const Fallback = React.memo(({ fallback, ...props }) =>
  fallback?.external ? <ReactSVG src={getIconUrlByName(fallback.external)} {...props} /> : fallback
);

const AffiliateIconComponent = React.memo(
  ({
    name,
    fallback = null,
    style = {},
    className,
    inline = false,
    ariaHidden,
    ariaDisabled,
    ariaLabel = name,
    role,
    ...rest
  }) => {
    const [hidden, setHidden] = useState(false);

    const onBeforeMount = svg => {
      appendSVGStyle(svg, style);

      appendAccessibleSVGAttributes(svg, { ariaHidden, ariaDisabled, ariaLabel, role });
    };

    const onMount = error => {
      if (error && !fallback) {
        setHidden(true);
      }
    };

    const onFallbackMount = error => {
      if (error) {
        setHidden(true);
      }
    };

    return (
      <ReactSVG
        src={getIconUrlByName(name)}
        className={classnames(styles.container, { [styles.inline]: inline, [styles.hidden]: hidden }, className)}
        fallback={() => (
          <Fallback fallback={fallback} beforeInjection={onBeforeMount} afterInjection={onFallbackMount} {...rest} />
        )}
        beforeInjection={onBeforeMount}
        afterInjection={onMount}
        {...rest}
      />
    );
  }
);

AffiliateIconComponent.displayName = 'AffiliateIconComponent';

export const AffiliateIcon = compose(
  withButton,
  withLink,
  withPropsOnChange(['inline'], ({ inline }) => ({
    wrapper: inline ? 'span' : 'div'
  }))
)(AffiliateIconComponent);

AffiliateIcon.displayName = 'AffiliateIcon';
