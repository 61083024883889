import dayjs from 'dayjs';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import {
  mabUserNameApostrophesRegex,
  mabUserNameExcludedRegex,
  mabUserNameRegex,
  mabUserNameRepeatedRegex,
  mabUserPhoneNumberRegex
} from '../../../helpers/regex';
import { DATE_FORMAT } from '../../../helpers/constants';

export const applyAdditionalRules = (type, currentValidation, rulesToApply) => {
  if (!additionalRules[type] || !ObjectShim.isObject(rulesToApply)) {
    return;
  }
  Object.keys(rulesToApply).forEach(key => {
    if (additionalRules[type][key]) {
      currentValidation.rule(value => {
        try {
          const data = rulesToApply[key];

          return additionalRules[type][key](value, data);
        } catch {
          return true;
        }
      }, rulesToApply[key].errorMessage);
    }
  });
};

const additionalRules = {
  date: {
    'date-of-birth': (value, { limit = 16, format = DATE_FORMAT }) => {
      const date = dayjs(value, format);
      const today = dayjs();

      return today.diff(date, 'year') >= limit;
    },
    'date-before-today': (value, { format = DATE_FORMAT }) => {
      const date = dayjs(value, format);
      const today = dayjs().subtract(1, 'day');

      return date.isBefore(today);
    },
    'date-in-one-year-from-today': (value, { format = DATE_FORMAT }) => {
      const date = dayjs(value.toString(), format);
      const today = dayjs();
      const oneYearFromToday = today.add(1, 'year');

      return date.isAfter(today) && date.isBefore(oneYearFromToday);
    }
  },
  phoneNumber: {
    'mab-phone-number': value => mabUserPhoneNumberRegex.test(value)
  },
  text: {
    'mab-user-name': value => {
      const userNameMatch = mabUserNameRegex.test(value);
      const userNameApostrophesMatch = mabUserNameApostrophesRegex.test(value);
      const userNameExcludedMatch = mabUserNameExcludedRegex.test(value);
      const userNameRepeatedMatch = mabUserNameRepeatedRegex.test(value);

      return userNameMatch && !userNameApostrophesMatch && !userNameExcludedMatch && !userNameRepeatedMatch;
    }
  }
};
