import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { ConfigService } from '../../../../services/config-service';
import { UserService } from '../../../../services/user-service';

import styles from '../../../../styles/components/application-header.module.scss';

export const InitialsComponent = ({ shortDisplayName }) => (
  <div className={styles.profile}>
    <span className={styles['profile-text']}>{shortDisplayName}</span>
  </div>
);

const mapStateToProps = ({ account }) => ({ shortDisplayName: account.shortDisplayName ?? 'LM' });

export const Initials = compose(
  connect(mapStateToProps, null),
  branch(
    ({ shortDisplayName }) =>
      !shortDisplayName ||
      !UserService.hasUser() ||
      !ConfigService.get('ACCOUNT.PAGES.MAIN.businessAccount.enabled', false),
    renderNothing
  )
)(InitialsComponent);
