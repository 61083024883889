import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Route as ReactRoute } from 'react-router';
import { Route } from '../../route';
import { NotFoundPage } from '../not-found-page';

export const Router = React.memo(({ routes }) => {
  const {
    NAVIGATION_BAR: { launchPage }
  } = window.CONFIG;

  return (
    <Switch>
      {routes.map(route => {
        const { to, exact } = route;

        return <Route key={to} exact={exact ? exact : true} path={to} route={route} />;
      })}
      <ReactRoute path='(/|/index.html|//index.html)' render={() => <Redirect to={launchPage} />} />
      <ReactRoute component={NotFoundPage} />
    </Switch>
  );
});
