import { asTemplate } from '../components/layout/with-layout';
import { VideoTemplate } from '../templates/video';
import { VIDEO } from '../helpers/navigation';

export const videoRoutes = [
  {
    to: '/videos/:slug',
    component: asTemplate(VIDEO)(VideoTemplate)
  }
];
