export const actions = {
  addSlot: 'add-slot',
  removeSlot: 'remove-slot'
};

export const initialState = {
  'top-navigation-left': null,
  'top-navigation-right': null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.addSlot: {
      const { name, ref } = action.payload;

      return {
        ...state,
        [name]: ref
      };
    }
    case actions.removeSlot: {
      const { name } = action.payload;

      return {
        ...state,
        [name]: null
      };
    }
    default:
      return state;
  }
};
