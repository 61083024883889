import { ConfigService } from '../../../services/config-service';

export const calculateLegalFees = propertyPrice => {
  try {
    const legalFees = ConfigService.get('DEFAULT_VALUES.LEGAL_FEES', []);

    const result = legalFees.find(item => {
      return propertyPrice < item.value;
    });

    return result?.average ?? legalFees[legalFees.length - 1].average;
  } catch {
    throw new Error('Calculate legal-page fees failed. Probably config value is missing');
  }
};
