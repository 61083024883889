import useFocusByHashEffect from '@packages/hooks/core/use-focus-by-hash-effect';
import React, { useRef } from 'react';
import classnames from 'classnames';
import { useMergeRefs } from '../helpers/hooks/use-merge-refs';
import styles from '../styles/components/template.module.scss';
import { withMemoAndRef } from '../helpers/hocs/with-memo-and-ref';
import { Grid, Column } from './layout/grid';
import { PurifiedHtmlContainer } from './layout/purified-html-container';
import { AspectRatioImage } from './images/aspect-ratio-image';
import { Loader, ProgressLoader } from './with-loader';

const TemplateComponent = ({ id = 'article', className, children, ...props }, ref) => {
  const staticRef = useRef(null);
  const mergedRef = useMergeRefs(ref, staticRef);
  useFocusByHashEffect({ id, ref: staticRef });

  return (
    <article id={id} tabIndex={-1} ref={mergedRef} className={classnames(styles.container, className)} {...props}>
      <Grid>{children}</Grid>
    </article>
  );
};

const Component = ({ children, classNames = {}, id, ...props }, ref) => {
  return (
    <Column ref={ref} id={id} {...props}>
      <div className={classnames(styles.component, classNames.component)}>{children}</div>
    </Column>
  );
};

const Image = ({ renderAs, edge, className, ...props }, ref) => (
  <Column ref={ref} renderAs={renderAs} className={classnames(styles.image, className)} edge={edge}>
    <AspectRatioImage {...props} />
  </Column>
);

const Body = ({ wrapperAs: Wrapper = Column, html, children, className, ...props }, ref) => {
  return (
    <Wrapper ref={ref} className={classnames(styles.body, 'typography typography-extended', className)} {...props}>
      <PurifiedHtmlContainer
        parse
        html={html}
        sanitizer={{
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allowfullscreen', 'frameborder']
        }}
      >
        {children}
      </PurifiedHtmlContainer>
    </Wrapper>
  );
};

export const Template = withMemoAndRef(TemplateComponent);
export const TComponent = withMemoAndRef(Component);
export const TImage = withMemoAndRef(Image);
export const TBody = withMemoAndRef(Body);

export const TSubHeading = React.memo(({ renderAs: SubHeading = 'p', children, className }) => (
  <SubHeading className={classnames(styles.subtitle, className)}>{children}</SubHeading>
));

export const THeading = React.memo(({ renderAs: Heading = 'h2', children, className }) => (
  <Heading className={classnames(styles.title, className)}>{children}</Heading>
));

export const THeadlineContainer = React.memo(({ renderAs: Component = 'div', children, className }) => (
  <Component className={classnames(styles.headline, className)}>{children}</Component>
));

export const THeadline = React.memo(
  ({ title, subtitle, className }) =>
    title && (
      <div className={className}>
        <THeadlineContainer>
          <THeading>{title}</THeading>
          {subtitle && <TSubHeading>{subtitle}</TSubHeading>}
        </THeadlineContainer>
      </div>
    )
);

export const TLoader = () => (
  <Column>
    <Loader />
  </Column>
);
export const LinearLoader = ({ progress }) => (
  <Column>
    <ProgressLoader progress={progress} />
  </Column>
);

export const withLoader = Component => props => {
  if (props.loading) {
    return <TLoader />;
  } else {
    return <Component {...props} />;
  }
};

export const TColumn = React.memo(({ children, className, ...props }) => (
  <Column className={classnames(styles.column, className)} {...props}>
    {children}
  </Column>
));
