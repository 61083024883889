import PropTypes from 'prop-types';
import { insertVarIntoString } from '../../helpers/insert-var-into-string';
import styles from '../../styles/components/progress-indicator.module.scss';

const StepIndicator = props => (
  <span className={styles.indicator}>
    {insertVarIntoString(props.indicatorLabel, { currentStep: props.pageNumber, totalSteps: props.pagesTotal })}
  </span>
);

StepIndicator.displayName = 'StepIndicator';

StepIndicator.propTypes = {
  pageNumber: PropTypes.number,
  pagesTotal: PropTypes.number,
  indicatorLabel: PropTypes.string
};

export { StepIndicator };
