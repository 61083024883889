import { useCallback, useRef } from 'react';

export const useMergeRefs = (...refs) => {
  const targets = useRef(refs);

  return useCallback(node => {
    targets.current.forEach(ref => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref !== null) {
        ref.current = node;
      }
    });
  }, []);
};
