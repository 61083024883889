import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { IS_PASSIVE_SUPPORTED, prevent, stop, Touch } from '../helpers/layout/event';
import { hasScroll, isScrollAtBottom, isScrollAtTop } from '../helpers/layout/scroll';
import { DEVICE } from '../helpers/user-agent';
import { setHandler, removeHandlers } from '../helpers/utils';

const DEFAULT_OPTIONS = ObjectShim.immutable(
  {
    target: null,
    className: 'frozen'
  },
  true
);

export class ScrollControl {
  constructor(options) {
    this.touch = null;
    this.state = { disabled: false, locked: false, tracked: false };

    this.options = Object.assign({}, DEFAULT_OPTIONS, options);

    this.bodyHandlers = {};
    this.targetHandlers = {};
    this.targetIOSHandlers = {};

    this.locks = {
      touchmove: {
        callback: this.handleTouchMove,
        options: IS_PASSIVE_SUPPORTED ? { passive: false } : false
      }
    };

    this.trackers = {
      touchstart: {
        callback: this.handleTouch
      },
      touchmove: {
        callback: this.handleScroll
      }
    };
  }

  handleTouch = event => {
    const { currentTarget: target } = event;

    if (Touch.isSingle(event)) {
      this.touch = event.targetTouches;

      this.targetIOSHandlers.touchend = setHandler(target, 'touchend', this.handleTouchEnd);
    }
  };

  handleTouchEnd = () => {
    this.touch = null;
    this.targetIOSHandlers = removeHandlers(this.targetIOSHandlers);
  };

  handleTouchMove = event => {
    if (!Touch.isSingle(event)) {
      return true;
    }

    if (event.cancelable) {
      return prevent(event);
    }
  };

  handleScroll = event => {
    if (this.touch && Touch.isSingle(event)) {
      const { currentTarget: target } = event;

      const [prevTouch] = this.touch;
      const [nextTouch] = event.targetTouches;

      const delta = nextTouch.clientY - prevTouch.clientY;

      if (isScrollAtTop(target) && delta > 0) {
        return prevent(event);
      }

      if (isScrollAtBottom(target) && delta < 0) {
        return prevent(event);
      }

      return stop(event);
    }
  };

  enableScroll() {
    const { disabled, locked, tracked } = this.state;
    const { className } = this.options;

    if (!disabled) {
      return false;
    }

    if (locked) {
      this.bodyHandlers = removeHandlers(this.bodyHandlers);
      this.state.locked = false;
    }

    if (tracked) {
      this.targetHandlers = removeHandlers(this.targetHandlers);
      this.state.escaped = false;
    }

    document.body.classList.remove(className);

    this.state.disabled = false;
  }

  disableScrollOnIOS(target) {
    ObjectShim.entries(this.locks).forEach(([type, { callback, options }]) => {
      this.bodyHandlers[type] = setHandler(window, type, callback, options);
    });

    this.state.locked = true;

    if (hasScroll(target)) {
      ObjectShim.entries(this.trackers).forEach(([type, { callback }]) => {
        this.targetHandlers[type] = setHandler(target, type, callback);
      });

      this.state.tracked = true;
    }
  }

  disableScroll(ref) {
    const { disabled } = this.state;

    if (disabled) {
      return false;
    }

    if (DEVICE.IOS()) {
      PromiseShim.queueMicrotask(() => {
        const { target } = this.options;

        this.disableScrollOnIOS(ref ?? target);
      });
    } else {
      const { className } = this.options;

      document.body.classList.add(className);
    }

    this.state.disabled = true;
  }
}
