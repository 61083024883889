import { parseJwt } from '../helpers/parse-jwt';
import { USER_SERVICE_KEYS, UserService } from '../services/user-service';
import { users } from '../api/users';
import { BugsnagService } from '../services/bugsnag';
import { createShadowUser } from './reducers/account/shadow-user-actions';
import { getUser } from './reducers/account/get-user-action';

/* eslint-disable no-console*/
/**
 * @description Encapsulates action creation logic and delayed requests management.
 */
export class ActionCreator {
  /**
   * Manages delayed requests in case 401 response.
   * isDelayed - flag that indicates if there are any requests that try to refresh user token/shadowId, and we need to delay concurrent requests.
   * delayedFunctions - set of delayed functions that will be executed after the first successful request.
   */
  static isDelayed = false;
  static delayedFunctions = new Set();

  /**
   * Manages success API response.
   */
  static handleSuccess(dispatch, successCase, payload, params) {
    dispatch({ type: successCase, payload, params });
    return payload;
  }

  /**
   * Manages delayed requests in case 401 response.
   */
  static async handleDelayedRequests() {
    ActionCreator.isDelayed = false;

    for (const delayedFunction of ActionCreator.delayedFunctions) {
      ActionCreator.delayedFunctions.delete(delayedFunction);

      await delayedFunction();
    }
  }

  /**
   * Manages all API errors, except 401 status codes.
   */
  static handleFailure(dispatch, failureCase, payload, response, params) {
    const errorMsg = payload.message;
    const errorCode = response.status;
    dispatch({ type: failureCase, errorMsg, params, errorCode });
    console.log(`[API] Request failed with status: ${errorCode} and message: ${errorMsg}`);
    return { errorMsg, errorCode };
  }

  /**
   * Resets the user session data by logging out if the user is currently logged in,
   * or deletes user data if no user is logged in.
   * Forces window reloading - to define new shadowUserId.
   */
  static resetUserSession() {
    if (UserService.hasUser()) {
      UserService.logout();
    } else {
      UserService.delete();
    }

    return window.location.reload();
  }

  /**
   * Any error during execution is expected to be handled outside this function.
   */
  static async refreshSession({ email, currentRefreshToken }) {
    const sessionResponse = await users.refreshSession({ refreshToken: currentRefreshToken, email });
    const payload = await sessionResponse.json();
    const { session, message } = payload;

    if (sessionResponse.ok) {
      const { jwtToken: token, accessToken, refreshToken } = session;

      UserService.setTokens({ token, accessToken, refreshToken });
    } else {
      throw new Error(message);
    }
  }

  /** Manages 401 status error codes. */
  static async handle401Error(dispatch, getState, types, method, params, payload, api) {
    if (payload.message === 'Token has expired') {
      console.log('[US] Token has expired. Trying to refresh the session and retry.');

      try {
        const email = getState().account.email || (await parseJwt(UserService.getToken()).email);
        const currentRefreshToken = UserService.getItem(USER_SERVICE_KEYS.REFRESH_TOKEN);
        await ActionCreator.refreshSession({ email, currentRefreshToken });

        /** Retry origin request with a refreshed token.*/
        return await ActionCreator.createAction(types, method)(params)(dispatch, getState, { api });
      } catch (refreshSessionError) {
        console.log('[US] Failed to refresh the session. Log out user.', { refreshSessionError });

        return ActionCreator.resetUserSession();
      }
    } else if (payload.message === 'Token is invalid') {
      console.log('[US] Token is invalid. Log out user.');

      return ActionCreator.resetUserSession();
    } else {
      /** Here we assume that User has wrong/cursed shadowUserId.*/
      console.log('[US] Wrong shadow user. Trying to reset shadowId and retry.');

      UserService.removeShadowUser();

      if (!UserService.hasUser()) {
        await dispatch(createShadowUser());
      } else {
        await dispatch(getUser());
      }

      /**
       * Retry origin request without any shadowUserId (BE will assign a correct one)
       * or with a brand new generated shadowUserId.
       */
      return await ActionCreator.createAction(types, method)(params)(dispatch, getState, { api });
    }
  }

  /**
   * @description Creates an action that handles all API requests and responses.
   * @description Schema: https://drive.google.com/file/d/1UeQhPUX6vilK0FGfR7O_wAcRtRumMLxL/view?usp=sharing
   */
  static createAction(types, method, skipDelayedHandling = false) {
    return params =>
      async (dispatch, getState, { api }) => {
        const { request: requestCase, success: successCase, failure: failureCase } = types;
        try {
          if (ActionCreator.delayedFunctions.size > 0 && !skipDelayedHandling) {
            await ActionCreator.handleDelayedRequests();
          }

          if (requestCase) {
            dispatch({ type: requestCase, params });
          }

          /** Here we make an API request and handle the response. */
          const response = await method(api)(params);
          const payload = await response.json();

          if (response.ok) {
            return ActionCreator.handleSuccess(dispatch, successCase, payload, params);
          } else {
            if (response.status === 401 && !UserService.hasAccount()) {
              /**
               * If concurrent requests are delayed, we add a new request to the delayedFunctions set,
               * which will retry the origin request after the first successful one.
               */
              if (ActionCreator.isDelayed) {
                ActionCreator.delayedFunctions.add(() =>
                  ActionCreator.createAction(types, method, true)(params)(dispatch, getState, { api })
                );
                return;
              }
              /**
               * If the first request gets a 401 response, we set the isDelayed flag to true and delay all concurrent requests.
               */
              ActionCreator.isDelayed = true;

              return ActionCreator.handle401Error(dispatch, getState, types, method, params, payload, api);
            }
            return ActionCreator.handleFailure(dispatch, failureCase, payload, response, params);
          }
        } catch (error) {
          console.error('[API] Request failed with error:', error);
          dispatch({ type: failureCase, errorMsg: error.message, params });
          BugsnagService.notify(error);

          return { errorMsg: error.message };
        }
      };
  }
}
/* eslint-enable no-console*/
