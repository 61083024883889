import React from 'react';
import PropTypes from 'prop-types';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { sortByWeight } from '../../../../../helpers/array';
import { withLoadingHandlers } from '../../../../../components/with-loader';
import { Input } from '../../../input';

const PreSubmitButtonComponent = ({ type, data, ...props }) => <Input type={type} data={data} {...props} />;

const PreSubmitButton = withLoadingHandlers()(PreSubmitButtonComponent);

PreSubmitButton.propTypes = {
  type: PropTypes.oneOf(['infoButton', 'button']).isRequired,
  data: PropTypes.object.isRequired
};

PreSubmitButton.displayName = 'PreSubmitButton';

const PreSubmitButtonController = React.memo(({ preSubmitButtonConfig }) => {
  if (!ObjectShim.isObject(preSubmitButtonConfig)) {
    return null;
  }

  //For existing configurations that are not updated yet
  if (preSubmitButtonConfig.type) {
    return <PreSubmitButton data={preSubmitButtonConfig} type={preSubmitButtonConfig.type} />;
  }

  return sortByWeight(Object.values(preSubmitButtonConfig)).map((config, key) => (
    <PreSubmitButton type={config.type} data={config} key={key} />
  ));
});

export const withPreSubmitButton = Component =>
  React.memo(({ preSubmitButton = {}, ...props }) => (
    <>
      <Component {...props} />
      <PreSubmitButtonController preSubmitButtonConfig={preSubmitButton} />
    </>
  ));
