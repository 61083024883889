import { asTemplate } from '../components/layout/with-layout';
import { StoryTemplate } from '../templates/story';
import { STORY } from '../helpers/navigation';

export const storiesRoutes = [
  {
    to: '/stories/:slug',
    component: asTemplate(STORY)(StoryTemplate)
  }
];
