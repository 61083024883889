import React from 'react';
import classnames from 'classnames';
import { checkIfSlickDisabledClassExist } from '../../../helpers/advert/advert';
import styles from '../../../styles/components/card-slider.module.scss';
import { Slot } from '../../slots';
import { AffiliateIcon } from '../../icons/affiliate-icon';

export function SampleArrow(props) {
  const { className, style, onClick, slotName, name } = props;

  const disabled = checkIfSlickDisabledClassExist(className);

  return (
    <Slot name={slotName} className={className}>
      <AffiliateIcon
        name={name}
        onClick={onClick}
        ariaDisabled={disabled}
        className={styles.button}
        style={{ ...style }}
      />
    </Slot>
  );
}

export function SampleStoryNextArrow(props) {
  const { className, style, onClick } = props;
  const disabled = checkIfSlickDisabledClassExist(className);

  return (
    <AffiliateIcon
      name='next-slide'
      onClick={onClick}
      ariaDisabled={disabled}
      className={classnames(styles.button, styles['story-slick-arrow'], styles.next)}
      style={{ ...style }}
    />
  );
}

export function SampleStoryPrevArrow(props) {
  const { className, style, onClick } = props;
  const disabled = checkIfSlickDisabledClassExist(className);

  return (
    <AffiliateIcon
      name='prev-slide'
      onClick={onClick}
      ariaDisabled={disabled}
      className={classnames(styles.button, styles['story-slick-arrow'], styles.prev)}
      style={{ ...style }}
    />
  );
}
