import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import React, { useEffect, useRef, useState, Ref, PropsWithChildren, SyntheticEvent, KeyboardEvent } from 'react';
import { ScrollContainer } from '../scroll-container';

import styles from './styles.module.scss';

export type ChangeCallback<V> = {
  (item: Item<V>): void;
};

export type Item<V extends unknown> = {
  id: string | number;
  label: string;
  disabled?: boolean;
  value: V;
};

type MenuListItemProps = PropsWithChildren<{
  index: number;
  focused: boolean;
  selected: boolean;
  disabled?: boolean;
  onClick: (event: SyntheticEvent) => void;
  className?: string;
}>;

type MenuListProps = PropsWithChildren<{
  loading?: boolean;
  headerElement?: JSX.Element;
  className?: string;
  closeButtonElement?: JSX.Element;
}>;

export type UseMenuListProps<V> = {
  items: Item<V>[];
  value?: Item<V>;
  onChange?: ChangeCallback<V>;
};

type UseMenuListReturnType<V> = {
  visible: boolean;
  open: (event: SyntheticEvent) => void;
  close: (event: SyntheticEvent) => void;
  click: (item: Item<V>) => (event: SyntheticEvent) => void;
  change: (event: KeyboardEvent) => void;
  isFocused: (item: number) => boolean;
  isSelected: (item: Item<V>) => boolean;
  MenuList: typeof MenuList;
  MenuListItem: typeof MenuListItem;
};

const useFocus = <E extends HTMLElement>(focused: boolean): Ref<E> => {
  const ref = useRef<E>(null);

  useEffect(() => {
    const item = ref.current;

    if (item && focused) {
      const {
        parentElement: list,
        clientHeight: itemHeight,
        dataset: { index }
      } = item;

      if (list) {
        const { clientHeight: listHeight, scrollTop } = list;

        // item's position related to the top of the list;
        const offsetTop = itemHeight * Number(index);

        // scroll list up if item is out of view
        if (offsetTop < Math.floor(scrollTop)) {
          // Safari throwing an error if scrollTop is less then 0;
          list.scrollTop = NumberShim.inRange(offsetTop - listHeight + itemHeight, { min: 0 });
        }

        // scroll list down if item is out of view
        if (offsetTop + itemHeight > Math.ceil(scrollTop) + listHeight) {
          list.scrollTop = offsetTop;
        }
      }
    }
  }, [focused]);

  return ref;
};

export const MenuListItem = ({
  index,
  focused,
  selected,
  disabled,
  onClick,
  children,
  className
}: MenuListItemProps): JSX.Element => {
  const ref = useFocus<HTMLLIElement>(focused);

  const onMouseDown = (event: React.MouseEvent<HTMLLIElement>) => {
    return event.preventDefault();
  };

  return (
    <li
      ref={ref}
      role='option'
      data-index={index}
      data-focused={focused}
      aria-selected={selected}
      aria-disabled={disabled}
      className={className}
      onClick={onClick}
      onMouseDown={onMouseDown}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </li>
  );
};

export const MenuList = ({
  loading = false,
  className,
  children,
  closeButtonElement,
  headerElement
}: MenuListProps): JSX.Element => (
  <>
    {(headerElement || closeButtonElement) && (
      <div className={styles.heading}>
        {headerElement}
        {closeButtonElement}
      </div>
    )}
    <ScrollContainer as='ul' role='listbox' data-loading={loading} className={className}>
      {children}
    </ScrollContainer>
  </>
);

export const useMenuList = <V extends unknown>({
  items = [],
  value,
  onChange
}: UseMenuListProps<V>): UseMenuListReturnType<V> => {
  const [visible, show] = useState(false);
  const [focusedIndex, focus] = useState<number | undefined>();

  const selectedId = value?.id;

  const open = (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    show(true);
  };

  const close = (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    show(false);
  };

  const click = (item: Item<V>) => {
    const { disabled } = item;

    return (event: SyntheticEvent) => {
      if (disabled) {
        return void 0;
      }

      if (onChange) {
        onChange(item);
      }

      return close(event);
    };
  };

  const handleArrowKey: (key: string, event: KeyboardEvent) => void = (key, event) => {
    open(event);

    if (items.length) {
      if (key === 'ArrowUp') {
        focus((index = 0) => (index > 0 ? index - 1 : items.length - 1));
      }

      if (key === 'ArrowDown') {
        focus((index = 0) => (index < items.length - 1 ? index + 1 : 0));
      }
    }
  };

  const handleEnterKey = (event: KeyboardEvent) => {
    const item = focusedIndex !== void 0 && items[focusedIndex];

    if (item) {
      click(item)(event);
    }
  };

  const change = (event: KeyboardEvent) => {
    const { key } = event;

    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
        handleArrowKey(key, event);
        break;
      case 'Escape':
        close(event);
        break;
      case 'Enter':
        handleEnterKey(event);
        break;
      default:
        break;
    }
  };

  const isFocused = (index: number): boolean => {
    return index === focusedIndex;
  };

  const isSelected = (item: Item<V>): boolean => {
    return item?.id === selectedId;
  };

  useEffect(() => {
    focus(items.findIndex(({ id }) => id === selectedId));

    return () => {
      focus(void 0);
    };
  }, [items, selectedId]);

  return {
    visible,
    open,
    close,
    click,
    change,
    isFocused,
    isSelected,
    MenuList,
    MenuListItem
  };
};
