import React from 'react';
import { compose, withState } from 'recompose';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import styles from '../../../styles/widgets/digital-coach-widget/poll-bar.module.scss';
import ProgressBar from '../../../components/progress-bar';

export const Component = React.memo(({ winner, label, percentage, displayPercentage }) => (
  <div className={styles.container}>
    <p className={styles.value}>{displayPercentage}%</p>
    <ProgressBar className={classnames(styles.bar, { [styles.winner]: winner })} value={percentage} label={label} />
  </div>
));

const PollBarRow = compose(
  withState('displayPercentage', 'setDisplayPercentage', ({ count, totalCount }) =>
    NumberShim.percentOf(count, totalCount)
  ),
  withState('percentage', 'setPercentage', ({ count, maxCount }) => NumberShim.percentOf(count, maxCount)),
  withState('winner', 'setWinner', ({ count, maxCount }) => maxCount === count)
)(Component);

PollBarRow.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  maxCount: PropTypes.number,
  totalCount: PropTypes.number
};

export default PollBarRow;
