import dayjs from 'dayjs';
import { compose } from 'redux';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ConfigService } from '../../services/config-service';
import { withQueryParams, editMode } from '../navigation/routing';
import { getRoutesByPathname } from '../utils';
import { formatValueToPercent } from '../formatters';
import { toMoneyStringWithK, toMoneyStringWithComma } from '../formatters/money-format';
import { prettifyName } from '../../pages/question/helpers/prettify-name';
import { PROPERTY } from '../navigation';
import { DATE_FORMAT } from '../constants';
import { getDateFromString } from '../time-format';

const PROPERTY_ID = 'manual-property';
const PROPERTY_CURRENT_MORTGAGE_ID = 'mortgage';

const ALTERNATIVE_MORTGAGE_SVR_KEY = 'altmortagesvr';
const ALTERNATIVE_MORTGAGE_SAVING_KEY = 'altmortageamountSaved';

const MORTGAGE_TO_PROPERTY_SEPARATOR = '_';
const INCREMENT_SEPARATOR = '-';

export const createPropertyId = properties => prettifyName(PROPERTY_ID, properties, INCREMENT_SEPARATOR);
export const createMortgageId = (propertyId, mortgages) =>
  prettifyName(
    `${propertyId}${MORTGAGE_TO_PROPERTY_SEPARATOR}${PROPERTY_CURRENT_MORTGAGE_ID}`,
    mortgages,
    INCREMENT_SEPARATOR
  );

export const parsePropertyId = mortgageId =>
  mortgageId?.split(`${MORTGAGE_TO_PROPERTY_SEPARATOR}${PROPERTY_CURRENT_MORTGAGE_ID}`)[0];

const PROPERTY_ID_PATHNAME_INDEX = 3;

export const parsePropertyIdFromPathname = (pathname = '') => {
  return getRoutesByPathname(pathname)[PROPERTY_ID_PATHNAME_INDEX];
};

export const getPropertyRoute = (propertyId, isEditing) => {
  let pathname = ConfigService.get('PROPERTY_PORTFOLIO.ENTRY_POINTS.PROPERTY_FLOW');

  if (!pathname) {
    return '';
  }

  if (!propertyId) {
    return pathname;
  }

  pathname = `${pathname}/${propertyId}`;

  if (isEditing) {
    return editMode(pathname);
  }

  return pathname;
};

export const getCurrentMortgageRoute = ({ propertyId, mortgageId, isEditing }) => {
  let pathname = ConfigService.get('PROPERTY_PORTFOLIO.ENTRY_POINTS.MORTGAGE_FLOW');

  if (!pathname) {
    return '';
  }

  if (propertyId) {
    return withQueryParams(pathname, { propertyId });
  }

  if (mortgageId) {
    return isEditing ? editMode(`${pathname}/${mortgageId}`) : `${pathname}/${mortgageId}`;
  }

  return pathname;
};

export const getPropertyDetailsRoute = propertyId => {
  if (!propertyId) {
    return '';
  }

  return `/${PROPERTY}/${propertyId}`;
};

const extendedValueToPercent = value => formatValueToPercent(value, 2);

const FORMATS_MAP = {
  percent: extendedValueToPercent,
  money: toMoneyStringWithComma,
  'money-short': toMoneyStringWithK
};

export const calculateTotalValuation = (properties = []) => {
  return properties.reduce((valuation, { value = 0 }) => (valuation += Number(value)), 0);
};

export const mapByDictionaryRule =
  (entity, faq = {}) =>
  ({ key, title, format, extra, type, row, icon }) => {
    const value = entity[key];
    const formatter = FORMATS_MAP[format];

    return {
      title,
      value: value ? (formatter ? formatter(value) : value) : null,
      accessor: key,
      extra,
      type,
      row,
      icon,
      faqDetails: faq[key] ?? null
    };
  };

export const mapItemsBy = (items, mapper) => keys => {
  return keys.map(id => {
    const item = { id, ...items[id] };

    return FunctionShim.isFunction(mapper) ? mapper(item) : item;
  });
};

export const getProperties = (items, mapper) => {
  return compose(mapItemsBy(items, mapper), Object.getOwnPropertyNames)(items);
};

export const getMortgagesByPropertyId = id => items =>
  items.filter(key => key.split(`${MORTGAGE_TO_PROPERTY_SEPARATOR}${PROPERTY_CURRENT_MORTGAGE_ID}`)[0] === id);

export const mapMortgagesByPropertyId = id => (items, mapper) => {
  return compose(mapItemsBy(items, mapper), getMortgagesByPropertyId(id), Object.getOwnPropertyNames)(items);
};

export const getAlternativeMortgages = (items, mapper) => {
  return compose(mapItemsBy(items, mapper), Object.keys)(items);
};

export const getBiggestMortgageTerm = mortgages =>
  mortgages.reduce((prev, curr) => (prev?.term > curr?.term ? prev : curr));

export const getMaxMortgageSavings = mortgages =>
  mortgages.reduce((prev, curr) => {
    return prev < curr?.savings ? curr.savings : prev;
  }, 0);

export const getBestAlternativeMortgage = mortgages => {
  return mortgages.reduce((prev, curr) =>
    prev?.altmortagemonthRepayment < curr?.altmortagemonthRepayment ? prev : curr
  );
};

export const calculateAlternativeMortgagesValues = (data, mortgageList) => {
  const biggestMortgage = getBiggestMortgageTerm(mortgageList);
  const biggestMortgageTerm = Number(biggestMortgage?.term) * 12;

  const biggestSavings = getMaxMortgageSavings(mortgageList);

  return data.map(item => {
    const altmortagesvr = biggestMortgageTerm - Number(item.altmortageinitialPeriodmonths);
    return {
      ...item,
      [ALTERNATIVE_MORTGAGE_SVR_KEY]: altmortagesvr.toString(),
      [ALTERNATIVE_MORTGAGE_SAVING_KEY]: biggestSavings.toString()
    };
  });
};

export const rearrangeAlternativeMortgageDeal = altMortgageDescription => {
  const highlights = [],
    dataFacts = [];
  altMortgageDescription.forEach(currItem => {
    if (currItem.type === 'highlights') {
      highlights.push(currItem);
    }
    if (currItem.type === 'dataFacts' && typeof currItem.row === 'number') {
      dataFacts[currItem.row] ? dataFacts[currItem.row].push(currItem) : (dataFacts[currItem.row] = [currItem]);
    }
  });

  return { highlights, dataFacts };
};

export const mortgageWithPED = mortgage => {
  if (mortgage.mortgagePed) return mortgage;

  if (mortgage.mortgageStartDate && mortgage.mortgagePedTerm) {
    const date = getDateFromString(mortgage.mortgageStartDate, {
      input: 'DD/MM/YYYY'
    });
    mortgage.mortgagePed = dayjs(date).add(mortgage.mortgagePedTerm, 'years').format(DATE_FORMAT);
  }

  return mortgage;
};
