import { ActionCreator } from './action-creator';

export const createReducer =
  (initialState = null, actions = {}) =>
  (state = initialState, action) => {
    if (actions.hasOwnProperty(action.type)) {
      return actions[action.type](state, action);
    }

    return state;
  };

export const createAction = ActionCreator.createAction;
