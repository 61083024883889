import React from 'react';
import { branch, compose, renderNothing, withProps } from 'recompose';
import styles from '../styles/components/screen-reader-description.module.scss';

export const EXTERNAL_LINK_TEXT = 'Link opens in a new window';

/**
 * It's purpose in adding an external text to the announcement in case user provides a custom aria-label.
 * @param ariaLabel {string | undefined}
 * @param isExternal {boolean}
 * @return {string | undefined}
 */
export const combineAriaLabel = ({ ariaLabel = '', isExternal }) => {
  return isExternal ? `${ariaLabel} ${EXTERNAL_LINK_TEXT}` : ariaLabel;
};

export const withCombinedAriaLabel = compose(
  withProps(({ children, ariaLabel, isExternal }) => ({
    ariaLabel: combineAriaLabel({ ariaLabel, isExternal })
  }))
);

const ScreenReaderHiddenText = React.memo(({ text }) => <span className={styles.text}>{text}</span>);

export const ExternalLinkHiddenDescription = compose(branch(({ text }) => !text, renderNothing))(
  ScreenReaderHiddenText
);
