import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/widgets/virgin-money/vm-plan-heading.module.scss';
import { toMoneyStringWithComma } from '../../../helpers/formatters/money-format';
import { timeframeToString } from '../../../helpers/time-format';
import { Body, SubHeading, Icon } from '../../../components/layout/page-header/components';

const HeadingVMPlanComponent = React.memo(({ goal }) => {
  const {
    PAGE_HEADER: { ICONS }
  } = window.CONFIG;

  return (
    <>
      <Icon name={ICONS.VM_PLAN} />
      <Body>
        <SubHeading>To buy a {goal.bedroomsQuantity} bed property worth</SubHeading>
        <SubHeading className={styles.value}>{toMoneyStringWithComma(goal.propertyPrice)}</SubHeading>
        <SubHeading>
          Located in {goal.goalsLocation} in {timeframeToString(goal.timeframe)} time
        </SubHeading>
      </Body>
    </>
  );
});

const mapStateToProps = state => ({
  goal: state.userAttributes.goal
});

export const HeadingVMPlan = connect(mapStateToProps)(HeadingVMPlanComponent);
