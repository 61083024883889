import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { setNavThemedPages } from '../../store/reducers/navigation';
import { getAffiliateRelatedStaticData } from '../../store/reducers/static-data';
import { getStatusData } from '../../store/reducers/status-data';
import { setGlobalConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { UserService } from '../../services/user-service';
import { MarketingInfoService } from '../../services/marketing-info-serice';
import { getConsentList } from '../../store/reducers/consent-list';
import { getValidations } from '../../store/reducers/validations';
import { getUserAttributes } from '../../store/reducers/user-attributes/actions';

export const withEmbeddedApp = compose(
  connect(null, {
    setNavThemedPages,
    getAffiliateRelatedStaticData,
    getStatusData,
    getConsentList,
    getValidations,
    getUserAttributes
  }),
  lifecycle({
    async componentDidMount() {
      const {
        turnOffLoading,
        setNavThemedPages,
        processUserData,
        location,
        getAffiliateRelatedStaticData,
        getStatusData,
        getConsentList,
        getValidations,
        getUserAttributes
      } = this.props;

      const response = await processUserData();

      await setGlobalConfig(response?.introducerCode);

      NativeAction.initialize();

      setNavThemedPages();

      const promises = [
        getConsentList(location),
        getAffiliateRelatedStaticData(),
        getStatusData(),
        getValidations(),
        getUserAttributes()
      ];

      UserService.track();

      await PromiseShim.allSettled(promises);

      turnOffLoading();
    },
    componentDidUpdate() {
      const { location } = this.props;

      MarketingInfoService.processURLAttributes(location.search);
    }
  })
);
