import { createAction, createReducer } from '../utils';

const GET_VALIDATIONS_REQUEST = 'GET_VALIDATIONS_REQUEST';
const GET_VALIDATIONS_SUCCESS = 'GET_VALIDATIONS_SUCCESS';
const GET_VALIDATIONS_FAILURE = 'GET_VALIDATIONS_FAILURE';

export const getValidations = () =>
  createAction(
    {
      request: GET_VALIDATIONS_REQUEST,
      success: GET_VALIDATIONS_SUCCESS,
      failure: GET_VALIDATIONS_FAILURE
    },
    api => api.userAttributes.getUserAttributesSettings
  )();

export const validations = createReducer(
  { validations: {}, loading: true, error: false },
  {
    [GET_VALIDATIONS_REQUEST]: state => ({ ...state, loading: true }),
    [GET_VALIDATIONS_SUCCESS]: (state, action) => ({
      ...state,
      validations: splitByUserAttribute(action.payload),
      loading: false
    }),
    [GET_VALIDATIONS_FAILURE]: state => ({ ...state, error: true, loading: true })
  }
);

const splitByUserAttribute = data => {
  let result = {};

  Object.keys(data).forEach(item => {
    const categoryName = item.split('.')[0];
    const activityName = item.split('.')[1];

    result = {
      ...result,
      [categoryName]: {
        ...result[categoryName],
        [activityName]: data[item]
      }
    };
  });

  return result;
};
