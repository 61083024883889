import React from 'react';
import { branch, componentFromProp, compose, mapProps, renderNothing } from 'recompose';
import styles from '../../../styles/widgets/story-step/widget.module.scss';
import { StoryFeedback } from './widgets/feedback';
import { DigitalCoach } from './widgets/digital-coach';

const widgets = {
  StoryFeedback: StoryFeedback,
  DigitalCoach: DigitalCoach
};

const withWidgetsWrapper = Component => props =>
  (
    <div className={styles.wrapper}>
      <Component {...props} />
    </div>
  );

export const WidgetComponent = compose(
  mapProps(props => ({ ...props, component: widgets[props.widget] })),
  branch(({ component }) => !component, renderNothing),
  withWidgetsWrapper
)(componentFromProp('component'));
