import { isNil } from '@packages/helpers/core/common';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';

// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-additional-values';

const worker = workerBase();

const parseValues = async (values, params = {}) => {
  const acc = {};

  for (const key of Object.keys(values)) {
    //if value is not valid - it will not be added
    try {
      const evaluated = await worker.evaluate(values[key], params);

      if (!isNil(evaluated)) {
        acc[key] = evaluated;
      }
    } catch {}
  }

  return acc;
};

export const processAdditionalValues = async (userAttributes, value, extraParams) => {
  try {
    const normalized = ArrayShim.normalize(value, 'userAttributeType');
    const { identifier } = extraParams;

    const params = { userAttributes, ...extraParams };
    const acc = {};
    for (const key of Object.keys(normalized)) {
      const name = key.split('.')[1];
      if (name && identifier) {
        const defaultKey = `${key}.${identifier}`;

        acc[defaultKey] = await parseValues(normalized[key].userAttributeValues, params);
        continue;
      }
      acc[key] = await parseValues(normalized[key].userAttributeValues, params);
    }

    return acc;
  } catch {
    return false;
  }
};
