import { compose } from 'redux';
import { lifecycle, withHandlers, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import {
  SET_PAGE_STATE_INITIAL,
  SET_WIDGET_STATE_INITIAL,
  SET_WIDGET_STATE_POSTPONED,
  SET_WIDGET_STATE_STATIC_SUCCESS,
  setNativeLoadingState
} from '../../../store/reducers/application';
import { checkPageInitialization, createPageIdentifier } from '../../../helpers/reducers/application';
import { withHistory } from '../../with-history';

const MAXIMUM_LOADING_TIME = 10000;

const mapStateToProps = state => ({
  loadingState: state.application.loadingState,
  isNativeTriggerSent: state.application.isNativeTriggerSent
});

const mapDispatchToProps = { setNativeLoadingState };

export const withWidgetLoadingHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    setWidgetLoadedState:
      ({ pageIdentifier, widgetIdentifier, isPageInitialized, order, setNativeLoadingState, isNativeTriggerSent }) =>
      (type, loadingState) => {
        if (isPageInitialized || isNativeTriggerSent) return;

        setNativeLoadingState(type, { pageIdentifier, widgetIdentifier, loadingState, order });
      }
  })
);

export const withInitialWidgetLoadingLifecycle = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['loadingState'], ({ loadingState, pageIdentifier }) => ({
    isPageInitialized: checkPageInitialization(loadingState, pageIdentifier)
  })),
  withWidgetLoadingHandlers,
  withState('timeoutId', 'setTimeoutId', 0),
  lifecycle({
    componentDidMount() {
      const { isWidgetHidden, setWidgetLoadedState, setTimeoutId, timeoutId } = this.props;

      if (!isWidgetHidden) {
        setWidgetLoadedState(SET_WIDGET_STATE_INITIAL, false);
        clearTimeout(timeoutId);
      }

      const postponedId = setTimeout(
        () => setWidgetLoadedState(SET_WIDGET_STATE_POSTPONED, true),
        MAXIMUM_LOADING_TIME
      );
      setTimeoutId(postponedId);
    },
    componentWillUnmount() {
      const { timeoutId } = this.props;

      clearTimeout(timeoutId);
    }
  })
);

export const withStaticWidgetLoadingLifecycle = ({ condition = true }) =>
  compose(
    withWidgetLoadingHandlers,
    lifecycle({
      componentDidMount() {
        const { setWidgetLoadedState } = this.props;
        if (condition) {
          setWidgetLoadedState(SET_WIDGET_STATE_STATIC_SUCCESS, true);
        }
      }
    })
  );

const withPageLoadingHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['location'], ({ location }) => ({ pageIdentifier: createPageIdentifier(location) })),
  withHandlers({
    setPageLoadedState:
      ({ pageIdentifier, isPageInitialized, setNativeLoadingState, isNativeTriggerSent }) =>
      type => {
        if (isPageInitialized || isNativeTriggerSent) return;

        setNativeLoadingState(type, { pageIdentifier });
      }
  })
);

export const withPageLoadingLifecycle = compose(
  connect(mapStateToProps, { mapDispatchToProps }),
  withHistory,
  withPropsOnChange(['location'], ({ location }) => ({ pageIdentifier: createPageIdentifier(location) })),
  withPropsOnChange(['loadingState'], ({ loadingState, pageIdentifier }) => ({
    isPageInitialized: checkPageInitialization(loadingState, pageIdentifier)
  })),
  withPageLoadingHandlers,
  lifecycle({
    componentDidMount() {
      const { setPageLoadedState } = this.props;

      setPageLoadedState(SET_PAGE_STATE_INITIAL);
    }
  })
);

export { mapStateToProps as applicationLoadingState, mapDispatchToProps as applicationLoadingActions };
