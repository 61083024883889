import React, { forwardRef, ElementType, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { useScrollControl } from '@packages/hooks/core/use-scroll-control';
import { useMergeRefs } from '@packages/hooks/core/use-merge-refs';

import styles from './scroll-container.module.scss';

type OwnProps = {
  at?: 'x' | 'y' | 'both';
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
};

export type Props<Element extends ElementType> = PolymorphicComponentProps<Element, OwnProps>;

const defaultElement = 'div';

const ScrollContainerComponent = <T extends ElementType = typeof defaultElement>(
  { className, children, at = 'both', disabled = false, ...props }: Props<T>,
  ref: typeof props.ref
): JSX.Element => {
  const { anchor } = useScrollControl<typeof props.as>(disabled);

  return (
    <Box
      ref={useMergeRefs(ref, anchor)}
      as={defaultElement}
      className={classnames(styles.container, styles[`at-${at}`], className)}
      {...props}
    >
      {children}
    </Box>
  );
};

export const ScrollContainer: <T extends ElementType = typeof defaultElement>(props: Props<T>) => ReactElement | null =
  forwardRef(ScrollContainerComponent);
