import { parse } from 'query-string';
import { UserService } from '../../services/user-service';
import { INTRODUCER_ATTRIBUTE_FIELDS } from './constants';

export const getMarketingListsByAccountType = (lists = []) => {
  return lists.filter(list => {
    if (UserService.hasUser() || UserService.hasAccount()) {
      return list;
    }

    return list.nonRegisteredEnabled;
  });
};

export const parseClientSchemaIdParameter = clientSchemeId => {
  return clientSchemeId?.toLowerCase() === 'leaver' ? null : clientSchemeId;
};

/**
 * This function is used to define the redirect link after completing sign in.
 * Depends on data from withRedirectAfterComplete HOC.
 *
 * @param state
 * @return {string}
 */
export const getRedirectLinkAfterComplete = state => {
  const { redirectAfterComplete } = parse(window.location.search);

  return state.navigation.redirectLink || state.navigation.redirectHistory[redirectAfterComplete] || '/dashboard';
};

export const extractIntroducerDataFromUser = user => {
  if (!user.introducer) {
    return {};
  }
  const { code, brokerName, image } = user.introducer;
  const broker = `${brokerName} (${code})`;

  return {
    [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: code,
    [INTRODUCER_ATTRIBUTE_FIELDS.broker]: broker,
    [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: brokerName,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducerImage]: image
  };
};
