import { get } from './utils';

const getGeocode = async value => get(`/geocode?q=${encodeURI(value)}`);
const getPropertiesByPostcode = async ({ value, offset = 0, maxResults = 30 }) =>
  get(`/geo-data/property-addresses?postcode=${value}&offset=${offset}&maxResults=${maxResults}`);

export const helpers = {
  getGeocode,
  getPropertiesByPostcode
};
