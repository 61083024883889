import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import styles from '../../styles/components/safe-area.module.scss';
import { useOnOrientationChange } from '../../helpers/hooks/use-on-orientation-change';
import { isElementTouchesEdge } from '../../helpers/layout/scroll';

const EDGES = ['top', 'bottom', 'left', 'right'];

export const SafeArea = React.memo(
  ({ renderAs: Component = 'div', edges = EDGES, fixed = false, timeout, children, className, ...props }) => {
    const ref = useRef();

    const [{ top, bottom, left, right }, setInset] = useState(
      edges.reduce(
        (acc, edge) => ({
          ...acc,
          [edge]: false
        }),
        {}
      )
    );

    const test = useCallback(async () => {
      if (timeout) {
        await PromiseShim.delay(timeout);
      }

      setInset(state =>
        Object.keys(state).reduce(
          (acc, edge) => ({
            ...acc,
            [edge]: isElementTouchesEdge(ref.current, { edge, fixed })
          }),
          {}
        )
      );
    }, [fixed, timeout]);

    useEffect(() => {
      test();
    }, [test]);

    useOnOrientationChange(test);

    return (
      <Component
        ref={ref}
        className={classnames(
          {
            [styles.top]: top,
            [styles.bottom]: bottom,
            [styles.left]: left,
            [styles.right]: right
          },
          className
        )}
        {...props}
      >
        {children}
      </Component>
    );
  }
);
