import { withHandlers } from 'recompose';

export const withStopPropagation = method =>
  withHandlers({
    [method]:
      ({ [method]: handler }) =>
      event => {
        event.stopPropagation();

        if (handler) {
          handler(event);
        }
      }
  });
