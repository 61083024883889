import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { sortByWeight } from '../../../../helpers/array';
import { sendContentStatus } from '../../../../store/reducers/content-interaction';
import { TComponent } from '../../../../components/template';
import { getAdditionalDataByContentType } from '../../../../helpers/content-interaction';
import styles from '../../../../styles/templates/post-page/article-like.module.scss';
import { FeedbackAction } from './components/feedback-button';

const FeedbackComponent = ({ actions, text, feedback, onSendFeedback, contentKey, setFeedback }) => (
  <TComponent className={styles.block}>
    <p className={styles.heading}>{text}</p>
    <div className={styles.answers}>
      {actions.map(({ icon, label, ariaLabel, data }) => (
        <FeedbackAction
          key={data}
          data={data}
          icon={icon}
          label={label}
          propFeedback={feedback}
          ariaLabel={ariaLabel}
          contentKey={contentKey}
          onSendFeedback={onSendFeedback}
          setFeedback={setFeedback}
        />
      ))}
    </div>
  </TComponent>
);

const FeedbackBlock = compose(
  withRouter,
  connect(null, { sendContentStatus }),
  // Added to select option without waiting for API response
  withState('feedback', 'setFeedback', null),
  withProps(({ text, actions }) => ({
    text: text || 'Was it useful?',
    actions: sortByWeight(actions)
  })),
  withHandlers({
    onSendFeedback:
      ({ sendContentStatus, setFeedback, contentKey, contentType, post = {} }) =>
      feedback =>
      () => {
        setFeedback(feedback);
        const data = getAdditionalDataByContentType({ contentType, key: contentKey, ...post });

        const options = {
          activityKey: contentType,
          activityStatusKey: feedback,
          data
        };
        sendContentStatus(options);
      }
  })
)(FeedbackComponent);

FeedbackBlock.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      data: PropTypes.string
    })
  ).isRequired,
  text: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf(['Article', 'story', 'video', 'blog']),
  contentKey: PropTypes.string.isRequired
};

export { FeedbackBlock };
