import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../../../styles/components/knowledge-base-item.module.scss';
import { AffiliateIcon } from '../../../components/icons/affiliate-icon';

const ReadTime = React.memo(({ readTime, className, readIcon = 'time-stamp' }) => (
  <div className={classnames(styles['read-container'], className)}>
    <span className={styles['read-text']}>{readTime}</span>
    <AffiliateIcon className={styles['read-icon']} name={readIcon} ariaHidden />
  </div>
));

ReadTime.propTypes = {
  readTime: PropTypes.string.isRequired,
  className: PropTypes.string,
  readIcon: PropTypes.string
};

ReadTime.displayName = 'ReadTime';

export { ReadTime };
