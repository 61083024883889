const SALT_COEFFICIENT = 0.089;
export const defaultCalculation = data => {
  const { mortgageTerm, mortgageRate, housePrice, depositPercent, htbPercent } = data;

  const loan =
    housePrice - Math.round(housePrice * (depositPercent / 100)) - Math.round(housePrice * (htbPercent / 100));

  const rate = mortgageRate / 12 / 100;

  const upperScope = rate * Math.pow(1 + rate, mortgageTerm);
  const downScope = Math.pow(1 + rate, mortgageTerm) - 1;
  return Math.round(loan * (upperScope / downScope));
};

export const lbsCalculation = data => {
  const { mortgageTerm, mortgageRate, housePrice, depositPercent } = data;

  const loan = housePrice - Math.round(housePrice * (depositPercent / 100));
  const pureTerm = mortgageTerm / 12;
  const saltPercentage = (SALT_COEFFICIENT / 100) * pureTerm;
  const term = pureTerm + saltPercentage;

  if (mortgageRate < 0.01) {
    return loan / (term * 12);
  }

  // Formula is provided by LBS without explanation and documentation - I have no idea what the letters mean. Just believe it

  const x = (loan * 365) / 12;
  const y = mortgageRate;
  const k = term * 365;
  const l = y / 36500;
  const m = 1 + l;
  const n = Math.pow(m, -k);
  const o = 1 - n;
  const z = 36500 * o;

  const result = x * (y / z);

  return Math.round(result);
};
