import { CALCULATOR_COUNTRY } from './general';

export const getCountry = goalsLocationParsed => {
  switch (true) {
    case goalsLocationParsed.isWales:
      return CALCULATOR_COUNTRY.WALES;
    case goalsLocationParsed.isEngland || goalsLocationParsed.isNorthernIreland:
      return CALCULATOR_COUNTRY.ENGLAND_OR_NORTHERN_IRELAND;
    case goalsLocationParsed.isScotland:
      return CALCULATOR_COUNTRY.SCOTLAND;
    default:
      return CALCULATOR_COUNTRY.ENGLAND_OR_NORTHERN_IRELAND;
  }
};
