import { get } from './utils';

const loadPEDFileData = ({ invitationCode, dateOfBirth, postcode }) => {
  if (dateOfBirth) {
    return get(`/ped-file-data/load?invitationCode=${invitationCode}&dateOfBirth=${dateOfBirth}`);
  }
  if (postcode) {
    return get(`/ped-file-data/load?invitationCode=${invitationCode}&postcode=${postcode}`);
  }
};

const getLoadPEDFileStatus = ({ invitationCode, dateOfBirth, postcode }) => {
  if (dateOfBirth) {
    return get(`/ped-file-data/check-status?invitationCode=${invitationCode}&dateOfBirth=${dateOfBirth}`);
  }
  if (postcode) {
    return get(`/ped-file-data/check-status?invitationCode=${invitationCode}&postcode=${postcode}`);
  }
};

export const propertyPortfolio = {
  loadPEDFileData,
  getLoadPEDFileStatus
};
