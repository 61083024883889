// TODO: Find a way to skip instance checking each time.
export const hasScroll = <T>(target: T): boolean => {
  return target instanceof HTMLElement && target.clientHeight < target.scrollHeight;
};

// TODO: Find a way to skip instance checking each time.
export const isScrollAtTop = <T>(target: T): boolean => {
  return target instanceof HTMLElement && hasScroll(target) && target.scrollTop === 0;
};

// TODO: Find a way to skip instance checking each time.
export const isScrollAtBottom = <T>(target: T): boolean => {
  return (
    target instanceof HTMLElement && hasScroll(target) && target.scrollHeight - target.scrollTop <= target.clientHeight
  );
};
