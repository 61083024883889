import React from 'react';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { SelectService } from '../../services/select-service';
import { InputSelect } from '../inputs/select';
import styles from '../../styles/components/category-filter.module.scss';

export const CategoryFilterComponent = React.memo(props => {
  const { select, values, handleSelect, filterTitle, className } = props;

  return (
    <div className={styles.filter}>
      <InputSelect
        className={className}
        label={filterTitle ?? 'Categories'}
        items={values}
        value={String(select)}
        defaultSelected={value => value.key}
        name='select'
        onChange={handleSelect}
      />
    </div>
  );
});

export const CategoryFilter = compose(
  withState('select', 'setSelect', ({ selectName, value }) => SelectService.getSelects({ selectName, value })),
  withHandlers({
    handleSelect:
      ({ setSelect, handleFilterChange, selectName }) =>
      name =>
      value => {
        const toggleSelectValue = SelectService.setSelects({ name: selectName, value: value.key });
        setSelect(toggleSelectValue);
        handleFilterChange(value.key);
      },
    selectStorageHandler:
      ({ setSelect, handleFilterChange, selectName }) =>
      () => {
        let currentSelectedValue = SelectService.getSelects();
        setSelect(currentSelectedValue?.[selectName] ?? 'All');
        handleFilterChange(currentSelectedValue?.[selectName] ?? 'All');
      }
  }),
  lifecycle({
    componentDidMount() {
      const { selectStorageHandler } = this.props;

      selectStorageHandler();
    }
  })
)(CategoryFilterComponent);
