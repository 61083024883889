import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { sendLastQuestion } from '../../../store/reducers/user-attributes/actions';

const mapStateToProps = ({ userAttributes }) => {
  const { lastQuestion } = userAttributes;

  return { lastQuestionData: lastQuestion };
};

export const withLastQuestion = compose(
  connect(mapStateToProps, { sendLastQuestion }),
  withHandlers({
    setLastQuestion:
      ({ sendLastQuestion, lastQuestionData }) =>
      ({ activity, lastQuestion }) => {
        const updateLastQuestion = { [activity]: lastQuestion };

        sendLastQuestion({ data: { ...lastQuestionData?.data, ...updateLastQuestion } });
      },
    unsetLastQuestion:
      ({ sendLastQuestion, lastQuestionData }) =>
      activity => {
        const updateLastQuestion = { [activity]: null };

        sendLastQuestion({ data: { ...lastQuestionData?.data, ...updateLastQuestion } });
      }
  })
);
