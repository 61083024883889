import { useRef } from 'react';
import { useEventListener } from '@packages/hooks/core/use-event-listener';

export const useOnOrientationChange = onChange => {
  const { current: element } = useRef(window.matchMedia('(orientation: portrait)'));

  const handleOrientationChange = event => {
    onChange(event);
  };

  useEventListener('change', handleOrientationChange, element);
};
