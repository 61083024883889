import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/widgets/story-step/image.module.scss';

const ImageComponent = ({ image, imageAlt, objectFit = 'contain', objectPosition = 'center' }) => (
  <>{image && <img src={image} alt={imageAlt} className={styles.image} style={{ objectFit, objectPosition }} />}</>
);

ImageComponent.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
  objectPosition: PropTypes.oneOf([
    'center',
    'left',
    'right',
    'top',
    'bottom',
    'top left',
    'top right',
    'bottom left',
    'bottom right'
  ])
};

export { ImageComponent };
