import React from 'react';
import { compose, withProps } from 'recompose';
import classnames from 'classnames';
import { Column, Grid } from '../layout/grid';
import { createStepsArray } from '../../helpers/progress-indicator';
import styles from '../../styles/components/progress-indicator.module.scss';
import { ConfigService } from '../../services/config-service';
import { ProgressIndicatorStep } from './step';
import { StepIndicator } from './step-indicator';

const ProgressIndicatorComponent = ({ steps, type, className }) => (
  <div className={classnames(styles.container, className)}>
    {steps.map(({ key, state, currentStep }) => (
      <ProgressIndicatorStep key={key} state={state} type={type} currentStep={currentStep} />
    ))}
    <div className={styles['key-line']} />
  </div>
);

const withStepIndicator = Component => props => {
  if (!props.indicatorLabel) return <Component {...props} />;
  return (
    <div className={styles['indicator-parent-container']}>
      <Component {...props} />
      <StepIndicator {...props} />
    </div>
  );
};

const withWrapper =
  Component =>
  ({ edge, grid, forwardRef, ...props }) => {
    if (grid) {
      return (
        <Grid className={styles.wrapper} ref={forwardRef}>
          <Column flex justify='center' edge={edge}>
            <Component {...props} />
          </Column>
        </Grid>
      );
    }

    return <Component {...props} />;
  };

export const ProgressIndicator = compose(
  withProps(({ pageNumber, pagesTotal, configKey }) => {
    const { PROGRESS_INDICATOR = {} } = ConfigService.get(configKey, {});
    return {
      steps: createStepsArray(pagesTotal, pageNumber),
      ...PROGRESS_INDICATOR
    };
  }),
  withWrapper,
  withStepIndicator
)(ProgressIndicatorComponent);
