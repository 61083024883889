import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { sendStatus } from '../../store/reducers/status-data';
import { TrackingService } from '../../services/tracking-service';

// this is a name of the activity that needs to be set in the Activities list in admin panel
const TRACKING_ACTIVITY = 'Tracking';

export const withTrackingHandlers = compose(
  connect(null, { sendStatus }),
  withHandlers({
    sendTrackingData:
      ({ sendStatus }) =>
      (tracker, additionalData) => {
        const initialData = { timestamp: TrackingService.getTimestamp() };

        sendStatus(TRACKING_ACTIVITY, tracker, null, { ...initialData, ...additionalData });
      }
  })
);
