export const STAGES = {
  DEFAULT: 0,
  VALID_POST_CODE: 1,
  ADDRESS_LOOKUP: 2,
  READY: 3,
  PROCESSING: 4,
  DONE: 5
};

export const DEFAULT_ERROR = 'Something wrong happens, try again...';
export const MIN_DELAY = 500;

export const PROPERTY_POSTCODE_NAME = 'postcode';
export const PROPERTY_ADDRESS_NAME = 'address';
export const PROPERTY_UPRN_NAME = 'uprn';
