const isFunction = <T extends unknown>(target: T): boolean => {
  return typeof target === 'function';
};

// const unstack =
//   target =>
//   (...args) => {
//     return isFunction(target) ? unstack(target(...args))(...args) : target;
//   };

type MemoizeResolver =
  | {
      (args: any[], preevArgs: any[]): any;
    }
  | number;

const memoize = <T>(target: T, resolver?: MemoizeResolver): ((...args: any[]) => any) => {
  if (typeof target !== 'function') {
    throw new Error('Provide a valid function for memoization as a first argument');
  }

  const cache = new Map();
  const weakCache = new WeakMap();

  let prevArguments: any[] = [];

  return (...args) => {
    let key: any = args[0];
    const store =
      typeof resolver === 'function' || (typeof resolver === 'object' && resolver !== null) ? weakCache : cache;

    if (typeof resolver === 'number') {
      key = args[resolver];
    }

    if (typeof resolver === 'function') {
      key = resolver(args, prevArguments);
    }

    if (store.has(key)) {
      return store.get(key);
    }

    prevArguments = args;

    const result = target(...args);

    store.set(key, result);

    return result;
  };
};

export class FunctionShim {
  static isFunction = isFunction;
  // static unstack = unstack;
  static memoize = memoize;
}
