import { useRef, useState, useMemo, useLayoutEffect } from 'react';

export const useView = dimensions => {
  const ref = useRef(null);

  const rotationPerPanel = useMemo(() => {
    const { grips } = window.CONFIG.COMPONENTS.WHEEL;

    return 360 / grips;
  }, []);

  const [gripWidth, setGripWidth] = useState(0);
  const [zTranslate, setTranslate] = useState(0);

  const { width } = dimensions;

  useLayoutEffect(() => {
    const { grips } = window.CONFIG.COMPONENTS.WHEEL;

    const windowRef = ref.current;

    if (windowRef) {
      const gripWidth = windowRef.clientWidth / (grips / 6);
      const translate = gripWidth / 2 / Math.tan((rotationPerPanel * Math.PI) / 180);

      setGripWidth(gripWidth);
      setTranslate(translate);
    }
  }, [rotationPerPanel, width]);

  const { grips, steppersDisplay } = window.CONFIG.COMPONENTS.WHEEL;

  return [{ grips, gripWidth, zTranslate, rotationPerPanel, steppersDisplay }, ref];
};
