import React from 'react';
import { withPropsOnChange, compose } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/radio.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withLabel, withOptionLabel, withOptionRowClick } from '../input-field';

const Input = React.memo(({ id, className, inputRef, ...props }) => {
  return <input type='radio' id={id} className={classnames(styles.input, className, 'thrown-away')} {...props} />;
});

const Component = React.memo(({ id, icon = <AffiliateIcon name='radio-input' />, value = false, pulse, ...props }) => {
  const additionalProps = { ...props };

  if (props.explainer) {
    additionalProps['aria-describedby'] = `${id}_explainer`;
  }

  return (
    <div>
      <Input id={id} className={styles.field} value={value} {...additionalProps} />
      <div className={classnames(styles.icon, { pulse, [styles['icon-disabled']]: props.disabled })}>{icon}</div>
    </div>
  );
});

export const Radio = compose(withAnchor, withLabel)(Component);
export const InputRadio = compose(
  withAnchor,
  withContainer,
  withOptionRowClick,
  // These are the styles which will be applied to the label tag
  withPropsOnChange(['styling', 'className'], ({ styling, className }) => ({
    className: classnames({ [styles.label]: !styling }, { [styles[styling]]: styling }, className)
  })),
  withOptionLabel({
    layout: styles.container
  })
)(Component);
