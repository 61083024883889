import { useCallback, useEffect, useRef, useState } from 'react';
import { observe } from '@packages/helpers/core/shims/intersection-observer-shim';

const DEFAULT_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
  once: false
};

export const useOnViewportIntersect = config => {
  const [state, setState] = useState(() => ({
    visible: false,
    entry: null
  }));

  const observer = useRef();

  const { once, root, rootMargin, threshold } = { ...DEFAULT_OPTIONS, ...config };

  const ref = useCallback(
    node => {
      if (node) {
        observer.current = observe(
          (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (once) {
                  observer.disconnect();
                }

                setState({ visible: true, entry });
              } else {
                setState({ visible: false, entry });
              }
            });
          },
          { once, root, rootMargin, threshold }
        );

        observer.current.observe(node);
      }
    },
    [once, root, rootMargin, threshold]
  );

  useEffect(
    () => () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    },
    []
  );

  return [ref, state.visible, state.entry];
};
