import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setRefreshPageBackLink } from '../../../store/reducers/digital-coach';
import { digitalCoach as digitalCoachAPI } from '../../../api/digital-coach';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';

const CustomButtonComponent = React.memo(
  ({
    setRefreshPageBackLink,
    onConfirm,
    label,
    ariaLabel,
    callUrl,
    body,
    confirmationMessage,
    acceptButtonText,
    rejectButtonText,
    buttonType = 'major'
  }) => {
    const history = useHistory();

    const {
      location: { pathname }
    } = history;

    const onAccept = async () => {
      await digitalCoachAPI.callCustomPostAction(callUrl, body);
      setRefreshPageBackLink(pathname);
      history.push('/updating-results');
    };

    const onClick = () =>
      onConfirm(onAccept, {
        children: <p>{confirmationMessage}</p>,
        acceptLabel: acceptButtonText,
        declineLabel: rejectButtonText
      });

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const CustomButton = connect(null, { setRefreshPageBackLink })(CustomButtonComponent);
