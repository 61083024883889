import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { SafeArea } from '../../../components/layout/safe-area';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import styles from '../../../styles/widgets/story-step/words.module.scss';
import { withStoryBlockWrapper } from './hocs/with-story-block-wrapper';

const Component = ({ heading, text }) => (
  <SafeArea edges={['bottom']} fixed timeout={250}>
    <div className='fill-height'>
      {heading && <h2 className={styles.title}>{heading}</h2>}
      {text && <PurifiedHtmlContainer renderAs='div' className='typography typography-extended' html={text} />}
    </div>
  </SafeArea>
);

const WordsComponent = compose(withStoryBlockWrapper)(Component);

WordsComponent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string
};

export { WordsComponent };
