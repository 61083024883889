import { compose } from 'recompose';
import { Autocomplete as Input } from '../../../../../components/inputs/autocomplete';
import { withInputLabel } from '../../../../../components/inputs/input';
import {
  withAdvancedInformer,
  withAnchor,
  withError,
  withExplainer
} from '../../../../../components/inputs/input-field';
import { omitProps } from '../../../../../helpers/hocs/omit-props';
import { withSubmit, withContainer } from '../input-field';
import { withPreSubmitButton } from '../pre-submit-button/with-pre-submit-button';

const CONSTRAINTS = [
  'attributes',
  'autocompleteItems',
  'currentValue',
  'dispatch',
  'history',
  'identifier',
  'location',
  'match',
  'onAutoCompleteChange',
  'onChooseAutocompleteItem',
  'onValueChange',
  'setAutocompleteItems',
  'setCurrentValue',
  'staticContext',
  'valueName',
  'setLoading',
  'toggleLoading',
  'turnOnLoading',
  'turnOffLoading',
  'errorMessages',
  'userAttributeName',
  'datasetKey',
  'setFullItemsList',
  'fullItemsList',
  'setDisabled',
  'setError',
  'getItems',
  'submitDisable'
];

const mapProps = ({ autocompleteItems, onChooseAutocompleteItem, onValueChange, value, valueName, disabled }) => ({
  type: 'text',
  // 1. When user typing in a field 'value' will be a string
  // 2. When user clicks on choosen item 'value' will be an object
  // where it should be picked by 'valueName'.
  // 3. When 'value' is null or undefined 'value' will be initialized
  // as empty string
  value: value?.[valueName] ?? value ?? '',
  items: autocompleteItems,
  disabled,
  onChange: onChooseAutocompleteItem,
  onInput: onValueChange
});

export const AutocompleteInput = compose(
  omitProps(CONSTRAINTS, mapProps),
  withAnchor,
  withContainer,
  withSubmit,
  withPreSubmitButton,
  withInputLabel,
  withError,
  withExplainer,
  withAdvancedInformer
)(Input);
