// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-message';

const worker = workerBase();

export const parseMessage = async (userAttributes, message, extraParams) => {
  const params = {
    userAttributes,
    ...extraParams
  };

  return await worker.evaluate(message, params);
};
