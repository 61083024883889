const offset = {
  name: 'offset',
  enabled: true,
  options: {
    offset: ({ reference }) => {
      return [0, -reference.height];
    }
  }
};

const preventOverflow = {
  name: 'preventOverflow',
  enabled: true
};

const flip = {
  name: 'flip',
  enabled: true,
  options: {
    flipVariations: false,
    fallbackPlacements: ['top']
  }
};

export const popper = {
  placement: 'bottom',
  modifiers: [offset, preventOverflow, flip]
};
