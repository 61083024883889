export const HIDDEN_COMPONENTS_IF_EMBEDDED = {
  applicationFooter: true,
  appLogo: true,
  sidebar: true,
  topNavigation: true,
  siteFooter: true,
  desktopHeaderNavigation: true,
  desktopHeaderCollapseNav: true
};

export const ASPECT_RATIOS = {
  square: '1x1',
  landscape: '16x9',
  portrait: '9x16',
  standard: '4x3'
};
