import React from 'react';
import { compose, mapProps } from 'recompose';
import { sortByWeight } from '../../../helpers/array';
import { WidgetComponent } from './widget';

const Component = ({ widgets, ...rest }) => (
  <>
    {widgets.map(widget => (
      <WidgetComponent key={widget.name} widget={widget.name} {...rest} />
    ))}
  </>
);

export const WidgetsComponent = compose(
  mapProps(props => ({
    ...props,
    widgets: sortByWeight(Object.values(props.widgets))
  }))
)(Component);
