import React from 'react';
import PropTypes from 'prop-types';
import { TComponent } from '../../../components/template';
import IntersectionVisible from '../../../components/intersection-visibility';
import { withStoryBlockWrapper } from './hocs/with-story-block-wrapper';

const Component = React.memo(({ onStoryRead }) => (
  <TComponent>
    <IntersectionVisible onShow={onStoryRead} once />
  </TComponent>
));

const StoryRead = withStoryBlockWrapper(Component);

StoryRead.propTypes = {
  onStoryRead: PropTypes.func.isRequired
};

export { StoryRead };
