import { createAction } from '../utils';

const SUBSCRIPTION_MARKETING_LIST_REQUEST = 'SUBSCRIPTION_MARKETING_LIST_REQUEST';
const SUBSCRIPTION_MARKETING_LIST_SUCCESS = 'SUBSCRIPTION_MARKETING_LIST_SUCCESS';
const SUBSCRIPTION_MARKETING_LIST_FAILURE = 'SUBSCRIPTION_MARKETING_LIST_FAILURE';

export const subscribeUnsubscribeMarketingList = params =>
  createAction(
    {
      request: SUBSCRIPTION_MARKETING_LIST_REQUEST,
      success: SUBSCRIPTION_MARKETING_LIST_SUCCESS,
      failure: SUBSCRIPTION_MARKETING_LIST_FAILURE
    },
    api => api.marketingList.subscribeUnsubscribeMarketingList
  )(params);
