import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/components/callout.module.scss';
import { AffiliateIcon } from './icons/affiliate-icon';
import { PurifiedHtmlContainer } from './layout/purified-html-container';

const CalloutHeading = React.memo(({ heading, icon = <AffiliateIcon name='callout' /> }) => (
  <div className={styles.header}>
    <div className={styles.icon}>{icon}</div>
    <PurifiedHtmlContainer html={heading} parse={false} />
  </div>
));

const CalloutBlock = React.memo(({ heading, description, icon }) => (
  <div className={styles.callout}>
    <CalloutHeading heading={heading} icon={icon} />
    <PurifiedHtmlContainer className={styles.description} html={description} parse={false} />
  </div>
));

CalloutBlock.displayName = 'CalloutBlock';

CalloutBlock.propTypes = {
  icon: PropTypes.element,
  heading: PropTypes.string,
  description: PropTypes.string
};

export { CalloutBlock };
