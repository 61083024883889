import { CONTENT_TYPES } from '../constants';

export const TYPE_TO_ACTIVITY_KEY = {
  [CONTENT_TYPES.article]: 'Article',
  [CONTENT_TYPES.blog]: 'blog',
  [CONTENT_TYPES.announcement]: 'blog',
  [CONTENT_TYPES.project]: 'project',
  [CONTENT_TYPES.story]: 'story',
  [CONTENT_TYPES.video]: 'video'
};

export const TYPE_TO_ROUTE = {
  story: 'stories',
  project: 'projects',
  article: 'articles',
  video: 'videos',
  blog: 'blog',
  announcement: 'blog'
};

export const ROUTE_TO_TYPE = {
  [TYPE_TO_ROUTE.article]: CONTENT_TYPES.article,
  [TYPE_TO_ROUTE.blog]: CONTENT_TYPES.blog,
  [TYPE_TO_ROUTE.story]: CONTENT_TYPES.story
};

export const CONTENT_ROUTE_TO_STORE_KEY = {
  [CONTENT_TYPES.article]: 'contentPages',
  [CONTENT_TYPES.blog]: 'contentPages',
  [CONTENT_TYPES.story]: 'stories'
};
