/**
 * Checklist Section Item type.
 * @typedef {object} ChecklistSectionItem
 * @property {boolean | undefined} isCompleted
 * @property {number} weight
 */

/**
 * Sort CheckList Items by Status (firstly all uncompleted).
 * @param {Array.<ChecklistSectionItem>} arrayOfItems
 * @return {Array.<ChecklistSectionItem>}
 */
export const sortCheckListItemsByStatus = arrayOfItems =>
  arrayOfItems.sort((a, b) => {
    if (a.isCompleted && !b.isCompleted) return 1;

    if (!a.isCompleted && b.isCompleted) return -1;

    return 0;
  });

/**
 * Slice CheckList Items according to limit
 * @param { object } params
 * @param {Array.<ChecklistSectionItem>} params.arrayOfItems
 * @param {number | undefined} params.limit
 * @param {boolean | undefined} params.condition
 * @return {Array.<ChecklistSectionItem>}
 */
export const sliceCheckListItems = ({ arrayOfItems, limit, condition }) =>
  condition ? arrayOfItems.slice(0, limit) : arrayOfItems;
