import React from 'react';
import { connect } from 'react-redux';
import { Question } from '../../index';
import { createCustomPageProps } from '../../../../helpers/utils';

const DynamicInputQuestionComponent = React.memo(({ component: Component, value, onChange, ...rest }) => (
  <Question {...rest}>
    {({ questionInputs }) => {
      const { data } = questionInputs[0];

      return <Component {...data} {...rest} />;
    }}
  </Question>
));

const mapQuestionStateToProps = (state, ownProps) => {
  const params = createCustomPageProps(ownProps.location.pathname);

  return {
    match: { params: { activity: params.name, slug: params.trigger } }
  };
};

export const DynamicInputQuestion = connect(mapQuestionStateToProps)(DynamicInputQuestionComponent);
