import React, { useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { compose } from 'recompose';

const FocusTrapComponent = ({ renderAs, children, disabled, activator, onActivation, onDeactivation }, ref) => {
  const lastFocused = useRef(activator);

  const handleActivation = () => {
    if (!lastFocused.current) {
      // Without the zero-timeout, activeElement will likely be wrong one.
      setTimeout(() => {
        lastFocused.current = document.activeElement;
      }, 0);
    }

    onActivation && onActivation();
  };

  const handleDeactivation = () => {
    const { current: focused } = lastFocused;

    onDeactivation && onDeactivation();

    // Without the zero-timeout, focus will likely remain trapped
    if (focused) {
      setTimeout(() => focused.focus(), 0);
    }
  };

  return (
    <FocusLock
      ref={ref}
      as={renderAs}
      disabled={disabled}
      noFocusGuards={disabled}
      returnFocus={{ preventScroll: true }}
      onActivation={handleActivation}
      onDeactivation={handleDeactivation}
      hasPositiveIndices={false}
    >
      {children}
    </FocusLock>
  );
};

export const FocusTrap = compose(React.memo, React.forwardRef)(FocusTrapComponent);
