import { compose } from 'redux';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { checkTopicDisplayCondition, filterPostsByVisibility, mapAllPostsToVisible } from '../../helpers/knowledgebase';
import { excludeUnsetConfigCategories } from '../../helpers/knowledgebase/category-filter';

const mapStateToProps = ({ userAttributes, statusData: { plainStatuses }, points }) => ({
  userAttributes,
  plainStatuses,
  userData: { ...userAttributes?.user, ...points }
});

export const withKnowledgeBaseDisplayCondition = compose(
  connect(mapStateToProps),
  withHandlers({
    filterTopics:
      ({ userAttributes, plainStatuses, userData, configCategories, accessor }) =>
      async knowledgeBase => {
        const knowledgeBaseList = configCategories
          ? excludeUnsetConfigCategories({ postsList: knowledgeBase, configCategories, accessor })
          : knowledgeBase;

        const withParsedCondition = await checkTopicDisplayCondition(knowledgeBaseList, {
          ...userAttributes,
          activity: plainStatuses,
          user: userData
        });

        return {
          ignoredDisplayCondition: mapAllPostsToVisible(knowledgeBaseList),
          checkedParsedCondition: filterPostsByVisibility(withParsedCondition)
        };
      }
  })
);
