import React from 'react';
import { compose, withHandlers, withState, branch, renderNothing, withPropsOnChange } from 'recompose';
import classnames from 'classnames';
import { DEVICE } from '../../helpers/user-agent';
import styles from '../../styles/components/smartbanner.module.scss';
import { ConfigService } from '../../services/config-service';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Image } from '../images/image';

export const SmartBannerComponent = React.memo(({ onClose, data, device, title, author, button }) => (
  <div className={classnames(styles.container, styles[device])}>
    <AffiliateIcon name='nav-exit' className={styles.exit} onClick={onClose} />
    <Image src={data.icon} className={styles.image} />
    <div className={styles.info}>
      <div className={styles.title}>{title}</div>
      <div className={styles.author}>{author}</div>
    </div>
    <a href={data.url} target='_blank' className={styles.button} rel='noreferrer'>
      <span className={styles.label}>{button}</span>
    </a>
  </div>
));

export const SmartBanner = compose(
  withState('isClosed', 'setIsClosed', localStorage.getItem('isBannerClosed')),
  withHandlers({
    onClose:
      ({ setIsClosed }) =>
      () => {
        setIsClosed(true);
        localStorage.setItem('isBannerClosed', 'true');
      }
  }),
  withPropsOnChange(['isClosed'], ({ isClosed }) => {
    const { title, button, iconGoogle, iconApple, buttonUrlGoogle, buttonUrlApple, author } = ConfigService.get(
      'SMART_BANNER',
      {}
    );

    const { show, permanentShow, permanentDevice } = ConfigService.get('SMART_BANNER', {});
    const isMobile = DEVICE.IS_MOBILE_BROWSER();
    const appDevice = DEVICE.IOS() ? 'ios' : 'android';
    const device = permanentDevice || appDevice;

    const data = {
      ios: {
        icon: iconApple,
        url: buttonUrlApple
      },
      android: {
        icon: iconGoogle,
        url: buttonUrlGoogle
      }
    };

    return {
      isVisible: permanentShow || (show && isMobile && !isClosed),
      data: data[device],
      device,
      title,
      button,
      author
    };
  }),
  branch(({ isVisible }) => !isVisible, renderNothing)
)(SmartBannerComponent);
