import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { CoachMessage } from '../../../components/coach-message';

const CoachLikeBlock = ({ className, text, label, link, isExternal, onEditQuestionSection }) => {
  return (
    <div className={className}>
      <CoachMessage html={text} />
      {isExternal ? (
        <ButtonWithIcon icon={null} type='major' href={link} isExternal>
          {label}
        </ButtonWithIcon>
      ) : (
        <ButtonWithIcon icon={null} type='major' onClick={onEditQuestionSection(link)}>
          {label}
        </ButtonWithIcon>
      )}
    </div>
  );
};

CoachLikeBlock.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onEditQuestionSection: PropTypes.func.isRequired,
  isExternal: PropTypes.bool
};

export default CoachLikeBlock;
