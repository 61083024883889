import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { POINTS, REWARDS } from '../helpers/navigation';

const PointsAndAwards = Loadable({
  loader: () =>
    import(/* webpackChunkName: "points-and-awards" */ '../pages/points-and-awards').then(
      module => module.PointsAndAwards
    ),
  loading: Loader
});

const ClaimAndRewardsPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "claims-and-awards" */ '../pages/claim-rewards').then(
      module => module.ClaimAndRewardsPage
    ),
  loading: Loader
});

export const pointsRoutes = [
  {
    to: '/points-and-rewards',
    component: asTemplate(POINTS)(PointsAndAwards)
  },
  {
    to: '/points-and-rewards/:slug',
    component: asTemplate(REWARDS)(ClaimAndRewardsPage)
  }
];
