import { useCallback, useRef, useState } from 'react';
import { setHandler, removeHandlers } from '../../../../helpers/utils';
import { getEventCoordinates } from '../../../../helpers/layout/event';
import { useScrollControl } from '../../../../helpers/hooks/use-scroll-control';

export const useController = () => {
  const [angle, setAngle] = useState();
  const [duration, setDuration] = useState();

  const _touch = useRef({ x: null, y: null, moment: null, target: null });
  const _position = useRef({ now: 0, last: 0 });
  const _momentum = useRef({ callback: null });
  const _handlers = useRef({});
  const _increment = useRef(0);

  const { enableScroll, disableScroll } = useScrollControl();

  const emit = (detail, type = '') => {
    const {
      current: { target }
    } = _touch;

    const event = new CustomEvent(`drag${type}`, { bubbles: true, detail });

    target.dispatchEvent(event);
  };

  const increase = () => {
    const { incrementRatio } = window.CONFIG.COMPONENTS.WHEEL;

    _increment.current += incrementRatio;

    emit({ multiplier: 1, boundary: 'max' });
  };

  const decrease = () => {
    const { incrementRatio } = window.CONFIG.COMPONENTS.WHEEL;

    _increment.current -= incrementRatio;

    emit({ multiplier: -1, boundary: 'min' });
  };

  const touch = event => {
    const { xCoord, yCoord } = getEventCoordinates(event, 'client');

    _touch.current = {
      x: xCoord,
      y: yCoord,
      moment: new Date(),
      target: event.currentTarget
    };

    emit(null, 'start');
  };

  const release = () => {
    const {
      current: { now }
    } = _position;

    _position.current = {
      now,
      last: now
    };

    _increment.current = 0;
  };

  const rotate = shift => {
    const { speedRatio, direction } = window.CONFIG.COMPONENTS.WHEEL;

    const {
      current: { last }
    } = _position;

    const now = last + shift;

    _position.current = { now, last };

    setAngle(Math.trunc(now / speedRatio) * direction);
  };

  const move = xCoord => {
    const { incrementRatio, direction } = window.CONFIG.COMPONENTS.WHEEL;
    const { current: previous } = _touch;

    const shift = (xCoord - previous.x) * direction;

    if (shift > _increment.current + incrementRatio) {
      increase();
    } else if (shift < _increment.current - incrementRatio) {
      decrease();
    }

    rotate(shift);
  };

  const stop = () => {
    const {
      current: { callback }
    } = _momentum;

    if (callback) {
      callback();
    }
  };

  const clearMomentum = id => () => {
    _momentum.current = {
      callback: null
    };

    clearInterval(id);
    setDuration();
    release();
    emit(null, 'end');
  };

  const runMomentum = (momentum, shift) => {
    const { momentumAmount, incrementRatio } = window.CONFIG.COMPONENTS.WHEEL;

    let updates = Math.trunc(momentum / (incrementRatio * momentumAmount));

    const tick = Math.trunc(momentum / updates);

    const id = setInterval(() => {
      if (--updates) {
        shift > 0 ? increase() : decrease();
      } else {
        clear();
      }
    }, tick);

    const clear = clearMomentum(id);

    return clear;
  };

  const setMomentum = () => {
    const { momentum } = window.CONFIG.COMPONENTS.WHEEL;

    if (momentum) {
      const {
        current: { now, last }
      } = _position;

      const { current: previous } = _touch;

      const shift = now - last;

      const time = new Date() - previous.moment;
      const distance = Math.abs(shift);
      const speed = distance / time;

      const { momentumRangeThreshold, momentumSpeedThreshold, momentumAmount } = window.CONFIG.COMPONENTS.WHEEL;

      if (distance > momentumRangeThreshold && speed > momentumSpeedThreshold) {
        const momentum = distance * speed * momentumAmount;

        setDuration(momentum);

        _momentum.current = {
          callback: runMomentum(momentum, shift)
        };

        rotate(Math.sign(shift) * momentum);

        return true;
      }
    }

    return false;
  };

  const handleStart = event => {
    stop();
    touch(event);

    const { current: handlers } = _handlers;

    const setWheelHandler = setHandler.bind(this, event.currentTarget);

    if (event.touches) {
      handlers.touchmove = setWheelHandler('touchmove', handleMove);
      handlers.touchend = setWheelHandler('touchend', handleEnd);
    } else {
      handlers.mousemove = setWheelHandler('mousemove', handleMove);
      handlers.mouseleave = setWheelHandler('mouseleave', handleEnd);
      handlers.mouseup = setWheelHandler('mouseup', handleEnd);
    }
  };

  const handleMove = event => {
    const { current: previous } = _touch;

    const { xCoord, yCoord } = getEventCoordinates(event, 'client');

    const { wheelScrollLock, pageScrollLock } = window.CONFIG.COMPONENTS.WHEEL;

    const isTouch = event.touches;
    const isTouchLeave = isTouch && Math.abs(previous.y - yCoord) > wheelScrollLock;
    const isTouchLocked = isTouch && Math.abs(previous.x - xCoord) > pageScrollLock;

    if (isTouch && isTouchLocked) {
      disableScroll();
    }

    move(xCoord);

    if (isTouch && isTouchLeave) {
      event.currentTarget.dispatchEvent(new TouchEvent('touchend'));
    }
  };

  const handleEnd = () => {
    const { current: handlers } = _handlers;

    _handlers.current = removeHandlers(handlers);

    enableScroll();

    const wasIncremented = _increment.current;
    const withMomentum = setMomentum();

    release();

    if (wasIncremented && !withMomentum) {
      emit(null, 'end');
    }
  };

  const handleIncrease = ({ currentTarget }) => {
    const { incrementRatio } = window.CONFIG.COMPONENTS.WHEEL;

    _touch.current = {
      target: currentTarget
    };

    stop();
    rotate(incrementRatio);
    increase();
    release();
  };

  const handleDecrease = ({ currentTarget }) => {
    const { incrementRatio } = window.CONFIG.COMPONENTS.WHEEL;

    _touch.current = {
      target: currentTarget
    };

    stop();
    rotate(-incrementRatio);
    decrease();
    release();
  };

  return [
    {
      transform: angle && `rotateY(${angle}deg)`,
      transition: duration && `transform ${duration}ms ease-out`
    },
    {
      handleStart,
      handleIncrease,
      handleDecrease,
      stop: useCallback(stop, [])
    }
  ];
};
