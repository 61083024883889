import { isUndefined } from '@packages/helpers/core/common';
import { ConfigService } from '../../services/config-service';
import * as NAVIGATION from '../navigation';
import { getRoutesByPathname } from '../utils';

/**
 * @typedef {Object} BreadcrumbItem
 * @param {string} name - name for the breadcrumb item
 * @param {string} url - url for the breadcrumb item
 */

/**
 * Map rest of the items based on the current pathname
 * Adds dashboard item if needed
 * @param pathname
 * @param matchParams
 * @return {Array.<BreadcrumbItem>|[]}
 */
const mapRestItems = (pathname, matchParams) => {
  //For dashboard pages we don't need to add any rest breadcrumbs
  if (pathname.includes(NAVIGATION.DASHBOARD)) {
    return [];
  }

  const showDashboardItem = ConfigService.get('BREADCRUMBS.showItem.dashboard', false);

  const breadcrumbList = getBreadcrumbFromPathname(pathname, matchParams);

  if (showDashboardItem) {
    const dashboardItem = {
      name: ConfigService.get(`BREADCRUMBS.names.${NAVIGATION.DASHBOARD}`, NAVIGATION.DASHBOARD),
      url: `/${NAVIGATION.DASHBOARD}`
    };

    return [dashboardItem, ...breadcrumbList];
  }

  return breadcrumbList;
};

/**
 * Get breadcrumb items based on the current pathname
 * All exceptions are handled here
 * @param pathname
 * @param matchParams
 * @return {Array.<BreadcrumbItem>|[]}
 */
const getBreadcrumbFromPathname = (pathname, matchParams) => {
  const initialPathnameList = getRoutesByPathname(pathname);

  //if pathname contains 'start' or 'result' - include only first element from path
  //otherwise include first two elements
  const sliceParams = pathname.includes(NAVIGATION.START) || pathname.includes(NAVIGATION.RESULT) ? -2 : -1;

  const pathnameList = initialPathnameList.slice(0, sliceParams).filter(Boolean);

  /* eslint-disable no-fallthrough */
  switch (true) {
    // For market-place pages we  don't need to add any breadcrumbs (as we don't have a 'market-place' page)
    case pathnameList[1] === NAVIGATION.MARKET_PLACE:
    // For following pages we don't need to add any breadcrumbs (as we don't have a 'help' page)
    case pathnameList[0] === NAVIGATION.HELP:
    // // For pages with only one item in the path we don't need to add any breadcrumbs
    case pathnameList.length === 0:
      return [];

    // For account pages we just add one item to the breadcrumbs
    case pathnameList[0] === NAVIGATION.ACCOUNT:
      return [
        {
          name: ConfigService.get(`BREADCRUMBS.names.${NAVIGATION.ACCOUNT}`, NAVIGATION.ACCOUNT),
          url: `/${NAVIGATION.ACCOUNT}`
        }
      ];

    //For question pages we add a link to activity start page
    case !isUndefined(matchParams.activity) && pathnameList[0] === NAVIGATION.CHECKLIST:
      return [
        {
          name: ConfigService.get(`BREADCRUMBS.names.${NAVIGATION.CHECKLIST}`, NAVIGATION.CHECKLIST),
          url: `/${NAVIGATION.CHECKLIST}`
        },
        {
          name: ConfigService.get(`BREADCRUMBS.names.${pathnameList[1]}`, pathnameList[1]),
          url: `/${pathnameList[0]}/${pathnameList[1]}/start`
        }
      ];

    default:
      return pathnameList.map((path, index) => ({
        name: ConfigService.get(`BREADCRUMBS.names.${path}`, path),
        url: `/${pathnameList.slice(0, index + 1).join('/')}`
      }));
  }
  /* eslint-enable no-fallthrough */
};

/**
 * Build breadcrumb items based on the current match
 * Adds home page item if needed
 * @param {Object} match - current match object
 * @returns {Array.<BreadcrumbItem>} - list of breadcrumb items
 */
export const buildBreadcrumbItems = match => {
  const showHomePageItem = ConfigService.get('BREADCRUMBS.showItem.homePage', false);
  const { url, params } = match;
  const restItems = mapRestItems(url, params);

  if (showHomePageItem) {
    const homepageItem = ConfigService.get('BREADCRUMBS.homePage', {});

    return [homepageItem, ...restItems];
  }

  return restItems;
};
