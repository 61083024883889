import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const valueToActiveState = (inputValue, plainValue) => {
  if (!inputValue || (ObjectShim.isEmpty(inputValue) && !plainValue)) {
    return null;
  }

  if (plainValue) {
    return inputValue;
  }

  const { checked, value } = Object.values(inputValue)[0];

  return checked ? value : null;
};
