import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { DISPATCH_AFTER_USER_ATTRIBUTES, sendAdditionalUserAttributes } from '../../reducers/user-attributes/actions';
import { generateMABProps } from '../generators/generate-mab-props';
import { generateFFProps } from '../generators/generate-ff-props';

//here we trigger specific action depends on user attributes prefix, in this case output method is same,
//but we can use same structure for generating props from goals

//TODO: This part should be refactored too. This logic should be on the product level.
const triggers = {
  beVM: { method: state => generateFFProps(state), attributeType: 'futureFit', triggers: [] },
  scoreVM: { method: state => generateFFProps(state), attributeType: 'futureFit' },
  positiveScoreVM: { method: state => generateFFProps(state), attributeType: 'futureFit' },
  property: { method: state => generateMABProps(state), attributeType: 'property' },
  mortgage: { method: state => generateMABProps(state), attributeType: 'property' }
};

const triggersToBeSent = ['beVM', 'scoreVM', 'positiveScoreVM', 'property', 'mortgage'];

export const afterUserAttributesSend = ({ action, store }) => {
  const state = store.getState();
  const propType = action.params.type;
  const trigger = triggers[propType];

  if (trigger) {
    const params = trigger.method(state);

    if (!ObjectShim.isEmpty(params)) {
      store.dispatch({ type: DISPATCH_AFTER_USER_ATTRIBUTES, params });

      //here we send calculated data for specific property type
      if (triggersToBeSent.includes(propType)) {
        store.dispatch(sendAdditionalUserAttributes({ [triggers[propType].attributeType]: params }));
      }
    }
  }
};

// For section skipping we generate triggered data for sending as additional user attributes
// analogue for afterUserAttributesSend
export const getActionTriggeredData = ({ propType, state }) => {
  const params = getGeneratedTriggeredData({ propType, state });

  if (!ObjectShim.isEmpty(params)) {
    return { [triggers[propType].attributeType]: params };
  }

  return {};
};

export const getGeneratedTriggeredData = ({ propType, state }) => {
  const trigger = triggers[propType];

  if (trigger) {
    return trigger.method(state);
  }

  return {};
};
