import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { DISPATCH_AFTER_USER_ATTRIBUTES } from '../../reducers/user-attributes/actions';
import { generateFFProps } from '../generators/generate-ff-props';

export const afterUserAttributesGet = ({ store }) => {
  const state = store.getState();

  const params = generateFFProps(state);

  if (!ObjectShim.isEmpty(params)) {
    store.dispatch({ type: DISPATCH_AFTER_USER_ATTRIBUTES, params });
  }
};
