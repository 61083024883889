import { transformBE } from './transform-future-fit';

export const transformBeforeSend = ({ type, prevState, params }) => {
  switch (type) {
    case 'beVM': {
      return transformBE(params, prevState[type]);
    }
    default:
      return params;
  }
};
