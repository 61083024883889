import React from 'react';
import { Column, Grid } from '@packages/ui-kit/core/grid';
import styles from '../../../../styles/widgets/story-step/block-wrapper.module.scss';

export const withStoryBlockWrapper = Component => props =>
  (
    <Grid className={styles.wrapper}>
      <Column>
        <Component {...props} />
      </Column>
    </Grid>
  );
