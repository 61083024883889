import { attachObserverToElement } from './helpers';

const onIntersect = ({ target }) => {
  const data = target.dataset;

  const urlKey = Boolean(data.srcset) ? 'srcset' : 'src';
  const url = data[urlKey];

  const img = new Image();

  img[urlKey] = data[urlKey];

  const clone = target.cloneNode();

  clone.setAttribute(urlKey, url);
  clone.removeAttribute(`data-${urlKey}`);
  clone.removeAttribute('style');

  img.onload = () => {
    target.parentNode.replaceChild(clone, target);
  };
};

export const changeImages = element => {
  const media = [...element.querySelectorAll('.wp-block-image, .wp-block-media-text__media')];

  media.forEach(entry => {
    const target = entry.querySelector('img');

    const urlKey = Boolean(target.srcset) ? 'srcset' : 'src';

    const url = target[urlKey];

    const clone = target.cloneNode();

    clone.setAttribute(`data-${urlKey}`, url);

    // Template image should get available width but the height should be 'auto'.
    // That is guarantee that image would be contained inside a template grid.

    const height = parseInt(clone.getAttribute('height'));

    if (height) {
      clone.style.maxHeight = height + 'px';
    }

    const observer = attachObserverToElement(onIntersect, {
      once: true,
      threshold: 0.1
    });

    observer.observe(clone);

    target.parentNode.replaceChild(clone, target);
  });

  return element;
};
