import { generatePropsPerProperty } from './per-property';
import { generateGeneralProps } from './general';

export const generateMABProps = state => {
  const { property, mortgage } = state.userAttributes;

  const properties = property.items;
  const mortgages = mortgage.items;

  return {
    ...generatePropsPerProperty(properties, mortgages),
    ...generateGeneralProps(properties, mortgages)
  };
};
