export const VISIBILITY_PAGE_TYPES = {
  dashboard: 'dashboard',
  checklist: 'checklist',
  custom: 'custom',
  question: 'question',
  feed: 'feed',
  knowledgebase: 'knowledgebase',
  article: 'article',
  account: 'account',
  blog: 'blog',
  legal: 'legal',
  guidance: 'guidance',
  goals: 'goals',
  video: 'video'
};

export const STYLING_OPTIONS = {
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  option4: 'option4',
  option5: 'option5'
};
