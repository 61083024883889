import uuid from 'uuid';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { compose } from 'redux';
import { isHtmlElement } from '../../layout/element';
import { AspectRatioContainer } from '../../../components/layout/aspect-ratio-container';
import { attachObserverToElement, getElementFromHtmlString } from './helpers';

const parseIFrameProps = element => {
  if (isHtmlElement(element, HTMLIFrameElement)) {
    return {
      src: element.src,
      title: element.title,
      allowFullScreen: element.allowFullscreen
    };
  }

  return {};
};

const renderIFrame = element => {
  const { title = uuid('embedded content'), src, ...props } = parseIFrameProps(element);

  const jsx = <iframe data-src={src} data-lazy title={title} {...props} />;

  return {
    id: title,
    Component: jsx
  };
};

const onIntersect = ({ target }) => {
  target.src = target.dataset.src;
};

const observeIFrames = elements => {
  const targets = elements.querySelectorAll('[data-lazy]');

  targets.forEach(media => {
    const observer = attachObserverToElement(onIntersect, {
      once: true
    });

    observer.observe(media);
  });
};

export const changeEmbeddedMedia = (element, children = element.querySelectorAll('.wp-block-embed')) => {
  children.forEach(media => {
    if (isHtmlElement(media)) {
      const jsx = React.createElement(
        media.tagName.toLowerCase(),
        {
          className: media.classList.value
        },
        [...media.children].map(child => {
          if (child.classList.contains('wp-block-embed__wrapper')) {
            const { id, Component: IFrame } = renderIFrame(child.firstElementChild);

            return <AspectRatioContainer key={id}>{React.cloneElement(IFrame)}</AspectRatioContainer>;
          }

          return React.createElement(child.tagName.toLowerCase(), { key: uuid() }, child.innerHTML);
        })
      );

      const component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      media.parentNode.replaceChild(component, media);
    }
  });

  observeIFrames(element);

  return element;
};
