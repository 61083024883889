export const getLayer = (zIndex, nested) => ({
  zIndex: (nested ? 100 : 1000) + zIndex
});

export const getPosition = ({ nested, placement, offset, nav, safearea }) => {
  if (!nested) {
    return {
      [placement]: offset[placement] ?? 0
    };
  }

  const values = [];

  values.push(offset[placement] + nav[placement] + 'px');

  if (nested) {
    values.push(`var(${safearea[placement]})`);
  }

  return {
    [placement]: `calc(${values.join(' + ')})`
  };
};
