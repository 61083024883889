export const calculateSingleQuestionScore = item => {
  //if there is no score for current question we set null
  if (!item) {
    return null;
  }

  return Object.values(item).reduce((acc, current) => {
    acc += current.checked ? current.value : 0;

    return acc;
  }, 0);
};

export const calculateKPIs = (items, entry) => {
  return items.reduce((acc, { value, name, label }) => {
    if (value > 0) {
      acc.push({
        label: label,
        checked: entry?.[name]?.checked ?? false
      });
    }

    return acc;
  }, []);
};

export const concatGuidance = data => {
  let count = 0;
  const result = [];

  while (count !== 3) {
    const item = data[`guidance${count}`];

    if (item) {
      result.push(item);
    }

    count++;
  }

  return result.join('<br/>');
};

export const getGuidanceInputData = questionInputs =>
  questionInputs.filter(({ type }) => type === 'checkbox' || type === 'radio')?.data ?? {};
