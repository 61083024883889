import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../styles/components/progress-bar.module.scss';

const ProgressBar = React.memo(({ label, value, className }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.filler} style={{ width: `${value}%` }} />
      {label && <p className={styles.label}>{label}</p>}
    </div>
  );
});

ProgressBar.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  className: PropTypes.string
};

export default ProgressBar;
