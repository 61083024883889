import { createReducer } from '../utils';

const STORE_ANIMATION_CACHE = 'STORE_ANIMATION_CACHE';

const initialState = {
  animations: {}
};

export const storeAnimationCache = payload => ({ type: STORE_ANIMATION_CACHE, payload });

export const lottie = createReducer(initialState, {
  [STORE_ANIMATION_CACHE]: (state, action) => ({
    ...state,
    animations: {
      ...state.animations,
      [action.payload.path]: action.payload.data
    }
  })
});
