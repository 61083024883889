import React from 'react';
import classnames from 'classnames';
import { ConfigService } from '../../../services/config-service';
import { insertVarIntoString } from '../../../helpers/insert-var-into-string';
import styles from '../../../styles/components/activity-progress.module.scss';
import { PurifiedHtmlContainer } from '../../layout/purified-html-container';

export const Message = React.memo(({ quantity, className }) => {
  const { message } = ConfigService.get('CHECKLIST.activityProgress', {});

  return (
    <>
      {message && (
        <PurifiedHtmlContainer
          className={classnames(styles.message, className, 'typography', 'typography-extended')}
          html={insertVarIntoString(message, quantity)}
        />
      )}
    </>
  );
});
