import { createAction, createReducer } from '@apps/firsthomecoach/src/store/utils';
import { addUniqueItemByKey, filterItemsByKey, mapRepeatersData } from '../../helpers/repeaters';

const REPEATERS_DATA_REQUEST = 'REPEATERS_DATA_REQUEST';
const REPEATERS_DATA_SUCCESS = 'REPEATERS_DATA_SUCCESS';
const REPEATERS_DATA_FAILURE = 'REPEATERS_DATA_FAILURE';

const REPEATER_DATA_REQUEST = 'REPEATER_DATA_REQUEST';
const REPEATER_DATA_SUCCESS = 'REPEATER_DATA_SUCCESS';
const REPEATER_DATA_FAILURE = 'REPEATER_DATA_FAILURE';

export const fetchRepeatersByKeysParam = params =>
  createAction(
    {
      request: REPEATERS_DATA_REQUEST,
      success: REPEATERS_DATA_SUCCESS,
      failure: REPEATERS_DATA_FAILURE
    },
    api => api.repeaters.getRepeaters
  )(params);

export const fetchRepeaterByKey = params =>
  createAction(
    {
      request: REPEATER_DATA_REQUEST,
      success: REPEATER_DATA_SUCCESS,
      failure: REPEATER_DATA_FAILURE
    },
    api => api.repeaters.getRepeater
  )(params);

const REMOVE_SAVED_REPEATER_ITEM = 'REMOVE_SAVED_REPEATER_ITEM';
export const removeSavedRepeaterItem = payload => ({ type: REMOVE_SAVED_REPEATER_ITEM, payload });

const ADD_SAVED_REPEATER_ITEM = 'ADD_SAVED_REPEATER_ITEM';
export const addSavedRepeaterItem = payload => ({ type: ADD_SAVED_REPEATER_ITEM, payload });

const initialState = {
  list: {},
  loading: true,
  error: false
};

export const repeaters = createReducer(initialState, {
  [REPEATERS_DATA_REQUEST]: state => ({ ...state, loading: true }),
  [REPEATERS_DATA_SUCCESS]: (state, action) => ({
    ...state,
    error: false,
    loading: false,
    list: {
      ...state.list,
      ...mapRepeatersData(action.payload)
    }
  }),
  [REPEATERS_DATA_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [REPEATER_DATA_REQUEST]: state => ({ ...state, loading: true }),
  [REPEATER_DATA_SUCCESS]: (state, action) => ({
    ...state,
    error: false,
    loading: false,
    list: {
      ...state.list,
      ...mapRepeatersData([action.payload])
    }
  }),
  [REPEATER_DATA_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [REMOVE_SAVED_REPEATER_ITEM]: (state, action) => {
    const { filterKey, targetRepeaterKey } = action.payload;

    return {
      ...state,
      list: {
        ...state.list,
        [targetRepeaterKey]: {
          ...state.list[targetRepeaterKey],
          items: filterItemsByKey({ targetItems: state.list[targetRepeaterKey]?.items, filterKey })
        }
      }
    };
  },
  [ADD_SAVED_REPEATER_ITEM]: (state, action) => {
    const { filterKey, targetRepeaterKey, sourceRepeaterKey } = action.payload;

    return {
      ...state,
      list: {
        ...state.list,
        [targetRepeaterKey]: {
          ...state.list[targetRepeaterKey],
          items: addUniqueItemByKey({
            filterKey,
            sourceItems: state.list[sourceRepeaterKey]?.items,
            targetItems: state.list[targetRepeaterKey]?.items
          })
        }
      }
    };
  }
});
