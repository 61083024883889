import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

interface useFocusByHashEffectArguments {
  id: string;
  ref: React.MutableRefObject<HTMLElement>;
}

const useFocusByHashEffect = ({ id, ref }: useFocusByHashEffectArguments): void => {
  const { hash } = useLocation();
  const pureHash = useMemo(() => hash?.split('#')[1], [hash]);

  useEffect(() => {
    if (pureHash === id) {
      ref?.current?.focus();
    }
  }, [id, ref, pureHash]);
};

export default useFocusByHashEffect;
