import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../../styles/components/page-actions-section.module.scss';
import { PageAction, pageActionTypes } from './components/page-action';

//TODO: will be good to pus it as a separate section on custom page - configuration needed

const PageActionsSection = ({ className, actionsList, label }) => (
  <div className={classnames(styles.container, className)}>
    {label ? <p className={styles.label}>{label}</p> : null}
    <div className={styles.buttons}>
      {actionsList.map(({ label, to, type, iconName, isButton, onClick }) => (
        <PageAction key={label} to={to} iconName={iconName} type={type} isButton={isButton} onClick={onClick}>
          {label}
        </PageAction>
      ))}
    </div>
  </div>
);

PageActionsSection.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      isButton: PropTypes.bool,
      onClick: PropTypes.func,
      to: PropTypes.string,
      iconName: PropTypes.string,
      className: PropTypes.string,
      type: PropTypes.oneOf(pageActionTypes)
    })
  ).isRequired,
  label: PropTypes.string,
  className: PropTypes.string
};

PageActionsSection.displayName = 'PageActionsSection';

export { PageActionsSection };
