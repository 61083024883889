export const ATTRIBUTE_TYPES = {
  HPG: 'ff-goals',
  PP: 'ff-positive-pursuits',
  AOG: 'ff-goals'
};

export const ATTRIBUTE_NAMES = {
  ALL_OTHER_GOALS: 'allOtherGoals',
  HIGH_PRIORITY_GOALS: 'highPriorityGoals',
  POSITIVE_PURSUITS: 'positivePursuits'
};

export const HPG_LIST_HEADERS = {
  descriptionColumn: 'Goal name & description',
  scoreColumn: 'Score',
  actionsColumn: 'Actions completed'
};

export const GOALS_SELECTORS = {
  all: 'all',
  high: 'high',
  other: 'other'
};
