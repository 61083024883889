import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isUndefined } from '@packages/helpers/core/common';
import { MAXIMUM_GOAL_SCORE } from '../../templates/guidance';
import { FHC_MODULE_STATUSES } from '../constants';
import { GOALS_SELECTORS } from '../future-fit/constants';

export const DEFAULT_CATEGORY_KEY = 'All';

/**
 * Combines URL params in appropriate sequence.
 * @param {object} options
 * @param {string} options.category
 * @return {string} stringify URL params
 */
export const buildGuidanceParams = ({ category }) => {
  // The case where we don't need to provide a category filter overall.
  if (category === DEFAULT_CATEGORY_KEY) {
    return ``;
  }

  return `categories=${encodeURIComponent(category)}`;
};

/**
 * Filter guidance's depending on currentCategory.
 * @param {object} options
 * @param {array} options.guidanceItems
 * @param {string} options.currentCategory
 * @return {array} list of filtered guidanceItems
 */
export const filterGuidances = ({ guidanceItems, currentCategory }) => {
  if (currentCategory === DEFAULT_CATEGORY_KEY) {
    return guidanceItems;
  }

  return guidanceItems.filter(({ categories }) => categories && categories.includes(currentCategory));
};

/**
 * Get amount of guidance activities with not completed status.
 * @param {array} activities
 * @return {number} amount of non-completed guidance's
 */
export const calculateUncompletedActivities = activities =>
  activities.filter(({ activityStatusKey }) => activityStatusKey === FHC_MODULE_STATUSES.TO_DO).length;

/**
 * Defines whether the
 * @param {object} userAttributes - user attributes (answers on questions etc.)
 * @param {string} userAttribute - the path where the score for specific guidance is stored. Comes from guidance api (getGuidance)
 * @return {boolean}
 */
export const isHighPriorityGoal = ({ userAttributes, userAttribute }) => {
  const isHPG = ObjectShim.getNested(userAttributes, userAttribute);

  return !isUndefined(isHPG);
};

export const getHPGFromGuidances = ({ guidances, userAttributes }) =>
  guidances.filter(({ userAttribute }) => isHighPriorityGoal({ userAttributes, userAttribute }));

export const getOtherGoalsFromGuidances = ({ guidances, userAttributes }) =>
  guidances.filter(({ userAttribute }) => !isHighPriorityGoal({ userAttributes, userAttribute }));

/**
 * Gets action status label depending on whether the guidance is external or not.
 * @param {boolean} checked
 * @param {boolean} isExternal
 * @param {object} actionLabels - set in configuration GUIDANCE.actionLabels
 * @param {string} actionLabels.complete
 * @param {string} actionLabels.completeExternal
 * @param {string} actionLabels.incomplete
 * @param {string} actionLabels.incompleteExternal
 * @return {string} appropriate label
 */
export const getActionStatusLabel = ({ checked, isExternal, actionLabels }) => {
  const { complete, completeExternal, incomplete, incompleteExternal } = actionLabels;

  if (isExternal) {
    return checked ? completeExternal : incompleteExternal;
  }

  return checked ? complete : incomplete;
};

export const getBadgeState = ({ uncompletedActivitiesQuantity, activities, score }) => {
  if (isUndefined(score) || score === MAXIMUM_GOAL_SCORE) {
    return { badgeValue: '-', isCompleted: true };
  }

  return {
    badgeValue: `${activities.length - uncompletedActivitiesQuantity}/${activities.length}`,
    isCompleted: false
  };
};

export const selectorFunction = {
  [GOALS_SELECTORS.all]: (guidances, userAttributes) => guidances,
  [GOALS_SELECTORS.high]: (guidances, userAttributes) => getHPGFromGuidances({ guidances, userAttributes }),
  [GOALS_SELECTORS.other]: (guidances, userAttributes) => getOtherGoalsFromGuidances({ guidances, userAttributes })
};

/**
 * Returns goals list depending on goalsSelector.
 * @param {object} guidances - list of guidances from store
 * @param {object} userAttributes - user attributes (answers on questions etc.)
 * @param {string} goalsSelector - selector for goals list (all, high, other), set in input configuration in admin1
 * @return {array} list of goals
 */
export const getGoalsListBySelector = ({ guidances, userAttributes, goalsSelector = 'all' }) => {
  const selectorMethod = selectorFunction[goalsSelector] ?? selectorFunction[GOALS_SELECTORS.all];

  return selectorMethod(guidances, userAttributes);
};

const emptyItem = {
  id: 0,
  label: '',
  value: ''
};

export const mapGoalsListToSelectItems = goalsList => {
  const mappedList = goalsList.map(({ id, heading, slug }) => ({
    id: id,
    label: heading,
    value: { key: slug, title: heading },
    name: slug
  }));

  return [emptyItem, ...mappedList];
};
