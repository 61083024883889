import { isNil } from '@packages/helpers/core/common';
import {
  toMoneyStringWithComma,
  fromMoneyStringWithCommaToFloat,
  fromMoneyStringWithPercentage
} from '../formatters/money-format';
import { toNumberFormat } from '../formatters/number-format';

export const formatDisplayValue = (value, format) => {
  if (!isNil(value)) {
    switch (format) {
      case 'currency':
        return toMoneyStringWithComma(value);
      case 'percentage':
        return value;
      case 'upper':
        return value.toUpperCase();
      case 'number':
        return toNumberFormat(value);
      default:
        break;
    }
  }

  return value ?? '';
};

export const formatAfterChangeValue = (value, format) => {
  if (!isNil(value)) {
    switch (format) {
      case 'currency':
        return fromMoneyStringWithCommaToFloat(value);
      case 'percentage':
        return fromMoneyStringWithPercentage(value);
      case 'upper':
        return value.toUpperCase();
      case 'number':
        return toNumberFormat(value);
      default:
        break;
    }
  }

  if (!isNaN(value)) {
    return parseFloat(value);
  }

  return value;
};

export const generateError = errors => {
  if (errors && errors[0]) {
    return errors[0].message.includes('Must be') ? (errors[1] ? errors[1].message : null) : errors[0].message;
  }

  return null;
};
