import { setPointsDetails } from '../../helpers/reducers/points';
import { createAction, createReducer } from '../utils';
import { SEND_STATUS_DATA_SUCCESS } from './status-data';
import { DELETE_ACCOUNT_SUCCESS, LOG_OUT_SUCCESS } from './account/action-types';
import { SEND_CONTENT_STATUS_SUCCESS } from './content-interaction';

const GET_POINTS_REQUEST = 'GET_POINTS_REQUEST';
const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
const GET_POINTS_FAILURE = 'GET_POINTS_FAILURE';

const GET_REWARDS_CARD_REQUEST = 'GET_REWARDS_CARD_REQUEST';
const GET_REWARDS_CARD_SUCCESS = 'GET_REWARDS_CARD_SUCCESS';
const GET_REWARDS_CARD_FAILURE = 'GET_REWARDS_CARD_FAILURE';

const GET_REWARD_PAGE_REQUEST = 'GET_REWARD_PAGE_REQUEST';
const GET_REWARD_PAGE_SUCCESS = 'GET_REWARD_PAGE_SUCCESS';
const GET_REWARD_PAGE_FAILURE = 'GET_REWARD_PAGE_FAILURE';

export const getPoints = () =>
  createAction(
    {
      request: GET_POINTS_REQUEST,
      success: GET_POINTS_SUCCESS,
      failure: GET_POINTS_FAILURE
    },
    api => api.points.getPoints
  )();

export const getRewardCards = () =>
  createAction(
    {
      request: GET_REWARDS_CARD_REQUEST,
      success: GET_REWARDS_CARD_SUCCESS,
      failure: GET_REWARDS_CARD_FAILURE
    },
    api => api.content.getRewardCards
  )();

export const getRewardPage = slug =>
  createAction(
    {
      request: GET_REWARD_PAGE_REQUEST,
      success: GET_REWARD_PAGE_SUCCESS,
      failure: GET_REWARD_PAGE_FAILURE
    },
    api => api.content.getRewardPage
  )(slug);

const initialState = {
  pointsDetails: {
    details: [],
    userTotal: 0
  },
  loading: true,
  error: true,
  rewardCards: [],
  loadingCards: true,
  rewardPages: {},
  loadingRewardPage: true
};

export const points = createReducer(initialState, {
  [SEND_STATUS_DATA_SUCCESS]: setPointsDetails,
  [SEND_CONTENT_STATUS_SUCCESS]: setPointsDetails,
  [GET_POINTS_REQUEST]: state => ({ ...state, loading: true }),
  [GET_POINTS_SUCCESS]: (state, { payload }) => ({ ...state, pointsDetails: payload, loading: false }),
  [GET_POINTS_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_REWARDS_CARD_REQUEST]: state => ({ ...state, loadingCards: true }),
  [GET_REWARDS_CARD_SUCCESS]: (state, { payload }) => ({ ...state, rewardCards: payload, loadingCards: false }),
  [GET_REWARDS_CARD_FAILURE]: state => ({ ...state, loadingCards: false, error: true }),
  [GET_REWARD_PAGE_REQUEST]: state => ({ ...state, loadingRewardPage: true }),
  [GET_REWARD_PAGE_SUCCESS]: (state, { payload, params }) => ({
    ...state,
    rewardPages: { ...state.rewardPages, [params]: payload },
    loadingRewardPage: false
  }),
  [GET_REWARD_PAGE_FAILURE]: state => ({ ...state, loadingRewardPage: false, error: true }),
  [DELETE_ACCOUNT_SUCCESS]: state => ({
    ...initialState,
    rewardCards: state.rewardCards,
    rewardPages: state.rewardPages
  }),
  [LOG_OUT_SUCCESS]: () => initialState
});
